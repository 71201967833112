import React, { useEffect, useState } from 'react';
import Typography from '../../../FieldLabel';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { format } from 'date-fns';

const CurrentTime = ({ classes }) => {
  const getTimeFormatHook = useTimeSettingsFormat();
  const formatForTimeParsSec = getTimeFormatHook().formatForTimeParsSec;
  const getTime = () => {
    return format(new Date(), formatForTimeParsSec);
  };

  const [time, setTime] = useState(getTime());

  useEffect(() => {
    const timer = setInterval(() => setTime(getTime()), 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <Typography variant="caption" className={classes.time}>
      {time}
    </Typography>
  );
};

export default CurrentTime;
