import React, { useState, useEffect, useLayoutEffect } from 'react';
import propTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import { format } from 'date-fns';

import SortingAndFilteringPanel from './components/SortingAndFilteringPanel';
import FiltersBar from '../../../../../../components/FiltersBar';
import PageLoader from '../../../../../../components/PageLoader';
import EditTimeoffForm from '../../../../../../components/EditTimeoffForm';
import EditSeveralTimeoffsForm from '../../../../../../components/EditTimeoffForm/EditSeveralTimeoffsForm';
import AlertModal from '../../../../../../components/AlertModal';
import CalendarTable from '../CalendarTable';
import AssignModal from '../AssignModal';
import { getCurrentMonthDates, splitArrayToArraysOfRow } from 'helpers/_helpers';
import { drawerTypes } from '../../../../../Equipment/components/helpers/drawerTypes';
import { getTimeoffs } from 'store/availability/availabilityPagesOperations';
import { useDispatch } from 'react-redux';
import { useWeekStart } from 'hooks/useWeekStart';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { globalBEDateFormat } from 'common/dateFormatConfig';

export const defaultPeopleCalendarFilters = {
  reason: 'All',
  isPTO: 'All',
  userShift: 'All',
  userRole: [
    'Admin',
    'Field Technician',
    'Dispatcher',
    'Accounting',
    'Fleet Maintenance',
    'Project Management',
    'Machine Shop',
  ],
  type: 'All',
};

const PeopleCalendar = ({ selectedDate, ...props }) => {
  const { dateFormat } = useDateSettingsFormat();

  const dispatch = useDispatch();
  const { weekDayFromO } = useWeekStart();
  const [drawer, setDrawer] = useState({
    type: drawerTypes.filters,
    isOpen: false,
    timeoff: {},
  });
  const [userEvents, setUserEvents] = useState([]);
  const [modal, setModal] = useState({
    isOpen: false,
    timeoff: {},
  });
  const [assignModal, setAssignModal] = useState({
    isOpen: false,
    type: 'people',
    eventInfo: {},
  });
  const [infoModal, setInfoModal] = useState({
    isOpen: false,
    info: [],
  });
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState(defaultPeopleCalendarFilters);

  useEffect(() => {
    updateVisibleItems(null, searchValue);
  }, [format(selectedDate, dateFormat)]);

  useLayoutEffect(() => {
    const field = ((pto) => ({ All: 'inDays', true: 'inWorkDays', false: 'inWeekendDays' }[pto]))(
      String(filter.isPTO)
    );
    const mappedEvents = getCurrentMonthDates(selectedDate, props.weekStart, globalBEDateFormat);
    mappedEvents.forEach((dateObj, i) => {
      props.userEvents.forEach((event) => {
        if ((event[field] || []).some((el) => el === dateObj.dayNumber)) {
          mappedEvents[i].events.push(event);
        }
      });
    });

    const arrayOfRow = splitArrayToArraysOfRow(mappedEvents);

    closeDrawer();
    setUserEvents(arrayOfRow);
  }, [props.userEvents]);

  const setLoading = (value) => setIsLoading(value);

  const updateSearchValue = (value) => {
    setSearchValue(value);
    updateVisibleItems(filter, value);
  };

  const updateFilter = (filter) => {
    setFilter(filter);
    updateVisibleItems(filter, searchValue);
    closeDrawer();
  };

  const updateVisibleItems = async (propFilter, searchValue) => {
    const { isPTO, ...filterData } = filter;
    const usedFilter = propFilter || filter;

    await dispatch(getTimeoffs(usedFilter, searchValue, weekDayFromO)).catch((err) =>
      openSnackbar('error', err.message)
    );
  };

  const openDrawer =
    (type, timeoff = {}) =>
    (e) => {
      if (e) e.stopPropagation();
      setModal((prev) => ({ ...prev, isOpen: false }));
      setDrawer({ type, timeoff, isOpen: true });
    };

  const closeDrawer = () => {
    setDrawer((prev) => ({ ...prev, isOpen: false }));
  };

  const openModal = (timeoff) => {
    setModal({ timeoff, isOpen: true });
  };

  const openAssignModal = (type, eventInfo) => () => {
    setAssignModal({ isOpen: true, type, eventInfo });
  };

  const closeAssignModal = () => {
    setAssignModal((prev) => ({ ...prev, isOpen: false }));
  };

  const openInfoModal = (info) => {
    setInfoModal({ isOpen: true, info });
    closeAssignModal();
  };

  const closeInfoModal = () => {
    setInfoModal((prev) => ({ ...prev, isOpen: false }));
  };

  const onCloseRemove = () => {
    updateVisibleItems();
    closeDrawer();
  };

  const { openSnackbar, holidays } = props;

  return (
    <>
      <FiltersBar
        openDrawer={openDrawer}
        updateSearchValue={updateSearchValue}
        isDynamicSearch={true}
      />
      {/* <PageLoader loading={isLoading}> */}
      <CalendarTable
        userEvents={userEvents}
        selectedDate={selectedDate}
        openDrawer={openDrawer}
        openSnackbar={openSnackbar}
        openModal={openModal}
        openAssignModal={openAssignModal}
        holidays={holidays}
        weekStart={props.weekStart}
      />
      {/* </PageLoader> */}

      <Drawer anchor="right" open={drawer.isOpen}>
        {drawer.type === drawerTypes.filters && (
          <SortingAndFilteringPanel
            closeDrawer={closeDrawer}
            filter={filter}
            updateFilter={updateFilter}
            isLoading={isLoading}
          />
        )}
        {drawer.type !== drawerTypes.filters && !drawer.timeoff.oldTimeOffId && (
          <EditTimeoffForm
            closeEdit={closeDrawer}
            closeRemove={onCloseRemove}
            closeDrawer={closeDrawer}
            openSnackbar={openSnackbar}
            timeoff={drawer.timeoff}
            isAdminPage={true}
            isCalendar={true}
          />
        )}
        {drawer.type !== drawerTypes.filters && drawer.timeoff.oldTimeOffId && (
          <EditSeveralTimeoffsForm
            closeDrawer={closeDrawer}
            openSnackbar={openSnackbar}
            data={drawer.timeoff.data}
            oldTimeoffId={drawer.timeoff.oldTimeOffId}
          />
        )}
      </Drawer>

      {assignModal.isOpen && (
        <AssignModal
          type={assignModal.type}
          events={userEvents}
          defaultEventInfo={assignModal.eventInfo}
          onClose={closeAssignModal}
          openSnackbar={openSnackbar}
          openInfoModal={openInfoModal}
          holidays={holidays}
        />
      )}

      {infoModal.isOpen && (
        <AlertModal isOpen={infoModal.isOpen} info={infoModal.info} onClose={closeInfoModal} />
      )}
    </>
  );
};

PeopleCalendar.propTypes = {
  openSnackbar: propTypes.func.isRequired,
  selectedDate: propTypes.object.isRequired,
  userEvents: propTypes.array.isRequired,
  holidays: propTypes.array.isRequired,
};

export default PeopleCalendar;
