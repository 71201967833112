import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'

import kioskPageActions from 'store/kiosk/kioskPageActions';
import { getCompany } from 'store/kiosk/kioskPageOperations';
import Box from '../../components/Field';
import TopBar from './components/TopBar';
import Kiosk from '../../components/Kiosk';
import StillHereModal from './components/StillHereModal';
import { useKioskUser } from './hooks/useKioskUser';
import { IsHereListener } from '../../components/common';
import { useKioskEvents } from './hooks/useKioskEvents';
import './styles.scss';
import { getTimeZone } from 'helpers/getTimeZone';

export const KioskPage = () => {
  const [userList, setUserList] = useState([]);
  const { user, authorize, logout, error: authError, clearError } = useKioskUser();
  const [modalShown, setModalShown] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useKioskEvents(setUserList);

  const logoutFromStore = () => {
    dispatch(kioskPageActions.logout())
  };

  const closeModal = () => setModalShown(false);

  const handleNotHere = () => {
    closeModal();
    logoutFromStore();
    logout();
  };

  useEffect(() => {
    logoutFromStore();

    const apiCall = async () => {
      try {
        const options = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          headers: {
            'Content-Type': 'application/json',
            'x-admin-kiosk-token': localStorage.getItem('x-admin-kiosk-token'),
            'X-User-TZ': getTimeZone(),
          },
        };
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users?isKiosk=true`, options).then((res) => res.json());
        setUserList(response);
      } catch (e) {
        console.log(e);
      }
    };
    apiCall();
    dispatch(getCompany())
    const userId = localStorage.getItem('userId');
    if (!userId) {
      navigate('/signin')
    }
  }, []);

  return (
    <Box display="flex" flexDirection="column" className="kioskModeWrapper">
      <TopBar />
      <Box
        className="page-width fullTab"
        style={{ paddingTop: 26, width: '100%', boxSizing: 'content-box' }}
      >
        <Box style={{ padding: '0 15px', boxSizing: 'border-box', maxWidth: '1280px', margin: '0 auto' }}>
          <Kiosk
            logoutFromStore={logoutFromStore}
            authError={authError}
            clearAuthError={clearError}
            selectedUser={user}
            userList={userList}
            authorize={authorize}
            logout={logout}
          />
        </Box>
      </Box>
      {user && (
        <IsHereListener
          condition={!!user}
          wait={180}
          visible={modalShown}
          setVisible={setModalShown}
        >
          <StillHereModal onSubmit={closeModal} onCancel={handleNotHere} />
        </IsHereListener>
      )}
    </Box>
  );
};
