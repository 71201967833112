import { useSelector } from 'react-redux';
import { TIME_FORMAT, getTimeFormat } from 'common/dateFormatConfig';
import { allDatesFormat } from './dateFormatConfig';

/**
 * 
 * @param {{ dateFormat: string, timeFormat: string }} fallbackFormats. Time format = '12hour' | '24hour'
 * @returns 
 */
export const useTimeSettingsFormat = (fallbackFormats) => {
  const dateFormat = useSelector(
    (store) => store.personalProfile.organization?.settings?.dateFormat || fallbackFormats?.dateFormat || allDatesFormat[0]
  );

  const timeFormat = useSelector(
    (store) => store.personalProfile.organization?.settings?.timeFormat || fallbackFormats?.timeFormat || TIME_FORMAT[0]
  );

  const getTimeFormatHook = (hoursObj) => {
    return getTimeFormat(hoursObj, timeFormat, dateFormat);
  };

  return getTimeFormatHook;
};
