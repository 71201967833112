import React, { useCallback, useMemo, useState, useEffect } from 'react';
import Fuse from 'fuse.js';
import FiltersBar from '../FiltersBar';
import MaterialFilters from './components/MaterialFiltersPanel/MaterialFilters';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import MaterialsCard from './components/MaterialForm/components/MaterialCard/MaterialsCard';
import {
  fetchMaterials,
  fetchDeleteMaterial,
  fetchDublicateMaterial,
  fetchGlassBeads,
} from 'store/supplies/suppliesOperations';
import materialActions from 'store/supplies/suppliesActions';

import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import PageLoader from '../../../../components/PageLoader';

const Materials = ({ onSelect, setSnackbar }) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [drawer, setDrawer] = useState(null);
  const [deletingMaterial, setDeletingMaterial] = useState(null);
  const [fuse, setFuse] = useState(null);

  const searchOptions = {
    shouldSort: true,
    keys: ['name'],
  };

  const loading = useSelector(
    (state) => state.supplies && state.supplies.materials && state.supplies.materials.loading
  );
  const materialsSnackbar = useSelector((state) => state.supplies.materials.snackBarInfo);
  const dataMaterials = useSelector(
    (state) => state.supplies && state.supplies.materials && state.supplies.materials.data
  );
  useMemo(() => {
    setFuse(new Fuse(dataMaterials, searchOptions));
  }, [dataMaterials]);

  const [filter, setFilter] = useState({
    unitType: ['All'],
    markingType: ['All'],
    sortBy: 'name',
    sortOrder: 'asc',
  });

  useEffect(() => {
    setSnackbar(materialsSnackbar);
  }, [materialsSnackbar]);

  const onFilterCancel = useCallback(() => {
    setFilter({
      unitType: ['All'],
      markingType: ['All'],
      sortBy: 'name',
      sortOrder: 'asc',
    });
    dispatch(fetchMaterials(null, false, false));
    closeDrawer();
  }, []);

  const onUpdateFilter = useCallback((values) => {
    setFilter(values);
    dispatch(fetchMaterials(values, false, false));
    closeDrawer();
  }, []);

  const onDeletingConfirmed = useCallback(async () => {
    if (!deletingMaterial) {
      console.warn('Deleting material is empty');
      return;
    }

    await dispatch(fetchDeleteMaterial(deletingMaterial._id));
    setSnackbar({
      text: 'Successfully Deleted!',
      type: 'success',
    });
    setDeletingMaterial(null);
  }, [deletingMaterial]);

  const onDelete = useCallback((material) => {
    setDeletingMaterial(material);
  }, []);

  const searchMaterialData = fuse && searchValue ? fuse.search(searchValue) : dataMaterials;

  const onDublicate = (id) => {
    dispatch(fetchDublicateMaterial(id, searchMaterialData));
  };

  const openDrawer = useCallback(
    (type) => () => {
      setDrawer({ isOpen: true, type });
    },
    []
  );
  const closeDrawer = useCallback(() => {
    setDrawer({ ...drawer, isOpen: false });
  }, [drawer]);

  useEffect(() => {
    dispatch(fetchMaterials(null, false, false));
    dispatch(fetchGlassBeads());
  }, []);

  return loading ? (
    <PageLoader loading>
      <div style={{ height: 100 }} />
    </PageLoader>
  ) : (
    <>
      <FiltersBar openDrawer={openDrawer} updateSearchValue={setSearchValue} isDynamicSearch={true}/>
      <Grid container spacing={2}>
        {searchMaterialData &&
          searchMaterialData.map((m) => (
            <Grid item xs={12} md={6} lg={3} key={m._id}>
              <MaterialsCard
                data={m}
                onDelete={onDelete}
                onClick={() => onSelect(m)}
                onDublicate={onDublicate}
              />
            </Grid>
          ))}
      </Grid>
      {drawer && (
        <Drawer anchor="right" open={drawer.isOpen}>
          <MaterialFilters
            materials={dataMaterials}
            closeDrawer={closeDrawer}
            onCancel={onFilterCancel}
            filter={filter}
            onUpdate={onUpdateFilter}
          />
        </Drawer>
      )}
      {deletingMaterial && (
        <ConfirmDialog
          isOpen={!!deletingMaterial}
          onClose={() => setDeletingMaterial(null)}
          onSubmit={onDeletingConfirmed}
          text="Are you sure you want to delete this material?"
          cancelBtn={'Cancel'}
          confirmBtn={'Delete'}
          disableEscape
          loadingOnSubmit
        />
      )}
    </>
  );
};
export default Materials;
