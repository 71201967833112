import React from 'react';
import './styles.scss';
import AccountingRole from './components/AccountingRole/AccountingRole';
import DispatcherRole from './components/DispatcherRole/DispatcherRole';
import FieldTechRole from './components/FieldTechRole/FieldTechRole';
import FleetMaintenanceRole from './components/FleetMaintenanceRole/FleetMaintenanceRole';
import ProjectManagementRole from './components/ProjectManagementRole/ProjectManagementRole';
import AdminRoleWrapper from './components/AdminRoleWrapper/AdminRoleWrapper';

function SpecialRoleInfo({ user, data }) {
  const userRole = user.profile.role.roleName;
  
  return (
    <div className="special-role-block">
      {(userRole === 'Admin' || userRole === 'Super Admin') && <AdminRoleWrapper />}
      {userRole === 'Field Technician' && <FieldTechRole data={data} />}
      {userRole === 'Fleet Maintenance' && <FleetMaintenanceRole data={data} />}
      {userRole === 'Accounting' && <AccountingRole data={data} />}
      {userRole === 'Dispatcher' && <DispatcherRole data={data} />}
      {userRole === 'Project Management' && <ProjectManagementRole data={data} />}
    </div>
  );
}

export default SpecialRoleInfo;
