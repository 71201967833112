import React, { useState } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import ContractorsPage from './components/ContractorsPage';
import ContractorForm from './components/ContractorsPage/components/ContractorForm';
import styles from './styles';
import { drawerTypes } from '../Equipment/components/helpers/drawerTypes';
import ContractorsHeader from './components/ContractorsHeader/ContractorsHeader';

const Contractors = ({ classes, openSnackbar }) => {
  const [drawer, setDrawer] = useState({
    type: drawerTypes.create,
    isOpen: false,
  });

  const openDrawer = (type, open) => () => {
    setDrawer({ type, isOpen: open });
  };

  const closeDrawer = () => {
    setDrawer((prev) => ({ ...prev.drawer, isOpen: false }));
  };
  return (
    <div className={classes.root}>
      <ContractorsHeader openDrawer={openDrawer} type={'Contractors'} />
      <ContractorsPage openSnackbar={openSnackbar} classes={classes} />
      <Drawer
        anchor="right"
        open={drawer.isOpen}
        classes={{
          paper: classes.drawer,
        }}
      >
        <ContractorForm closeDrawer={closeDrawer} openSnackbar={openSnackbar} type={drawer.type} />
      </Drawer>
    </div>
  );
};

Contractors.propTypes = {
  classes: propTypes.object.isRequired,
  openSnackbar: propTypes.func.isRequired,
};

export default withStyles(styles)(Contractors);
