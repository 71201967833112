import { createReducer } from '@reduxjs/toolkit';
import kioskPageActions from './kioskPageActions';
import { rolePermissions } from 'common/permissions';

const initialState = {
  authorizedUser: null,
  summary: null,
  clockIn: null,
  pto: null,
  alert: {
    type: null,
    text: null,
  },
  error: null,
  company: null,
  fullUserPermissions: null,
};

const kiosk = createReducer(initialState, {
  [kioskPageActions.setSummary]: (state, { payload }) => ({ ...state, summary: payload }),
  [kioskPageActions.setError]: (state, { payload }) => ({ ...state, error: payload }),
  [kioskPageActions.setClockInStatus]: (state, { payload }) => ({ ...state, clockIn: payload }),
  [kioskPageActions.login]: (state, { payload }) => ({ ...state, authorizedUser: payload }),
  [kioskPageActions.logout]: (state) => ({
    ...state,
    authorizedUser: null,
    summary: null,
    pto: null,
    clockIn: null,
    error: null,
    company: null, // not sure that it should be here, but let's try
  }),
  [kioskPageActions.setAlert]: (state, { payload }) => ({ ...state, alert: payload }),
  [kioskPageActions.setPto]: (state, { payload }) => ({ ...state, pto: payload }),
  [kioskPageActions.setCompany]: (state, { payload }) => ({ ...state, company: payload }),
  [kioskPageActions.setSpecialProfilePermissions]: (state,  { payload }) => {
    const userRole = payload?.profile?.role;

    const permissionsList = {
      ...rolePermissions[userRole.roleName],
      ...userRole.extPermissions,
    };

    return {
      ...state,
      fullUserPermissions: permissionsList,
    };
  },
  [kioskPageActions.clearPersonalProfile]: (state, _) => {
    return {
      ...state,
      fullUserPermissions: null,
    };
  },
});

export default kiosk;
