import { convertTo24HourFormat } from 'common/dateFormatConfig';
export const assemblyPatchRequestData = (values = {}, settings) => {
  const result = {
    defaultDayTime: {
      hour:
        values.defaultDayTime &&
        convertTo24HourFormat(values.defaultDayTime.hour, values.defaultDayTime.amPm),
      minute: values.defaultDayTime && values.defaultDayTime.minute,
    },
    defaultNightTime: {
      hour:
        values.defaultNightTime &&
        convertTo24HourFormat(values.defaultNightTime.hour, values.defaultNightTime.amPm),
      minute: values.defaultNightTime && values.defaultNightTime.minute,
    },
    headquartersLocation: (values.hqLocation || '').trim(),
    forceAccountAdminEmail: (values.forceAccountAdminEmail || [])
      .filter((email) => !!email)
      .map((email) => email.trim()),
    emailReceivers: (values.emailReceivers || []).filter(
      (receiverObject) => !!receiverObject.email
    ),
    lateClockInEmails: (values.lateClockInEmails || [])
      .filter((email) => !!email)
      .map((email) => email.trim()),
    allowedTimeoff: Number.parseInt(values.allowedTimeoff || 0),
    PTOAdminEmail: (values.PTOAdminEmail || [])
      .filter((email) => !!email)
      .map((email) => email.trim()),
    payrollAdminEmail: (values.payrollAdminEmail || [])
      .filter((email) => !!email)
      .map((email) => email.trim()),
    homepage: {
      quote: values.homePageQuote,
      quoteColor: values.homePageQuoteColor,
      countdownDate: values.countdownDate,
      countdownTitle: (values.countdownTitle || '').trim(),
      countdownEnabled: values.countdownEnabled,
    },
    weekStart: values?.weekStart,
    injuryReportEmails: (values?.injuryReportEmails || [])
      .filter((email) => !!email)
      .map((email) => email.trim()),
    injuryReport: values?.injuryReport,
    miscStipend: values?.miscStipend,
  };

  if (settings?.generateDocusignCertificates) {
    result.docuSignSigner = values?.docuSignSigner || { name: '', email: '' };
  }

  return result;
};
