import worklogsPageActions from './worklogsPageActions';
import $api from 'http/index';
import { getTimeZone } from 'helpers/getTimeZone';
import { format, parse } from 'date-fns';

const defaultDate = [
  new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
  new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
];

export const WORKLOG_LIMIT = 40;

export function sortAndMap(array, backsort, shouldBeToday, dateFormat) {
  const map = array.reduce((res, curr) => {
    if (!curr) return res;

    const date = curr?.createdAt
      ? format(new Date(curr.createdAt), dateFormat)
      : format(new Date(curr.startedAt), dateFormat);

    if (!res[date]) res[date] = [curr];
    else res[date].push(curr);

    return res;
  }, {});

  if (shouldBeToday) {
    const today = format(new Date(), dateFormat);
    if (!map[today]) {
      map[today] = [null];
    }
  }
  if (backsort === 'reverse') {
    return Object.entries(map).sort(
      (a, b) =>
        parse(b[0], dateFormat, new Date()).getTime() -
        parse(a[0], dateFormat, new Date()).getTime()
    );
  }

  return Object.entries(map).sort(
    (a, b) =>
      parse(a[0], dateFormat, new Date()).getTime() - parse(b[0], dateFormat, new Date()).getTime()
  );
}
export const sortAndMapSubmitted = (array, dateFormat) => {
  const map = array.reduce((res, curr) => {
    if (!curr) return res;

    const date = curr?.hours?.start
      ? format(new Date(curr.hours.start), dateFormat)
      : format(new Date(curr.createdAt), dateFormat);

    if (!res[date]) res[date] = [curr];
    else res[date].push(curr);

    return res;
  }, {});

  return Object.entries(map).sort(
    (a, b) =>
      parse(b[0], dateFormat, new Date()).getTime() - parse(a[0], dateFormat, new Date()).getTime()
  );
};

export const getActiveWorklogs = (payload) => async (dispatch, getState) => {
  const {
    searchValue,
    filter = {
      dates: defaultDate,
    },
    page,
    userId,
    limit = WORKLOG_LIMIT,
    isClear,
    skip = !page || isClear ? '0' : page * WORKLOG_LIMIT,
    isTodayMode,
    shouldBeToday,
  } = payload;
  const dateFormat = getState().personalProfile.organization?.settings?.dateFormat;

  const from = filter.dates[0];
  const to = filter.dates[1];

  const optionalParams = {
    searchValue,
    ...(filter && filter),
    userId,
    limit,
    skip,
    from,
    to,
    dates: null,
  };

  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'X-User-TZ': getTimeZone(),
    },
    params: optionalParams,
  };

  try {
    dispatch(worklogsPageActions.setWorklogLoading());

    const res = await $api.get(`${process.env.REACT_APP_BASE_URL}/sheets`, options);

    const sheetIds = res.data.map((e) => e._id);
    dispatch(
      worklogsPageActions.setActiveWorklogs({
        ...{
          data: res.data,
          sheetIds: sheetIds,
          hasMore: isTodayMode || res.data.length === limit,
          clear: isClear || !page,
          shouldBeToday,
          dateFormat,
        },
      })
    );
  } catch (e) {
    console.log(e);
    dispatch(worklogsPageActions.setError(e));
  }
};

// actually returns worklogs where each have single submission entry
export const getSubmittedWorklogs = (payload) => async (dispatch, getState) => {
  const {
    searchValue,
    filter = {
      dates: defaultDate,
    },
    page,
    userId,
    limit = 40,
    skip = !page ? '0' : page * 40,
    isClear,
  } = payload;

  const from = filter.dates[0];
  const to = filter.dates[1];

  const optionalParams = {
    searchValue,
    ...(filter && filter),
    userId,
    limit,
    skip,
    from,
    to,
    dates: null,
    separateBySubmission: 'true',
  };
  const dateFormat = getState().personalProfile.organization?.settings?.dateFormat;

  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'X-User-TZ': getTimeZone(),
    },
    params: optionalParams,
  };

  try {
    dispatch(worklogsPageActions.setWorklogLoading());

    const res = await $api.get(`${process.env.REACT_APP_BASE_URL}/sheets`, options);

    const sheetIds = res.data.map((e) => e._id);

    dispatch(
      worklogsPageActions.setSubmittedWorklogs({
        ...{
          data: res.data,
          sheetIds: sheetIds,
          hasMore: res.data.length === limit,
          clear: isClear || !page,
          dateFormat,
        },
      })
    );
  } catch (e) {
    console.log(e);
    dispatch(worklogsPageActions.setError(e));
  }
};

export const getForemanDataWorklogs = (payload) => async (dispatch, getState) => {
  const {
    searchValue,
    filter = {
      dates: defaultDate,
    },
    page,
    userId,
    limit = 40,
    skip = !page ? '0' : page * 40,
    isClear,
  } = payload;

  const from = filter.dates[0];
  const to = filter.dates[1];

  const optionalParams = {
    searchValue,
    ...(filter && filter),
    userId,
    limit,
    skip,
    from,
    to,
    isCrewLeader: 'true',

    dates: null,
  };
  const dateFormat = getState().personalProfile.organization?.settings?.dateFormat;

  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'X-User-TZ': getTimeZone(),
    },
    params: optionalParams,
  };

  try {
    dispatch(worklogsPageActions.setWorklogLoading());

    const res = await $api.get(`${process.env.REACT_APP_BASE_URL}/sheets`, options);

    const sheetIds = res.data.map((e) => e._id);

    dispatch(
      worklogsPageActions.setForemanDataWorklogs({
        ...{
          data: res.data,
          sheetIds: sheetIds,
          hasMore: res.data.length === limit,
          clear: isClear || !page,
          dateFormat,
        },
      })
    );
  } catch (e) {
    console.log(e);
    dispatch(worklogsPageActions.setError(e));
  }
};

export const postNewWorklogSheet = async (data) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'X-User-TZ': getTimeZone(),
    },
  };

  await $api.post(`${process.env.REACT_APP_BASE_URL}/sheets`, data, options);
};

export const fetchDeleteActiveSheet = (_id) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'X-User-TZ': getTimeZone(),
    },
  };

  try {
    dispatch(worklogsPageActions.setWorklogLoading());
    const res = await $api.delete(`${process.env.REACT_APP_BASE_URL}/sheets/${_id}`, options);
    dispatch(worklogsPageActions.setDeletedActiveSheet(_id));
  } catch (e) {
    console.log(e);
    dispatch(worklogsPageActions.setError(e));
  }
};

export const fetchDeleteCrewSheet = (_id) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'X-User-TZ': getTimeZone(),
    },
  };

  try {
    dispatch(worklogsPageActions.setWorklogLoading());
    const res = await $api.delete(`${process.env.REACT_APP_BASE_URL}/sheets/${_id}`, options);
    dispatch(worklogsPageActions.setDeletedCrewSheet(_id));
  } catch (e) {
    console.log(e);
    dispatch(worklogsPageActions.setError(e));
  }
};

export const fetchDeleteSubmittedSheet = (_id) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'X-User-TZ': getTimeZone(),
    },
  };

  try {
    dispatch(worklogsPageActions.setWorklogLoading());
    const res = await $api.delete(`${process.env.REACT_APP_BASE_URL}/submissions/${_id}`, options);
    dispatch(worklogsPageActions.setDeletedSubmittedSheet(_id));
  } catch (e) {
    console.log(e);
    dispatch(worklogsPageActions.setError(e));
  }
};

export const setChangeArchivedActiveState = (_id, archived, openSnackbar) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'X-User-TZ': getTimeZone(),
    },
  };

  const data = {
    archived,
  };

  try {
    const res = await $api.patch(
      `${process.env.REACT_APP_BASE_URL}/sheets/${_id}/archive`,
      data,
      options
    );
    openSnackbar('success', archived ? 'Sheet Archived' : 'Sheet Reactivated');
    dispatch(worklogsPageActions.changeArchivedActiveState(_id));
  } catch (e) {
    console.log(e);
    dispatch(worklogsPageActions.setError(e));
  }
};

export const setChangeArchivedCrewState = (_id, archived, openSnackbar) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'X-User-TZ': getTimeZone(),
    },
  };

  const data = {
    archived,
  };

  try {
    const res = await $api.patch(
      `${process.env.REACT_APP_BASE_URL}/sheets/${_id}/archive`,
      data,
      options
    );
    openSnackbar('success', archived ? 'Sheet Archived' : 'Sheet Reactivated');
    dispatch(worklogsPageActions.changeArchivedCrewState(_id));
  } catch (e) {
    console.log(e);
    dispatch(worklogsPageActions.setError(e));
  }
};

// export const fetchGenerateDocuSignCert = (submissionId) => async (dispatch) => {
//   const options = getOptions();

//   try {
//     dispatch(worklogsPageActions.setCertGenerates(true));
//     await $api.get(`${process.env.REACT_APP_BASE_URL}/submissions/${submissionId}/generate-docusign-certificate
//     `, options);
//     dispatch(worklogsPageActions.setCertGenerates(false));
//   } catch (e) {
//     console.log(e);
//     dispatch(worklogsPageActions.setError(e));
//   }
// }
