const defaultData = {
  name: '',
  type: '',
  discreteHomepageStats: false,
  customDescription: false,
  lines: [],
  legends: [],
  letterings: [],
  glassBeads: [],
  ticks: {},
};
export default defaultData;
