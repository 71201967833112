export default (theme) => ({
  resourcesWrapper: {
    padding: 10,
    '@media (max-width: 1050px)': {
      height: 'calc(100vh - 268px)',
      overflow: 'auto',
    },
    '@media (min-width: 1051px)': {
      height: 'calc(100vh - 198px)',
      overflow: 'auto',
    },
  },
  flexColum: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 15,
    
  },
  column: {
    display: 'inline-block',
    verticalAlign: 'top',
    width: 126,
    '&:first-child': {
      marginRight: 8,
    },
  },
  marginBottom: {
    marginBottom: 8,
  },
  marginBottomBig: {
    marginBottom: 16,
  },
  photoWrapper: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.textColor.blue}`,
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
    width: 20,
    height: 20,
    transform: 'rotate(135deg)',
    minWidth: 20,
    boxSizing: 'border-box',
  },
  photo: {
    display: 'block',
    transform: 'rotate(-135deg)',
    height: 18,
    maxWidth: 18,
    borderRadius: '50%',
    margin: '0 auto',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  username: {
    // marginLeft: 8,
    wordBreak: 'break-word',
    fontSize: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  day: {
    color: theme.palette.textColor.yellow,
  },
  night: {
    color: theme.palette.textColor.darkBlue,
  },
  inactive: {
    color: theme.palette.textColor.secondary,
  },
  dayBorder: {
    border: `1px solid ${theme.palette.textColor.yellow}`,
  },
  nightBorder: {
    border: `1px solid ${theme.palette.textColor.darkBlue}`,
  },
  inactiveBorder: {
    border: `1px solid ${theme.palette.textColor.secondary}`,
  },
  equipmentBlock: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 17,
    width: 'max-content',
    maxWidth: '100%',
    height: 20,
  },
  equipmentNumber: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: theme.palette.textColor.white,
    padding: '3px 6px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  itemWrapper: {
    padding: 3,
    border: `1px solid ${theme.palette.borderColor.white}`,
    backgroundColor: theme.palette.background.default,
    width: 'fit-content',
    maxWidth: 126,
  },
  usedResource: {
    opacity: 0.3,
  },
  unavailableItem: {
    pointerEvents: 'none',
    // borderColor: theme.palette.borderColor.lightGrey,
    width: 'fit-content',
    '& > div': {
      backgroundColor: `${theme.palette.borderColor.lightGrey} !important`,
      maxWidth: 127,
      '& div': {
        color: theme.palette.textColor.white,
      },
    },
  },
  unavailableUser: {
    pointerEvents: 'none',
    borderColor: theme.palette.borderColor.lightGrey,
    width: 'fit-content',
    '& > div': {
      backgroundColor: `${theme.palette.borderColor.lightGrey} !important`,
      borderRadius: 17,
      width: 'fit-content',
      paddingRight: 5,
      maxWidth: 127,
      '& div': {
        borderColor: theme.palette.borderColor.lightGrey,
      },
      '& p': {
        color: theme.palette.textColor.white,
      },
    },
  },
  closeButton: {
    marginLeft: 15,
    padding: 0,
    color: theme.palette.textColor.red,
    '&:hover': {
      color: theme.palette.textColor.red,
      background: theme.palette.background.default,
    },
    '&:focus': {
      color: theme.palette.textColor.red,
      background: theme.palette.background.default,
    },
  },
});
