import React, { useMemo } from 'react';
import propTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Grid, TextField, RadioGroup, FormControlLabel } from '@material-ui/core';
import { addHours } from 'date-fns';
import { Controller } from 'react-hook-form';
import classnames from 'classnames';
import photoPlaceholder from 'assets/images/user-photo-placeholder.svg';
import CustomRadioButton from '../../../CustomRadioButton';
import IOSSwitch from '../../../IOSSwitch';
import DatePickerInput from '../../../DatePickerInput';
import { isIOS } from '../../../Option';
import { getNextDay } from '../../../Kiosk/helpers/getNextDay';
import { validateDatesOverlapping } from '../../../Kiosk/helpers/validate';
import styles from '../styles';
import TimeSelectForm from '../TimeSelectForm';
import { reasons, relations } from '../../../Kiosk/components/AddTimeOff/helpers/helpers';
import { getDate } from '../../../../helpers/getDate';
import { styles as addTimeOffStyles } from '../../../Kiosk/components/AddTimeOff/styles';
import { ERROR_MESSAGES } from '../../../../helpers/error-messages.constant';
import { createOptions } from '../../../../helpers/createOptions';
import CalendarTime from 'assets/images/calendar-time.svg';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { allTimeFormat, globalBEDateFormat, DAY_PART } from 'common/dateFormatConfig';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { convertToArr, validParse } from 'helpers/_date-helpers';

const useStyles = makeStyles(addTimeOffStyles);

const TimeoffDetails = ({
  classes,
  errors,
  userWeekends,
  user,
  onRemove,
  control,
  values,
  withoutRemove,
  setValue,
  clearErrors,
  setError,
}) => {
  const addTimeOffClasses = useStyles();
  const { dateFormat, dateFormatWithTime } = useDateSettingsFormat();
  const getTimeFormatHook = useTimeSettingsFormat();

  const isBereavement = useMemo(() => values.reason === reasons[2], [values.reason]);

  const isOther = useMemo(() => {
    return isBereavement && values.relationship === relations[relations.length - 1];
  }, [isBereavement, values.relationship]);

  const isNotSubtractedAvailablePTO = useMemo(() => {
    return isBereavement && (!isOther || !values.isPTO);
  }, [isBereavement, isOther, values.isPTO]);

  const isSubtractedErrorShown = useMemo(() => {
    return values.relationship && isBereavement && (!isOther || values.isPTO);
  }, [values.relationship, isBereavement, isOther, values.isPTO]);

  const timeoffErrorType = useMemo(() => 'hasTimeoff', []);

  const hasTimeoffError = (() => {
    if (errors.type && errors.type.type === timeoffErrorType) return errors.type.message;

    const errorField = Object.keys(errors).find(
      (field) =>
        Array.isArray(errors[field]) &&
        ((errors[field][0] && errors[field][0].type === timeoffErrorType) ||
          (errors[field][1] && errors[field][1].type === timeoffErrorType))
    );
    return errorField && errors[errorField].find((e) => e && e.type === timeoffErrorType).message;
  })();

  const timeForms = [
    {
      label: 'Start Time',
      name: 'start',
    },
    {
      label: 'End Time',
      name: 'end',
    },
  ];

  return (
    <Grid container spacing={4} justifyContent="space-between" className={classes.root}>
      <Grid item xs={12}>
        <div className={classes.flexBox3}>
          <div className={classes.userPhotoWrapper}>
            <img src={photoPlaceholder} alt="user photo" className={classes.userPhoto} />
          </div>
          <Typography className={classes.name}>{user.username}</Typography>
        </div>
      </Grid>
      <Grid item xs={12} className={classes.zeroPaddingTop}>
        <label htmlFor="type">
          <Typography variant="body1" color="textSecondary">
            Select Type:
          </Typography>
          <Controller
            name="type"
            control={control}
            render={({ value, onChange }) => (
              <RadioGroup
                id="type"
                name="type"
                className={classes.flexBox2}
                onChange={(e) => {
                  const { value } = e.target;
                  onChange(value);
                  setValue(
                    'endDate[0]',
                    value === 'full'
                      ? getNextDay(validParse(values.startDate[0], dateFormat), dateFormat)
                      : values.startDate[0]
                  );
                  if (values.startDate[1]) {
                    setValue(
                      'endDate[1]',
                      value === 'full'
                        ? getNextDay(validParse(values.startDate[1], dateFormat), dateFormat)
                        : values.startDate[1]
                    );
                  }

                  const startHours = '8';
                  const startMinutes = '00';
                  const startAmPm = DAY_PART[0];
                  setValue('startHours[0]', startHours);
                  setValue('startMinutes[0]', startMinutes);
                  setValue('startAmPm[0]', startAmPm);
                  setValue('endHours[0]', value === 'full' ? startHours : '12');
                  setValue('endMinutes[0]', startMinutes);
                  setValue('endAmPm[0]', value === 'full' ? DAY_PART[0] : DAY_PART[1]);
                  if (values.startDate[1]) {
                    setValue('startHours[1]', startHours);
                    setValue('startMinutes[1]', startMinutes);
                    setValue('startAmPm[1]', startAmPm);
                    setValue('endHours[1]', value === 'full' ? startHours : '12');
                    setValue('endMinutes[1]', startMinutes);
                    setValue('endAmPm[1]', value === 'full' ? DAY_PART[0] : DAY_PART[1]);
                  }

                  validateDatesOverlapping(
                    {
                      ...values,
                      startHours: ['8', values.startDate[1] ? '8' : undefined],
                      endHours:
                        value === 'full'
                          ? ['8', values.startDate[1] ? '8' : undefined]
                          : ['12', values.startDate[1] ? '12' : undefined],
                      startAmPm: [DAY_PART[0], values.startDate[1] ? DAY_PART[0] : undefined],
                      endAmPm:
                        value === 'full'
                          ? [DAY_PART[0], values.startDate[1] ? DAY_PART[0] : undefined]
                          : [DAY_PART[1], values.startDate[1] ? DAY_PART[1] : undefined],
                      endDate: [
                        value === 'full'
                          ? getNextDay(validParse(values.startDate[0], dateFormat), dateFormat)
                          : values.startDate[0],
                        values.startDate[1]
                          ? value === 'full'
                            ? getNextDay(validParse(values.startDate[1], dateFormat), dateFormat)
                            : values.startDate[1]
                          : undefined,
                      ],
                    },
                    setError,
                    clearErrors,
                    getTimeFormatHook
                  );
                }}
                value={value}
                row
              >
                <FormControlLabel
                  value="full"
                  control={<CustomRadioButton />}
                  label="Full Day(s)"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="partial"
                  control={<CustomRadioButton />}
                  label="Partial Day (4 hours)"
                  labelPlacement="end"
                />
              </RadioGroup>
            )}
          />
        </label>
      </Grid>
      <Grid item xs={12} md={6} className={classes.zeroPaddingTop}>
        <label htmlFor="startDate">
          <Typography variant="body1" color="textSecondary">
            Start Date:
          </Typography>
          <Controller
            name={'startDate[0]'}
            control={control}
            render={({ value, onChange }) => (
              <DatePickerInput
                selectedDay={value}
                setFieldValue={(_, value) => {
                  onChange(value);

                  const [nextEndDate, nextEndHours, nextEndAmPm] =
                    values.type === 'full'
                      ? [
                          getNextDay(validParse(value, dateFormat), dateFormat),
                          values.startHours[0],
                          values.startAmPm?.[0],
                        ]
                      : convertToArr(
                          addHours(
                            getDate(
                              value,
                              values.startHours[0],
                              values.startMinutes[0],
                              values.startAmPm?.[0],
                              getTimeFormatHook
                            ),
                            4
                          ),
                          dateFormat,
                          getTimeFormatHook
                        );

                  setValue('endDate[0]', nextEndDate);
                  setValue('endHours[0]', nextEndHours);
                  setValue('endAmPm[0]', nextEndAmPm);
                  values.startDate[1] &&
                    validateDatesOverlapping(
                      {
                        ...values,
                        startDate: [value, values.startDate[1]],
                        endDate: [nextEndDate, values.endDate[1]],
                        endHours: [nextEndHours, values.endHours[1]],
                        endAmPm: [nextEndAmPm, values.endAmPm[1]],
                      },
                      setError,
                      clearErrors,
                      getTimeFormatHook
                    );
                }}
                field="startDate"
                userWeekends={userWeekends}
              />
            )}
          />
        </label>
      </Grid>
      <Grid item xs={12} md={6} className={classes.zeroPaddingTop}>
        <label htmlFor="endDate">
          <Typography variant="body1" color="textSecondary">
            End Date:
          </Typography>
          <Controller
            name={'endDate[0]'}
            control={control}
            render={({ value, onChange }) => (
              <DatePickerInput
                selectedDay={value}
                setFieldValue={(_, value) => {
                  onChange(value);
                  values.endDate[1] &&
                    validateDatesOverlapping(
                      { ...values, endDate: [value, values.endDate[1]] },
                      setError,
                      clearErrors,
                      getTimeFormatHook
                    );
                }}
                field="endDate"
                minDate={getNextDay(validParse(values.startDate[0], dateFormat), dateFormat)}
                userWeekends={userWeekends}
                disabled={values.type === 'partial'}
              />
            )}
          />
        </label>
      </Grid>

      {timeForms.map((form) => (
        <TimeSelectForm
          classes={classes}
          type={values.type}
          values={values}
          setValue={setValue}
          index={0}
          control={control}
          label={form.label}
          name={form.name}
          errors={errors}
          setError={setError}
          clearErrors={clearErrors}
        />
      ))}

      {values.startDate[1] && (
        <>
          <Grid item xs={12} md={6} className={classes.zeroPaddingTop}>
            <label htmlFor="startDate">
              <Typography variant="body1" color="textSecondary">
                Start Date:
              </Typography>
              <Controller
                name="startDate[1]"
                control={control}
                render={({ value, onChange }) => (
                  <DatePickerInput
                    selectedDay={value}
                    setFieldValue={(_, value) => {
                      console.log("🚀 ~ value:", value)
                      onChange(value);
                      const [nextEndDate, nextEndHours, nextEndAmPm] =
                        values.type === 'full'
                          ? [
                              getNextDay(validParse(value, dateFormat), dateFormat),
                              values.startHours[1],
                              values.startAmPm?.[1],
                            ]
                          : convertToArr(
                              addHours(
                                getDate(
                                  value,
                                  values.startHours[1],
                                  values.startMinutes[1],
                                  values.startAmPm?.[1],
                                  getTimeFormatHook
                                ),
                                4
                              ),
                              dateFormat,
                              getTimeFormatHook
                            );

                      setValue('endDate[1]', nextEndDate);
                      setValue('endHours[1]', nextEndHours);
                      setValue('endAmPm[1]', nextEndAmPm);
                      validateDatesOverlapping(
                        {
                          ...values,
                          startDate: [values.startDate[0], value],
                          endDate: [values.endDate[0], nextEndDate],
                          endHours: [values.endHours[0], nextEndHours],
                          endAmPm: [values.endAmPm[0], nextEndAmPm],
                          getTimeFormatHook,
                        },
                        setError,
                        clearErrors,
                        getTimeFormatHook
                      );
                    }}
                    field="startDate1"
                    userWeekends={userWeekends}
                  />
                )}
              />
            </label>
            {hasTimeoffError ? <div className={classes.error}>{hasTimeoffError}</div> : null}
          </Grid>
          <Grid item xs={12} md={6} className={classes.zeroPaddingTop}>
            <label htmlFor="endDate">
              <Typography variant="body1" color="textSecondary">
                End Date:
              </Typography>
              <Controller
                name="endDate[1]"
                control={control}
                render={({ value, onChange }) => (
                  <DatePickerInput
                    selectedDay={value}
                    setFieldValue={(_, value) => {
                      onChange(value);
                      validateDatesOverlapping(
                        { ...values, endDate: [values.endDate[0], value] },
                        setError,
                        clearErrors,
                        getTimeFormatHook
                      );
                    }}
                    field="endDate"
                    minDate={getNextDay(validParse(values.startDate[1], dateFormat), dateFormat)}
                    userWeekends={userWeekends}
                    disabled={values.type === 'partial'}
                  />
                )}
              />
            </label>
          </Grid>
          {timeForms.map((form) => (
            <TimeSelectForm
              classes={classes}
              type={values.type}
              values={values}
              setValue={setValue}
              index={1}
              control={control}
              label={form.label}
              name={form.name}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
            />
          ))}
        </>
      )}

      <Grid item xs={12} md={6} className={classes.zeroPaddingTop}>
        <label htmlFor="reason">
          <Typography variant="body1" color="textSecondary">
            Reason:
          </Typography>
          <Controller
            name="reason"
            control={control}
            as={
              <TextField
                select
                id="reason"
                name="reason"
                className={classes.textField}
                variant="outlined"
                fullWidth
                SelectProps={{
                  native: isIOS,
                }}
              >
                {reasons.map(createOptions)}
              </TextField>
            }
          />
          {isSubtractedErrorShown && (
            <div
              className={classnames(
                addTimeOffClasses.error,
                addTimeOffClasses.commonSubtractedError,
                classes.subtractedError,
                isNotSubtractedAvailablePTO
                  ? addTimeOffClasses.isNotSubtractedAvailablePTO
                  : addTimeOffClasses.willSubtractedAvailablePTO
              )}
            >
              {isNotSubtractedAvailablePTO
                ? ERROR_MESSAGES.isNotSubtractedAvailablePTO
                : ERROR_MESSAGES.willSubtractedAvailablePTO}
            </div>
          )}
        </label>
      </Grid>

      <Grid item xs={12} md={6} className={classes.zeroPaddingTop}>
        <label htmlFor="isPTO" className={classes.buttonsWrapper}>
          <Typography variant="body1" color="textSecondary">
            PTO:
          </Typography>
          <Controller
            name="isPTO"
            control={control}
            render={({ value, onChange }) => (
              <IOSSwitch
                name="isPTO"
                checked={value}
                onChange={(a, b) => onChange(b)}
                value="isPTO"
              />
            )}
          />
        </label>
      </Grid>

      {isBereavement && (
        <Grid item xs={12} md={6} className={classes.zeroPaddingTop}>
          <label htmlFor="relationship">
            <Typography variant="body1" color="textSecondary">
              Relationship:
            </Typography>
            <Controller
              name="relationship"
              control={control}
              rules={{
                required: "This field can't be blank",
              }}
              as={
                <TextField
                  select
                  defaultValue=""
                  id="relationship"
                  name="relationship"
                  variant="outlined"
                  fullWidth
                  error={Boolean(errors.relationship)}
                  className={classes.textField}
                  SelectProps={{
                    native: isIOS,
                  }}
                >
                  {relations.map(createOptions)}
                </TextField>
              }
            />
            {errors.relationship ? (
              <div className={classes.error}>{errors.relationship.message}</div>
            ) : null}
          </label>
        </Grid>
      )}

      <Grid item xs={12} md={isBereavement ? 6 : 12} className={classes.zeroPaddingTop}>
        <label htmlFor="notes">
          <Typography variant="body1" color="textSecondary">
            {isBereavement ? 'Name' : 'Notes'}:
          </Typography>
          <Controller
            name="notes"
            control={control}
            rules={{
              required: isBereavement && "This field can't be blank",
              validate: (value) =>
                value.trim().length <= 50 ||
                'The field cannot contain more than 50 symbols. Please, check.',
            }}
            as={
              <TextField
                id="notes"
                name="notes"
                rowsMax={2}
                error={Boolean(errors.notes)}
                className={classes.textArea}
                variant="outlined"
                multiline={!isBereavement}
              />
            }
          />
          {errors.notes ? <div className={classes.error}>{errors.notes.message}</div> : null}
        </label>
      </Grid>

      {!withoutRemove && (
        <Grid item xs={12} className={classes.zeroPaddingTop}>
          <label>
            <button className={classes.removeButton} type={'button'} onClick={onRemove}>
              <img className={classes.removeButtonIcon} src={CalendarTime} />
              Remove PTO
            </button>
          </label>
        </Grid>
      )}
    </Grid>
  );
};

TimeoffDetails.propTypes = {
  classes: propTypes.object.isRequired,
  values: propTypes.object.isRequired,
  errors: propTypes.object.isRequired,
  userWeekends: propTypes.array.isRequired,
  user: propTypes.object.isRequired,
  onRemove: propTypes.func.isRequired,
  clearErrors: propTypes.func.isRequired,
  setError: propTypes.func.isRequired,
  setValue: propTypes.func.isRequired,
};

export default withStyles(styles)(TimeoffDetails);
