import { createAction } from '@reduxjs/toolkit';

const getMaterialsSuccess = createAction('supplies/getMaterialsSuccess');
const getMaterialsRequest = createAction('supplies/getMaterialsRequest');
const getMaterialsError = createAction('supplies/getMaterialsError');

const dublicateMaterialSuccess = createAction('supplies/dublicateMaterialSuccess');
const dublicateMaterialRequest = createAction('supplies/dublicateMaterialRequest');
const dublicateMaterialError = createAction('supplies/dublicateMaterialError');

const postMaterialSuccess = createAction('supplies/postMaterialSuccess');
const postMaterialRequest = createAction('supplies/postMaterialRequest');
const postMaterialError = createAction('supplies/postMaterialError');
const isPostMaterialSuccessfully = createAction('supplies/isPostMaterialSuccessfully');

const updateMaterialSuccess = createAction('supplies/updateMaterialSuccess');
const updateMaterialRequest = createAction('supplies/updateMaterialRequest');
const updateMaterialError = createAction('supplies/updateMaterialError');

const deleteMaterialSuccess = createAction('supplies/deleteMaterialSuccess');
const deleteMaterialRequest = createAction('supplies/deleteMaterialRequest');
const deleteMaterialError = createAction('supplies/deleteMaterialError');

const getManufacturersSuccess = createAction('supplies/getManufacturersSuccess');
const getManufacturersRequest = createAction('supplies/getManufacturersRequest');
const getManufacturersError = createAction('supplies/getManufacturersError');

const postManufactureSuccess = createAction('supplies/postManufactureSuccess');
const postManufactureRequest = createAction('supplies/postManufactureRequest');
const postManufactureError = createAction('supplies/postManufactureError');
const successfullyAddedManufacturer = createAction('supplies/successfullyAddedManufacturer');

const updateManufactureSuccess = createAction('supplies/updateManufactureSuccess');
const updateManufactureRequest = createAction('supplies/updateManufactureRequest');
const updateManufactureError = createAction('supplies/updateManufactureError');

const deleteManufactureSuccess = createAction('supplies/deleteManufactureSuccess');
const deleteManufactureRequest = createAction('supplies/deleteManufactureRequest');
const deleteManufactureError = createAction('supplies/deleteManufactureError');
const validationManufactureError = createAction('supplies/validationManufactureError');
const isDeleteManufacturerSuccess = createAction('supplies/isDeleteManufacturerSuccess');

const getGlassBeadSuccess = createAction('supplies/getGlassBeadSuccess');
const getGlassBeadRequest = createAction('supplies/getGlassBeadRequest');
const getGlassBeadError = createAction('supplies/getGlassBeadError');

const postGlassBeadSuccess = createAction('supplies/postGlassBeadSuccess');
const postGlassBeadRequest = createAction('supplies/postGlassBeadRequest');
const postGlassBeadError = createAction('supplies/postGlassBeadError');
const successfullyAddedGlassBead = createAction('supplies/successfullyAddedGlassBead');

const updateGlassBeadSuccess = createAction('supplies/updateGlassBeadSuccess');
const updateGlassBeadRequest = createAction('supplies/updateGlassBeadRequest');
const updateGlassBeadError = createAction('supplies/updateGlassBeadError');

const deleteGlassBeadSuccess = createAction('supplies/deleteGlassBeadSuccess');
const deleteGlassBeadError = createAction('supplies/deleteGlassBeadError');
const validationGlassBeadError = createAction('supplies/validationGlassBeadError');
const isDeleteGlassBeadSuccess = createAction('supplies/isDeleteGlassBeadSuccess');

const getInventorySuccess = createAction('supplies/getInventorySuccess');
const getInventoryRequest = createAction('supplies/getInventoryRequest');
const getInventoryError = createAction('supplies/getInventoryError');

const inventorySnackBarInfo = createAction('supplies/inventorySnackBarInfo');
const successfullyAdded = createAction('supplies/successfullyAdded');
const isDeleteInventorySuccess = createAction('supplies/isDeleteInventorySuccess');

const postInventorySuccess = createAction('supplies/postInventorySuccess');
const postInventoryRequest = createAction('supplies/postInventoryRequest');
const postInventoryError = createAction('supplies/postInventoryError');

const updateInventorySuccess = createAction('supplies/updateInventorySuccess');
const updateInventoryRequest = createAction('supplies/updateInventoryRequest');
const updateInventoryError = createAction('supplies/updateInventoryError');

const deleteInventorySuccess = createAction('supplies/deleteInventorySuccess');
const deleteInventoryRequest = createAction('supplies/deleteInventoryRequest');
const deleteInventoryError = createAction('supplies/deleteInventoryError');

export default {
  getMaterialsSuccess,
  getMaterialsRequest,
  getMaterialsError,
  postMaterialSuccess,
  postMaterialRequest,
  postMaterialError,
  updateMaterialSuccess,
  updateMaterialRequest,
  updateMaterialError,
  deleteMaterialRequest,
  deleteMaterialSuccess,
  deleteMaterialError,
  getManufacturersSuccess,
  getManufacturersRequest,
  getManufacturersError,
  postManufactureSuccess,
  postManufactureRequest,
  postManufactureError,
  updateManufactureSuccess,
  updateManufactureRequest,
  updateManufactureError,
  deleteManufactureSuccess,
  deleteManufactureRequest,
  deleteManufactureError,
  successfullyAddedManufacturer,
  isDeleteManufacturerSuccess,
  validationManufactureError,
  dublicateMaterialSuccess,
  dublicateMaterialRequest,
  dublicateMaterialError,
  isPostMaterialSuccessfully,
  successfullyAdded,
  inventorySnackBarInfo,
  getInventorySuccess,
  getInventoryRequest,
  getInventoryError,
  postInventorySuccess,
  postInventoryRequest,
  postInventoryError,
  updateInventorySuccess,
  updateInventoryRequest,
  updateInventoryError,
  deleteInventorySuccess,
  deleteInventoryRequest,
  deleteInventoryError,
  isDeleteInventorySuccess,
  getGlassBeadSuccess,
  getGlassBeadRequest,
  getGlassBeadError,
  postGlassBeadSuccess,
  postGlassBeadRequest,
  postGlassBeadError,
  successfullyAddedGlassBead,
  updateGlassBeadSuccess,
  updateGlassBeadRequest,
  updateGlassBeadError,
  deleteGlassBeadSuccess,
  deleteGlassBeadError,
  isDeleteGlassBeadSuccess,
  validationGlassBeadError,
};
