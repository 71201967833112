import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import WorklogCard from './components/WorklogCard';
import styles from './styles';
import { format, parse, addDays, subDays, startOfWeek } from 'date-fns';
import { useWeekStart } from 'hooks/useWeekStart';
import { GLOBAL_DATE_FORMAT, allDatesFormat } from 'common/dateFormatConfig';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';

const WorklogsList = ({ classes, sheets, isFullSchedule, selectedDate, setDate }) => {
  const { dateFormat } = useDateSettingsFormat();
  const formatDate = date => format(parse(date, dateFormat, new Date()), GLOBAL_DATE_FORMAT.fullDateWithDay)
  const {weekDayFromO} = useWeekStart();

  const updateDate = type => () => {
    let newDate = null;
    if( type == 'add' ) newDate = addDays(new Date(selectedDate), 1)
    if( type == 'sub') newDate = subDays(new Date(selectedDate), 1)
    setDate(newDate);
  }

  const canGoBack = () => {
    const initialDay = format(startOfWeek(new Date(), {weekStartsOn: weekDayFromO}), allDatesFormat[2]);
    const selectedDay = format(new Date(selectedDate), allDatesFormat[2]);
    return initialDay !== selectedDay;
  }

  const canGoForward = () => {
    const initialDay = format(startOfWeek(new Date(), {weekStartsOn: weekDayFromO}), allDatesFormat[2]);
    const selectedDay = format(new Date(selectedDate), allDatesFormat[2]);
    const maxForwardDay =  format( addDays( parse(initialDay, allDatesFormat[2], new Date()) , 13 ) , allDatesFormat[2])
    return maxForwardDay !== selectedDay;
  }

  return (
    <>
      {isFullSchedule && (
        <Typography
          variant="h4"
          align="center"
          className={classnames(classes.regularFontWeight, classes.calendarWrapper)}
        >
          {canGoBack() && (
            <ArrowLeftIcon
              onClick={updateDate('sub')}
              className={classes.inlineBlock}
            />
          )}
          <span className={classnames(
            classes.inlineBlock,
            !canGoBack() && classes.marginLeft,
            !canGoForward() && classes.marginRight
          )}>
            {format( new Date(selectedDate), GLOBAL_DATE_FORMAT.fullDateWithDay)}
          </span>
          {canGoForward() && (
            <ArrowRightIcon
              onClick={updateDate('add')}
              className={classes.inlineBlock}
            />
          )}
        </Typography>
      )}
    {sheets?.length ?
      sheets.map(arraifySheets => (
        <Grid key={arraifySheets[0]} container spacing={1}>
          {!isFullSchedule && (
            <Grid item xs={12}>
              <Typography variant="h4" align="center" className={classes.dayName} >
                {formatDate(arraifySheets[0])}
              </Typography>
            </Grid>
          )}
          {arraifySheets[1].map(sheet => (
            <Grid
              key={Math.floor(+new Date() + Math.random() * 0xffffffff).toString(36)}
              item xs={12} lg={3} xl='auto'
            >
              <WorklogCard sheet={sheet} />
            </Grid>
          ))}
        </Grid>
      )) : (
        <Typography variant="h3" align="center" className={classes.noResults}>
          None
        </Typography>
      )
    }
  </>
)};

WorklogsList.propTypes = {
  classes: propTypes.object.isRequired,
  sheets: propTypes.array.isRequired,
  isFullSchedule: propTypes.bool.isRequired,
  selectedDate: propTypes.object,
  setDate: propTypes.func
};

export default withStyles(styles)(WorklogsList);
