import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SighInI from 'assets/images/sign-icon.svg';
import { Add } from '@material-ui/icons';
import { isIOS, Option } from 'components/Option';

import styles from './styles';
import { getContactName } from 'helpers/_helpers';

const SubmitDetails = ({
  classes,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  openSignatureModal,
  removeSignature,
  contractorsAlternateContacts,
  handleAppendContact,
  removeAlternateContacts,
  setContactDropdownSelector,
  contactDropdownSelector,
  handleAppendDOTContact,
}) => {
  const phoneNumberHandler = (e, fieldName) => {
    const { value } = e.target;
    setFieldValue(fieldName, value.replace(/[^0-9]*/g, ''));
  };
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <label htmlFor="notes">
          <Typography variant="body1" color="textSecondary">
            Notes:
          </Typography>
          <TextField
            id="notes"
            name="notes"
            value={values.notes}
            error={Boolean(errors.notes) && Boolean(touched.notes)}
            onChange={handleChange}
            onBlur={handleBlur}
            className={classes.textField}
            variant="outlined"
            fullWidth
          />
          {errors.notes && touched.notes ? (
            <div className={classes.error}>{errors.notes}</div>
          ) : null}
        </label>
      </Grid>

      {!!values.dot?.length && (
        <Grid item xs={12} md={12}>
          {values.dot.map((dotItem, i) => (
            <>
              <Grid container spacing={2}>
                {i === 0 && (
                  <Grid item xs={12}>
                    <Typography variant="h4" color="textPrimary">
                      DOT Inspector signature
                    </Typography>
                  </Grid>
                )}
                <Grid container item xs={12}>
                  {i !== 0 && (
                    <>
                      <Grid item xs={11}>
                        <Typography variant="h6" color="textSecondary" style={{ marginTop: 15 }}>
                          Contact {i}:
                        </Typography>
                      </Grid>
                      <Grid item container justifyContent="flex-end" xs={1}>
                        <IconButton
                          className={classes.removeBtn}
                          onClick={removeAlternateContacts(
                            values,
                            setFieldValue,
                            i,
                            'dot'
                          )}
                          disableRipple
                          disableTouchRipple
                        >
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} lg={4}>
                  <label htmlFor={`dot[${i}.name]`}>
                    <Typography variant="body1" color="textSecondary">
                      Name:
                    </Typography>
                    <TextField
                      id={`dot[${i}.name]`}
                      name={`dot[${i}.name]`}
                      value={values.dot[i].name}
                      error={Boolean(errors.dot?.[i]?.name) && Boolean(touched.dot?.[i]?.name)} //contractorAlternateContacts[${i}].name
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                    />
                    {errors.dot?.[i]?.name && touched.dot?.[i]?.name ? (
                      <div className={classes.error}>{errors.dot?.[i]?.name}</div>
                    ) : null}
                  </label>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <label htmlFor={`dot[${i}.email]`}>
                    <Typography variant="body1" color="textSecondary">
                      Email address:
                    </Typography>
                    <TextField
                      id={`dot[${i}.email]`}
                      name={`dot[${i}.email]`}
                      value={values.dot[i].email}
                      error={Boolean(errors.dot?.[i]?.email) && Boolean(touched.dot?.[i]?.email)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                    />
                    {errors.dot?.[i]?.email && touched.dot?.[i]?.email ? (
                      <div className={classes.error}>{errors.dot?.[i]?.email}</div>
                    ) : null}
                  </label>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <label htmlFor={`dot[${i}.phoneNumber]`}>
                    <Typography variant="body1" color="textSecondary">
                      Phone number:
                    </Typography>
                    <TextField
                      id={`dot[${i}.phoneNumber]`}
                      name={`dot[${i}.phoneNumber]`}
                      value={values.dot[i].phoneNumber}
                      onChange={(e) => phoneNumberHandler(e, `dot[${i}.phoneNumber`)}
                      error={
                        Boolean(errors?.dot?.[i]?.phoneNumber) &&
                        Boolean(touched?.dot?.[i]?.phoneNumber)
                      }
                      onBlur={handleBlur}
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                    />
                    {errors?.dot?.[i]?.phoneNumber && touched?.dot?.[i]?.phoneNumber ? (
                      <div className={classes.error}>{errors?.dot?.[i]?.phoneNumber}</div>
                    ) : null}
                  </label>
                </Grid>
                <Grid item xs={12}>
                  {values.dot?.[i]?.Signature ? (
                    <>
                      <div className={classes.flexBox}>
                        <Typography variant="body1" color="textSecondary">
                          Signature:
                        </Typography>
                        <IconButton
                          className={classes.closeButton}
                          onClick={removeSignature(`dot[${i}].Signature`, setFieldValue)}
                          disableRipple
                          disableTouchRipple
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                      <img src={values.dot?.[i]?.Signature} className={classes.signImg} />
                    </>
                  ) : (
                    <Button
                      variant="text"
                      className={classes.addButton}
                      onClick={openSignatureModal(`dot[${i}]`)}
                      disableRipple
                      disableTouchRipple
                    >
                      <img src={SighInI} alt="sign" className={classes.signIcon} />
                      Add signature
                    </Button>
                  )}
                </Grid>
              </Grid>
            </>
          ))}
        </Grid>
      )}

      <Grid container item xs={12} md={12} className={classes.zeroPaddingTop}>
        <Grid item xs={12}>
          <Button
            variant="text"
            className={classes.addButton}
            onClick={() => {
              handleAppendDOTContact(setFieldValue, values);
            }}
            disableRipple
            disableTouchRipple
          >
            <Add fontSize="small" className={classes.plusIcon} />
            Add DOT Inspector
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" color="textPrimary">
              Contractor signature
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
            <label htmlFor="contractorName">
              <Typography variant="body1" color="textSecondary">
                Name:
              </Typography>
              <TextField
                id="contractorName"
                name="contractorName"
                value={values.contractorName}
                error={Boolean(errors.contractorName) && Boolean(touched.contractorName)}
                onChange={handleChange}
                onBlur={handleBlur}
                className={classes.textField}
                variant="outlined"
                fullWidth
              />
              {errors.contractorName && touched.contractorName ? (
                <div className={classes.error}>{errors.contractorName}</div>
              ) : null}
            </label>
          </Grid>
          <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
            <label htmlFor="contractorEmail">
              <Typography variant="body1" color="textSecondary">
                Email address:
              </Typography>
              <TextField
                id="contractorEmail"
                name="contractorEmail"
                value={values.contractorEmail}
                error={Boolean(errors.contractorEmail) && Boolean(touched.contractorEmail)}
                onChange={handleChange}
                onBlur={handleBlur}
                className={classes.textField}
                variant="outlined"
                fullWidth
              />
              {errors.contractorEmail && touched.contractorEmail ? (
                <div className={classes.error}>{errors.contractorEmail}</div>
              ) : null}
            </label>
          </Grid>
          <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
            <label htmlFor="contractorPhoneNumber">
              <Typography variant="body1" color="textSecondary">
                Phone number:
              </Typography>
              <TextField
                id="contractorPhoneNumber"
                name="contractorPhoneNumber"
                value={values.contractorPhoneNumber}
                onChange={(e) => phoneNumberHandler(e, 'contractorPhoneNumber')}
                error={
                  Boolean(errors.contractorPhoneNumber) && Boolean(touched.contractorPhoneNumber)
                }
                onBlur={handleBlur}
                className={classes.textField}
                variant="outlined"
                fullWidth
              />
              {errors.contractorPhoneNumber && touched.contractorPhoneNumber ? (
                <div className={classes.error}>{errors.contractorPhoneNumber}</div>
              ) : null}
            </label>
          </Grid>
          <Grid item xs={12} className={classes.zeroPaddingTop}>
            {values.contractorSignature ? (
              <>
                <div className={classes.flexBox}>
                  <Typography variant="body1" color="textSecondary">
                    Signature:
                  </Typography>
                  <IconButton
                    className={classes.closeButton}
                    onClick={removeSignature('contractorSignature', setFieldValue)}
                    disableRipple
                    disableTouchRipple
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <img src={values.contractorSignature} className={classes.signImg} />
              </>
            ) : (
              <Button
                variant="text"
                className={classes.addButton}
                onClick={openSignatureModal('contractor')}
                disableRipple
                disableTouchRipple
              >
                <img src={SighInI} alt="sign" className={classes.signIcon} />
                Add signature
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      {!!values.contractorAlternateContacts?.length && (
        <Grid item xs={12} md={12}>
          {values.contractorAlternateContacts.map((contact, i) => (
            <Grid container spacing={2} key={i + contact?._id}>
              {i === 0 && (
                <Grid item xs={12}>
                  <Typography variant="h4" color="textPrimary">
                    Alternate Contractor signature
                  </Typography>
                </Grid>
              )}
              <Grid container item xs={12}>
                <Grid item xs={11}>
                  <Typography variant="h6" color="textSecondary" style={{ margin: '5px 0' }}>
                    Contact {i + 1}:
                  </Typography>
                </Grid>

                <Grid item container justifyContent="flex-end" xs={1}>
                  <IconButton
                    className={classes.removeBtn}
                    onClick={removeAlternateContacts(
                      values,
                      setFieldValue,
                      i,
                      'contractorAlternateContacts'
                    )}
                    disableRipple
                    disableTouchRipple
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
                <label htmlFor={`contractorAlternateContacts[${i}].name`}>
                  <Typography variant="body1" color="textSecondary">
                    Name:
                  </Typography>
                  <TextField
                    id={`contractorAlternateContacts[${i}].name`}
                    name={`contractorAlternateContacts[${i}].name`}
                    value={values.contractorAlternateContacts?.[i]?.name}
                    error={
                      Boolean(errors?.contractorAlternateContacts?.[i]?.name) &&
                      Boolean(touched?.contractorAlternateContacts?.[i]?.name)
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classes.textField}
                    variant="outlined"
                    fullWidth
                  />
                  {errors?.contractorAlternateContacts?.[i]?.name &&
                  touched?.contractorAlternateContacts?.[i]?.name ? (
                    <div className={classes.error}>
                      {errors?.contractorAlternateContacts?.[i]?.name}
                    </div>
                  ) : null}
                </label>
              </Grid>
              <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
                <label htmlFor={`contractorAlternateContacts[${i}].email`}>
                  <Typography variant="body1" color="textSecondary">
                    Email address:
                  </Typography>
                  <TextField
                    id={`contractorAlternateContacts[${i}].email`}
                    name={`contractorAlternateContacts[${i}].email`}
                    value={values.contractorAlternateContacts?.[i]?.email}
                    error={
                      Boolean(errors?.contractorAlternateContacts?.[i]?.email) &&
                      Boolean(touched?.contractorAlternateContacts?.[i]?.email)
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classes.textField}
                    variant="outlined"
                    fullWidth
                  />
                  {errors?.contractorAlternateContacts?.[i]?.email &&
                  touched?.contractorAlternateContacts?.[i]?.email ? (
                    <div className={classes.error}>
                      {errors?.contractorAlternateContacts?.[i]?.email}
                    </div>
                  ) : null}
                </label>
              </Grid>
              <Grid item xs={12} lg={4} className={classes.zeroPaddingTop}>
                <label htmlFor={`contractorAlternateContacts[${i}].phoneNumber`}>
                  <Typography variant="body1" color="textSecondary">
                    Phone number:
                  </Typography>
                  <TextField
                    id={`contractorAlternateContacts[${i}].phoneNumber`}
                    name={`contractorAlternateContacts[${i}].phoneNumber`}
                    value={values.contractorAlternateContacts?.[i]?.phoneNumber}
                    error={
                      Boolean(errors?.contractorAlternateContacts?.[i]?.phoneNumber) &&
                      Boolean(touched?.contractorAlternateContacts?.[i]?.phoneNumber)
                    }
                    onChange={(e) =>
                      phoneNumberHandler(e, `contractorAlternateContacts[${i}].phoneNumber`)
                    }
                    onBlur={handleBlur}
                    className={classes.textField}
                    variant="outlined"
                    fullWidth
                  />
                  {errors?.contractorAlternateContacts?.[i]?.phoneNumber &&
                  touched?.contractorAlternateContacts?.[i]?.phoneNumber ? (
                    <div className={classes.error}>
                      {errors?.contractorAlternateContacts?.[i]?.phoneNumber}
                    </div>
                  ) : null}
                </label>
              </Grid>
              <Grid
                container
                justifyContent="flex-start"
                item
                xs={12}
                lg={4}
                className={classes.zeroPaddingTop}
              >
                {values.contractorAlternateContacts?.[i]?.Signature ? (
                  <>
                    <div className={classes.flexBox} style={{ width: '100%' }}>
                      <Typography variant="body1" color="textSecondary">
                        Signature:
                      </Typography>
                      <IconButton
                        className={classes.closeButton}
                        onClick={removeSignature(
                          `contractorAlternateContacts[${i}].Signature`,
                          setFieldValue
                        )}
                        disableRipple
                        disableTouchRipple
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <img
                      src={values.contractorAlternateContacts?.[i]?.Signature}
                      className={classes.signImg}
                    />
                  </>
                ) : (
                  <Button
                    variant="text"
                    className={classes.addButton}
                    onClick={openSignatureModal(`contractorAlternateContacts[${i}]`)}
                    disableRipple
                    disableTouchRipple
                  >
                    <img src={SighInI} alt="sign" className={classes.signIcon} />
                    Add signature
                  </Button>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}

      {contactDropdownSelector && (
        <Grid item xs={12} className={classes.zeroPaddingTop}>
          <label htmlFor="alternateContacts">
            <Typography variant="body1" color="textSecondary">
              Contact:
            </Typography>
            <div className={classes.flexBox}>
              <TextField
                select
                id="alternateContacts"
                className={classes.textField}
                variant="outlined"
                value={''}
                onChange={handleAppendContact(setFieldValue, values)}
                inputProps={{ 'aria-label': 'Force account' }}
                SelectProps={{
                  native: isIOS,
                }}
                fullWidth
              >
                <Option key={0} value=""></Option>
                {contractorsAlternateContacts
                  .filter(
                    (contact) =>
                      !values.contractorAlternateContacts.find(
                        (formCont) => contact._id === formCont._id
                      )
                  )
                  .sort((a, b) => a.name?.localeCompare(b?.name))
                  .map((contact, index) => (
                    <Option key={`${contact._id}_${index}`} value={contact._id}>
                      {getContactName(contact)}
                    </Option>
                  ))}
                <Option key={'newEmpty'} value={'newEmpty'} className={classes.primaryColor}>
                  + Add Contractor
                </Option>
              </TextField>
              <IconButton
                className={classes.deleteLocationButton}
                onClick={() => setContactDropdownSelector(false)}
                aria-label="Delete"
                disableTouchRipple
              >
                <CloseIcon />
              </IconButton>
            </div>
          </label>
        </Grid>
      )}
      {!contactDropdownSelector && (
        <Grid container item xs={12} md={12} className={classes.zeroPaddingTop}>
          <Grid item xs={12}>
            <Button
              variant="text"
              className={classes.addButton}
              onClick={() => {
                setContactDropdownSelector(true);
              }}
              disableRipple
              disableTouchRipple
            >
              <Add fontSize="small" className={classes.plusIcon} />
              Add Contractor
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

SubmitDetails.propTypes = {
  classes: propTypes.object.isRequired,
  values: propTypes.object.isRequired,
  errors: propTypes.object.isRequired,
  touched: propTypes.object.isRequired,
  handleChange: propTypes.func.isRequired,
  handleBlur: propTypes.func.isRequired,
  setFieldValue: propTypes.func.isRequired,
  openSignatureModal: propTypes.func.isRequired,
  removeSignature: propTypes.func.isRequired,
};

export default withStyles(styles)(SubmitDetails);
