export default theme => ({
  search: {
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      minWidth: 996,
    },
    '& input': {
      padding: 6,
      height: 31,
      boxSizing: 'border-box',
      // color: theme.palette.textColor.grey
      opacity: '.9',
    }
  },
  todaySearch: {
    width: '100%',
    '& input': {
      padding: 6,
      height: 31,
      boxSizing: 'border-box',
      opacity: '.9',
    }
  },
  searchIcon: {
    color: theme.palette.textColor.secondary,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 8
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  toggleFiltersBtn: {
    height: 35,
    width: '100%',
    paddingTop: 4,
    marginTop: 8,
    marginBottom: 8,
    [theme.breakpoints.up('xl')]: {
      width: 237
    },
  },
  todayToggleFiltersBtn: {
    height: 35,
    width: '100%',
    paddingTop: 4,
    marginTop: 8,
    marginBottom: 8,
  },
  isToday: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    color: '#777',
  },
  filtersIcon: {
    marginRight: 10,
  }
});
