import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import ShiftsBlock from './components/ShiftsBlock';
import RightBlock from './components/RightBlock';
import ForceAccountsBlock from './components/ForceAccountsBlock';
import HomePageBlock from './components/HomePageBlock';
import styles from './styles';
import PtoBlock from './components/PtoBlock';
import PayrollBlock from './components/PayrollBlock';
import EmailNotifications from './components/EmailNotifications';
import LateClockIn from './components/LateClockIn/index';
import WeekShift from './components/WeekShift/index';
import DocuSignSigner from './components/DocuSignSigner/index';
import InjuryReportEmails from './components/InjuryReportEmails/index';

const SettingsForm = ({
  classes,
  pageType,
  hours,
  minutes,
  dayPart,
  errors,
  control,
  settings,
}) => {
  return (
    <Grid
      container
      spacing={5}
      item
      xs={12}
      alignItems="center"
      className={classes.settingsDetailsBlock}
    >
      <ShiftsBlock
        pageType={pageType}
        hours={hours}
        minutes={minutes}
        dayPart={dayPart}
        errors={errors}
        control={control}
      />
      <RightBlock pageType={pageType} errors={errors} control={control} />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <WeekShift pageType={pageType} errors={errors} control={control} />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <HomePageBlock pageType={pageType} errors={errors} control={control} />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <EmailNotifications pageType={pageType} errors={errors} control={control} />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {settings?.generateDocusignCertificates && (
        <>
          <DocuSignSigner pageType={pageType} errors={errors} control={control} />
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      )}
      <LateClockIn pageType={pageType} errors={errors} control={control} />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <InjuryReportEmails pageType={pageType} errors={errors} control={control} />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <ForceAccountsBlock pageType={pageType} errors={errors} control={control} />
      <PtoBlock pageType={pageType} errors={errors} control={control} />
      <PayrollBlock pageType={pageType} errors={errors} control={control} />
    </Grid>
  );
};

SettingsForm.propTypes = {
  classes: propTypes.object.isRequired,
  pageType: propTypes.string.isRequired,
  hours: propTypes.array.isRequired,
  minutes: propTypes.array.isRequired,
  dayPart: propTypes.array.isRequired,
  control: propTypes.object.isRequired,
  errors: propTypes.object.isRequired,
};

export default withStyles(styles)(SettingsForm);
