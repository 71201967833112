import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  TextField,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { parse, isSameDay, addHours, format } from 'date-fns';
import classnames from 'classnames';

import CustomRadioButton from '../../../CustomRadioButton/CustomRadioButton';
import DatePickerInputMobile from '../../../DatePickerInputMobile';
import { useTimeStates } from 'hooks/useTimeStates';
import { isIOS } from '../../../Option';
import IOSSwitch from '../../../IOSSwitch';
import { styles } from './styles';
import kioskPageActions from 'store/kiosk/kioskPageActions';
import { validateEndAfterStart } from '../../helpers/validate';
import { getNextDay } from '../../helpers/getNextDay';
import {
  formatData,
  getDefaultFormValues,
  getWeekends,
  reasons,
  relations,
} from './helpers/helpers';
import ConfirmDialog from '../../../ConfirmDialog';
import { ERROR_MESSAGES } from '../../../../helpers/error-messages.constant';
import { createOptions } from 'helpers/createOptions';
import $api from 'http/index';
import axios from 'axios';
import { getKioskOptions } from 'helpers/getOptions';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { useTimeSettingsFormat } from '../../../../common/useTimeSettingsFormat';
import { DAY_PART, convertTimeObject } from 'common/dateFormatConfig';

const useStyles = makeStyles(styles);

const AddTimeOff = ({ user, pto, shiftTime }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { dateFormat, dateFormatWithTime } = useDateSettingsFormat();
  const getTimeFormatHook = useTimeSettingsFormat();
  const is12Format = getTimeFormatHook().is12Format;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmDialogShown, setConfirmDialogShown] = useState(null);

  const userWeekends = useMemo(() => getWeekends(user), [user]);
  const dataReady = useMemo(() => Boolean(shiftTime), [shiftTime]);

  const { hours, minutes, dayPart } = useTimeStates({ takeSettingsHours: true });
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
    reset,
    getValues,
    setError,
    clearErrors,
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues: dataReady && getDefaultFormValues({ pto, shiftTime, dateFormat, is12Format }),
  });
  const values = watch([
    'startDate',
    'endDate',
    'type',
    'reason',
    'startHours',
    'endHours',
    'startAmPm',
    'endAmPm',
    'relationship',
    'isPTO',
    'startMinutes',
    'endMinutes',
  ]);

  const isBereavement = useMemo(() => values.reason === reasons[2], [values.reason]);

  const isOther = useMemo(() => {
    return isBereavement && values.relationship === relations[relations.length - 1];
  }, [isBereavement, values.relationship]);

  const isNotSubtractedAvailablePTO = useMemo(() => {
    return isBereavement && (!isOther || !values.isPTO);
  }, [isBereavement, isOther, values.isPTO]);

  const isSubtractedErrorShown = useMemo(() => {
    return values.relationship && isBereavement && (!isOther || values.isPTO);
  }, [values.relationship, isBereavement, isOther, values.isPTO]);

  useEffect(() => {
    isBereavement ? setValue('relationship', '') : trigger('notes');
    setValue('notes', '');
  }, [values.reason === reasons[2]]);

  useEffect(() => {
    if (isBereavement && values.relationship !== relations[relations.length - 1])
      setValue('isPTO', true);
  }, [values.relationship]);

  useEffect(() => {
    setValue('isPTO', !!(pto && pto.available));
  }, [pto]);

  const closeConfirmDialog = () => setConfirmDialogShown(null);
  const handleConfirmSubmit = () => {
    const { relationship, ...values } = getValues();

    let resultData;
    if (isBereavement) {
      resultData = { relationship, ...values };
      resultData.relativeName = resultData.notes;
      delete resultData.notes;
    } else {
      resultData = values;
    }
    closeConfirmDialog();
    onSubmit(resultData);
  };
  const onSubmit = (valuesData) => {
    setIsSubmitting(true);

    const apiCall = async () => {
      try {
        const { relationship } = getValues();

        let values;
        if (isBereavement) {
          values = { relationship, ...valuesData };
          values.relativeName = values.notes;
          delete values.notes;
        } else {
          values = valuesData;
        }

        const data = {
          ...formatData({ values, user }, dateFormatWithTime, getTimeFormatHook),
          isForcePTO: !!confirmDialogShown,
        };
        data.startTime.hour = String(data.startTime.hour);
        data.endTime.hour = String(data.endTime.hour);

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/kiosk/timeoffs
        `,
          data,
          getKioskOptions()
        );

        dispatch(kioskPageActions.setPto(response.data.summaryPTOHours));
        dispatch(
          kioskPageActions.setAlert({
            type: 'success',
            text: 'Request has been successfully sent',
          })
        );
        reset();
      } catch (e) {
        dispatch(kioskPageActions.setError(e?.response?.data?.message || e.message));
        if (e?.response?.data?.type === 'not-enough-pto-hours')
          return setConfirmDialogShown(e?.response?.data?.message || e.message);
        dispatch(
          kioskPageActions.setAlert({
            type: 'error',
            text: e?.response?.data?.message || e.message,
          })
        );
      }
    };
    return apiCall().then(() => setIsSubmitting(false));
  };

  const addObjectHours = (timeObject, addHours = 4, is12HourFormat = true) => {
    let { hour, minute, amPm } = timeObject;
    let hourInt = parseInt(hour, 10);
    let newHour;

    if (is12HourFormat) {
      // 12-hour format logic
      newHour = hourInt + addHours;
      if (newHour > 12) {
        newHour = newHour % 12 || 12; // Handle midnight and noon
        amPm = hourInt + addHours >= 12 ? (amPm === DAY_PART[0] ? DAY_PART[1] : DAY_PART[0]) : amPm;
      }
      if (newHour === 0) newHour = 12; // Adjust for midnight in 12-hour format
    } else {
      // 24-hour format logic
      newHour = hourInt + addHours;
      if (newHour >= 24) newHour %= 24; // Adjusts hour for the next day(s)
      amPm = undefined; // amPm is irrelevant in 24-hour format
    }

    const newTimeObject = {
      hour: newHour.toString().padStart(2, '0'),
      minute,
      amPm: is12HourFormat ? amPm : undefined,
    };
    return newTimeObject;
  };

  return (
    <Box display="flex" flexDirection="column" className={classes.addTimeOffWrapper}>
      <Typography variant="h2" className={classes.heading}>
        Add Time Off
      </Typography>
      {dataReady && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="type">
            <Typography variant="body1" color="textSecondary">
              Select Type:
            </Typography>
            <Controller
              name="type"
              control={control}
              render={({ onChange, value }) => (
                <RadioGroup
                  id="type"
                  value={value}
                  onChange={(e) => {
                    const { value } = e.target;
                    const {
                      hour,
                      minute: startMinutes,
                      amPm: startAmPm,
                    } = convertTimeObject(shiftTime, is12Format);

                    const startHours = +hour;
                    const partialTime = addObjectHours(
                      shiftTime,
                      4,
                      getTimeFormatHook().is12Format
                    );

                    const startHoursObj = [values.startDate, startHours, startMinutes, startAmPm];
                    const parsedStartDate = parse(
                      getTimeFormatHook(startHoursObj).formattedTimeDate,
                      getTimeFormatHook(startHoursObj).formatForDateTimePars,
                      new Date()
                    );

                    const isPartialSameDay = isSameDay(
                      parse(values.startDate, dateFormat, new Date()),
                      addHours(parsedStartDate, 4)
                    );
                    const partialDay = isPartialSameDay
                      ? values.startDate
                      : format(addHours(parsedStartDate, 4), dateFormat);

                    onChange(value);
                    setValue(
                      'endDate',
                      value === 'full'
                        ? getNextDay(parse(values.startDate, dateFormat, new Date()), dateFormat)
                        : partialDay
                    );

                    setValue('startHours', startHours);
                    setValue('startMinutes', startMinutes);
                    setValue('startAmPm', startAmPm);
                    setValue('endHours', value === 'full' ? startHours : partialTime.hour || 12);
                    setValue('endMinutes', startMinutes);
                    setValue(
                      'endAmPm',
                      value === 'full' ? DAY_PART[0] : partialTime.amPm || DAY_PART[0]
                    );
                    clearErrors();
                  }}
                  className={classes.radioGroup}
                  row
                >
                  <FormControlLabel
                    value="full"
                    control={<CustomRadioButton />}
                    label="Full Day(s)"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="partial"
                    control={<CustomRadioButton />}
                    label="Partial Day (4 hours)"
                    labelPlacement="end"
                  />
                </RadioGroup>
              )}
            />
          </label>
          <Typography variant="h4" color="textSecondary" className={classes.text}>
            Please select the time when you will be unavailable
          </Typography>
          <div className={classes.formInputWrapper}>
            <Box className={classes.startFormBig}>
              <Box className={classes.startForm}>
                <label htmlFor="startDate">
                  <Typography variant="body1" color="textSecondary">
                    Start Date:
                  </Typography>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ onChange, value }) => (
                      <DatePickerInputMobile
                        selectedDay={value}
                        setFieldValue={(_, value) => {
                          const { startHours, startMinutes, startAmPm } = values;
                          const shiftTime = {
                            hour: startHours,
                            minute: startMinutes,
                            amPm: startAmPm,
                          };
                          const partialTime = addObjectHours(
                            shiftTime,
                            4,
                            getTimeFormatHook().is12Format
                          );
                          const startHoursObj = [value, startHours, startMinutes, startAmPm];
                          const parsedStartDate = parse(
                            getTimeFormatHook(startHoursObj).formattedTimeDate,
                            getTimeFormatHook(startHoursObj).formatForDateTimePars,
                            new Date()
                          );
                          // const parsedStartDate = parse(
                          //   `${value} ${startHours}:${startMinutes} ${startAmPm}`,
                          //   dateFormatWithTime,
                          //   new Date()
                          // );
                          const isPartialSameDay = isSameDay(
                            parse(value, dateFormat, new Date()),
                            addHours(parsedStartDate, 4)
                          );
                          const partialDay = isPartialSameDay
                            ? value
                            : format(addHours(parsedStartDate, 4), dateFormat);

                          onChange(value);
                          setValue(
                            'endDate',
                            values.type === 'full'
                              ? getNextDay(parse(value, dateFormat, new Date()), dateFormat)
                              : partialDay
                          );
                          setValue(
                            'endHours',
                            values.type === 'full' ? startHours : partialTime.hour || 12
                          );
                          setValue(
                            'endAmPm',
                            values.type === 'full' ? DAY_PART[0] : partialTime.amPm || DAY_PART[0]
                          );
                        }}
                        field="startDate"
                        userWeekends={userWeekends}
                      />
                    )}
                  />
                </label>
                <label htmlFor="startHours" className={classes.startTimeWrapper}>
                  <Typography variant="body1" color="textSecondary">
                    Start Time:
                  </Typography>
                  <Box display="flex" className={classes.startTimeSelectorsWrapper}>
                    <Controller
                      name="startHours"
                      control={control}
                      render={({ onChange, value }) => (
                        <TextField
                          select
                          id="startHours"
                          value={value}
                          onChange={(e) => {
                            const { value } = e.target;
                            onChange(value);
                            const { startAmPm, endAmPm } = getValues();
                            if (values.type === 'full') {
                              setValue('endHours', value);
                              setValue('endAmPm', endAmPm);
                              const values = getValues();
                              validateEndAfterStart(
                                { ...values, startHours: value, endHours: value, endAmPm },
                                setError,
                                clearErrors,
                                getTimeFormatHook
                              );
                            } else {
                              let hourIncrement = 4;
                              let nextValue = parseInt(value, 10) + hourIncrement;
                              let nextEndDateValue;
                              let newEndHours;
                              let newEndAmPm = startAmPm;

                              if (getTimeFormatHook().is12Format) {
                                nextEndDateValue =
                                  startAmPm === DAY_PART[1] && value !== '12' && nextValue >= 12
                                    ? getNextDay(
                                        parse(values.startDate, dateFormat, new Date()),
                                        dateFormat
                                      )
                                    : values.startDate;
                                newEndHours = nextValue % 12 || 12;
                                newEndAmPm =
                                  nextValue >= 12 && value !== '12'
                                    ? values.startAmPm === DAY_PART[1]
                                      ? DAY_PART[0]
                                      : DAY_PART[1]
                                    : startAmPm;
                              } else {
                                nextEndDateValue =
                                  nextValue >= 24
                                    ? getNextDay(
                                        parse(values.startDate, dateFormat, new Date()),
                                        dateFormat
                                      )
                                    : values.startDate;
                                newEndHours = nextValue % 24;
                              }

                              setValue('endDate', nextEndDateValue);
                              setValue('endHours', newEndHours);
                              setValue('endAmPm', newEndAmPm);

                              validateEndAfterStart(
                                {
                                  ...values,
                                  endDate: nextEndDateValue,
                                  startHours: value,
                                  endHours: newEndHours,
                                  endAmPm: newEndAmPm,
                                },
                                setError,
                                clearErrors,
                                getTimeFormatHook
                              );
                            }
                            //  else {
                            //   const values = getValues();
                            //   const nextEndDateValue =
                            //     startAmPm === DAY_PART[1] && value !== '12' && +value + 4 >= 12
                            //       ? getNextDay(
                            //           parse(values.startDate, dateFormat, new Date()),
                            //           dateFormat
                            //         )
                            //       : values.startDate;
                            //   setValue('endDate', nextEndDateValue);
                            //   setValue('endHours', (+value + 4) % 12 || 12);
                            //   setValue(
                            //     'endAmPm',
                            //     +value + 4 >= 12 && value !== '12'
                            //       ? values.startAmPm === DAY_PART[1]
                            //         ? DAY_PART[0]
                            //         : DAY_PART[1]
                            //       : startAmPm
                            //   );
                            //   validateEndAfterStart(
                            //     {
                            //       ...values,
                            //       endDate: nextEndDateValue,
                            //       startHours: value,
                            //       endHours: (+value + 4) % 12 || 12,
                            //       endAmPm:
                            //         +value + 4 >= 12 && value !== '12'
                            //           ? values.startAmPm === DAY_PART[1]
                            //             ? DAY_PART[0]
                            //             : DAY_PART[1]
                            //           : startAmPm,
                            //     },
                            //     setError,
                            //     clearErrors,
                            //     getTimeFormatHook
                            //   );
                            // }
                          }}
                          className={classnames(classes.select, classes.time)}
                          error={Boolean(errors.startHours)}
                          variant="outlined"
                          inputProps={{ 'aria-label': 'Hours' }}
                          SelectProps={{
                            native: isIOS,
                          }}
                        >
                          {hours.map(createOptions)}
                        </TextField>
                      )}
                    />
                    <Controller
                      name="startMinutes"
                      control={control}
                      render={({ onChange, value }) => (
                        <TextField
                          select
                          id="startMinutes"
                          value={value}
                          onChange={(e) => {
                            const { value } = e.target;
                            onChange(value);
                            setValue('endMinutes', value);

                            const values = getValues();
                            validateEndAfterStart(
                              { ...values, startMinutes: value, endMinutes: value },
                              setError,
                              clearErrors,
                              getTimeFormatHook
                            );
                          }}
                          className={classnames(classes.select, classes.time)}
                          error={Boolean(errors.startHours)}
                          variant="outlined"
                          inputProps={{ 'aria-label': 'Minutes' }}
                          SelectProps={{
                            native: isIOS,
                          }}
                        >
                          {minutes.map(createOptions)}
                        </TextField>
                      )}
                    />
                    {getTimeFormatHook().is12Format && (
                      <Controller
                        name="startAmPm"
                        control={control}
                        render={({ onChange, value }) => (
                          <TextField
                            select
                            id="startAmPm"
                            value={value}
                            onChange={(e) => {
                              const { value } = e.target;
                              onChange(value);
                              const values = getValues();
                              if (values.type === 'full') {
                                setValue('endAmPm', value);
                                setValue('endHours', values.startHours);
                                validateEndAfterStart(
                                  { ...values, endAmPm: value, endHours: values.startHours },
                                  setError,
                                  clearErrors,
                                  getTimeFormatHook
                                );
                              } else {
                                const nextEndDateValue =
                                  value === DAY_PART[1] &&
                                  values.startHours !== '12' &&
                                  +values.startHours + 4 >= 12
                                    ? getNextDay(
                                        parse(values.startDate, dateFormat, new Date()),
                                        dateFormat
                                      )
                                    : values.startDate;
                                setValue('endDate', nextEndDateValue);
                                setValue('endHours', (+values.startHours + 4) % 12 || 12);
                                setValue(
                                  'endAmPm',
                                  +values.startHours + 4 >= 12 && values.startHours !== '12'
                                    ? value === DAY_PART[1]
                                      ? DAY_PART[0]
                                      : DAY_PART[1]
                                    : value
                                );
                                validateEndAfterStart(
                                  {
                                    ...values,
                                    startAmPm: value,
                                    endDate: nextEndDateValue,
                                    endHours: (+values.startHours + 4) % 12 || 12,
                                    endAmPm:
                                      +values.startHours + 4 >= 12 && values.startHours !== '12'
                                        ? value === DAY_PART[1]
                                          ? DAY_PART[0]
                                          : DAY_PART[1]
                                        : value,
                                  },
                                  setError,
                                  clearErrors,
                                  getTimeFormatHook
                                );
                              }
                            }}
                            className={classnames(classes.select, classes.time)}
                            error={Boolean(errors.startHours)}
                            variant="outlined"
                            inputProps={{ 'aria-label': 'AmPm' }}
                            SelectProps={{
                              native: isIOS,
                            }}
                          >
                            {dayPart.map(createOptions)}
                          </TextField>
                        )}
                      />
                    )}
                  </Box>
                  {/*{errors.startHours ? <div className={classes.error}>{errors.startHours.message}</div> : null}*/}
                </label>
              </Box>
              <Box className={classes.startForm}>
                <label htmlFor="endDate">
                  <Typography variant="body1" color="textSecondary">
                    End Date:
                  </Typography>
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ onChange, value }) => (
                      <DatePickerInputMobile
                        selectedDay={value}
                        setFieldValue={(_, value) => onChange(value)}
                        field="endDate"
                        minDate={getNextDay(
                          parse(values.startDate, dateFormat, new Date()),
                          dateFormat
                        )}
                        userWeekends={userWeekends}
                        disabled={values.type === 'partial'}
                      />
                    )}
                  />
                </label>
                <label htmlFor="endHours" className={classes.startTimeWrapper}>
                  <Typography variant="body1" color="textSecondary">
                    End Time:
                  </Typography>
                  <Box display="flex" className={classes.startTimeSelectorsWrapper}>
                    <Controller
                      name="endHours"
                      control={control}
                      render={({ onChange, value }) => (
                        <TextField
                          select
                          id="endHours"
                          value={value}
                          onChange={(e) => {
                            const { value } = e.target;
                            onChange(value);
                            const values = getValues();
                            validateEndAfterStart(
                              { ...values, endHours: value },
                              setError,
                              clearErrors,
                              getTimeFormatHook
                            );
                          }}
                          className={classnames(classes.select, classes.time)}
                          error={Boolean(errors.startHours)}
                          variant="outlined"
                          inputProps={{ 'aria-label': 'Hours' }}
                          SelectProps={{
                            native: isIOS,
                          }}
                        >
                          {hours.map(createOptions)}
                        </TextField>
                      )}
                    />
                    <Controller
                      name="endMinutes"
                      control={control}
                      render={({ onChange, value }) => (
                        <TextField
                          select
                          id="endMinutes"
                          value={value}
                          onChange={(e) => {
                            const { value } = e.target;
                            onChange(value);
                            const values = getValues();
                            validateEndAfterStart(
                              { ...values, endMinutes: value },
                              setError,
                              clearErrors,
                              getTimeFormatHook
                            );
                          }}
                          className={classnames(classes.select, classes.time)}
                          error={Boolean(errors.startHours)}
                          variant="outlined"
                          inputProps={{ 'aria-label': 'Minutes' }}
                          SelectProps={{
                            native: isIOS,
                          }}
                        >
                          {minutes.map(createOptions)}
                        </TextField>
                      )}
                    />
                    {getTimeFormatHook().is12Format && (
                      <Controller
                        name="endAmPm"
                        control={control}
                        render={({ onChange, value }) => (
                          <TextField
                            select
                            id="endAmPm"
                            value={value}
                            onChange={(e) => {
                              const { value } = e.target;
                              onChange(value);
                              const values = getValues();
                              validateEndAfterStart(
                                { ...values, endAmPm: value },
                                setError,
                                clearErrors,
                                getTimeFormatHook
                              );
                            }}
                            className={classnames(classes.select, classes.time)}
                            error={Boolean(errors.startHours)}
                            variant="outlined"
                            inputProps={{ 'aria-label': 'AmPm' }}
                            SelectProps={{
                              native: isIOS,
                            }}
                          >
                            {dayPart.map(createOptions)}
                          </TextField>
                        )}
                      />
                    )}
                  </Box>
                  {errors.startHours ? (
                    <div className={classes.error} style={{ position: 'absolute' }}>
                      {errors.startHours.message}
                    </div>
                  ) : null}
                </label>
              </Box>
            </Box>
            <Box className={classes.startFormBig}>
              <Box className={classes.startForm}>
                <label htmlFor="isPTO" className={classes.pto}>
                  <Typography variant="body1" color="textSecondary">
                    PTO (paid time off):
                  </Typography>
                  <Controller
                    name="isPTO"
                    control={control}
                    render={({ value, onChange }) => (
                      <IOSSwitch
                        name="isPTO"
                        checked={value}
                        onChange={(_, value) => onChange(value)}
                        value="isPTO"
                      />
                    )}
                  />
                </label>
                <label htmlFor="reason">
                  <Typography variant="body1" color="textSecondary">
                    Reason:
                  </Typography>
                  <Controller
                    name="reason"
                    control={control}
                    as={
                      <TextField
                        select
                        id="reason"
                        name="reason"
                        variant="outlined"
                        fullWidth
                        className={classnames(classes.select, classes.selectMob)}
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        {reasons.map(createOptions)}
                      </TextField>
                    }
                  />
                  {isSubtractedErrorShown && (
                    <div
                      className={classnames(
                        classes.error,
                        classes.commonSubtractedError,
                        isNotSubtractedAvailablePTO
                          ? classes.isNotSubtractedAvailablePTO
                          : classes.willSubtractedAvailablePTO
                      )}
                    >
                      {isNotSubtractedAvailablePTO
                        ? ERROR_MESSAGES.isNotSubtractedAvailablePTO
                        : ERROR_MESSAGES.willSubtractedAvailablePTO}
                    </div>
                  )}
                </label>
              </Box>
              <Box className={classes.startForm}>
                {isBereavement && (
                  <label htmlFor="relationship">
                    <Typography variant="body1" color="textSecondary">
                      Relationship:
                    </Typography>
                    <Controller
                      name="relationship"
                      control={control}
                      rules={{
                        required: "This field can't be blank",
                      }}
                      as={
                        <TextField
                          select
                          defaultValue=""
                          id="relationship"
                          name="relationship"
                          variant="outlined"
                          fullWidth
                          error={Boolean(errors.relationship)}
                          className={classnames(classes.select, classes.relationshipBlock)}
                          SelectProps={{
                            native: isIOS,
                          }}
                        >
                          {relations.map(createOptions)}
                        </TextField>
                      }
                    />
                    {errors.relationship ? (
                      <div className={classnames(classes.error, classes.errorMob)}>
                        {errors.relationship.message}
                      </div>
                    ) : null}
                  </label>
                )}
                <label htmlFor="notes" style={{ width: isBereavement ? 'unset' : '100%' }}>
                  <Typography variant="body1" color="textSecondary">
                    {isBereavement ? 'Name' : 'Notes'}:
                  </Typography>
                  <Controller
                    name="notes"
                    control={control}
                    rules={{
                      required: isBereavement && "This field can't be blank",
                      validate: (value) =>
                        value.trim().length <= 50 ||
                        'The field cannot contain more than 50 symbols. Please, check.',
                    }}
                    as={
                      <TextField
                        id="notes"
                        name="notes"
                        maxRows={2}
                        error={Boolean(errors.notes)}
                        className={classes.textArea}
                        variant="outlined"
                        multiline={!isBereavement}
                      />
                    }
                  />
                  {errors.notes ? (
                    <div className={classes.error}>{errors.notes.message}</div>
                  ) : null}
                </label>
              </Box>
            </Box>
            <Box>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={classes.submit}
                disabled={isSubmitting || !!Object.keys(errors).length}
                disableTouchRipple
              >
                Request Timeoff
              </Button>
            </Box>
          </div>
        </form>
      )}
      {!!confirmDialogShown && (
        <ConfirmDialog
          onClose={closeConfirmDialog}
          text={
            <span>
              {`${confirmDialogShown}. Please, confirm to proceed`.split('. ').map((line) => (
                <>
                  <span key={line}>{line}.</span> <br />
                </>
              ))}
            </span>
          }
          isOpen
          onSubmit={handleConfirmSubmit}
        />
      )}
    </Box>
  );
};

export default AddTimeOff;
