import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { format, parse, isValid } from 'date-fns';
import { formatDate, parseDate } from 'react-day-picker/moment';

import './../DateInput/styles.scss';
import './../DatePicker/styles.css';
import './styles.css';
import { connect } from 'react-redux';
import { getWeekStartDayNumO } from 'helpers/_helpers';
import { dateSettingsFormat, momentAdepter } from 'common/dateFormatConfig';

class DatePickerInput extends Component {
  handleDayChange = (day) => {
    this.props.setFieldValue(this.props.field, format(new Date(day), this.props.dateFormat));
  };

  createDisabledDayRules = () => {
    const disabledDays = [];
    if (this.props.maxDate) {
      disabledDays.push({ after: parse(this.props.maxDate, this.props.dateFormat, new Date()) });
    }
    if (this.props.minDate) {
      disabledDays.push({ before: parse(this.props.minDate, this.props.dateFormat, new Date()) });
    }
    return disabledDays;
  };

  formatSelectedDate = () => {
    return typeof this.props.selectedDay === 'string'
      ? isValid(parse(this.props.selectedDay, this.props.dateFormat, new Date()))
        ? parse(this.props.selectedDay, this.props.dateFormat, new Date())
        : parse(this.props.selectedDay, this.props.dateFormat, new Date())
      : this.props.selectedDay;
  };

  render() {
    const classname1 = this.props.field === 'endDate' ? 'endDatePicker' : '';
    const className2 = this.props.alignRight ? 'endDatePicker' : '';
    const className3 = this.props.fullWidth ? 'fullWidth' : '';
    const className4 = this.props.sm ? 'sm' : '';

    const modifiers = {
      disabled: this.createDisabledDayRules(),
    };
    const { userWeekends, weekStart } = this.props;
    if (userWeekends && userWeekends.length) {
      modifiers.weekends = { daysOfWeek: userWeekends };
    }

    return (
      <div className={`${classname1} ${className2} ${className3} ${className4}`}>
        <DayPickerInput
          formatDate={formatDate}
          parseDate={parseDate}
          format={
            momentAdepter(dateSettingsFormat(this.props.dateFormat, this.props.timeFormat).dayDateSettingsFormat)
              .momentShortDayDate
          } //* @ "ddd MM-DD-YYYY"(settings format for ex. MM-DD-YYYY)
          value={this.formatSelectedDate()}
          onDayChange={this.handleDayChange}
          inputProps={{
            disabled: !!this.props.disabled,
          }}
          dayPickerProps={{
            firstDayOfWeek: getWeekStartDayNumO(weekStart),
            selectedDays: this.formatSelectedDate(),
            modifiers,
          }}
        />
      </div>
    );
  }
}

// export default DatePickerInput;
export default connect((state) => ({
  weekStart: state.settings?.settings?.weekStart || 'Sun',
  dateFormat: state.personalProfile.organization?.settings?.dateFormat,
  timeFormat: state.personalProfile.organization?.settings?.timeFormat,
}))(DatePickerInput);
