import React, { useCallback, useState, useMemo } from 'react';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PageLoader from 'components/PageLoader';
import UserDetails from './components/UserDetails/UserDetails';
import UserPermissions from './components/UserPermissions/UserPermissions';
import UserPhotoPlaceholderIcon from 'assets/images/user-photo-placeholder.svg';
import { extPermissions, userTypes } from 'common/permissions';

import styles from './styles';
import { rolePermissions } from '../../../../../People/components/UserForm/components/UserPermissions/helpers/rolePermissions';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { convertTimeObject, convertTo24HourFormat } from 'common/dateFormatConfig';

const validation = (values) => {
  const errors = {};

  if (!values.username) {
    errors.username = "This field can't be blank";
  } else {
    if (!/^\S*$/.test(values.username)) {
      errors.username = 'No spaces allowed';
    }
  }

  if (!values.profile?.fullName?.trim()) {
    errors.fullName = "This field can't be blank";
  }

  if (!values.profile?.HICode.trim()) {
    errors.HICode = "This field can't be blank";
  }
  if (!values.profile?.employeeNum) {
    errors.employeeNum = "This field can't be blank";
  } else {
    if (!/^[a-zA-Z0-9]+$/i.test(values.employeeNum)) {
      errors.employeeNum =
        'The value is invalid. The field can contain alphanumeric characters only';
    }
    // const uniqueError = UsersService.checkUniqueEmployeeNum(values.employeeNum, values.userId);
    // if (uniqueError) errors.employeeNum = uniqueError;
  }
  if (!values.profile?.email) {
    errors.email = "This field can't be blank";
  } else {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.profile.email)) {
      errors.email = 'Invalid email address';
    }
    if (values.profile.email.length > 50) {
      errors.email = 'The field cannot contain more than 50 symbols. Please, check';
    }
    if (!/^\S*$/.test(values.profile.email)) {
      errors.email = 'No spaces allowed';
    }
  }

  if (values.profile?.phoneNumber?.trim()) {
    if (!/^[0-9._() +-]+$/i.test(values.profile.phoneNumber.trim())) {
      errors.phoneNumber = 'The phone number is invalid. Please, check';
    }
    const digits = values.profile.phoneNumber.replace(/[^0-9]*/g, '');
    if (digits.length !== 10) {
      errors.phoneNumber = 'Phone number should have 10 digits';
    }
  }

  if (!`${values.profile?.timeoff?.allowedTime}`.trim()) {
    errors.allowedTime = "This field can't be blank";
  }

  if (
    values?.profile?.timeoff?.allowedTime &&
    !/^[0-9]+$/i.test(values.profile.timeoff.allowedTime)
  ) {
    errors.allowedTime = 'The number is invalid. The field can contain figures only';
  }

  return errors;
};

const UserForm = ({
  classes,
  closeDrawer,
  editingUser = null,
  clientsData,
  editUser,
  deleteUser,
  isCreating,
}) => {
  const [tabValue, setTabValue] = useState(0);
  const getTimeFormatHook = useTimeSettingsFormat();
  const is12Format = getTimeFormatHook().is12Format;
  const handleChangeTab = (event, newValue) => setTabValue(newValue);
  const adminPermissions = useMemo(() => {
    const result = {};
    rolePermissions[userTypes.admin].forEach((permission) => (result[permission] = false));
    return result;
  }, []);

  const initialValues = useMemo(
    () =>
      isCreating
        ? {
            username: '',
            profile: {
              fullName: '',
              email: '',
              phoneNumber: '',
              employeeNum: '',
              HICode: '',
              role: {
                roleName: userTypes.admin,
                extPermissions: adminPermissions,
              },
              shifts: {
                timeOfDay: 'Day',
                weekDays: 'Mon-Fri',
              },
              timeoff: {
                allowedTime: 10, //!
              },
              emergencyContacts: null,
              individualStartTime: {
                hours: '',
                minutes: '',
                amPm: '',
              },
            },
            organizationId: '',
          }
        : {
            username: editingUser.username,
            profile: {
              fullName: editingUser.profile?.fullName,
              email: editingUser.profile?.email,
              phoneNumber: editingUser.profile?.phoneNumber,
              employeeNum: editingUser.profile?.employeeNum,
              HICode: editingUser.profile?.HICode,
              shifts: {
                timeOfDay: editingUser?.profile?.shifts?.timeOfDay,
                weekDays: editingUser?.profile?.shifts?.weekDays,
              },
              role: {
                roleName: editingUser.profile?.role?.roleName || userTypes.fieldTechnician,
                extPermissions:
                  editingUser.profile?.role?.extPermissions ||
                  extPermissions[userTypes.fieldTechnician],
              },
              timeoff: {
                allowedTime: editingUser.profile?.timeoff?.allowedTime || 0,
              },
              emergencyContacts: editingUser.profile?.emergencyContacts || [],
              individualStartTime: {
                hours: editingUser.profile?.individualStartTime?.hour
                  ? convertTimeObject(editingUser.profile?.individualStartTime, is12Format).hour
                  : '',
                minutes: editingUser.profile?.individualStartTime?.minute || '',
                amPm: editingUser.profile?.individualStartTime?.hour
                  ? convertTimeObject(editingUser.profile?.individualStartTime, is12Format).amPm
                  : '',
              },
            },
            organizationId: editingUser.tenantId,
          },
    [editingUser]
  );

  const handleDeleteUser = useCallback(() => {
    deleteUser(editingUser);
  }, [editingUser]);

  return (
    <div className={classes.formWrapper} role="presentation">
      <Formik
        validate={validation}
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values) => {
          if (values.profile?.timeoff?.allowedTime) {
            values.profile.timeoff.allowedTime = +values.profile.timeoff.allowedTime;
          }
          if (
            !values.profile?.individualStartTime?.hours &&
            !values.profile?.individualStartTime?.minutes &&
            !values.profile?.individualStartTime?.amPm
          ) {
            values.profile.individualStartTime = null;
          } else {
            values.profile.individualStartTime = {
              hour: convertTo24HourFormat(
                values.profile.individualStartTime.hours,
                values.profile.individualStartTime.amPm
              ),
              minute: values.profile?.individualStartTime?.minutes,
            };
          }
          editUser(values);
        }}
      >
        {({ errors, touched, values, handleChange, handleBlur, setFieldValue }) => (
          <PageLoader loading={false}>
            <Form autoComplete="off" className={classes.formFlex}>
              <div>
                <Typography variant="h3" className={classes.marginHeader}>
                  {isCreating ? 'Create Person' : 'Edit Person'}
                </Typography>

                <IconButton
                  className={classes.closeButton}
                  onClick={closeDrawer}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>

                {!isCreating && (
                  <Grid container>
                    <Grid item xs={12} md={8}>
                      <div className={classes.flexBox}>
                        <div className={classes.userPhotoWrapper}>
                          <img
                            src={UserPhotoPlaceholderIcon}
                            alt="User"
                            className={classes.userPhoto}
                          />
                        </div>
                        <div className={classes.fixedWidth}>
                          <Typography variant="h5" className={classes.fixedHeight}>
                            {editingUser.profile?.fullName || editingUser?.username}
                          </Typography>
                          <Typography variant="body1" color="textSecondary">
                            {editingUser.profile?.role?.roleName || ''}
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Button
                        color="secondary"
                        className={classes.deleteButton}
                        onClick={handleDeleteUser}
                        disableTouchRipple
                      >
                        <CloseIcon />
                        Delete User
                      </Button>
                    </Grid>
                  </Grid>
                )}
                <Tabs value={tabValue} onChange={handleChangeTab} className={classes.tabs}>
                  <Tab label="User details" disableRipple />
                  <Tab label="User permissions" disableRipple />
                </Tabs>
                {tabValue === 0 && (
                  <UserDetails
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    clientsData={clientsData}
                  />
                )}
                {tabValue === 1 && (
                  <UserPermissions
                    values={values}
                    editingUser={editingUser}
                    setFieldValue={setFieldValue}
                  />
                )}
              </div>
              <div className={classes.buttonHolder}>
                <Button color="secondary" className={classes.cancelButton} onClick={closeDrawer}>
                  Cancel
                </Button>
                <div className={classes.verticalDivider} />
                <Button type="submit" color="primary" className={classes.saveButton}>
                  Save
                </Button>
              </div>
            </Form>
          </PageLoader>
        )}
      </Formik>
    </div>
  );
};

export default withStyles(styles)(UserForm);
