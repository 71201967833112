import React from 'react';
import { Grid, Typography, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import styles from './styles';
import { Option, isIOS } from '../../../../../../../../components/Option';

const UnitsType = ({ errors, classes, values, handleChange, handleBlur }) => {
  const materials = [
    {
      name: 'Volume (Linear Feet (LF) / Gallon)',
      type: 'volume',
    },
    { name: 'Weight (Linear Feet (LF)/Pound)', type: 'weight' },
    { name: 'Each', type: 'each' },
  ];
  return (
    <Grid container spacing={1} style={{ justifyContent: 'space-between', marginBottom: '10px' }}>
      <Grid item xs={12} lg={6}>
        <label htmlFor="name">
          <Typography variant="body1" color="textSecondary">
            Material Name:
          </Typography>
          <TextField
            id="name"
            name="name"
            variant="outlined"
            className="materials-input"
            error={Boolean(errors.name)}
            onBlur={handleBlur}
            InputProps={{
              className: `${errors.name ? 'materials-input--error' : ''}`,
            }}
            fullWidth={true}
            defaultValue={values.name || ''}
            onChange={handleChange}
          />
        </label>
        {errors.name && <span className="materials-input--error-text">{errors.name}</span>}
      </Grid>
      <Grid item xs={12} lg={6}>
        <label htmlFor="type">
          <Typography variant="body1" color="textSecondary">
            Unit of Measurement:
          </Typography>
          <TextField
            select
            id="type"
            name="type"
            error={Boolean(errors.type)}
            variant="outlined"
            InputProps={{
              className: `${errors.type ? 'materials-input--error' : ''}`,
              style: {
                  height: '35px',
                },
            }}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            defaultValue={values.type || ''}
            SelectProps={{
              native: isIOS,
            }}
          >
            {materials.map((m) => (
              <Option key={m.type} value={m.type}>
                {m.name}
              </Option>
            ))}
          </TextField>
        </label>
        {errors.type && <span className="materials-input--error-text">Field is required</span>}
      </Grid>
    </Grid>
  );
};
export default withStyles(styles)(UnitsType);
