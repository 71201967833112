export default (theme) => ({
  workShiftBlock: {
    paddingBottom: 20,
  },
  scheduleNotificationBlock: {
    textAlign: 'right',
  },
  settingsDetailsBlock: {
    paddingBottom: 24,
  },
  secondaryText: {
    color: theme.palette.textColor.secondary,
    fontSize: '0.8rem',
    fontWeight: 400,
    marginRight: 10,
    fontFamily: 'Poppins,Open Sans,sans-serif',
  },
  inlineBlock: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  regularFontWeight: {
    fontWeight: 300
  },
  paddingBottom: {
    paddingBottom: 16
  },
  shiftImage: {
    height: 16,
    margin: '0 5px 2px 0',
  },
  primaryTextButton: {
    padding: 0,
    color: theme.palette.textColor.primary,
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`
    }
  },
})