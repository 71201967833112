import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import styles from './styles';
import { getEquipmentColorClass } from 'helpers/_helpers';
import { generateHoursObject } from 'helpers/helpers';
import { getTimeFormat } from 'common/dateFormatConfig';
import { connect } from 'react-redux';
import { shortDate } from 'helpers/_date-helpers';

class EquipmentSection extends PureComponent {
  state = {
    expanded: false,
  };

  toggleExpand = () => this.setState((prevState) => ({ expanded: !prevState.expanded }));

  getEquipmentInfo = (currentEquipment, field) => {
    if (!currentEquipment) return '';
    if (field === 'number') {
      return parseInt(currentEquipment.name);
    }
    if (field === 'className') {
      return getEquipmentColorClass(currentEquipment.color);
    }
    return currentEquipment[field];
  };

  formatHours = (hourValueObj) => {
    const { sheet } = this.props;
    const h = hourValueObj;
    const getTimeFormatHook = (hourObj) =>
      getTimeFormat(hourObj, this.props.timeFormat, this.props.dateFormat);

    const { hourStart, minuteStart, amPmStart, dayStart, hourEnd, minuteEnd, amPmEnd, dayEnd } =
      generateHoursObject(h.start, h.end, sheet.createdAt, getTimeFormatHook);

    const startObj = [null, hourStart, minuteStart, amPmStart];
    const endObj = [null, hourEnd, minuteEnd, amPmEnd];

    const start = `${getTimeFormatHook(startObj).formattedTime} ${shortDate(
      sheet.createdAt,
      dayStart,
      getTimeFormatHook().localDayMonth
    )}`;
    const end = `${getTimeFormatHook(endObj).formattedTime} ${shortDate(
      sheet.createdAt,
      dayEnd,
      getTimeFormatHook().localDayMonth
    )}`;

    return `${start} \u2013 ${end}`;
  };

  render() {
    const { classes, sheet } = this.props;
    const { expanded } = this.state;

    return (
      !!(sheet.equipment && sheet.equipment.length) && (
        <>
          <Grid item xs={12} className={classes.paddingBottomSmall}>
            <div
              className={classnames(
                classes.equipmentBlock,
                this.getEquipmentInfo(sheet.equipment[0], 'className'),
                classes.marginBottom
              )}
              style={{
                backgroundColor: this.getEquipmentInfo(sheet.equipment[0], 'color'),
              }}
            >
              <div className={classes.equipmentNumber}>
                {this.getEquipmentInfo(sheet.equipment[0], 'name')}
              </div>
            </div>
            {sheet.equipment[0].hours && (
              <div>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
                >
                  On Job Time:
                </Typography>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  className={classes.paddingBottomSmall}
                >
                  {this.formatHours(sheet.equipment[0].hours || sheet.hours)}
                </Typography>
              </div>
            )}
          </Grid>

          {sheet.equipment.length > 1 && (
            <Grid item xs={12} className={classes.paddingBottom}>
              <CardActions
                disableSpacing
                onClick={this.toggleExpand}
                className={classes.cardActionsWrapper}
              >
                <Typography className={classnames(classes.primaryColor, classes.mediumFontWeight)}>
                  Other equipment
                </Typography>
                <IconButton
                  className={classnames(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                  aria-expanded={expanded}
                  aria-label="Show more"
                  disableRipple
                  disableFocusRipple
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                {sheet.equipment.map((equipment, i, arr) =>
                  i !== 0 ? (
                    <div key={equipment._id} className={classes.paddingBottomSmall}>
                      <div
                        className={classnames(
                          classes.equipmentBlock,
                          this.getEquipmentInfo(equipment, 'className'),
                          classes.marginBottom
                        )}
                        style={{
                          backgroundColor: this.getEquipmentInfo(equipment, 'color'),
                        }}
                      >
                        <div className={classes.equipmentNumber}>
                          {this.getEquipmentInfo(equipment, 'name')}
                        </div>
                      </div>
                      {equipment.hours && (
                        <div>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            className={classnames(
                              classes.regularFontWeight,
                              classes.paddingBottomSmall
                            )}
                          >
                            On Job Time:
                          </Typography>
                          <Typography
                            variant="body1"
                            color="textPrimary"
                            className={classes.paddingBottomSmall}
                          >
                            {this.formatHours(equipment.hours)}
                          </Typography>
                        </div>
                      )}
                      {i + 1 !== arr.length && (
                        <div className={classes.dividerWrapper}>
                          <Divider />
                        </div>
                      )}
                    </div>
                  ) : null
                )}
              </Collapse>
            </Grid>
          )}
        </>
      )
    );
  }
}

EquipmentSection.propTypes = {
  classes: propTypes.object.isRequired,
  sheet: propTypes.object.isRequired,
};

export default connect((state) => ({
  dateFormat: state.personalProfile.organization?.settings?.dateFormat,
  timeFormat: state.personalProfile.organization?.settings?.timeFormat,
}))(withStyles(styles)(EquipmentSection));
