import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useController } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from './styles';
import CloseIcon from 'assets/images/close-icon.svg';
import cn from 'classnames';

const DocuSignSigner = ({ classes, pageType, control }) => {
  const settings = useSelector((state) => state.settings.settings);
  const [isShowEmails, setShowEmails] = useState(false);

  const {
    field: { onChange, value: docuSignSigner },
  } = useController({
    name: 'docuSignSigner',
    control,
    defaultValue: settings?.docuSignSigner || { name: '', email: '' },
  });

  useEffect(() => {
    onChange(settings?.docuSignSigner || { name: '', email: '' });
  }, [settings?.docuSignSigner]);

  const handleNotificationChange = (fieldName) => (e) => {
    const { value } = e.target;
    const nextValue = { ...docuSignSigner, [fieldName]: value };
    onChange(nextValue);
  };

  const handleEmailRemoving = () => {
    onChange({ name: '', email: '' });
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4">DocuSign Signer Email</Typography>
      <div className={classes.actionsWrapper} style={{ marginTop: '15px' }}>
        {isShowEmails && (
          <div className={classes.emailsWrapper}>
            <Grid container spacing={5} alignItems="center">
              <Grid item xs={10} sm={6} md={3} className={classes.zeroPaddingTop}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classes.regularFontWeight}
                >
                  Name:
                </Typography>

                <TextField
                  id={`docuSignSigner.name`}
                  name={`docuSignSigner.name`}
                  type="text"
                  value={docuSignSigner.name}
                  className={classes.textField}
                  onChange={handleNotificationChange('name')}
                  variant="outlined"
                  inputProps={{ 'aria-label': 'Name Notifications' }}
                  fullWidth
                  disabled={pageType === 'read'}
                />
              </Grid>
              <Grid item xs={10} sm={6} md={3} className={classes.zeroPaddingTop}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classes.regularFontWeight}
                >
                  Email:
                </Typography>

                <TextField
                  id={`docuSignSigner.email`}
                  name={`docuSignSigner.email`}
                  type="email"
                  value={docuSignSigner.email}
                  className={classes.textField}
                  onChange={handleNotificationChange('email')}
                  variant="outlined"
                  inputProps={{ 'aria-label': 'Email Notifications' }}
                  fullWidth
                  disabled={pageType === 'read'}
                />
              </Grid>
              {pageType === 'edit' && (
                <span
                  className={classes.removeEmail}
                  onClick={handleEmailRemoving}
                  style={{ marginTop: 15 }}
                >
                  <img src={CloseIcon} alt="delete" />
                </span>
              )}
              <Grid item xs={12} className={classes.mobileDivider}>
                <Divider style={{ margin: '1px 0 15px' }} />
              </Grid>
            </Grid>
          </div>
        )}

        <div
          className={classes.buttonWrapper}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowEmails((prev) => !prev);
          }}
        >
          <div className={classes.nonActionsIcon}>
            <ExpandMoreIcon className={cn(isShowEmails ? '' : classes.reversIcon)} />
          </div>
          <p className={classes.textIconMore}>{`${
            isShowEmails ? 'Hide' : 'Show'
          } email address`}</p>
        </div>
      </div>
    </div>
  );
};

DocuSignSigner.propTypes = {
  classes: propTypes.object.isRequired,
  pageType: propTypes.string.isRequired,
  errors: propTypes.object.isRequired,
  control: propTypes.object.isRequired,
};

export default withStyles(styles)(DocuSignSigner);
