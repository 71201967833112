import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useController } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from '../ForceAccountsBlock/styles';
import CloseIcon from 'assets/images/close-icon.svg';
import cn from 'classnames';

const PayrollBlock = ({ classes, pageType, control, errors }) => {
  const { payrollAdminEmail } = useSelector((state) => state.settings.settings);

  const {
    field: { onChange, value: payrollValues },
  } = useController({
    name: 'payrollAdminEmail',
    control,
    defaultValue: [],
  });

  const [isShowEmailsPayroll, setShowEmailsPayroll] = useState(false);

  useEffect(() => {
    onChange(payrollAdminEmail || []);
  }, [payrollAdminEmail]);

  const handleEmailChange = (i) => (e) => {
    const { value } = e.target;
    const nextValue = payrollValues.map((t, index) => (index === i ? value : t));
    onChange(nextValue);
  };
  const handleEmailRemoving = (i) => () => {
    const nextValue = payrollValues.filter((_, index) => index !== i);
    onChange(nextValue);
  };

  const handleAddEmail = () => {
    setShowEmailsPayroll(true);
    onChange([...payrollValues.filter((v) => v.length), ''])

  };

  return (
    <div className={classes.root}>
      <Typography variant="h4">Payroll Admin</Typography>
      <div className={classes.actionsWrapper}>
        {pageType === 'edit' && (
          <Button
            variant="text"
            className={classes.primaryTextButton}
            onClick={handleAddEmail}
            disableRipple
          >
            + Add Email
          </Button>
        )}
        {isShowEmailsPayroll &&
          payrollValues.map((email, index) => (
            <Grid container spacing={5} key={`payrollAdminEmail-${index}`} alignItems="center">
              <Grid item xs={10} sm={6} md={3} className={classes.zeroPaddingTop}>
                <TextField
                  id={`payrollAdminEmail[${index}]`}
                  name={`payrollAdminEmail[${index}]`}
                  type="email"
                  value={email}
                  className={classes.textField}
                  onChange={handleEmailChange(index)}
                  variant="outlined"
                  inputProps={{ 'aria-label': 'Payroll Admin Email' }}
                  fullWidth
                  disabled={pageType === 'read'}
                />
              </Grid>
              {pageType === 'edit' && (
                <span className={classes.removeEmail} onClick={handleEmailRemoving(index)}>
                  <img src={CloseIcon} alt="delete" />
                </span>
              )}
            </Grid>
          ))}
        {!!payrollValues.length && (
          <div
            className={classes.buttonWrapper}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowEmailsPayroll((prev) => !prev);
            }}
          >
            <div className={classes.nonActionsIcon}>
              <ExpandMoreIcon className={cn(isShowEmailsPayroll ? '' : classes.reversIcon)} />
            </div>
            <p className={classes.textIconMore}>{`${
              isShowEmailsPayroll ? 'Hide' : 'Show'
            } email addresses`}</p>
          </div>
        )}
      </div>
    </div>
  );
};

PayrollBlock.propTypes = {
  classes: propTypes.object.isRequired,
  pageType: propTypes.string.isRequired,
  errors: propTypes.object.isRequired,
};

export default withStyles(styles)(PayrollBlock);
