import React, { useEffect } from 'react';
import './styles.scss';
import HoursContainer from '../Hours/Hours';
import CountDownTimer from '../CountDownTimer/CountDownTimer';
import PieChart from '../PieChart/PieChart';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getSettings } from 'store/settings/settingsOperations';
import settingsSelectors from 'store/settings/settingsSelectors';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { hasPermissionsFor } from 'helpers/_helpers';
import { onDSLogin } from 'pages/Settings/components/WorkShiftsHeader/WorkShiftsHeader';

const GeneralInfoBlock = ({ user, data, isDocuSignUnauthorized, docuSignPermissions }) => {
  const navigate = useNavigate();
  const matches = useMediaQuery(
    'screen and (max-width: 1367px) and (orientation: landscape), (max-width: 1024px)'
  );

  const kioskPath =
    matches && hasPermissionsFor('kioskModeMobile') ? '/mobile-kiosk' : '/remote-kiosk';

  const path = `${process.env.REACT_APP_BASE_URL}/settings/homepage-image?imageKey=`;
  const userRole = user.profile.role.roleName;
  const remoteKioskPermision = hasPermissionsFor('remoteKiosk');
  const permissionScheduleButton =
    userRole !== 'Accounting' && userRole !== 'Machine Shop' && userRole !== 'Fleet Maintenance';
  const permissionWorklogButton =
    userRole !== 'Machine Shop' && userRole !== 'Accounting' && userRole !== 'Fleet Maintenance';

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  const settingsIsReady = useSelector(settingsSelectors.getAllSettings);
  const image = useSelector(settingsSelectors.getImage);
  const title = useSelector(settingsSelectors.getTitle);
  const toggleCountdownEnabled = useSelector(settingsSelectors.getToggleCountdownEnabled);
  const titleColor = useSelector(settingsSelectors.getTitleColor);
  const countdownTitle = useSelector(settingsSelectors.getCountdownTitle);
  const countdownDate = useSelector(settingsSelectors.getCountdownDate);
  const imagePath = image ? `${path}${image && image.imageKey}` : null;
  const userName = user?.profile?.fullName.split(' ')[0] || user.username;

  return (
    <>
      <div className="main-general-info-wrapper">
        <div
          className="main-container"
          style={{
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          {imagePath && <img className="main-continer-image" src={settingsIsReady && imagePath} alt="" />}
          <div className="header-title">
            <h3 className="main-container-title-mobile">Hi, {userName}!</h3>
            {docuSignPermissions && isDocuSignUnauthorized && (
              <div className="mobile-primary-text" onClick={onDSLogin}>
                Login to DocuSign
              </div>
            )}
          </div>
          {title && (
            <div className="main-container-bcg">
              <p className="main-container-text" style={{ color: titleColor }}>
                {title || ' '}
              </p>
            </div>
          )}

          <div className="main-container-btn">
            {permissionScheduleButton && (
              <button
                className="main-button"
                type="button"
                onClick={
                  userRole === 'Field Technician'
                    ? () => navigate('/schedule-user')
                    : () => navigate('/schedule')
                }
              >
                <span className="button-text">
                  {userRole === 'Field Technician' ? 'View my schedule' : 'Schedule'}
                </span>
              </button>
            )}
            {permissionWorklogButton && (
              <button className="main-btn" type="button" onClick={() => navigate('/worklog')}>
                <span className="btn-text"> Add worklog</span>
              </button>
            )}
            <button className="main-btn" type="button" onClick={() => navigate(kioskPath)}>
              {remoteKioskPermision ? (
                <span className="btn-text">Remote Kiosk</span>
              ) : (
                <span className="btn-text">Request time off</span>
              )}
            </button>
          </div>
        </div>
        <div className="statistic-info-block">
          <HoursContainer data={data.workedHours} user={user} />
          <PieChart data={data.timeoffSummary} user={user} />
        </div>
        {toggleCountdownEnabled && (
          <CountDownTimer deadline={new Date(countdownDate)} title={countdownTitle} />
        )}
      </div>
    </>
  );
};

export default GeneralInfoBlock;
