import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useController } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from '../ForceAccountsBlock/styles';
import CloseIcon from 'assets/images/close-icon.svg';
import cn from 'classnames';

const PtoAccountBlock = ({ classes, pageType, control, errors }) => {
  const { PTOAdminEmail } = useSelector((state) => state.settings.settings);

  const {
    field: { onChange, value: ptoValues },
  } = useController({
    name: 'PTOAdminEmail',
    control,
    defaultValue: [],
  });

  const [isShowEmailsPTO, setShowEmailsPTO] = useState(false);

  useEffect(() => {
    onChange(PTOAdminEmail || []);
  }, [PTOAdminEmail]);

  const handleEmailChange = (i) => (e) => {
    const { value } = e.target;
    const nextValue = ptoValues.map((t, index) => (index === i ? value : t));
    onChange(nextValue);
  };
  const handleEmailRemoving = (i) => () => {
    const nextValue = ptoValues.filter((_, index) => index !== i);
    onChange(nextValue);
  };

  const handleAddEmail = () => {
    setShowEmailsPTO(true);
    onChange([...ptoValues.filter((v) => v.length), ''])
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4">PTO Admin</Typography>
      <div className={classes.actionsWrapper}>
        {pageType === 'edit' && (
          <Button
            variant="text"
            className={classes.primaryTextButton}
            onClick={handleAddEmail}
            disableRipple
          >
            + Add Email
          </Button>
        )}
        {isShowEmailsPTO &&
          ptoValues.map((email, index) => (
            <Grid container spacing={5} key={`PTOAdminEmail-${index}`} alignItems="center">
              <Grid item xs={10} sm={6} md={3} className={classes.zeroPaddingTop}>
                <TextField
                  id={`PTOAdminEmail[${index}]`}
                  name={`PTOAdminEmail[${index}]`}
                  type="email"
                  value={email}
                  className={classes.textField}
                  onChange={handleEmailChange(index)}
                  variant="outlined"
                  inputProps={{ 'aria-label': 'PTO Admin Email' }}
                  fullWidth
                  disabled={pageType === 'read'}
                />
              </Grid>
              {pageType === 'edit' && (
                <span className={classes.removeEmail} onClick={handleEmailRemoving(index)}>
                  <img src={CloseIcon} alt="delete" />
                </span>
              )}
            </Grid>
          ))}
        {!!ptoValues.length && (
          <div
            className={classes.buttonWrapper}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowEmailsPTO((prev) => !prev);
            }}
          >
            <div className={classes.nonActionsIcon}>
              <ExpandMoreIcon className={cn(isShowEmailsPTO ? '' : classes.reversIcon)} />
            </div>
            <p className={classes.textIconMore}>{`${
              isShowEmailsPTO ? 'Hide' : 'Show'
            } email addresses`}</p>
          </div>
        )}
      </div>
    </div>
  );
};

PtoAccountBlock.propTypes = {
  classes: propTypes.object.isRequired,
  pageType: propTypes.string.isRequired,
  errors: propTypes.object.isRequired,
  control: propTypes.object.isRequired,
};

export default withStyles(styles)(PtoAccountBlock);
