import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from './components/TabPanel/TabPanel';
import Home from './components/Home/Home';
import Clients from './components/Clients/Clients';
import Users from './components/Users/Users';
import SnackBar from 'components/SnackBar';
import HomeIcon from "assets/images/ion_home.svg";
import ClientIcon from "assets/images/ion_business.svg";
import UsersIcon from "assets/images/ion_person.svg";
import superadminActions from 'store/superadmin/superadminActions';
import './styles.scss';


const SuperAdmin = () => {
  const snackbarInfo = useSelector((state) => state.superadmin.snackBarInfo);
  const dispatch = useDispatch()

  const [tabValue, setTabValue] = useState(0);
  const [snackbar, setSnackbar] = useState();

  useEffect(() => {
    if (!!snackbarInfo) {
      setSnackbar(snackbarInfo)
    }
    return () => dispatch(superadminActions.snackBarInfo(null))
  }, [snackbarInfo])

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function addProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }


  return (
    <div className={'super-admin-page__wrapper'}>
      <div className={'super-admin-tabs'}>
        <Tabs
          orientation="vertical"
          value={tabValue}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={''}
          style={{ marginTop: 30 }}
        >
          <Tab
            icon={<img className={'super-admin-tab-icon'} src={HomeIcon} alt="print" />}
            label="Home"
            {...addProps(0)}
          />
          <Tab
            icon={
              <img className={'super-admin-tab-icon'} src={ClientIcon} alt="print" />
            }
            label="Companies"
            {...addProps(1)}
          />
          <Tab
            icon={
              <img className={'super-admin-tab-icon'} src={UsersIcon} alt="print" />
            }
            label="Users"
            {...addProps(2)}
          />
          {/* <Tab
            icon={<img className={'super-admin-tab-icon'} src={BillingIcon} alt="print" />}
            label="Billing"
            {...addProps(3)}
          /> */}
        </Tabs>
      </div>

      <TabPanel value={tabValue} index={0}>
        <Home />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Clients setSnackbar={setSnackbar} />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Users setSnackbar={setSnackbar} />
      </TabPanel>

      {snackbar && (
        <SnackBar
          closeSnackbar={() => setSnackbar(null)}
          {...snackbar}
          isOpen={true}
        />
      )}
    </div>
  );
};
export default SuperAdmin;
