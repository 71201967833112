import {  SHEET_SURFACES_EVENT } from 'subscriptions/events/events.enum';
import { subscriptionsFabric } from 'subscriptions/subscriptions.fabric';
import currentWorklogActions from 'store/currentWorklog/actions';


const handlers = {
  [SHEET_SURFACES_EVENT.created]: (store) => (data) => {
    store.dispatch(currentWorklogActions.addWorklogSurface(data));
  },
  [SHEET_SURFACES_EVENT.updated]: (store) => (data) => {
    store.dispatch(currentWorklogActions.updateWorklogSurface(data));
  },
  [SHEET_SURFACES_EVENT.deleted]: (store) => (data) => {
    store.dispatch(currentWorklogActions.deleteWorklogSurface(data));
  },
}

export const currentWorklogSheetSurfacesSubscriptionMiddleware = subscriptionsFabric(
  currentWorklogActions,
  SHEET_SURFACES_EVENT,
  handlers
);