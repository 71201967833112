import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import classnames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import UsersFilter from './components/UsersFilter/UsersFilter';
import UserForm from './components/EditUserForm/EditUserForm';
import AssignToClient from './components/AssignToClient/AssignToClient';
import Drawer from '@material-ui/core/Drawer';
import Table from '../Table/Table';
import FiltersBar from '../FilterBar/FilterBar';
import {
  fetchUsers,
  putUser,
  postAssignUsers,
  postResetPassword,
  exportUsers,
  importUsers,
  deleteUser,
  postUser,
} from 'store/superadmin/superadminOperations';
import { headCells, DRAWER_TYPES } from './constants';
import { getLastSignDate } from './helpers';
import LockIcon from 'assets/images/lock.svg';
import PeopleIcon from 'assets/images/people.svg';
import ImportIcon from "assets/images/arrow-import-down-grey.svg";
import ExportIcon from "assets/images/arrow-export-up-grey.svg";
import EditIcon from "assets/images/edit-icon.svg";
import AddIcon from '@material-ui/icons/Add';

import './styles.scss';


const UserTable = ({ setSnackbar }) => {
  
  const dispatch = useDispatch();

  const clientsData = useSelector((state) => state.superadmin.clients);
  const usersData = useSelector((state) => state.superadmin.users);

  const [sortOrder, setSortOrder] = useState('asc');
  const [sortBy, setSortBy] = useState('fullName');
  const [selected, setSelected] = useState([]);
  const [drawer, setDrawer] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState({ organizationId: 'All', userType: 'All', status: 'All', lastSignIn: 'All' });
  const [editingUser, setEditingUser] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);


  useEffect(() => {
    dispatch(fetchUsers(filters, searchValue, sortBy, sortOrder));
  }, [filters, searchValue, sortBy, sortOrder]);

  useEffect(() => {
    setSelected(selected.filter(_id => usersData.some(user => user._id === _id)));
  }, [usersData])


  const openDrawer = (type) => {
    setDrawer(type);
  };

  const closeDrawer = () => {
    setDrawer(false);
  };

  const onUpdateFilters = (values) => {
    setFilters(values);
    closeDrawer();
  };
  

  const filtersReset = () => {
    setFilters({ organizationId: 'All', userType: 'All', status: 'All', lastSignIn: 'All' });
    closeDrawer();
  };

  const handleSort = useCallback((property) => {
    const isAsc = sortBy !== property || sortOrder === 'desc';
    setSortOrder(isAsc ? 'asc' : 'desc');
    setSortBy(property);
  }, [sortOrder, sortBy]);


  const handleSelectAllClick = useCallback((event) => {
    if (event.target.checked && !selected.length) {
      const allSelected = usersData.map((n) => n._id);
      setSelected(allSelected);
    } else {
      setSelected([]);
    }
  }, [usersData, selected]);


  const handleSelect = useCallback((_id) => {
    const isSelected = selected.some((userId) => userId === _id);

    if (isSelected) {
      setSelected(selected.filter(userId => userId !== _id));
    } else {
      setSelected([...selected, _id]);
    }
  }, [selected]);


  const onEditUser = (user) => {
    setEditingUser(user);
    openDrawer(DRAWER_TYPES.editUser);
  };

  const onCreateUser = () => {
    setEditingUser(null);
    openDrawer(DRAWER_TYPES.createUser);
  };

  const handleResetPasword = useCallback((e) => {
    e.preventDefault();
    if (!selected.length) {
      setSnackbar({ type: 'error', text: 'At least 1 user should be selected' });
      return;
    }

    setOpenDialog(true);
  }, [selected])

  const resetPassword = useCallback((e) => {
    e.preventDefault();
    if (!selected?.length) return;
    dispatch(postResetPassword(selected));
    setOpenDialog(false);
  }, [selected]);

  const handleAssignUsers = useCallback((e) => {
    e.preventDefault();
    if (!selected.length) {
      setSnackbar({ type: 'error', text: 'At least 1 user should be selected' });
      return;
    }

    openDrawer(DRAWER_TYPES.assignToClient);
  }, [selected])

  const assignUsers = useCallback((values) => {
    if (!selected?.length) return;
    dispatch(postAssignUsers(selected, values));
    closeDrawer();
  }, [selected])


  const sendEditUser = useCallback(async (data) => {
    if (!editingUser?._id) {
      await dispatch(postUser(data)).then(()=>closeDrawer()).catch(e=>console.log(e))
    };
    if (editingUser?._id) {
      await dispatch(putUser(editingUser._id, data)).then(()=>closeDrawer()).catch(e=>console.log(e))
    }
  }, [editingUser])

  const sendDeleteUser = (user) => {
    dispatch(deleteUser(user._id));
    closeDrawer();
  };


  const sendExportUsers = useCallback(() => {
    if (!filters.organizationId || filters.organizationId === 'All') {
      setSnackbar({ type: 'error', text: 'Please, do filtration by needed \'Company\' in order to export users.' })
      return;
    }
    const company = clientsData.find((client) => client._id === filters._id);

    dispatch(exportUsers(filters, searchValue, sortBy, sortOrder, company?.name));
  }, [filters, searchValue, sortBy, sortOrder]);

  const sendImportUsers = async (file) => {
    const data = new FormData();
    data.append('file', file);

    await dispatch(importUsers(data));
    dispatch(fetchUsers(filters, searchValue, sortBy, sortOrder));
  }

  const onDropAccepted = ([droppedFile]) => {
    sendImportUsers(droppedFile);
  };

  const { getRootProps: dropzoneRootProps, getInputProps: dropzoneInputProps, open: openDropzone } = useDropzone({
    onDropAccepted,
    accept: 'text/csv',
    maxFiles: 1,
    maxSize: 10 * 1024 * 1024, // 10MB
    multiple: false,
    noClick: true,
    noDrag: true,
  });


  const isAllSelected = useMemo(() => selected.length === usersData.length, [selected, usersData]);

  const svgIconLock = (classname) => <Icon><img src={LockIcon} alt='lock' className={classname}/></Icon>;
  const svgIconPeople = <Icon><img src={PeopleIcon} alt='people' className={'svg-icon'}/></Icon>;


  const rows = useMemo(() => {
    return usersData.map((user) => ({
      onRowClick: () => handleSelect(user._id),
      isItemSelected: selected.includes(user._id),
      key: user._id,
      cells: [
        { align: 'left', children: user.profile?.fullName || user.username },
        { align: 'left', className: !user.organization?.name && 'inactive', children: user.organization?.name || 'Not assigned' },
        { align: 'left', children: user.profile?.role?.roleName },
        { children: (
          <div className={classnames('cell-status', user.profile?.shifts?.timeOfDay !== 'Inactive' && 'active')}>
            {user.profile?.shifts?.timeOfDay === 'Inactive' ? 'Inactive' : 'Active'}
          </div>
        ) },
        { children: getLastSignDate(user.profile?.lastLogin) },
        {
          className: 'action-cell',
          onClick: (e) =>{
            e.stopPropagation()
            onEditUser(user)},
          children: (
          <div className="cell-button">
            <img className="cell-button__icon" src={EditIcon} alt="edit" />
            Edit
          </div>
        ) }
      ]
    }))
  }, [selected, usersData, handleSelect]);


  return (
    <div className={'users-wrapper'}>
      <div className={'users-root'}>
        <FiltersBar openFilters={() => openDrawer(DRAWER_TYPES.filters)} updateSearchValue={setSearchValue} isDynamicSearch={true}>
          <Button
            variant="contained"
            color="secondary"
            className={'filters-button'}
            onClick={sendExportUsers}
            disableTouchRipple
          >
            <img className="filters-button__icon" src={ExportIcon} alt="export" />
            Export CSV
          </Button>
          <div {...dropzoneRootProps()} style={{ display: 'none' }}>
            <input type="file" {...dropzoneInputProps()} />
          </div>
          <Button
            variant="contained"
            color="secondary"
            className={'filters-button'}
            onClick={openDropzone}
            disableTouchRipple
          >
            <img className="filters-button__icon" src={ImportIcon} alt="import" />
            Import CSV
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={'filters-button'}
            onClick={ () => onCreateUser() }
            disableTouchRipple
          >
            <AddIcon fontSize="small" /> Add
          </Button>
        </FiltersBar>
        <Paper>
          <Table
            headCells={headCells}
            rows={rows}
            checkboxes={true}
            sortBy={sortBy}
            sortOrder={sortOrder}
            onSort={handleSort}
            isAllSelected={isAllSelected}
            selected={selected}
            onSelectAllClick={handleSelectAllClick}
          />
        </Paper>
        <div className={'users-button-wrapper'}>
          <Button
            type="submit"
            color="primary"
            variant="outlined"
            disableTouchRipple
            startIcon={selected.length ? svgIconLock('svg-icon') : svgIconLock('svg-icon-dis')}
            disabled={!selected.length}
            onClick={handleResetPasword}
          >
            Reset Password
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleAssignUsers}
            className={'assign-button'}
            disableTouchRipple
            startIcon={svgIconPeople}
            disabled={!selected.length}
          >
            Assign to Company
          </Button>
        </div> 
      </div>
      
      <Drawer anchor="right" open={!!drawer}>
        {drawer && drawer === DRAWER_TYPES.filters && ( <UsersFilter
          closeDrawer={closeDrawer}
          onUpdateFilters={onUpdateFilters}
          filters={filters}
          filtersReset={filtersReset}
          clientsData={clientsData}
        />)}
        {drawer && drawer === DRAWER_TYPES.createUser && ( <UserForm
          closeDrawer={closeDrawer}
          editingUser={editingUser}
          clientsData={clientsData}
          editUser={sendEditUser}
          deleteUser={ () => null}
          isCreating={true}
        />)}
        {drawer && drawer === DRAWER_TYPES.editUser && ( <UserForm
          closeDrawer={closeDrawer}
          editingUser={editingUser}
          clientsData={clientsData}
          editUser={sendEditUser}
          deleteUser={sendDeleteUser}
        />)}
        {drawer && drawer === DRAWER_TYPES.assignToClient && ( <AssignToClient
          closeDrawer={closeDrawer}
          clients={clientsData}
          assignUsers={assignUsers}
        />)}
      </Drawer>
      {openDialog && (
        <Dialog
          open={Boolean(openDialog)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            classes: {
              root: 'users-dialog-root',
            },
          }}
          // disableEscapeKeyDown
        >
          <DialogContent>
            <Typography variant="h5" component="div">
              Are you sure you want to reset password?
              This action cannot be undone.
            </Typography>
          </DialogContent>
          <DialogActions>
            <div className={'users-dialog-root__box'}>
              <Button
                color="primary"
                variant="outlined"
                size="medium"
                className={'users-dialog-button users-dialog-button__save'}
                disableTouchRipple
                onClick={resetPassword}
              >
                Yes
              </Button>
              <Button
                color="secondary"
                variant="outlined"
                size="medium"
                className={'users-dialog-button users-dialog-button__cancel'}
                onClick={() => setOpenDialog(false)}
                disableTouchRipple
              >
                No
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export default UserTable;