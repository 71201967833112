import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Typography, TextField, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import defaultLettersArray from '../../../helpers/constants';
import { withStyles } from '@material-ui/styles';
import './styles.scss';
import btnStyles from '../../styles';
import {
  LetteringMaterialSchema,
  LetteringLetterSquareSchema,
  LetteringNewLetterSchema,
} from '../../schemas';
import { calculateLettering } from 'helpers/material-calculations';
import { v4 as uuidv4 } from 'uuid';
import { DndContext, DragOverlay, pointerWithin } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { useMaterialLabelsDnd } from '../hooks/useMaterialLabelsDnd';
import { GridDND, SortableItem, Item } from '../materialDndComponents/materialDndComponents';

const LetteringSpecifications = ({
  values,
  remove,
  push,
  replace,
  move,
  type,
  classes,
  setIsCorrectRequest,
}) => {
  const [id, setId] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [name, setName] = useState('');
  const [height, setHeight] = useState('');
  const [width, setWidth] = useState('');
  const [stroke, setStroke] = useState('');
  const [ratio, setRatio] = useState('');
  const [letterSet, setLetterSet] = useState({});
  const [selectedLetter, setSelectedLetter] = useState('');
  const [newLetter, setNewLetter] = useState('');
  const [letterSquare, setLetterSquare] = useState('');
  const [lettersArray, setLettersArray] = useState(defaultLettersArray.slice());
  const [errors, setErrors] = useState({});

  const [letteringText, setLetteringText] = useState('');
  const [testQuantity, setTestQuantity] = useState('');
  const [testResult, setTestResult] = useState('');

  const { items, setItems, activeId, sensors, handleDragStart, handleDragEnd, handleDragCancel } =
    useMaterialLabelsDnd({ values, move });

  const clearErrors = useCallback(
    (prop) => {
      if (!prop) {
        setErrors({});
      } else {
        const errorsObj = Object.assign({}, errors);
        delete errorsObj[prop];
        if (prop === 'height' || prop === 'width' || prop === 'stroke') {
          delete errorsObj['params'];
        }
        setErrors(errorsObj);
      }
    },
    [errors]
  );

  const clearLetterSquareErrors = useCallback(() => {
    const errorsObj = Object.assign({}, errors);
    delete errorsObj['newLetter'];
    delete errorsObj['letterSquare'];

    setErrors(errorsObj);
  }, [errors]);

  const resetForm = useCallback(() => {
    setName('');
    setHeight('');
    setWidth('');
    setStroke('');
    setRatio('');
    setSelectedLetter('');
    setLetterSet({});
    setNewLetter('');
    setLetterSquare('');
    setLetteringText('');
    setTestQuantity('');
    setTestResult('');
    setId('');
    clearErrors();
  }, []);

  const addNewSymbol = useCallback(() => {
    try {
      const result = {
        newLetter,
        letterSquare,
      };

      LetteringNewLetterSchema(lettersArray).validateSync(result, {
        abortEarly: false,
        stripUnknown: true,
      });

      setLetterSet({ ...letterSet, [newLetter]: letterSquare });
      setLettersArray([...lettersArray, newLetter]);
      setLetterSquare('');
      setNewLetter('');
      clearLetterSquareErrors();
    } catch (err) {
      const errObject = {};
      (err.inner || []).forEach(({ path, message }) => (errObject[path] = message));
      setErrors(errObject);
    }
  }, [newLetter, letterSquare, lettersArray]);

  const deleteSymbol = useCallback(
    (symbol) => {
      const { ...rest } = letterSet;
      delete rest[symbol];
      setLetterSet(rest);

      setLettersArray(lettersArray.filter((letter) => letter !== symbol));
    },
    [letterSet, lettersArray]
  );

  const addLetteringSquare = useCallback(() => {
    const errors = [];
    try {
      if (!selectedLetter) {
        errors.push({ path: 'letterSquare', message: 'Letter is not selected' });
      }
      const result = {
        letterSquare,
      };

      LetteringLetterSquareSchema.validateSync(result, {
        abortEarly: false,
      });

      if (errors.length) {
        throw new Error();
      }

      setLetterSet({ ...letterSet, [selectedLetter]: Number(letterSquare) });
      setLetterSquare('');
      setSelectedLetter('');
      clearLetterSquareErrors();
    } catch (err) {
      const errObject = {};
      (err.inner || []).concat(errors).forEach(({ path, message }) => (errObject[path] = message));
      setErrors(errObject);
    }
  }, [letterSet, letterSquare, selectedLetter]);

  const addLettering = useCallback(() => {
    const errors = [];
    try {
      const result = {
        name,
        height,
        stroke,
        width,
        letterSet,
        ratio,
        _id: uuidv4(),
      };

      if (
        values.find(
          (lettering) =>
            lettering.height === height && lettering.width === width && lettering.stroke === stroke
        )
      ) {
        errors.push({
          path: 'params',
          message: 'Such combination of width, height and stroke already added to the lettering',
        });
      }
      if (defaultLettersArray.some((letter) => !letterSet.hasOwnProperty(letter))) {
        errors.push({ path: 'letterSet', message: 'All default letters should be specified' });
      }

      const validated = LetteringMaterialSchema(values || []).validateSync(result, {
        abortEarly: false,
      });

      if (errors.length) {
        throw new Error();
      }

      push(validated);
      setItems((dragItems) => [...dragItems, validated]);
      setIsCorrectRequest(true);
      resetForm();
    } catch (err) {
      const errObject = {};
      (err.inner || []).concat(errors).forEach(({ path, message }) => (errObject[path] = message));
      setErrors(errObject);
    }
  }, [push, name, ratio, height, stroke, width, letterSet]);

  const editSave = () => {
    const errors = [];
    try {
      const result = {
        name,
        height,
        stroke,
        width,
        letterSet,
        ratio,
        _id: id,
      };

      if (defaultLettersArray.some((letter) => !letterSet.hasOwnProperty(letter))) {
        errors.push({ path: 'letterSet', message: 'All default letters should be specified' });
      }

      const validated = LetteringMaterialSchema(values || [], editIndex).validateSync(result, {
        abortEarly: false,
      });

      if (errors.length) {
        throw new Error();
      }
      replace(editIndex, validated);
      setItems((dragItems) => dragItems.map((el) => (el._id === validated._id ? validated : el)));
      setIsCorrectRequest(true);
      resetForm();
      setEditIndex(null);
    } catch (err) {
      const errObject = {};
      (err.inner || []).concat(errors).forEach(({ path, message }) => (errObject[path] = message));
      setErrors(errObject);
    }
  };

  useEffect(() => {
    if (ratio > 0 && letteringText && testQuantity > 0) {
      try {
        setTestResult(calculateLettering(testQuantity, letteringText, letterSet, ratio));
      } catch (err) {
        setErrors({ ...errors, letteringText: err.message });
      }
    } else {
      setTestResult('');
    }
  }, [ratio, letterSet, letteringText, testQuantity]);

  return (
    <Grid container xs={12} style={{ borderTop: '1px solid #D9DAE3', marginBottom: '15px' }}>
      <Grid container spacing={1}>
        <Grid item xs={8} md={9} style={{ marginBottom: '17px', marginTop: '10px' }}>
          <Typography variant="h5">Lettering Specifications:</Typography>
        </Grid>
        <Grid item xs={12} lg={6} style={{ marginBottom: '10px' }}>
          <label htmlFor="letteringName">
            <Typography variant="body1" color="textSecondary">
              Name of Letter Set
            </Typography>
            <TextField
              id="letteringName"
              name="letteringName"
              variant="outlined"
              value={name}
              className="materials-input"
              onChange={(e) => {
                setName(e.target.value);
                clearErrors('name');
              }}
              InputProps={{
                className: `${errors.name ? 'materials-input--error' : ''}`,
              }}
              fullWidth={true}
            />
          </label>
          {errors.name && <span className="materials-input--error-text">{errors.name}</span>}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6} lg={3}>
          <label htmlFor="letteringHeight">
            <Typography variant="body1" color="textSecondary">
              Available Height FT
            </Typography>
            <TextField
              id="letteringHeight"
              name="letteringHeight"
              variant="outlined"
              type="number"
              value={height}
              onChange={(e) => {
                setHeight(e.target.value);
                clearErrors('height');
              }}
              className="materials-input"
              InputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                className: `${errors.height ? 'materials-input--error' : ''}`,
              }}
              fullWidth={true}
            />
          </label>
          {errors.height && <span className="materials-input--error-text">{errors.height}</span>}
        </Grid>
        <Grid item xs={6} lg={3}>
          <label htmlFor="letteringWidth">
            <Typography variant="body1" color="textSecondary">
              Availiable Width
            </Typography>
            <TextField
              id="letteringWidth"
              name="letteringWidth"
              variant="outlined"
              type="number"
              value={width}
              onChange={(e) => {
                setWidth(e.target.value);
                clearErrors('width');
              }}
              className="materials-input"
              InputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                className: `${errors.width ? 'materials-input--error' : ''}`,
              }}
              fullWidth={true}
            />
          </label>
          {errors.width && <span className="materials-input--error-text">{errors.width}</span>}
        </Grid>
        <Grid item xs={6} lg={3}>
          <label htmlFor="letteringStroke">
            <Typography variant="body1" color="textSecondary">
              Stroke
            </Typography>
            <TextField
              id="letteringStroke"
              name="letteringStroke"
              variant="outlined"
              type="number"
              value={stroke}
              onChange={(e) => {
                setStroke(e.target.value);
                clearErrors('stroke');
              }}
              className="materials-input"
              InputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                className: `${errors.stroke ? 'materials-input--error' : ''}`,
              }}
              fullWidth={true}
            />
          </label>
          {errors.stroke && <span className="materials-input--error-text">{errors.stroke}</span>}
        </Grid>
        <Grid item xs={6} lg={3}>
          <label htmlFor="letteringRatio">
            <Typography variant="body1" color="textSecondary">
              {type === 'volume' && 'Ratio SF/Gal'}
              {type === 'weight' && 'Ratio SF/Lbs'}
            </Typography>
            <TextField
              id="letteringRatio"
              name="letteringRatio"
              variant="outlined"
              type="number"
              value={ratio}
              onChange={(e) => {
                setRatio(e.target.value);
                clearErrors('ratio');
              }}
              className="materials-input"
              InputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                className: `${errors.ratio ? 'materials-input--error' : ''}`,
              }}
              fullWidth={true}
            />
          </label>
          {errors.ratio && <span className="materials-input--error-text">{errors.ratio}</span>}
        </Grid>
      </Grid>
      <Typography
        variant="body1"
        color="textSecondary"
        style={{ marginBottom: '10px', marginTop: '15px' }}
      >
        Letter SF
      </Typography>
      <ul className="list">
        {lettersArray.map((elem, inx) => {
          return (
            <li
              key={inx}
              className={`list_item${
                Object.keys(letterSet).includes(elem)
                  ? ' added'
                  : selectedLetter === elem
                  ? ' letter--selected'
                  : errors.letterSet
                  ? ' letter--error'
                  : ''
              } list_item${
                selectedLetter === elem
                  ? ' letter--selected'
                  : errors.letterSet
                  ? ' letter--error'
                  : ''
              }`}
              style={{
                position: 'relative',
              }}
              onClick={() => {
                if (selectedLetter === elem) {
                  setSelectedLetter('');
                } else {
                  setSelectedLetter(elem);
                  clearErrors('letterSet');
                }
                setLetterSquare('');
                if (letterSet[elem] && selectedLetter != elem) {
                  setLetterSquare(letterSet[elem]);
                } else {
                  setLetterSquare('');
                }
              }}
            >
              {Object.keys(letterSet).includes(elem) && !defaultLettersArray.includes(elem) && (
                <div
                  style={{
                    width: '16px',
                    height: '16px',
                    borderRadius: '50%',
                    background: '#DD6060',
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'start',
                    justifyContent: 'center',
                    top: '-9px',
                    right: '-7px',
                  }}
                >
                  <button
                    type="button"
                    style={{
                      width: '13px',
                      height: '13px',
                      margin: '0',
                      padding: '0',
                      background: 'none',
                    }}
                    onClick={() => deleteSymbol(elem)}
                  >
                    <CloseIcon
                      fontSize="small"
                      style={{ color: 'white', width: '11px', height: '12px' }}
                    />
                  </button>
                </div>
              )}
              <label
                className={`list_item_label${
                  Object.keys(letterSet).includes(elem)
                    ? ' added_label'
                    : selectedLetter === elem
                    ? ' letter--selected_label'
                    : errors.letterSet
                    ? ' letter--error_label'
                    : ''
                }`}
              >
                <span
                  style={{
                    display: 'flex',
                    width: '25px',
                    height: '25px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {elem}
                </span>
              </label>
            </li>
          );
        })}
      </ul>
      {errors.letterSet && <span className="materials-input--error-text">{errors.letterSet}</span>}
      <Grid container spacing={1} style={{ marginTop: '15px' }}>
        <Grid item xs={10} lg={3}>
          <label htmlFor="letteringLetterSquare">
            <Typography variant="body1" color="textSecondary">
              Letter Square SF
            </Typography>
            <TextField
              id="letteringLetterSquare"
              name="letteringLetterSquare"
              variant="outlined"
              type="number"
              value={letterSquare}
              onChange={(e) => {
                setLetterSquare(e.target.value);
                clearErrors('letterSquare');
              }}
              className="materials-input"
              InputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                className: `${errors.letterSquare ? 'materials-input--error' : ''}`,
              }}
              fullWidth={true}
            />
          </label>
          {errors.letterSquare && (
            <span className="materials-input--error-text">{errors.letterSquare}</span>
          )}
        </Grid>
        <Grid item xs={2} lg={1}>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            style={{ marginTop: '23px', minWidth: 'auto', width: '100%', padding: '7px 8px' }}
            className={classes.addButton}
            onClick={addLetteringSquare}
          >
            <AddIcon fontSize="small" color="primary" />
          </Button>
        </Grid>
        <Grid item xs={10} lg={3}>
          <label htmlFor="letteringNewSymbol">
            <Typography variant="body1" color="textSecondary">
              Add new symbol :
            </Typography>
            <TextField
              id="letteringNewSymbol"
              name="letteringNewSymbol"
              variant="outlined"
              value={newLetter}
              onChange={(e) => {
                setNewLetter(e.target.value.toString().toUpperCase().slice(-1));
                clearErrors('newLetter');
              }}
              className="materials-input"
              InputProps={{
                className: `${errors.newLetter ? 'materials-input--error' : ''}`,
              }}
              fullWidth={true}
            />
          </label>
          {errors.newLetter && (
            <span className="materials-input--error-text">{errors.newLetter}</span>
          )}
        </Grid>
        <Grid item xs={2} lg={1}>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            style={{ marginTop: '23px', minWidth: 'auto', width: '100%', padding: '7px 8px' }}
            className={classes.addButton}
            onClick={addNewSymbol}
          >
            <AddIcon fontSize="small" color="primary" />
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{ alignItems: 'end', marginTop: '15px', justifyContent: 'space-between' }}
      >
        <Grid item xs={6} lg={4}>
          <label htmlFor="letteringTestQuantity">
            <Typography variant="body1" color="textSecondary">
              Test Quantity
            </Typography>
            <TextField
              id="letteringTestQuantity"
              name="letteringTestQuantity"
              value={testQuantity}
              variant="outlined"
              type="number"
              InputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
              onChange={(e) => {
                setTestQuantity(e.target.value);
              }}
              fullWidth={true}
            />
          </label>
        </Grid>
        <Grid item xs={6} lg={4}>
          <label htmlFor="letteringTestText">
            <Typography variant="body1" color="textSecondary">
              Test Lettering
            </Typography>
            <TextField
              id="letteringTestText"
              name="letteringTestText"
              value={letteringText}
              variant="outlined"
              InputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
              onChange={(e) => {
                setLetteringText(e.target.value.trim().toUpperCase());
              }}
              fullWidth={true}
            />
          </label>
        </Grid>
        <Grid item xs={6} lg={4}>
          <label htmlFor="letteringResult">
            <Typography variant="body1" color="textSecondary">
              {type && type === 'volume' && 'Result LF/Gal'}
              {type && type === 'weight' && 'Result LF/Lbs'}
            </Typography>
            <TextField
              id="letteringResult"
              name="letteringResult"
              value={testResult}
              variant="outlined"
              disabled={true}
              InputProps={{
                type: 'number',
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
              fullWidth={true}
            />
          </label>
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ justifyContent: 'flex-end' }}>
        {!Number.isInteger(editIndex) && (
          <Grid item xs={'auto'}>
            <Button
              variant="outlined"
              size="medium"
              color="primary"
              style={{ padding: '2px 7px 2px 3px', marginTop: '15px' }}
              className={classes.addButton}
              onClick={addLettering}
            >
              <AddIcon fontSize="small" color="primary" />
              <span>Add</span>
            </Button>
          </Grid>
        )}
        {!!Number.isInteger(editIndex) && (
          <>
            <Grid item xs={'auto'}>
              <Button
                variant="outlined"
                size="medium"
                style={{ padding: '2px', marginTop: '15px' }}
                onClick={(e) => {
                  setEditIndex(null);
                  resetForm();
                }}
                className={classes.cancelEddingButton}
              >
                <span>Cancel</span>
              </Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button
                variant="outlined"
                size="medium"
                color="primary"
                style={{ padding: '2px 10px', marginTop: '15px' }}
                onClick={editSave}
                className={classes.addButton}
              >
                <span>Save</span>
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <Grid container spacing={1} style={{ margin: 0 }}>
        <Typography variant="body1" color="textSecondary">
          Click to edit
        </Typography>
      </Grid>
      <Grid container xs={12} style={{ marginTop: '15px' }}>
        <DndContext
          sensors={sensors}
          collisionDetection={pointerWithin}
          onDragStart={handleDragStart}
          onDragEnd={handleDragCancel}
          onDragCancel={handleDragCancel}
          onDragOver={handleDragEnd}
        >
          <SortableContext items={items} strategy={rectSortingStrategy}>
            <GridDND columns={5} classes={classes}>
              <ul className={classes.itemsList}>
                {items.map((elem, index) => (
                  <li key={index} style={{ margin: '0 8px 8px 0' }}>
                    <SortableItem
                      classes={classes}
                      key={elem._id}
                      id={elem._id}
                      elem={elem}
                      index={index}
                      editIndex={editIndex}
                      remove={remove}
                      typeOfLabel={'lettering'}
                      setItems={setItems}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSelectedLetter('');
                        setLetterSquare('');
                        setErrors({});
                        setName(elem.name);
                        setHeight(elem.height);
                        setRatio(elem.ratio);
                        setStroke(elem.stroke);
                        setWidth(elem.width);
                        setLetterSet(elem.letterSet);
                        setEditIndex(index);
                        setId(elem._id);
                      }}
                    />
                  </li>
                ))}
              </ul>
            </GridDND>
          </SortableContext>
          <DragOverlay>
            {activeId ? (
              <Item
                classes={classes}
                id={activeId}
                editIndex={editIndex}
                elem={items.find((el) => el._id === activeId)}
                typeOfLabel={'lettering'}
                isDragging
              />
            ) : null}
          </DragOverlay>
        </DndContext>
      </Grid>
    </Grid>
  );
};
export default withStyles(btnStyles)(LetteringSpecifications);
