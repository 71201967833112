import { createAction } from '@reduxjs/toolkit';

const getClients = createAction('superadmin/getClients');
const createClient = createAction('superadmin/createClient');
const updateClient = createAction('superadmin/updateClient');
const getStatistic = createAction('superadmin/getStatistic');
const getUsers = createAction('superadmin/getUsers');
const createUser = createAction('superadmin/createUser');
const updateUser = createAction('superadmin/updateUser');
const updateBunchUsers = createAction('superadmin/updateBunchUsers');
const deleteUser = createAction('superadmin/deleteUser');
const snackBarInfo = createAction('superadmin/snackBarInfo');

const actions = {
  getClients,
  createClient,
  updateClient,
  getStatistic,
  getUsers,
  createUser,
  updateUser,
  updateBunchUsers,
  deleteUser,
  snackBarInfo,
};

export default actions;