import kioskPageActions from './kioskPageActions';
import axios from 'axios';
import { format } from 'date-fns';
import { getKioskOptions } from '../../helpers/getOptions';
import $api from 'http/index';
import { globalBEDateFormat } from 'common/dateFormatConfig';

export const clearAlert = () => (dispatch) => {
  dispatch(kioskPageActions.setAlert({ type: null, text: null }));
};

export const getPto = (userId) => async (dispatch) => {
  /** types:
   * @param userId - "string"
   * @returns ({
   *   allowed: number,
   *   designated: number,
   *   available: number
   * })
   */

  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/kiosk/info`, {
      ...getKioskOptions(),
    });
    dispatch(
      kioskPageActions.setClockInStatus(response.data.timeclock ? response.data.timeclock : null)
    );
    dispatch(kioskPageActions.setPto(response.data.timeoffSummary));
  } catch (e) {
    dispatch(kioskPageActions.setAlert({ type: 'error', text: e.message }));
    dispatch(kioskPageActions.setError(e));
  }
};

export const sendEmail = () => async (dispatch) => {
  try {
    await $api.post(
      `${process.env.REACT_APP_BASE_URL}/kiosk/send-email-hours
    `,
      {},
      getKioskOptions()
    );

    dispatch(
      kioskPageActions.setAlert({
        type: 'success',
        text: 'An email has been sent to your email address on file.',
      })
    );
  } catch (e) {
    dispatch(kioskPageActions.setAlert({ type: 'error', text: e.message }));
    dispatch(kioskPageActions.setError(e));
  }
};

export const getSummary =
  ({ date, userId }) =>
  async (dispatch, getState) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/kiosk/time`,
        getKioskOptions()
      );
      const actualDayObject = response.data.summaries.find(
        (el) => el.date === format(date, globalBEDateFormat)
      );
      const res = actualDayObject?.summary ? [actualDayObject?.summary] : [];

      dispatch(kioskPageActions.setSummary(res));
    } catch (e) {
      dispatch(kioskPageActions.setAlert({ type: 'error', text: e.message }));
      dispatch(kioskPageActions.setError(e));
    }
  };

export const getClockInStatus = (userId) => async (dispatch) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };
  try {
    // const response = await fetch(
    //   `${process.env.REACT_APP_BASE_URL}/timeclocks?userId=${userId}&sort=-inTime&limit=1&outTime=0`,
    //   options
    // ).then((res) => res.json());
    // dispatch(
    //   kioskPageActions.setClockInStatus(response[0] && response[0].outTime ? null : response[0])
    // );
  } catch (e) {
    dispatch(kioskPageActions.setAlert({ type: 'error', text: e.message }));
    dispatch(kioskPageActions.setError(e));
  }
};

export const getCompany = () => async (dispatch) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/kiosk/organization`, {
      headers: {
        ...getKioskOptions().headers,
        'x-admin-kiosk-token': localStorage.getItem('x-admin-kiosk-token'),
      },
    });

    dispatch(kioskPageActions.setCompany(response.data));
  } catch (e) {
    dispatch(kioskPageActions.setAlert({ type: 'error', text: e.message }));
    dispatch(kioskPageActions.setError(e));
  }
};
