import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useController } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from './styles';
import CloseIcon from 'assets/images/close-icon.svg';
import cn from 'classnames';

const LateClockIn = ({ classes, pageType, control, errors }) => {
  const { lateClockInEmails } = useSelector((state) => state.settings.settings);

  const {
    field: { onChange, value: LateClockIn },
  } = useController({
    name: 'lateClockInEmails',
    control,
    defaultValue: [],
  });

  const [isShowEmails, setShowEmails] = useState(false);

  useEffect(() => {
    onChange(lateClockInEmails || []);
  }, [lateClockInEmails]);

  const handleEmailChange = (i) => (e) => {
    const { value } = e.target;
    const nextValue = LateClockIn.map((t, index) => (index === i ? value : t));
    onChange(nextValue);
  };
  const handleEmailRemoving = (i) => () => {
    const nextValue = LateClockIn.filter((_, index) => index !== i);
    onChange(nextValue);
  };
  const handleAddEmail = () => {
    setShowEmails(true);
    onChange([...LateClockIn.filter((v) => v.length), ''])
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4">
        Late Clock-In Report
      </Typography>
      <div className={classes.actionsWrapper}>
        {pageType === 'edit' && (
          <Button
            variant="text"
            className={classes.primaryTextButton}
            onClick={handleAddEmail}
            disableRipple
          >
            + Add Email
          </Button>
        )}
        {isShowEmails &&
          LateClockIn.map((email, index) => (
            <Grid container spacing={5} key={`lateClockInEmails-${index}`} alignItems="center">
              <Grid item xs={10} sm={6} md={3} className={classes.zeroPaddingTop}>
                <TextField
                  id={`lateClockInEmails[${index}]`}
                  name={`lateClockInEmails[${index}]`}
                  type="email"
                  value={email}
                  className={classes.textField}
                  onChange={handleEmailChange(index)}
                  variant="outlined"
                  inputProps={{ 'aria-label': 'Late Clock-In Report' }}
                  fullWidth
                  disabled={pageType === 'read'}
                />
              </Grid>
              {pageType === 'edit' && (
                <span className={classes.removeEmail} onClick={handleEmailRemoving(index)}>
                  <img src={CloseIcon} alt="delete" />
                </span>
              )}
            </Grid>
          ))}
        {!!LateClockIn.length && (
          <div
            className={classes.buttonWrapper}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowEmails((prev) => !prev);
            }}
          >
            <div className={classes.nonActionsIcon}>
              <ExpandMoreIcon className={cn(isShowEmails ? '' : classes.reversIcon)} />
            </div>
            <p className={classes.textIconMore}>{`${
              isShowEmails ? 'Hide' : 'Show'
            } email addresses`}</p>
          </div>
        )}
      </div>
    </div>
  );
};

LateClockIn.propTypes = {
  classes: propTypes.object.isRequired,
  pageType: propTypes.string.isRequired,
  errors: propTypes.object.isRequired,
  control: propTypes.object.isRequired,
};

export default withStyles(styles)(LateClockIn);
