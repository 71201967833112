import React, { Component } from 'react';
import propTypes from 'prop-types';
import { add, sub, startOfMonth, endOfMonth, format, parse } from 'date-fns';

import PageLoader from '../../../../../../components/PageLoader';
import FiltersBar from '../../../../../../components/FiltersBar';
import CalendarTable from '../CalendarTable';
import AssignModal from '../AssignModal';
import { getCurrentMonthDates, splitArrayToArraysOfRow } from '../../../../../../helpers/_helpers';
import {
  getCalendarAllEquipment,
  getEquipmentEvents,
} from 'store/availability/availabilityPagesOperations';
import { connect } from 'react-redux';
import { dateSettingsFormat, globalBEDateFormat } from 'common/dateFormatConfig';

class EquipmentCalendar extends Component {
  state = {
    equipmentEvents: [],
    isLoading: false,
    searchValue: '',
    assignModal: {
      isOpen: false,
      type: 'equipment',
      eventInfo: {},
    },
  };

  componentDidMount() {
    this.updateVisibleItems();
    this.props.getCalendarAllEquipment();
  }

  componentDidUpdate(prevProps) {
    const prevDate = format(
      new Date(prevProps.selectedDate),
      dateSettingsFormat(this.props.dateFormat).dateFormat
    );
    const currentDate = format(
      new Date(this.props.selectedDate),
      dateSettingsFormat(this.props.dateFormat).dateFormat
    );
    if (prevDate !== currentDate) {
      this.updateVisibleItems();
    }
    if (prevProps.equipmentEvents !== this.props.equipmentEvents) {
      this.updateVisibleItems(true);
    }
  }

  setLoading = () => this.setState({ isLoading: true });

  updateSearchValue = (value) => {
    this.setState({ searchValue: value }, this.updateVisibleItems);
    this.props.getEquipmentEvents(value);
  };

  updateVisibleItems = (isNoLoadingRequired) => {
    if (!isNoLoadingRequired) {
      this.setState({ isLoading: true });
    }

    const mappedEvents = getCurrentMonthDates(this.props.selectedDate, this.props.weekStart, globalBEDateFormat);
    // const dateFormat = dateSettingsFormat(this.props.dateFormat).dateFormat
    mappedEvents.forEach((dateObj, i) => {
      this.props.equipmentEvents.forEach((event) => {
        // const startDate = format(
        //   parse(
        //     event.start,
        //     globalBEDateFormat,
        //     new Date(event.start)
        //   ),
        //   globalBEDateFormat
        // );

        if (event.start === dateObj.dayNumber) {
          mappedEvents[i].events.push(event);
        }
      });
    });
    const arrayOfRow = splitArrayToArraysOfRow(mappedEvents);
    this.setState((prevState) => ({
      isLoading: isNoLoadingRequired ? prevState.isLoading : false,
      equipmentEvents: arrayOfRow,
      drawer: { ...prevState.drawer, isOpen: false },
    }));
  };

  openAssignModal = (type, eventInfo) => () => {
    this.setState({ assignModal: { isOpen: true, type, eventInfo } });
  };

  closeAssignModal = () => {
    this.setState((prevState) => ({
      assignModal: { ...prevState.assignModal, isOpen: false },
    }));
  };

  render() {
    const { openSnackbar, selectedDate } = this.props;
    const { equipmentEvents, isLoading, assignModal } = this.state;

    return (
      <>
        <FiltersBar
          openDrawer={() => null}
          updateSearchValue={this.updateSearchValue}
          noFilters
          isDynamicSearch={true}
        />
        {/* <PageLoader loading={isLoading}> */}
        <CalendarTable
          equipmentEvents={equipmentEvents}
          selectedDate={selectedDate}
          openSnackbar={openSnackbar}
          openAssignModal={this.openAssignModal}
          weekStart={this.props.weekStart}
        />
        {/* </PageLoader> */}

        {assignModal.isOpen && (
          <AssignModal
            type={assignModal.type}
            defaultEventInfo={assignModal.eventInfo}
            events={equipmentEvents}
            onClose={this.closeAssignModal}
            openSnackbar={openSnackbar}
          />
        )}
      </>
    );
  }
}

EquipmentCalendar.propTypes = {
  openSnackbar: propTypes.func.isRequired,
  selectedDate: propTypes.object.isRequired,
  equipmentEvents: propTypes.array.isRequired,
};

export default connect(
  (state) => ({
    dateFormat: state.personalProfile.organization?.settings?.dateFormat,
  }),
  {
    getCalendarAllEquipment,
    getEquipmentEvents,
  }
)(EquipmentCalendar);
