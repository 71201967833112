import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContentText, Typography } from '@material-ui/core';
import styles from '../ResourcesForm/styles';
import ChangeTimeModal from './ChangeTimeModal';
import DialogContent from '@material-ui/core/DialogContent';
import classnames from 'classnames';
import photoPlaceholder from 'assets/images/user-photo-placeholder.svg';
const useStyles = makeStyles(styles);

const OverlapsingModal = ({ onClose, data, message, onSave, handleUpdateConflictWorkers, moveFrom }) => {
  const [changeTime, setChangeTime] = useState(null);
  const [overlappingData, setOverlappingData] = useState(data);
  const [overlappingMessage, setOverlappingMessage] = useState(message);

  useEffect(() => {
    setOverlappingData(data);
  }, [data]);

  useEffect(() => {
    setOverlappingMessage(message);
  }, [message]);

  const classes = useStyles();
  const showMessage = overlappingData.length === 1;

  const handleChangeTime = (data) => () => {
    setChangeTime(data);
  };

  const closeChangeTime = () => {
    setChangeTime(null);

    if (onClose) onClose();
  }
  const saveChangeTime = () => {
    const changeTimeUsername = changeTime.worker.username;
    const availableToChangeOverlappingData = overlappingData.filter(
      ({ worker }) => changeTime.worker._id !== worker._id
    );
    setChangeTime(null);
    setOverlappingData(availableToChangeOverlappingData);
    if (handleUpdateConflictWorkers) handleUpdateConflictWorkers(changeTime.workerId);
    const userMessage = overlappingMessage
      .split('\n')
      .filter((message) => !message.includes(changeTimeUsername))
      .join('\n');
    setOverlappingMessage(userMessage);
    if (!availableToChangeOverlappingData.length){
      onSave ? onSave() : onClose();
    }
  };

  return (
    <>
      <Dialog
        open
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          classes: {
            root: classes.dialogRoot,
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" disableTypography>
          {showMessage && <Typography variant="h3">{overlappingMessage.split(':')[0]}</Typography>}
          {!showMessage && <Typography variant="h3">Users already have worklogs</Typography>}
        </DialogTitle>
        <DialogContent>
          {showMessage && <DialogContentText>{overlappingMessage}</DialogContentText>}
          {!showMessage &&
            overlappingData.map(({ worker }, i) => (
              <div className={classes.inlineFlex}>
                <div
                  className={classnames(
                    classes.photoWrapper,
                    classes[`${worker?.profile?.shifts?.timeOfDay?.toLowerCase() || worker?.shifts?.timeOfDay?.toLowerCase()}Border`]
                  )}
                >
                  <img src={photoPlaceholder} className={classes.photo} />
                </div>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  className={classnames(
                    classes.name,
                    classes[worker?.profile?.shifts?.timeOfDay?.toLowerCase() ||  worker?.shifts?.timeOfDay?.toLowerCase()]
                  )}
                >
                  {worker.username}
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classnames(classes.saveButton, classes.changeButton)}
                  disableTouchRipple
                  onClick={handleChangeTime(overlappingData[i])}
                >
                  Change Time
                </Button>
              </div>
            ))}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            variant="outlined"
            color="secondary"
            className={classes.cancelButton}
            disableTouchRipple
          >
            Cancel
          </Button>
          {showMessage && (
            <Button
              onClick={handleChangeTime(overlappingData[0])}
              variant="outlined"
              color="primary"
              className={classes.saveButton}
              disableTouchRipple
            >
              Change Time
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {changeTime && (
        <ChangeTimeModal save={saveChangeTime} data={changeTime} onClose={closeChangeTime} moveFrom={moveFrom}/>
      )}
    </>
  );
};

OverlapsingModal.propTypes = {
  message: propTypes.string.isRequired,
  onClose: propTypes.func.isRequired,
  data: propTypes.array.isRequired,
};

export default OverlapsingModal;
