import { withStyles } from '@material-ui/core/styles';
import { Button, Dialog, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles from './../ConflictModal/styles';
import UsersList from './components/UserList/UsersList';

const TimeoffModal = ({ classes, data, onClose }) => {
  const usersTimeoffs = (data) => {
    return data.filter(day => day.workerTimeoffs)
  }

  return (
    <>
      <Dialog
        open={true}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          classes: {
            root: classes.dialogRoot,
          },
        }}
        disableEscapeKeyDown
      >
        <div className={classes.formWrapper} role="presentation">
          <div className={classes.formFlex}>
            <div>
              <Typography variant="h3" style={{ marginBottom: '20px' }}>
                Following Workers Timeoff
              </Typography>
              <IconButton
                className={classes.closeButton}
                onClick={onClose}
                aria-label="Close"
                disableTouchRipple
              >
                <CloseIcon />
              </IconButton>
            </div>
            <Typography style={{ fontSize: '14px', marginBottom: '20px' }}>
              Please, consult the Administrator to change the availability of those users
            </Typography>

            <UsersList data={usersTimeoffs(data)} />

            <div className={classes.buttonHolder}>
              <Button
                color="primary"
                variant="outlined"
                className={classes.saveButton}
                onClick={onClose}
                disableRipple
                disableTouchRipple
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(TimeoffModal);
