import React, { useState } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import PeopleCalendar from './components/PeopleCalendar/PeopleCalendar';
import EquipmentCalendar from './components/EquipmentCalendar';
import styles from './styles';
import { useSelector } from 'react-redux';

const AvailabilityCalendar = ({
  classes,
  openSnackbar,
  selectedDate,
  userEvents,
  equipmentEvents,
  tabValue,
  handleChangeTab,
  holidays,
}) => {
  const weekStart = useSelector((state) => state.settings.settings?.weekStart || 'Sun');

  return (
    <>
      <Tabs value={tabValue} onChange={handleChangeTab} className={classes.tabs}>
        <Tab label="People" disableRipple />
        <Tab label="Equipment" disableRipple />
      </Tabs>
      {tabValue === 0 && (
        <PeopleCalendar
          openSnackbar={openSnackbar}
          selectedDate={selectedDate}
          userEvents={userEvents}
          holidays={holidays}
          weekStart={weekStart}
        />
      )}
      {tabValue === 1 && (
        <EquipmentCalendar
          openSnackbar={openSnackbar}
          selectedDate={selectedDate}
          equipmentEvents={equipmentEvents}
          weekStart={weekStart}
        />
      )}
    </>
  );
};

AvailabilityCalendar.propTypes = {
  classes: propTypes.object.isRequired,
  openSnackbar: propTypes.func.isRequired,
  selectedDate: propTypes.object.isRequired,
  userEvents: propTypes.array.isRequired,
  equipmentEvents: propTypes.array.isRequired,
  tabValue: propTypes.number.isRequired,
  handleChangeTab: propTypes.func.isRequired,
  holidayDates: propTypes.array.isRequired,
  toggleHolidayModal: propTypes.func.isRequired,
  holidays: propTypes.array.isRequired,
};

export default withStyles(styles)(AvailabilityCalendar);
