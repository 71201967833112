import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import PageLoader from 'components/PageLoader';
import styles from './styles';
import { isIOS, Option } from 'components/Option';

const SortingAndFilteringPanel = ({
  classes,
  closeDrawer,
  onCancel,
  filter,
  onUpdate,
  manufacturers,
  colors,
  setFilter,
}) => {
  const [manufacturersForm, setManufacturersForm] = useState(filter.manufacturerIds);
  const [colorOrNumberForm, setColorOrNumberForm] = useState(filter.colorOrNumber);

  const handleChangeMultipleSelect = (setFieldValue) => (e) => {
    const clickedOption = e.currentTarget.dataset.value;
    const newValue = e.target.value;

    if (clickedOption === 'All') {
      if (manufacturersForm.includes('All')) {
        setManufacturersForm([]);
        setFieldValue('manufacturerIds', []);
      } else {
        setManufacturersForm(['All']);
        setFieldValue('manufacturerIds', ['All']);
      }
    }
    if (clickedOption !== 'All') {
      if (manufacturersForm.includes('All')) {
        setManufacturersForm(newValue.filter((el) => el !== 'All'));
        setFieldValue(
          'manufacturerIds',
          newValue.filter((el) => el !== 'All')
        );
      } else {
        setManufacturersForm(newValue);
        setFieldValue('manufacturerIds', newValue);
      }
    }
  };

  const handleChangeMultipleSelectColor = (setFieldValue) => (e) => {
    const clickedOption = e.currentTarget.dataset.value;
    const newValue = e.target.value;

    if (clickedOption === 'All') {
      if (colorOrNumberForm.includes('All')) {
        setColorOrNumberForm([]);
        setFieldValue('colorOrNumber', []);
      } else {
        setColorOrNumberForm(['All']);
        setFieldValue('colorOrNumber', ['All']);
      }
    }
    if (clickedOption !== 'All') {
      if (colorOrNumberForm.includes('All')) {
        setColorOrNumberForm(newValue.filter((el) => el !== 'All'));
        setFieldValue(
          'colorOrNumber',
          newValue.filter((el) => el !== 'All')
        );
      } else {
        setColorOrNumberForm(newValue);
        setFieldValue('colorOrNumber', newValue);
      }
    }
  };
  return (
    <div className={classes.formWrapper} role="presentation">
      <Formik
        initialValues={filter}
        enableReinitialize={true}
        onSubmit={(values) => {
          if (!values.manufacturerIds.length) {
            values.manufacturerIds = 'All';
          }
          if (!values.colorOrNumber.length) {
            values.colorOrNumber = 'All';
          }
          onUpdate(values);
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <PageLoader loading={false}>
            <Form autoComplete="off" className={classes.formFlex}>
              <div>
                <Typography variant="h3" className={classes.marginHeader}>
                  Filters
                </Typography>

                <IconButton
                  className={classes.closeButton}
                  onClick={closeDrawer}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={2} className={classes.flexContainer}>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="sortBy" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Sort By:
                      </Typography>
                      <TextField
                        select
                        id="sortBy"
                        name="sortBy"
                        value={values.sortBy}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Sort by' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={2} value="colorOrNumber">
                          Color/Number
                        </Option>
                        <Option key={5} value="lotNumber">
                          Lot number
                        </Option>
                        <Option key={4} value="manufacturer">
                          Manufacturer
                        </Option>
                        <Option key={1} value="material">
                          Material
                        </Option>
                        <Option key={3} value="symbolOrWidth">
                          Symbol/Width
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="sortOrder" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Sort Order:
                      </Typography>
                      <TextField
                        select
                        id="sortOrder"
                        name="sortOrder"
                        value={values.sortOrder}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Days' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={1} value="asc">
                          {'A \u2013 Z'}
                        </Option>
                        <Option key={2} value="desc">
                          {'Z \u2013 A'}
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                </Grid>

                <hr />

                <Grid container spacing={2} className={classes.flexContainer}>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="colorOrNumber" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Color/Number:
                      </Typography>
                      <TextField
                        select
                        id="colorOrNumber"
                        name="colorOrNumber"
                        value={values.colorOrNumber}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Color' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        {[
                          <Option key={'All'} value="All">
                            All
                          </Option>,
                          ...colors
                            .sort((a, b) => a.localeCompare(b))
                            .map((c) => (
                              <Option key={c} value={c}>
                                {c}
                              </Option>
                            )),
                        ]}
                        {/* {colors
                          .sort((a, b) => a.localeCompare(b))
                          .map((c) => (
                            <Option key={c} value={c}>
                              {c}
                            </Option>
                          ))} */}
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="manufacturerIds" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Manufacturer:
                      </Typography>
                      <TextField
                        select
                        id="manufacturerIds"
                        name="manufacturerIds"
                        value={values.manufacturerIds}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Manufacturer' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        {[
                          <Option key={'All'} value="All">
                            All
                          </Option>,
                          ...manufacturers.map((manufacturer) => (
                            <Option key={manufacturer._id} value={manufacturer._id}>
                              {manufacturer.name}
                            </Option>
                          )),
                        ]}
                        {/* {Object.keys(manufacturers)
                          .sort((a, b) =>
                            manufacturers[a].name.localeCompare(manufacturers[b].name)
                          )
                          .map((manufacturerId) => (
                            <Option key={manufacturerId} value={manufacturerId}>
                              {manufacturers[manufacturerId].name}
                            </Option>
                          ))} */}
                      </TextField>
                    </label>
                  </Grid>
                </Grid>

                <button className={classes.linkButton} onClick={() => onCancel()}>
                  Reset all filters
                </button>
              </div>

              <div className={classes.buttonHolder}>
                <Button color="secondary" className={classes.cancelButton} onClick={closeDrawer}>
                  Cancel
                </Button>

                <div className={classes.verticalDivider}></div>

                <Button type="submit" color="primary" className={classes.saveButton}>
                  Apply
                </Button>
              </div>
            </Form>
          </PageLoader>
        )}
      </Formik>
    </div>
  );
};

SortingAndFilteringPanel.propTypes = {
  classes: propTypes.object.isRequired,
  onCancel: propTypes.func.isRequired,
  closeDrawer: propTypes.func.isRequired,
  filter: propTypes.object.isRequired,
  onUpdate: propTypes.func.isRequired,
  manufacturers: propTypes.array.isRequired,
  colors: propTypes.array.isRequired,
};

export default withStyles(styles)(SortingAndFilteringPanel);
