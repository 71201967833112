import { startOfDay, endOfDay, isAfter, isBefore } from 'date-fns';
import { SHEET_EVENT } from 'subscriptions/events/events.enum';
import { subscriptionsFabric } from 'subscriptions/subscriptions.fabric';
import workerSchedulePageActions from 'store/workerSchedule/workerSchedulePageActions';
import { fetchMyScheduleSheets } from 'store/workerSchedule/workerSchedulePageOperations';
import { fetchPersonalProfile } from 'store/personalProfile/personalProfileOperations';


const handlers = {
  [SHEET_EVENT.myScheduleAdded]: async (store) => {
    let userId = store.getState().personalProfile?.user?._id;
    if (!userId) await store.dispatch(fetchPersonalProfile());

    userId = store.getState().personalProfile?.user?._id;
    const workerSheetEvent = SHEET_EVENT.myScheduleAdded.replace('{userId}', userId);

    const listener = (data) => {
      const sheets = store.getState().workerSchedule.mySchedule.sheets;
      const sheetInSchedule = sheets.some((sheet) => sheet._id === data?._id);
      // Add only if sheet is published and is within selected day
      if (data.published && !sheetInSchedule) {
        const sheetStart = new Date(data.hours.start);
        const sheetEnd = new Date(data.hours.end);
        const dayStart = startOfDay(new Date());
        const dayEnd = endOfDay(new Date());
        if (
          (isAfter(sheetStart, dayStart) && isBefore(sheetStart, dayEnd)) ||
          (isAfter(sheetEnd, dayStart) && isBefore(sheetEnd, dayEnd)) ||
          (isBefore(sheetStart, dayStart) && isAfter(sheetEnd, dayEnd))
        ) {
          store.dispatch(workerSchedulePageActions.addMyScheduleSheet(data))
        }
      }
    }

    return { name: workerSheetEvent, listener };
  },
  [SHEET_EVENT.updated]: (store) => (data) => {
    const sheets = store.getState().workerSchedule.mySchedule.sheets;
    const sheetInSchedule = sheets.some((sheet) => sheet._id === data?._id);

    if (sheetInSchedule) {
      store.dispatch(workerSchedulePageActions.putMyScheduleSheet(data));
    }
  },
  [SHEET_EVENT.resourcesUpdated]: (store) => async (data) => {
    let userId = store.getState().personalProfile?.user?._id;
    if (!userId) await store.dispatch(fetchPersonalProfile());

    userId = store.getState().personalProfile?.user?._id;
    const sheets = store.getState().workerSchedule.mySchedule.sheets;
    if (data?.length) {
      for (const updatedSheet of data) {
        const sheetInSchedule = sheets.some((sheet) => sheet._id === updatedSheet?._id);

        if (sheetInSchedule) {
          if (updatedSheet.workers.some(worker => worker._id === userId)) {
            store.dispatch(workerSchedulePageActions.patchMyScheduleSheet(updatedSheet));
          } else {
            store.dispatch(workerSchedulePageActions.deleteMyScheduleSheet(updatedSheet));
          }
        }
      }
    }
  },
  [SHEET_EVENT.deleted]: (store) => (data) => {
    const sheets = store.getState().workerSchedule.mySchedule.sheets;
    const sheetInSchedule = sheets.some((sheet) => sheet._id === data?._id);

    if (sheetInSchedule) store.dispatch(workerSchedulePageActions.deleteMyScheduleSheet(data));
  },
  [SHEET_EVENT.notes]: (store) => (data) => {
    const sheets = store.getState().workerSchedule.mySchedule.sheets;
    const sheetInSchedule = sheets.some((sheet) => sheet._id === data?._id);

    if (sheetInSchedule) {
      store.dispatch(workerSchedulePageActions.patchMyScheduleSheet(data));
    }
  },
  [SHEET_EVENT.updatedCrewLeader]: (store) => (data) => {
    const sheets = store.getState().workerSchedule.mySchedule.sheets;
    const sheetInSchedule = sheets.some((sheet) => sheet._id === data?._id);

    if (sheetInSchedule) store.dispatch(workerSchedulePageActions.patchMyScheduleSheetWorker(data));
  },
  [SHEET_EVENT.canceled]: (store) => (data) => {
    const sheets = store.getState().workerSchedule.mySchedule.sheets;
    const sheetInSchedule = sheets.some((sheet) => sheet._id === data?._id);

    if (sheetInSchedule) store.dispatch(workerSchedulePageActions.patchMyScheduleSheet(data));
  },
  [SHEET_EVENT.uncanceled]: (store) => (data) => {
    const sheets = store.getState().workerSchedule.mySchedule.sheets;
    const sheetInSchedule = sheets.some((sheet) => sheet._id === data?._id);

    if (sheetInSchedule) store.dispatch(workerSchedulePageActions.patchMyScheduleSheet({ ...data, canceledAt: null, canceledBy: null }));
  },
  [SHEET_EVENT.publishedBunch]: (store) => async (data) => {
    await store.dispatch(fetchMyScheduleSheets());
  },
  [SHEET_EVENT.submitted]: (store) => (data) => {
    const sheets = store.getState().workerSchedule.mySchedule.sheets;
    const sheetInSchedule = sheets.some((sheet) => sheet._id === data?._id);

    if (sheetInSchedule) {
      const submissions = sheetInSchedule.submissions?.map(sub => sub) || [];

      if (data.submission) submissions.push(data.submission);

      const updates = {
        _id: data._id,
        notes: data.notes || sheetInSchedule.notes || '',
        submittedAt: data.submittedAt,
        submittedBy: data.submittedBy,
        submissions: submissions,
      };

      store.dispatch(workerSchedulePageActions.patchMyScheduleSheet(updates));
    }
  },
  [SHEET_EVENT.submissionDeleted]: (store) => (data) => {
    const sheets = store.getState().workerSchedule.mySchedule.sheets;
    const sheetInSchedule = sheets.some((sheet) => sheet._id === data?._id);

    if (sheetInSchedule) {
      let submissions = sheetInSchedule.submissions;
      if (submissions?.length) submissions = submissions.filter(submission => submission._id !== data.submission?._id);

      const updates = {
        _id: data._id,
        notes: data.notes || sheetInSchedule.notes || '',
        submittedAt: data.submittedAt,
        submittedBy: data.submittedBy,
      };
      if (submissions) updates.submissions = submissions.map(sub => sub);

      store.dispatch(workerSchedulePageActions.patchMyScheduleSheet(updates));
    }
  },
}

export const myScheduleSheetsSubscriptionMiddleware = subscriptionsFabric(
  workerSchedulePageActions,
  SHEET_EVENT,
  handlers
);
