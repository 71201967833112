const getAllSettings = (state) => state.settings.settings;
const getImage = (state) => state.settings.settings.homepageImage;
const getTitle = (state) =>
  state.settings.settings.homepage && state.settings.settings.homepage.quote;
const getTitleColor = (state) =>
  state.settings.settings.homepage && state.settings.settings.homepage.quoteColor;
const getToggleCountdownEnabled = (state) =>
  state.settings.settings.homepage && state.settings.settings.homepage.countdownEnabled;
const getCountdownTitle = (state) =>
  state.settings.settings.homepage && state.settings.settings.homepage.countdownTitle;
const getCountdownDate = (state) =>
  state.settings.settings.homepage && state.settings.settings.homepage.countdownDate;

export default {
  getAllSettings,
  getImage,
  getTitle,
  getTitleColor,
  getToggleCountdownEnabled,
  getCountdownTitle,
  getCountdownDate,
};
