import superadminActions from './superadminActions';
import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

export const initialState = {
  clients: [],
  statistic: {},
  users: [],
  snackBarInfo: null,
};

const clients = createReducer(initialState.clients, {
  [superadminActions.getClients]: (state, { payload }) => ([
    ...payload,
  ]),
  [superadminActions.createClient]: (state, { payload }) => ([
    ...state,
    payload,
  ]),
  [superadminActions.updateClient]: (state, { payload }) => ([
    ...state.map((client) => (
      client._id === payload._id
        ? payload
        : client
    )),
  ]),
});

const statistic = createReducer(initialState.statistic, {
  [superadminActions.getStatistic]: (state, { payload }) => ({
    ...payload,
  }),
})

const users = createReducer(initialState.users, {
  [superadminActions.getUsers]: (state, { payload }) => ([
    ...payload,
  ]),
  [superadminActions.createUser]: (state, { payload }) => ([
    ...state, payload,
  ]),
  [superadminActions.updateUser]: (state, { payload }) => ([
    ...state.map((user) => 
      user._id === payload._id
        ? payload
        : user
    ),
  ]),
  [superadminActions.updateBunchUsers]: (state, { payload }) => ([
    ...state.map((user) =>
      payload.find((updated) => updated._id === user._id) || user
    ),
  ]),
  [superadminActions.deleteUser]: (state, { payload }) => ([
    ...state.filter(({ _id }) => _id !== payload)
  ]),
});

const snackBarInfo = createReducer(initialState.snackBarInfo, {
  [superadminActions.snackBarInfo]: (state, { payload }) => payload,
})

export default combineReducers({
  clients,
  statistic,
  users,
  snackBarInfo,
});