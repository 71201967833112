import React, { memo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import WorklogsList from '../WorklogsList';
import { sortAndMap } from 'store/worklogs/worklogsPageOperations'
import { fetchMyScheduleSheets } from 'store/workerSchedule/workerSchedulePageOperations';
import workerSchedulePageActions from 'store/workerSchedule/workerSchedulePageActions';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';

const MySchedule = memo(
  ({openSnackbar}) => {
    const dispatch = useDispatch();
    const { loading, mySchedule: { sheets, selectedDate } } = useSelector(state => state.workerSchedule);
    const { dateFormat } = useDateSettingsFormat();

    const [sheetsInView, setSheetsInView] = useState([]);

    useEffect(() => {
      const getData = async () =>{
        dispatch(workerSchedulePageActions.setLoading(true));
        try {
          dispatch(fetchMyScheduleSheets());
        } catch (e) {
          openSnackbar('error', e.message);
        } finally {
          dispatch(workerSchedulePageActions.setLoading(false));
        }
      }
      getData()
    }, [selectedDate]);

    useEffect(() => {
      setSheetsInView(sortAndMap(sheets, null, null, dateFormat))
    }, [sheets]);


    return (
      <div>
        {loading ? (
          <LoadingSpinner height={40} margin={32} />
        ) : (
          <WorklogsList sheets={sheetsInView} isFullSchedule={false} />
        )}
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.loading === nextProps.loading
);

export default MySchedule;