import React, { useState, useEffect } from 'react';
import '../../../../../../../../styles/base/definition-list.scss';
import formatNumber from '../../../../../../utils/formatNumber';
import hoursMinutesToNumber from '../../../../../../utils/hoursMinutesToNumber';
import IconButton from '../../../../../../../../components/core/IconButton/IconButton';
import ClockIcon from '../../../../../../../../components/icons/Clock';
import { Grid } from '@material-ui/core';
import { TotalTime } from '../../../styled';
import { Badge, ID, Root, Title } from './styled';
import WarningIcon from '@material-ui/icons/WarningRounded';
import palette from '../../../../../../../../theme/palette';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import { styles } from './styles';
import Warning from '../../../../../../../../components/icons/Warning';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';

const useStylesMob = makeStyles(styles);

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const JobItem = ({
  data,
  editMode,
  onDelete,
  onChange,
  currentRoute,
  shopTime,
  hasPayroll,
  payrollVerified,
  payrollSheetItems,
  ...props
}) => {
  const [payrollDifferentOnJobTime, setPayrollDifferentOnJobTime] = useState(false);
  const [payrollDifferentTravelTimeTo, setPayrollDifferentTravelTimeTo] = useState(false);
  const [payrollDifferentTravelTimeFrom, setPayrollDifferentTravelTimeFrom] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const getTimeFormatHook = useTimeSettingsFormat();
  const formatForTimePars = getTimeFormatHook().formatForTimePars;

  useEffect(() => {
    const initialTravelTo =
      data.travelTo >= 0
        ? data.travelTo
        : Number(formatNumber(hoursMinutesToNumber(data.hours.travelTimeTo)));

    const initialTravelFrom =
      data.travelFrom >= 0
        ? data.travelFrom
        : Number(formatNumber(hoursMinutesToNumber(data.hours.travelTimeFrom)));

    if (payrollSheetItems && payrollSheetItems.length) {
      let payrollSheetHours = {};
      let payrollSheetTravelTimeOnly;

      const payrollSheetByHoursId = payrollSheetItems.find(
        (item) => item.hours[0].id === data.hoursId
      );
      const payrollSheetBySheetId = payrollSheetItems.find(
        (item) => item.sheetId === data.sheet._id
      );

      if (payrollSheetByHoursId) {
        payrollSheetHours = payrollSheetByHoursId.hours[0];
        payrollSheetTravelTimeOnly = payrollSheetByHoursId.travelTimeOnly;
      } else {
        payrollSheetHours = payrollSheetBySheetId.hours[0];
        payrollSheetTravelTimeOnly = payrollSheetBySheetId.travelTimeOnly;
      }

      const differentStartTime =
        data.sheet.travelTimeOnly !== payrollSheetTravelTimeOnly ||
        (!payrollSheetTravelTimeOnly &&
          new Date(data.hours.start).getTime() !== new Date(payrollSheetHours.start).getTime());

      const differentEndTime =
        data.sheet.travelTimeOnly !== payrollSheetTravelTimeOnly ||
        (!payrollSheetTravelTimeOnly &&
          new Date(data.hours.end).getTime() !== new Date(payrollSheetHours.end).getTime());

      const differentTravelTimeTo = payrollSheetHours.travelTimeTo != initialTravelTo;
      const differentTravelTimeFrom = payrollSheetHours.travelTimeFrom != initialTravelFrom;

      setPayrollDifferentOnJobTime(differentStartTime || differentEndTime);
      setPayrollDifferentTravelTimeTo(differentTravelTimeTo);
      setPayrollDifferentTravelTimeFrom(differentTravelTimeFrom);
    } else {
      // set different time to true if sheet was added after verifying payroll
      setPayrollDifferentOnJobTime(true);
      setPayrollDifferentTravelTimeTo(true);
      setPayrollDifferentTravelTimeFrom(true);
    }
  }, [data, payrollSheetItems]);

  const classes = useStyles();
  const classesMob = useStylesMob();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const showExclamationMark = currentRoute && hasPayroll && !payrollVerified;

  return (
    <Root {...props}>
      <Grid
        container
        spacing={2}
        justifyContent={'space-between'}
        className={classesMob.jobItemWrapper}
      >
        <Grid item xs={3} className={classesMob.jobItemHeader}>
          {data.sheet.project && (
            <div className={classesMob.jobItemSubheader}>
              <Title onClick={() => (params.type ? navigate(data.sheet._id) : null)}>
                {data.sheet.project.contractor.name} - {data.sheet.project.route}
                {data.sheet.section ? ' - ' + data.sheet.section : null}
                {data.isClockInOutRange && (
                  <>
                    <WarningIcon
                      aria-owns={open ? 'mouse-over-popover' : undefined}
                      aria-haspopup="true"
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                      style={{ color: palette.textColor.yellow }}
                    />
                    <Popover
                      id="mouse-over-popover"
                      className={classes.popover}
                      classes={{
                        paper: classes.paper,
                      }}
                      open={open}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                      }}
                      onClose={handlePopoverClose}
                      disableRestoreFocus
                    >
                      <Typography>{data.isClockInOutRange}</Typography>
                    </Popover>
                  </>
                )}
              </Title>
              <ID>
                # {data.sheet.project.jobNumber}
                <Badge
                  red={data.sheet.forceAccount === 'Full' ? data.sheet.forceAccount : undefined}
                >
                  FA: {data.sheet.forceAccount}
                </Badge>
              </ID>
              <Grid container spacing={2} justifyContent={'space-between'}>
                <Grid item xs={6}>
                  <dl className={'dl'}>
                    <dt className={'dt'}>County:</dt>
                    <dd className={'dd'}>{data.sheet.project.county}</dd>
                  </dl>
                </Grid>
                <Grid item xs={6}>
                  <dl className={classesMob.jobItemSubheaderState}>
                    <dt className={'dt'}>State:</dt>
                    <dd className={'dd'}>{data.sheet.project.state || 'N/A'}</dd>
                  </dl>
                </Grid>
              </Grid>
            </div>
          )}
        </Grid>
        <div className={classesMob.main}>
          <div className={classesMob.wrap}>
            {data.sheet.project && (
              <>
                <div className={classesMob.projectType}>
                  <div>
                    <div className={classesMob.textTheme}>Project type:</div>
                    <div className={classesMob.subTextTheme}>{data.sheet.project.type}</div>
                  </div>
                  <div className={classesMob.flexBasis}>
                    <div className={classesMob.textTheme}>Certified Payroll:</div>
                    <div className={classesMob.subTextTheme}>
                      {data.sheet.project.rated ? 'Yes' : 'No'}
                    </div>
                  </div>
                </div>
                <div className={classesMob.benefits}>
                  <div className={classesMob.flexBasis}>
                    <div className={classesMob.textTheme}>EC:</div>
                    <div className={classesMob.subTextTheme}>
                      {data.sheet.project.earningsCode || 'N/A'}
                    </div>
                  </div>
                  <div className={classesMob.flexBasis}>
                    <div className={classesMob.textTheme}>BR:</div>
                    <div className={classesMob.subTextTheme}>
                      {data.sheet.project.baseRate || 'N/A'}
                    </div>
                  </div>
                  <div className={classesMob.flexBasis}>
                    <div className={classesMob.textTheme}>FB:</div>
                    <div className={classesMob.subTextTheme}>
                      {data.sheet.project.fringeBenefit || 'N/A'}
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className={classesMob.projectType}>
              <div className={classesMob.flexBasis}>
                <div className={classesMob.textTheme}>Shop time:</div>
                <div className={classesMob.subTextTheme}>{formatNumber(shopTime)}</div>
              </div>
            </div>
            <div className={classesMob.projectTypeBt}>
              {data.hours?.start && data.hours?.end && (
                <div>
                  <div className={classesMob.textTheme}>On job time:</div>
                  <div className={classesMob.subTextTheme}>
                    {format(new Date(data.hours.start), formatForTimePars)} -{' '}
                    {format(new Date(data.hours.end), formatForTimePars)}
                  </div>
                </div>
              )}
              <div className={classesMob.flexBasis}>
                <div>
                  {editMode ? (
                    <Grid item xs={'auto'} style={{ width: 50 }}>
                      <IconButton style={{ width: 50 }} onClick={() => onChange(data)}>
                        <ClockIcon />
                      </IconButton>
                    </Grid>
                  ) : null}
                </div>
                <div className={classesMob.subTextTheme}>
                  {formatNumber(data.hours.hours)} hours
                </div>
              </div>
            </div>

            <div className={classesMob.projectTypeBt}>
              <div>
                <div className={classesMob.textTheme}>Travel Time To Job:</div>
              </div>
              {showExclamationMark && payrollDifferentTravelTimeTo ? (
                <dd className={'warning'}>
                  <Warning />
                </dd>
              ) : null}
              <div className={classesMob.flexBasis}>
                <div className={classesMob.subTextTheme}>
                  {data.travelTo >= 0
                    ? formatNumber(data.travelTo)
                    : formatNumber(hoursMinutesToNumber(data.hours?.travelTimeTo))}
                </div>
              </div>
            </div>

            <div className={classesMob.projectTypeBt}>
              <div>
                <div className={classesMob.textTheme}>Travel Time From Job:</div>
              </div>
              {showExclamationMark && payrollDifferentTravelTimeFrom ? (
                <dd className={'warning'}>
                  <Warning />
                </dd>
              ) : null}
              <div className={classesMob.flexBasis}>
                <div className={classesMob.subTextTheme}>
                  {data.travelFrom >= 0
                    ? formatNumber(data.travelFrom)
                    : formatNumber(hoursMinutesToNumber(data.hours?.travelTimeFrom))}
                </div>
              </div>
            </div>

            {data.sheet?.rainPay &&
              (+data.sheet?.rainPay?.hours > 0 || +data.sheet?.rainPay?.minutes > 0) && (
                <div className={classesMob.projectTypeBt}>
                  <div>
                    <div className={classesMob.textTheme}>
                      Misc. Stipend:
                      <Tooltip
                        title={data.sheet?.rainPay?.description || ''}
                        placement="top"
                        enterTouchDelay={0}
                      >
                        <InfoIcon
                          fontSize="small"
                          style={{ width: 16, height: 16, marginLeft: 5 }}
                          color="primary"
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className={classesMob.flexBasis}>
                    <div className={classesMob.subTextTheme}>
                      {data.sheet?.rainPay?.hours}.{data.sheet?.rainPay?.minutes}
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
        <Grid
          item
          xs={9}
          container
          spacing={1}
          alignItems={'center'}
          style={{ justifyContent: 'space-between' }}
          display={{ sm: 'none' }}
          className={classesMob.desctopInfo}
        >
          <Grid item xs={3}>
            {data.sheet.project && (
              <Grid container direction={'column'} justifyContent={'space-between'}>
                <Grid item container spacing={2} style={{ marginBottom: 7 }}>
                  <Grid item xs={8}>
                    <dl className={'dl'}>
                      <dt className={'dt'}>Project type:</dt>
                      <dd className={'dd'}>{data.sheet.project.type}</dd>
                    </dl>
                  </Grid>
                  <Grid item xs={4}>
                    <dl className={'dl'}>
                      <dt className={'dt'}>Certified Payroll:</dt>
                      <dd className={'dd'}>{data.sheet.project.rated ? 'Yes' : 'No'}</dd>
                    </dl>
                  </Grid>
                </Grid>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ marginRight: '2px' }}>
                    <dl className={'dl'}>
                      <dt className={'dt'} style={{ marginRight: '5px' }}>
                        EC:
                      </dt>
                      <dd className={'dd'}>{data.sheet.project.earningsCode || 'N/A'}</dd>
                    </dl>
                  </div>
                  <div style={{ marginRight: '2px' }}>
                    <dl className={'dl'}>
                      <dt className={'dt'} style={{ marginRight: '5px' }}>
                        BR:
                      </dt>
                      <dd className={'dd'}>{data.sheet.project.baseRate || 'N/A'}</dd>
                    </dl>
                  </div>
                  <div style={{ marginRight: '2px' }}>
                    <dl className={'dl'}>
                      <dt className={'dt'} style={{ marginRight: '5px' }}>
                        FB:
                      </dt>
                      <dd className={'dd'}>{data.sheet.project.fringeBenefit || 'N/A'}</dd>
                    </dl>
                  </div>
                </div>
              </Grid>
            )}
          </Grid>
          <Grid item xs={6}>
            {data.hours?.start && data.hours?.end && (
              <Grid
                container
                item
                direction={'row'}
                wrap={'nowrap'}
                justifyContent={'space-between'}
                alignItems={'center'}
                xs={12}
                style={{ marginBottom: '5px' }}
              >
                <Grid container item direction={'row'} wrap={'nowrap'}>
                  <Grid className={'dt'}>On Job Time:</Grid>
                  <Grid className={'dd'}>
                    {format(new Date(data.hours?.start), formatForTimePars)} -{' '}
                    {format(new Date(data.hours?.end), formatForTimePars)}
                    {showExclamationMark && payrollDifferentOnJobTime ? (
                      <dd className={'warning'}>
                        <Warning />
                      </dd>
                    ) : null}
                  </Grid>
                </Grid>
                {editMode ? (
                  <Grid
                    container
                    item
                    justifyContent={'flex-end'}
                    xs={3}
                    wrap={'nowrap'}
                    alignItems={'center'}
                  >
                    <IconButton style={{ width: 50 }} onClick={() => onChange(data)}>
                      <ClockIcon />
                    </IconButton>
                    <Grid item>
                      <TotalTime>{formatNumber(data.hours.hours)}</TotalTime>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container justifyContent={'flex-end'} alignItems={'center'} xs={2}>
                    <Grid item>
                      <TotalTime>{formatNumber(data.hours.hours)}</TotalTime>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}

            <Grid
              container
              justifyContent={'space-between'}
              alignItems={'center'}
              style={{ marginBottom: '10px' }}
            >
              <div>
                <dt className={'dt'} style={{ marginRight: '16px' }}>
                  Travel Time To Job:
                </dt>
                {showExclamationMark && payrollDifferentTravelTimeTo ? (
                  <dd className={'warning'}>
                    <Warning />
                  </dd>
                ) : null}
              </div>
              <Grid item xs={'auto'}>
                <dd className={'dd'} style={{ fontSize: '20px' }}>
                  {data.travelTo >= 0
                    ? formatNumber(data.travelTo)
                    : formatNumber(hoursMinutesToNumber(data.hours?.travelTimeTo))}
                </dd>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent={'space-between'}
              alignItems={'center'}
              style={{ marginBottom: '10px' }}
            >
              <div>
                <dt className={'dt'} style={{ marginRight: '16px' }}>
                  Travel Time From Job:
                </dt>
                {showExclamationMark && payrollDifferentTravelTimeFrom ? (
                  <dd className={'warning'}>
                    <Warning />
                  </dd>
                ) : null}
              </div>
              <Grid item xs={'auto'}>
                <dd className={'dd'} style={{ fontSize: '20px' }}>
                  {data.travelFrom >= 0
                    ? formatNumber(data.travelFrom)
                    : formatNumber(hoursMinutesToNumber(data.hours?.travelTimeFrom))}
                </dd>
              </Grid>
            </Grid>

            {data.sheet?.rainPay &&
              (+data.sheet?.rainPay?.hours > 0 || +data.sheet?.rainPay?.minutes > 0) && (
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <dt className={'dt'} style={{ marginRight: '0' }}>
                      Misc. Stipend:
                    </dt>
                    <Tooltip
                      title={data.sheet?.rainPay?.description || ''}
                      placement="top"
                      enterTouchDelay={0}
                    >
                      <InfoIcon
                        fontSize="small"
                        style={{ width: 16, height: 16, marginLeft: 5 }}
                        color="primary"
                      />
                    </Tooltip>
                  </div>
                  <Grid item xs={'auto'}>
                    <dd className={'dd'} style={{ fontSize: '20px' }}>
                      {formatNumber(hoursMinutesToNumber(data.sheet?.rainPay))}
                    </dd>
                  </Grid>
                </Grid>
              )}
          </Grid>
        </Grid>
      </Grid>
    </Root>
  );
};

export default JobItem;
