import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import classnames from 'classnames';
import IconSunny from 'assets/images/icon-sunny.svg';
import IconMoon from 'assets/images/icon-moon.svg';
import styles from './styles';
import { isIOS, Option } from '../../../../../../components/Option';
import { Controller } from 'react-hook-form';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { convertTime, convertTimeObject } from 'common/dateFormatConfig';

const ShiftsBlock = ({ classes, pageType, hours, minutes, dayPart, errors, control }) => {
  const { defaultDayTime = {}, defaultNightTime = {} } = useSelector(
    (state) => state.settings.settings
  );
  const getTimeFormatHook = useTimeSettingsFormat();
  const is12Format = getTimeFormatHook().is12Format;

  const renderShiftTime = (shiftType, shiftTypeValue) => {
    const { hour, minute, amPm } = convertTimeObject(shiftTypeValue, is12Format);

    if (pageType === 'read') {
      return (
        <div>
          <Typography variant="body1" color="textSecondary" className={classes.regularFontWeight}>
            Start Time:
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            className={classnames(classes.fieldValue, classes.paddingTop)}
          >
            {convertTime(shiftTypeValue, is12Format)}
          </Typography>
        </div>
      );
    }
    return (
      <>
        <label>
          <Typography variant="body1" color="textSecondary" className={classes.regularFontWeight}>
            Start Time:
          </Typography>
        </label>
        <div className={classes.tripleSelectWrapper}>
          <Controller
            name={`${shiftType}.hour`}
            control={control}
            defaultValue={hour}
            as={
              <TextField
                select
                id={`${shiftType}.hour`}
                className={classes.textField}
                variant="outlined"
                inputProps={{ 'aria-label': 'Start time hours' }}
                fullWidth
                SelectProps={{
                  native: isIOS,
                }}
              >
                {hours.map((value, index) => (
                  <Option key={index} value={value}>
                    {value}
                  </Option>
                ))}
              </TextField>
            }
          />
          :
          <Controller
            name={`${shiftType}.minute`}
            control={control}
            defaultValue={minute}
            as={
              <TextField
                select
                id={`${shiftType}.minute`}
                className={classes.textField}
                variant="outlined"
                inputProps={{ 'aria-label': 'Start time minutes' }}
                fullWidth
                SelectProps={{
                  native: isIOS,
                }}
              >
                {minutes.map((value, index) => (
                  <Option key={index} value={value}>
                    {value}
                  </Option>
                ))}
              </TextField>
            }
          />
          {is12Format && (
            <Controller
              name={`${shiftType}.amPm`}
              control={control}
              defaultValue={amPm}
              as={
                <TextField
                  select
                  id={`${shiftType}.amPm`}
                  className={classes.textField}
                  variant="outlined"
                  inputProps={{ 'aria-label': 'Start time' }}
                  fullWidth
                  SelectProps={{
                    native: isIOS,
                  }}
                >
                  {dayPart.map((value, index) => (
                    <Option key={index} value={value}>
                      {value}
                    </Option>
                  ))}
                </TextField>
              }
            />
          )}
        </div>
      </>
    );
  };

  return (
    <Grid item xs={12} md={6}>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <Typography variant="body1" color="textSecondary" className={classes.regularFontWeight}>
            Shift:
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            className={classnames(classes.fieldValue, classes.paddingTop)}
          >
            <img src={IconSunny} alt="sunny" className={classes.shiftImage} />
            Day
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {renderShiftTime('defaultDayTime', defaultDayTime)}
        </Grid>
        <Grid item xs={6} className={classes.zeroPaddingTop}>
          <Typography variant="body1" color="textSecondary" className={classes.regularFontWeight}>
            Shift:
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            className={classnames(classes.fieldValue, classes.paddingTop)}
          >
            <img src={IconMoon} alt="moon" className={classes.shiftImage} />
            Night
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.zeroPaddingTop}>
          {renderShiftTime('defaultNightTime', defaultNightTime)}
        </Grid>
      </Grid>
    </Grid>
  );
};

ShiftsBlock.propTypes = {
  classes: propTypes.object.isRequired,
  pageType: propTypes.string.isRequired,
  hours: propTypes.array.isRequired,
  minutes: propTypes.array.isRequired,
  dayPart: propTypes.array.isRequired,
  errors: propTypes.object.isRequired,
};

export default withStyles(styles)(ShiftsBlock);
