import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { weekDaysMon, weekDaysSun } from './constants';
import {
  splitArrayToArraysOfRow,
  getCurrentMonthDates,
  changeDateFormatFromTo,
} from 'helpers/_helpers';
import DayCell from './components/DayCell/DayCell';
import { parse, isThisMonth } from 'date-fns';
import { adaptedDataHelper, getMonthSheets, publish } from 'store/schedule/schedulePageOperation';
import { format } from 'date-fns';
import { FORMATS } from 'helpers/formats';
import Modals from '../SchedulePage/components/Modals/Modals';
import _ from 'lodash';
import { getSheetsWithoutForeman } from 'pages/Schedule/components/ScheduleCalendar/scheduleHelpers';
import AlertModal from 'components/AlertModal/AlertModal';
import ConfirmDialog from 'components/ConfirmDialog';
import UpdateModal from 'pages/Schedule/components/ScheduleCalendar/components/UpdateModal/UpdateModal';
import schedulePageActions from 'store/schedule/schedulePageActions';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { globalBEDateFormat } from 'common/dateFormatConfig';

// export const isThisCurrMonth = (day) => {
//   const parsedDay = parse(day, 'dd/MM/yyyy', new Date());
//   return isThisMonth(parsedDay);
// };

const MonthViewCalendar = ({
  classes,
  isShow,
  openSnackbar,
  showPublishModal,
  updatePublishControl,
}) => {
  const dispatch = useDispatch();
  const { dateFormat } = useDateSettingsFormat();
  const weekStart = useSelector((state) => state.personalProfile.organization.settings.weekStart);
  const renderDays = weekStart === 'Sun' ? weekDaysSun : weekDaysMon;

  const sheets = useSelector((state) => state.schedule.sheets);
  const currentMonthDate = useSelector((state) => state.schedule.currentMonthDate);
  const selectedMonthDayDate = useSelector((state) => state.schedule.selectedMonthDayDate);
  const resourcesWorkers = useSelector((state) => state.schedule.resources.workers);
  const unpublishedSchedule = useSelector((state) => state.schedule.unpublishedSchedule);
  const settings = useSelector((state) => state.settings.settings);
  const filters = useSelector((state) => state.schedule.filters);
  const monthSheets = useSelector((state) => state.schedule.monthSheets);

  const [monthEvents, setMonthEvents] = useState([]);
  const [sheetsWithoutForemanIds, setSheetsWithoutForemanIds] = useState([]);

  const [infoModal, setInfoModal] = useState({
    isOpen: false,
    text: '',
  });
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    text: '',
  });
  const [updateModal, setUpdateModal] = useState({
    isOpen: false,
    data: {},
  });

  const fetchSheets = () => {
    dispatch(
      getMonthSheets({
        filters,
      })
    );
  };

  useEffect(() => {
    showPublishModal && publishSchedule();
  }, [showPublishModal]);

  useEffect(() => {
    fetchSheets();
  }, [currentMonthDate, filters]);
  useEffect(() => {
    return () => {
      dispatch(schedulePageActions.setSelectedMonthDayDate(''));
    };
  }, []);

  const handleSelectDay = (dayDate) => {
    dispatch(schedulePageActions.setSelectedMonthDayDate(dayDate));
    setDaySheetsBySelectDay(dayDate);
  };

  const setDaySheetsBySelectDay = (clickedDay) => {
    const formattedDay = changeDateFormatFromTo(dateFormat, globalBEDateFormat, clickedDay);
    const thisDaySheets = monthSheets?.[formattedDay] || [];
    dispatch(schedulePageActions.setSheets(adaptedDataHelper(thisDaySheets)));
  };

  useEffect(() => {
    const api = async () => {
      const mappedEvents = getCurrentMonthDates(currentMonthDate, weekStart, dateFormat); // make empty cells
      const res = mappedEvents.map((dateObj, i) => {
        const formattedToStoreFormatDay = changeDateFormatFromTo(
          dateFormat,
          globalBEDateFormat,
          mappedEvents[i].dayNumber
        );

        dateObj.events = monthSheets?.[formattedToStoreFormatDay] || [];
        return dateObj;
      });
      setMonthEvents(splitArrayToArraysOfRow(res));
    };
    api();
  }, [monthSheets]);

  const publishSchedule = async () => {
    //! publish header useeffect must call
    const sheetsInView = _.cloneDeep(sheets);
    const isGridView = false;
    const currentDate = format(currentMonthDate, dateFormat);
    let sheetsThisDay = sheetsInView.filter((s) => !s.noResources);

    if (unpublishedSchedule) {
      //* for publish
      let notPublishedSheets = sheetsThisDay.filter((s) => !s.sheet.published);
      const sheetsWithoutForemanIds = getSheetsWithoutForeman(notPublishedSheets);
      if (sheetsWithoutForemanIds.length) {
        setSheetsWithoutForemanIds(sheetsWithoutForemanIds);
        setInfoModal({ isOpen: true, text: 'Crew Leader missing from highlighted project(s).' });
      } else {
        const ids = sheetsInView
          .filter((s) => !s.noResources)
          .filter((s) => !s.sheet.published)
          .map((s) => s.sheet._id);

        if (!ids.length) {
          //! new feature for deny request if you have no data for publish
          setInfoModal({ isOpen: true, text: 'No sheets for publish' });
          updatePublishControl(false)();
          return;
        }

        const requiredTextPart = 'Do you want to publish this schedule?';
        const confirmText = settings.scheduleNotifications
          ? `Notices will be sent to all scheduled employees. ${requiredTextPart}`
          : `Notifications are currently turned off. Notices will not be sent. ${requiredTextPart}`;
        setConfirmModal({ isOpen: true, text: confirmText, type: 'publish' });
      }
    } else {
      //* for update
      let publishedSheets = sheetsThisDay.filter(
        (s) => s.sheet.published && s.sheet.unpublishedChanges
      );
      const sheetsWithoutForemanIds = getSheetsWithoutForeman(publishedSheets);
      if (sheetsWithoutForemanIds.length) {
        setSheetsWithoutForemanIds(sheetsWithoutForemanIds);
        setInfoModal({ isOpen: true, text: 'Crew Leader missing from highlighted project(s).' });
      } else {
        let ids = publishedSheets.map((s) => s.sheet._id);
        let workers = [];
        publishedSheets.forEach((s) => {
          let project = s.sheet.project;
          const title = project && project.route + (project.section ? ', ' + project.section : '');
          s.sheet.workers.forEach((add) => {
            let w = workers.find((worker) => worker._id === add._id);
            if (w) {
              w.addedTo.push(title);
            } else {
              workers.push({ _id: add._id, addedTo: [title] });
            }
          });
        });
        workers = workers.map((w) => {
          w.user = resourcesWorkers?.find((worker) => worker._id === w._id);
          return w;
        });

        if (!ids.length) {
          //! new feature for deny request if  you have no data for update
          setInfoModal({ isOpen: true, text: 'No sheets for update' });
          updatePublishControl(false)();
          return;
        }

        setSheetsWithoutForemanIds([]);
        setUpdateModal({
          isOpen: true,
          data: { workers, ids, isGridView, currentDate },
        });
      }
    }
    updatePublishControl(false)();
  };

  const submitPublishSchedule = async () => {
    const ids = sheets
      .filter((s) => !s.noResources)
      .filter((s) => !s.sheet.published)
      .map((s) => s.sheet._id);

    try {
      await dispatch(publish({ sheetIds: ids }));
      setSheetsWithoutForemanIds([]);
      setConfirmModal({ isOpen: false, text: '', type: 'publish' });
    } catch (err) {
      setSheetsWithoutForemanIds([]);
      setInfoModal({ isOpen: true, text: err?.response?.data?.message || err.message });
      setConfirmModal({ isOpen: false, text: '', type: 'publish' });
    }
  };

  return (
    <div className={classnames(classes.monthCalendarWrapper, isShow && classes.showRes)}>
      <div className={classnames(classes.calHeader)}>
        {renderDays.map((el, i) => (
          <div className={classnames(classes.headerCell)} key={i}>
            <div className={classes.calHeaderText}>{el}</div>
          </div>
        ))}
      </div>

      <div className={classes.grid}>
        {monthEvents.map((row, rowI) => (
          <div key={rowI} className={classes.gridRow}>
            {row.map((day) => {
              return (
                <DayCell
                  data={day}
                  // key={day.key}
                  key={day.dayNumber + day.events.length}
                  clickedDay={selectedMonthDayDate}
                  setClickedDay={handleSelectDay}
                  openSnackbar={openSnackbar}
                  sheetsWithoutForemanIds={sheetsWithoutForemanIds}
                />
              );
            })}
          </div>
        ))}
      </div>
      {infoModal.isOpen && (
        <AlertModal
          isOpen={infoModal.isOpen}
          info={infoModal.text}
          onClose={() =>
            setInfoModal({
              isOpen: false,
              text: '',
            })
          }
        />
      )}
      {confirmModal.isOpen && (
        <ConfirmDialog
          isOpen={confirmModal.isOpen}
          onClose={() =>
            setConfirmModal({
              isOpen: false,
              text: '',
            })
          }
          onSubmit={confirmModal.type === 'publish' ? submitPublishSchedule : null}
          // onSubmit={confirmModal.type === 'publish' ? submitPublishSchedule : submitReconcile}
          text={confirmModal.text}
          loadingOnSubmit={true}
        />
      )}
      {updateModal.isOpen && (
        <UpdateModal
          isOpen={updateModal.isOpen}
          onClose={() => {
            setUpdateModal({
              isOpen: false,
              data: {},
            });
          }}
          data={updateModal.data}
          openSnackbar={openSnackbar}
        />
      )}
      <Modals />
    </div>
  );
};

export default withStyles(styles)(MonthViewCalendar);
