import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import LoadingSpinner from 'components/LoadingSpinner';
import WorklogsList from '../WorklogsList';
import { sortAndMap } from 'store/worklogs/worklogsPageOperations';
import { fetchFullScheduleSheets } from 'store/workerSchedule/workerSchedulePageOperations';
import workerSchedulePageActions from 'store/workerSchedule/workerSchedulePageActions';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';

const FullSchedule = ({ openSnackbar }) => {
  const dispatch = useDispatch();
  const { loading, fullSchedule: { sheets, selectedDate } } = useSelector(state => state.workerSchedule);
  const { dateFormat } = useDateSettingsFormat();

  const [sheetsInView, setSheetsInView] = useState([]);

  useEffect(() => {
    const getData = async () =>{
      dispatch(workerSchedulePageActions.setLoading(true));
      try {
        dispatch(fetchFullScheduleSheets(selectedDate));
      } catch (e) {
        openSnackbar('error', e.message);
      } finally {
        dispatch(workerSchedulePageActions.setLoading(false));
      }
    }
    getData()
  }, [selectedDate]);


  useEffect(() => {
    setSheetsInView(sortAndMap(sheets, null, null, dateFormat))
  }, [sheets]);

  const setDate = (date) => dispatch(workerSchedulePageActions.setFullScheduleDate(date));

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            marginTop: '100px',
          }}
        >
          <LoadingSpinner height={40} margin={32} />
        </div>
      ) : (
        <WorklogsList
          sheets={sheetsInView}
          selectedDate={selectedDate}
          setDate={setDate}
          isFullSchedule
        />
      )}
    </div>
  );
};

FullSchedule.propTypes = {
  loading: propTypes.bool.isRequired,
  sheets: propTypes.array.isRequired,
  selectedDate: propTypes.object.isRequired,
  setDate: propTypes.func.isRequired,
};

export default FullSchedule;
