import { userTypes } from './userTypes';

export const rolePermissions = {
  [userTypes.admin]: {
    usersFullAccess: true,
    userPermissionsEdit: true,
    userRolesEdit: true,
    worklogsFullAccess: true,
    worklogsSubmit: true,
    scheduleFullAccess: true,
    gridViewFullAccess: true,
    notesFullAccess: true,
    projectsFullAccess: true,
    availabilityFullAccess: true,
    settings: true,
    timeClockFullAccess: true,
    timelineFullAccess: true,
    editClocks: true,
    editWorkTime: true,
    suppliesFullAccess: true,
    equipmentFullAccess: true,
    kioskMode: true,
    cipFullAccess: true,
    internalNotesFullAccess: true,
    timeClockAnalytics: true,
    holidaysFullAccess: true,
  },
  [userTypes.fieldTechnician]: {
    worklogsRead: true,
    worklogsSubmit: true,
    worklogsCreate: true,
    assignedWorklogs: true,
    assignedSchedule: true,
    worklogsDateEdit: false,
    worklogsEditSubmitted: false,
    worklogsEditAssigned: true,
    worklogsEditCreated: true,
    worklogsEditCLSubmitted: true,
    notesFullAccess: true,
  },
  [userTypes.dispatcher]: {
    worklogsFullAccess: true,
    worklogsSubmit: true,
    scheduleFullAccess: true,
    gridViewFullAccess: true,
    notesFullAccess: true,
    projectsFullAccess: true,
    // availabilityFullAccess: true,
    settings: true,
    timeClockFullAccess: true,
    usersBasicRead: true,
    usersFieldTechnicianToggle: true,
    kioskMode: true,
    timelineFullAccess: true,
    internalNotesFullAccess: true,
    timeClockAnalytics: true,
  },
  [userTypes.accounting]: {
    usersBasicRead: true,
    scheduleRead: true,
    availabilityRead: true,
    timeClockRead: true,
    timeClockExcel: true,
    kioskModeLogTime: true,
    timelineRead: true,
    timeClockAnalytics: true,
    // payrollActions: true,
  },
  [userTypes.fleetMaintenance]: {
    scheduleRead: true,
  },
  [userTypes.projectManagement]: {
    scheduleRead: true,
    availabilityRead: true,
    worklogsRead: true,
    notesFullAccess: true,
  },
  [userTypes.machineShop]: {
    kioskMode: true,
  },
  'Super Admin': {
    usersFullAccess: true,
    userPermissionsEdit: true,
    userRolesEdit: true,
    worklogsFullAccess: true,
    worklogsSubmit: true,
    scheduleFullAccess: true,
    gridViewFullAccess: true,
    notesFullAccess: true,
    projectsFullAccess: true,
    availabilityFullAccess: true,
    settings: true,
    timeClockFullAccess: true,
    timelineFullAccess: true,
    editClocks: true,
    editWorkTime: true,
    suppliesFullAccess: true,
    equipmentFullAccess: true,
    kioskMode: true,
    cipFullAccess: true,
    internalNotesFullAccess: true,
    timeClockAnalytics: true,
    holidaysFullAccess: true,
    cipKioskMode: true,
    editPTO: true,
    activeSchedule: false,
    PTOOvertimeException: true,
    HolidayOvertimeException: true,
    remoteKiosk: true,
    kioskModeMobile: true,
    payrollActions: true,
  },
};

export const extPermissions = {
  [userTypes.admin]: [
    'cipKioskMode',
    'editPTO',
    'activeSchedule',
    'HolidayOvertimeException',
    'PTOOvertimeException',
    'remoteKiosk',
    'kioskModeMobile',
    'payrollActions',
    'materialCertification',
  ],
  [userTypes.fieldTechnician]: [
    'cipKioskMode',
    'activeSchedule',
    'HolidayOvertimeException',
    'PTOOvertimeException',
    'remoteKiosk',
    'kioskModeMobile',
    'myScheduleFull',
  ],
  [userTypes.accounting]: [
    'projectsRead',
    'equipmentRead',
    'availabilityFullAccess',
    'timeClockFullAccess',
    'cipFullAccess',
    'usersFullAccess',
    'editPTO',
    'worklogsRead',
    'activeSchedule',
    'projectsFullAccess',
    'kioskMode',
    'holidaysCreate',
    'HolidayOvertimeException',
    'PTOOvertimeException',
    'remoteKiosk',
    'kioskModeMobile',
    'payrollActions',
    'materialCertification',
  ],
  [userTypes.fleetMaintenance]: [
    'cipKioskMode',
    'equipmentFullAccess',
    'availabilityRead',
    'availabilityEquipmentEdit',
    'cipProjectsFullAccess',
    'activeSchedule',
    'HolidayOvertimeException',
    'PTOOvertimeException',
    'remoteKiosk',
    'kioskModeMobile',
  ],
  [userTypes.projectManagement]: [
    'worklogsEdit',
    'worklogsEditSubmitted',
    'projectsFullAccess',
    'activeSchedule',
    'scheduleFullAccess',
    'suppliesFullAccess',
    'HolidayOvertimeException',
    'PTOOvertimeException',
    'remoteKiosk',
    'kioskModeMobile',
    'materialCertification',
  ],
  [userTypes.dispatcher]: [
    'editPTO',
    'activeSchedule',
    'availabilityEquipmentEdit',
    'availabilityPeopleEdit',
    'suppliesFullAccess',
    'HolidayOvertimeException',
    'PTOOvertimeException',
    'remoteKiosk',
    'kioskModeMobile',
    'materialCertification',
  ],
  [userTypes.machineShop]: [
    'cipKioskMode',
    'activeSchedule',
    'HolidayOvertimeException',
    'PTOOvertimeException',
    'remoteKiosk',
    'kioskModeMobile',
  ],
};
