import React, {useMemo, useEffect} from 'react';
import propTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import IconSunny from 'assets/images/icon-sunny.svg';
import IconMoon from 'assets/images/icon-moon.svg';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { startOfDay, endOfDay, sub, addYears, subYears } from 'date-fns';
import { getProjectName } from 'helpers/_helpers';
import PageLoader from 'components/PageLoader';
import DatePickerRange from 'components/DatePickerRange';
import styles from './styles';
import { isIOS, Option } from 'components/Option';
import { fetchProjects } from 'store/projectTypes/projectTypesOperations';
import { fetchAllContractorsData } from 'store/contractors/contractorsPageOperations';
import { useDispatch, useSelector } from 'react-redux';
import projectTypesActions from 'store/projectTypes/projectTypesActions';

const SortingAndFilteringPanel = ({
  classes,
  closeDrawer,
  filter,
  updateFilter,
  isLoading,
  isDataLoading = false,
  submissions,
}) => {
  const dispatch = useDispatch();
  
  const allProjectsStored = useSelector((store) => store.projectTypes.projects.data);
  const projectsLoading = useSelector((store) => store.projectTypes.projects.isLoading);
  const allContractors = useSelector((store) => store.contractors.data);
  const contractorsLoading = useSelector((store) => store.contractors.isLoading);
  const allProjects = useMemo(()=> allProjectsStored.filter(proj=>proj.active),[allProjectsStored])

  
  useEffect(()=>{
    dispatch(fetchProjects({}, NaN))
    dispatch(fetchAllContractorsData())
    return () => dispatch(projectTypesActions.resetProjects())
  },[])

  const defaultValues = {
    projectId: 'All',
    contractorId: 'All',
    dates: submissions
      ? [sub(startOfDay(new Date()), { weeks: 2 }), endOfDay(new Date())]
      : [subYears(new Date(), 1), addYears(new Date(), 1)],
    status: 'All',
    shift: 'All',
    forceAccount: 'All',
    rated: 'All',
  };

  return (
    <div className={classes.formWrapper} role="presentation">
      <Formik
        initialValues={filter}
        enableReinitialize={true}
        onSubmit={(values) => {
          updateFilter(values);
        }}
      >
        {({ values, handleChange, resetForm, setFieldValue }) => (
          <PageLoader loading={isLoading || isDataLoading || projectsLoading || contractorsLoading}>
            <Form autoComplete="off" className={classes.formFlex}>
              <div>
                <Typography variant="h3" className={classes.marginHeader}>
                  Filters
                </Typography>

                <IconButton
                  className={classes.closeButton}
                  onClick={closeDrawer}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={2} className={classes.flexContainer}>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="contractorId" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Contractor:
                      </Typography>
                      <TextField
                        select
                        id="contractorId"
                        name="contractorId"
                        value={values.contractorId}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Contractor' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={0} value="All">
                          All
                        </Option>
                        {allContractors.map((contractor) => (
                          <Option key={contractor._id} value={contractor._id}>
                            {contractor.name}
                          </Option>
                        ))}
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="projectId" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Project:
                      </Typography>
                      <TextField
                        select
                        id="projectId"
                        name="projectId"
                        value={values.projectId}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Project' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        {values.contractorId !== 'All'
                          ? [
                              <Option key={0} value="All">
                                All
                              </Option>,
                              ...allProjects
                                .filter((project) => project.contractorId === values.contractorId)
                                .sort((a, b) => a.route.localeCompare(b.route))
                                .map((project) => (
                                  <Option key={project._id} value={project._id}>
                                    {getProjectName(project)}
                                  </Option>
                                )),
                            ]
                          : [
                              <Option key={0} value="All">
                                All
                              </Option>,
                              ...allProjects
                                .sort((a, b) => a.route.localeCompare(b.route))
                                .map((project) => (
                                  <Option key={project._id} value={project._id}>
                                    {getProjectName(project)}
                                  </Option>
                                )),
                            ]}
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="dates" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Dates:
                      </Typography>
                      <DatePickerRange
                        dates={values.dates}
                        setFieldValue={setFieldValue}
                        reinitialize
                        isFilterDrawer
                      />
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="status" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Status:
                      </Typography>
                      <TextField
                        select
                        id="status"
                        name="status"
                        value={values.status}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Status' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={0} value="All">
                          All
                        </Option>
                        <Option key={1} value="Draft">
                          Draft
                        </Option>
                        <Option key={2} value="Published">
                          Published
                        </Option>
                        <Option key={3} value="Submitted">
                          Submitted
                        </Option>
                        <Option key={4} value="Cancelled">
                          Cancelled
                        </Option>
                        <Option key={5} value="Archived">
                          Archived
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="shift" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Shift:
                      </Typography>
                      <TextField
                        select
                        id="shift"
                        name="shift"
                        value={values.shift}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Shift' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={1} value="All">
                          All
                        </Option>
                        <Option key={2} value="Day">
                          {isIOS ? (
                            '🌤 '
                          ) : (
                            <img src={IconSunny} alt="sunny" className={classes.shiftImage} />
                          )}
                          Day
                        </Option>
                        <Option key={3} value="Night">
                          {isIOS ? (
                            '🌙 '
                          ) : (
                            <img src={IconMoon} alt="moon" className={classes.shiftImage} />
                          )}
                          Night
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="forceAccount" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Force Account:
                      </Typography>
                      <TextField
                        select
                        id="forceAccount"
                        name="forceAccount"
                        value={values.forceAccount}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Force account' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={0} value="All">
                          All
                        </Option>
                        <Option key={1} value="No">
                          No
                        </Option>
                        <Option key={2} value="Full">
                          Full
                        </Option>
                        <Option key={3} value="Partial">
                          Partial
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="rated" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Certified Payroll:
                      </Typography>
                      <TextField
                        select
                        id="rated"
                        name="rated"
                        value={values.rated}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Rated' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={1} value="All">
                          All
                        </Option>
                        <Option key={2} value={'True'}>
                          Yes
                        </Option>
                        <Option key={3} value={'No'}>
                          No
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      color="primary"
                      className={classes.resetButton}
                      onClick={() => resetForm({values:defaultValues})}
                      disableTouchRipple
                    >
                      Reset all filters
                    </Button>
                  </Grid>
                </Grid>
              </div>

              <div className={classes.buttonHolder}>
                <Button color="secondary" className={classes.cancelButton} onClick={closeDrawer}>
                  Cancel
                </Button>

                <div className={classes.verticalDivider}></div>

                <Button type="submit" color="primary" className={classes.saveButton}>
                  Apply
                </Button>
              </div>
            </Form>
          </PageLoader>
        )}
      </Formik>
    </div>
  );
};

SortingAndFilteringPanel.propTypes = {
  classes: propTypes.object.isRequired,
  closeDrawer: propTypes.func.isRequired,
  filter: propTypes.object.isRequired,
  updateFilter: propTypes.func.isRequired,
  isLoading: propTypes.bool.isRequired,
  isDataLoading: propTypes.bool.isRequired,
  submissions: propTypes.bool,
};

export default withStyles(styles)(SortingAndFilteringPanel);

