export const glassBeadsTypes = [
  {
    name: 'Lbs/Gallon',
    type: 'lbs/gal',
    materialType: 'volume',
  },
  {
    name: 'Lbs/Square Footage',
    type: 'lbs/sqft',
    materialType: 'weight',
  },
];
