import React, { Fragment, useState, useCallback, useMemo, useEffect } from 'react';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import { surfaces } from 'api/data/surface-options';
import LocationSurfaceInput from 'components/LocationSurfaceInput';
import ConfirmDialog from 'components/ConfirmDialog';
import MaterialItem from './components/MaterialItem';
import styles from './styles';
import { useSelector } from 'react-redux';
import {
  fetchDeleteWorklogMaterial,
  fetchDuplicateWorklogMaterial,
  getAllSurfaces,
} from 'store/currentWorklog/operations';

const MaterialsBlock = ({ classes, sheet, openSnackbar, openAdditionalDrawer, hasAccess }) => {
  const [materialToDelete, setMaterialToDelete] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const groupedMaterials = useSelector((state) => state.currentWorklog.groupedMaterials.data);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllSurfaces({}, sheet._id));
  }, []);

  const createLocationOptions = () => {
    if (sheet && sheet.project) {
      const { sheetLocations, materialLocations } = sheet;
      const fullLocationsAndDeleted = _.uniqBy(
        [...(sheetLocations || []), ...(sheet.project.locations || [])],
        '_id'
      );

      if (fullLocationsAndDeleted) {
        return _.sortBy(fullLocationsAndDeleted, (location) => {
          return (sheet.locations || []).includes(location._id) ? 0 : 1; // Sheet Location First
        }).concat(materialLocations || []);
      }

      return materialLocations ? [...materialLocations] : [];
    }

    return [];
  };

  const createSurfaceOptions = () => surfaces;

  const handleDeleteMaterial = (id) => () => {
    setMaterialToDelete(id);
    setIsModalOpen(true);
  };

  const deleteMaterial = useCallback(() => {
    dispatch(fetchDeleteWorklogMaterial(sheet._id, materialToDelete));
    setMaterialToDelete(null);
    setIsModalOpen(false);
  }, [materialToDelete]);

  const duplicateMaterial = (material) => () => {
    dispatch(fetchDuplicateWorklogMaterial(sheet._id, material._id));
  };

  const closeDeleteMaterial = () => {
    setMaterialToDelete(null);
    setIsModalOpen(false);
  };

  const locationOptions = useMemo(createLocationOptions, [sheet]);
  const surfaceOptions = useMemo(createSurfaceOptions, []);

  return (
    <Grid item xs={12}>
      {groupedMaterials.length ? (
        groupedMaterials.map((groupedMaterialsObject, i) => (
          <Fragment key={`${groupedMaterialsObject.location}_${groupedMaterialsObject.surface}`}>
            <LocationSurfaceInput
              locationOptions={locationOptions}
              surfaceOptions={surfaceOptions}
              sheet={sheet}
              groupedMaterials={groupedMaterialsObject}
              openSnackbar={openSnackbar}
              openAdditionalDrawer={openAdditionalDrawer}
              shouldRenderAddButton={i === 0 && hasAccess}
              hasAccess={hasAccess}
            />
            {groupedMaterialsObject.materials.map((material) => (
              <Grid item xs={12} key={`${material._id}`}>
                <MaterialItem
                  material={material}
                  handleDeleteMaterial={handleDeleteMaterial(material._id)}
                  duplicateMaterial={duplicateMaterial(material)}
                  openAdditionalDrawer={openAdditionalDrawer('material', 'update', material)}
                  hasAccess={hasAccess}
                />
              </Grid>
            ))}
          </Fragment>
        ))
      ) : (
        <>
          {hasAccess && (
            <Grid item xs={12} md={3}>
              <Button
                variant="text"
                className={classes.primaryButton}
                onClick={openAdditionalDrawer('material', 'create')}
                disableRipple
              >
                + Add Material
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="body1" color="textSecondary" className={classes.noMaterials}>
              No Materials Added
            </Typography>
          </Grid>
        </>
      )}

      {isModalOpen && (
        <ConfirmDialog
          isOpen={isModalOpen}
          onClose={closeDeleteMaterial}
          onSubmit={deleteMaterial}
          text="Are you sure you want to delete this material?"
          disableEscape
          loadingOnSubmit
        />
      )}
    </Grid>
  );
};

MaterialsBlock.propTypes = {
  classes: propTypes.object.isRequired,
  sheet: propTypes.object.isRequired,
  openSnackbar: propTypes.func.isRequired,
  openAdditionalDrawer: propTypes.func.isRequired,
  hasAccess: propTypes.bool.isRequired,
};

export default withStyles(styles)(MaterialsBlock);
