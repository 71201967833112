import React, { useState, useEffect, useRef } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';
import photoPlaceholder from 'assets/images/user-photo-placeholder.svg';
import ConfirmDialog from '../../../../../../../../components/ConfirmDialog';
import { hasPermissionsFor } from '../../../../../../../../helpers/_helpers';
import styles from './styles';
import { drawerTypes } from '../../../../../../../Equipment/components/helpers/drawerTypes';
import CloseIconGrey from 'assets/images/close-icon-grey.svg';
import { useSelector, useDispatch } from 'react-redux';
import $api from 'http/index';
import { getOptions } from 'helpers/getOptions';
import { getTimeoffs, getHolidays } from 'store/availability/availabilityPagesOperations';
import _ from 'lodash';
import { addDays, format, parse } from 'date-fns';
import { useWeekStart } from 'hooks/useWeekStart';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { globalBEDateFormat, convertTimeObject } from 'common/dateFormatConfig';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { validParse } from '../../../../../../../../helpers/_date-helpers';

const UserListForADay = ({
  classes,
  event,
  openDrawer,
  openAssignModal,
  isInPast,
  holidays,
  openSnackbar,
}) => {
  const dispatch = useDispatch();
  const allUsers = useSelector((store) => store.availability.users);
  const ref = useRef();
  const { weekDayFromO } = useWeekStart();
  const { dateFormat } = useDateSettingsFormat();
  const getTimeFormatHook = useTimeSettingsFormat();
  const is12Format = getTimeFormatHook().is12Format;

  const [isHidden, setIsHidden] = useState(true);
  const [modal, setModal] = useState({
    isOpen: false,
    timeoff: {},
  });

  useEffect(() => {
    document.addEventListener('click', hideList, true);
    return () => {
      document.removeEventListener('click', hideList, true);
    };
  }, []);

  const toggleListLength = (e) => {
    e.stopPropagation();
    setIsHidden((prev) => !prev);
  };

  const hideList = (event) => {
    if (ref.current && !ref.current.contains(event.target) && !isHidden && !modal.isOpen) {
      setIsHidden(true);
    }
  };

  const getUsername = (userId) => {
    if (!allUsers || !allUsers?.length) return '';
    const user = allUsers.find((user) => user._id === userId);
    return user?.username;
  };

  const getUserShift = (userId) => {
    if (!allUsers || !allUsers?.length) return '';
    const user = allUsers.find((user) => user._id === userId);
    return user?.profile?.shifts?.timeOfDay?.toLowerCase();
  };

  const getDayNumber = (date) => format(parse(date, globalBEDateFormat, new Date()), 'dd');

  const isToday = (date) => format(new Date(), globalBEDateFormat) === date;

  const handleDeleteTimeoff = (e, timeoff, dayNumber) => {
    e.stopPropagation();
    setModal({ isOpen: true, timeoff, isPTO: timeoff.startDate !== timeoff.endDate, dayNumber });
  };

  const deleteTimeoff = async () => {
    const { timeoff, dayNumber } = modal;

    if (timeoff.isIndividual) {
      try {
        await $api.delete(
          `${process.env.REACT_APP_BASE_URL}/holidays/${timeoff._id}/users/${timeoff.userId}`,
          getOptions()
        );
        dispatch(getHolidays());
        dispatch(getTimeoffs(null, null, weekDayFromO));
        openSnackbar('success', 'Successfully Deleted!');
      } catch (error) {
        openSnackbar('error', error.message);
      }
      return closeModal();
    }

    if (timeoff.startDate === timeoff.endDate) {
      try {
        await $api.delete(
          `${process.env.REACT_APP_BASE_URL}/timeoffs/${timeoff._id}`,
          getOptions()
        );
        dispatch(getTimeoffs(null, null, weekDayFromO));
        openSnackbar('success', 'Successfully Deleted!');
        closeModal();
      } catch (error) {
        openSnackbar('error', error.message);
      }
    } else {
      if (
        dayNumber === timeoff.inDays[0] ||
        dayNumber === timeoff.inDays[timeoff.inDays.length - 1]
      ) {
        try {
          await $api.delete(`${process.env.REACT_APP_BASE_URL}/timeoffs/${timeoff._id}/day`, {
            data: { date: dayNumber },
            ...getOptions(),
          });
          dispatch(getTimeoffs(null, null, weekDayFromO));
          openSnackbar('success', 'Successfully Deleted!');
          closeModal();
        } catch (error) {
          openSnackbar('error', error.message);
        }
      } else {
        const startDateRange = [
          format(validParse(timeoff.inDays[0], dateFormat), dateFormat),
          format(validParse(dayNumber, dateFormat), dateFormat),
        ]; //splitted from-to date of first period
        const endDateRange = [
          format(
            validParse(timeoff.inDays[timeoff.inDays.indexOf(dayNumber) + 1], dateFormat),
            dateFormat
          ),
          format(
            addDays(
              parse(timeoff.inDays[timeoff.inDays.length - 1], globalBEDateFormat, new Date()),
              1
            ),
            dateFormat
          ), //splitted from-to date of second period
        ];
        const firstPartData = { ...timeoff, dates: startDateRange };
        const secondPartData = { ...timeoff, dates: endDateRange };

        let splitTimeoff = {
          oldTimeOffId: timeoff._id,
          data: [firstPartData, secondPartData],
        };
        closeModal();
        openDrawer('update', splitTimeoff)();
      }
    }
  };

  const closeModal = () => {
    setModal((prev) => ({ ...prev, isOpen: false }));
    setIsHidden(true);
  };

  const hasAccess = () =>
    hasPermissionsFor('editPTO') ||
    hasPermissionsFor('availabilityPeopleEdit') ||
    hasPermissionsFor('availabilityFullAccess');

  const getHolidayInfo = () => {
    return holidays.filter((h) => h.inDays.includes(event.dayNumber) && !h.isIndividual);
  };

  const getIndividualHolidays = () => {
    const individual = holidays.filter((h) => h.inDays.includes(event.dayNumber) && h.isIndividual);

    return individual.reduce(
      (res, holiday) => [...res, ...holiday.userIds.map((userId) => ({ ...holiday, userId }))],
      []
    );
  };

  const getFormattedStartTime = (hoursObj) => {
    const { hour, minute, amPm } = convertTimeObject(hoursObj, is12Format);
    return (
      <>
        {hour}:{minute}
        <span>{amPm ? amPm : ''}</span>
      </>
    );
  };

  const eventsWithIndividualHolidays = [...getIndividualHolidays(), ...event.events];

  return (
    <>
      <div
        className={classnames(
          classes.cellContent,
          !!getHolidayInfo().length && classes.greenBackground,
          !isHidden && classes.absolute,
          !isHidden && isInPast(event.dayNumber) && classes.greyBackground
        )}
        onBlur={hideList}
        ref={ref}
        onClick={
          isInPast(event.dayNumber) ? null : hasAccess() ? openAssignModal('people', event) : null
        }
      >
        <div>
          <span
            className={classnames(
              classes.dayNumber,
              isToday(event.dayNumber) && classes.todayNumber
            )}
          >
            {getDayNumber(event.dayNumber)}
          </span>
        </div>

        {getHolidayInfo().map((el) => (
          <div
            className={classnames(
              classes.holidayName,
              !eventsWithIndividualHolidays.length && classes.emptyCellHolidayName
            )}
          >
            {el.name}
          </div>
        ))}

        <div
          className={classnames(
            classes.list,
            !!getHolidayInfo().length && classes.maxHeightHoliday,
            !isHidden && classes.visible
          )}
        >
          {eventsWithIndividualHolidays.map((timeoff) => (
            <div key={timeoff._id} className={classes.flexBox}>
              <div
                className={classes.flexBoxInline}
                onClick={
                  hasAccess() && !timeoff.isIndividual
                    ? openDrawer(drawerTypes.update, timeoff)
                    : null
                }
                style={{ cursor: timeoff.isIndividual ? 'default' : 'pointer' }}
              >
                <div
                  className={classnames(
                    classes.userPhotoWrapper,
                    classes[`${getUserShift(timeoff.userId)}Border`]
                  )}
                >
                  <img src={photoPlaceholder} alt="user photo" className={classes.userPhoto} />
                </div>
                <Typography
                  className={classnames(
                    classes.name,
                    timeoff.type === 'full' && classes.width2,
                    classes[getUserShift(timeoff.userId)]
                  )}
                >
                  {getUsername(timeoff.userId)}
                </Typography>
                {timeoff.type === 'partial' && (
                  <div className={classes.startTime}>
                    {getFormattedStartTime(timeoff.startTime)}
                  </div>
                )}
                {timeoff.isIndividual && (
                  <div className={classnames(classes.startTime, classes.dayOff)}>
                    <span>Day Off</span>
                  </div>
                )}
              </div>
              {hasAccess() && (
                <Button
                  color="secondary"
                  className={classes.actionBtn}
                  onClick={(e) => {
                    handleDeleteTimeoff(e, timeoff, event.dayNumber);
                  }}
                  disableRipple
                >
                  <img src={CloseIconGrey} alt="delete" />
                </Button>
              )}
            </div>
          ))}
        </div>
        {eventsWithIndividualHolidays.length > (getHolidayInfo().length ? 3 : 4) && (
          <div className={classnames(!isHidden && classes.borderBottom)}>
            <Button
              className={classes.othersBtn}
              onClick={toggleListLength}
              variant="text"
              disableRipple
            >
              {isHidden
                ? `+ ${
                    eventsWithIndividualHolidays.length - (getHolidayInfo().length ? 3 : 4)
                  } others`
                : 'hide'}
            </Button>
          </div>
        )}
      </div>

      {modal.isOpen && (
        <ConfirmDialog
          isOpen={modal.isOpen}
          onClose={closeModal}
          onSubmit={deleteTimeoff}
          text={
            modal.isPTO
              ? 'Are you sure you want to delete this day of PTO? This action cannot be reversed.'
              : `Are sure you want to delete this${
                  modal.timeoff.isIndividual ? ' individual' : ''
                } timeoff?`
          }
          disableEscape
          loadingOnSubmit
        />
      )}
    </>
  );
};

UserListForADay.propTypes = {
  classes: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
  selectedDate: propTypes.object.isRequired,
  openDrawer: propTypes.func.isRequired,
  openSnackbar: propTypes.func.isRequired,
  isNotCurrentMonth: propTypes.func.isRequired,
  openModal: propTypes.func.isRequired,
  openAssignModal: propTypes.func.isRequired,
  holidays: propTypes.array.isRequired,
};

export default withStyles(styles)(UserListForADay);
