import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Fuse from 'fuse.js';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import ConfirmDialog from 'components/ConfirmDialog';
import FiltersBar from '../../components/FiltersBar';
import SurfaceCard from './SurfaceCard/SurfaceCard';
import PageLoader from 'components/PageLoader';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import SortingPanel from './SortingPanel/SortingPanel';
import { SHEET_SURFACES_EVENT } from 'subscriptions/events/events.enum';
import currentWorklogActions from 'store/currentWorklog/actions';
import { deleteSurface, getAllSurfaces } from 'store/currentWorklog/operations';

const searchOptions = {
  shouldSort: true,
  keys: ['name'],
};

const Surfaces = ({ onSelect, setSnackbar }) => {
  const dispatch = useDispatch();
  const [fuse, setFuse] = useState(null);
  const [drawer, setDrawer] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [sorting, setSorting] = useState({ sortBy: 'name', sortOrder: 'asc' });
  const [deletingSurface, setDeletingSurface] = useState(false);

  const surfaces = useSelector((store) => store.currentWorklog.worklogSheet.surfaces);

  const snackbar = useSelector((state) => state.supplies.glassBeads.snackBarInfo);
  const loading = useSelector((state) => state.supplies.glassBeads.loading);

  useEffect(() => {
    dispatch(getAllSurfaces());
    dispatch(currentWorklogActions.socketConnect(SHEET_SURFACES_EVENT));

    return () => {
      dispatch(currentWorklogActions.socketDisconnect(SHEET_SURFACES_EVENT));
    };
  }, []);

  useEffect(() => {
    if (snackbar) {
      setSnackbar(snackbar);
    }
  }, [snackbar]);

  const openDrawer = useCallback(
    () => () => {
      setDrawer({ isOpen: true });
    },
    []
  );

  const closeDrawer = useCallback(() => {
    setDrawer({ isOpen: false });
  }, []);

  const onUpdate = useCallback((values) => {
    setSorting(values);
    dispatch(getAllSurfaces(values));
    closeDrawer();
  }, []);

  const onSortingCancel = useCallback(() => {
    setSorting({
      sortBy: 'name',
      sortOrder: 'asc',
    });
    dispatch(getAllSurfaces());
    closeDrawer();
  }, []);

  const onDelete = useCallback((surface) => {
    setDeletingSurface(surface);
  }, []);

  const onDeletingConfirmed = useCallback(async () => {
    await dispatch(deleteSurface(deletingSurface._id));
    setDeletingSurface(null);
    setSnackbar({
      text: 'Successfully Deleted!',
      type: 'success',
    });
  });

  useMemo(() => {
    setFuse(new Fuse(surfaces, searchOptions));
  }, [surfaces]);

  const filteredSurfaces = fuse && searchValue ? fuse.search(searchValue) : surfaces;

  return loading ? (
    <PageLoader loading>
      <div style={{ height: 100 }} />
    </PageLoader>
  ) : (
    <>
      <FiltersBar
        openDrawer={openDrawer}
        updateSearchValue={setSearchValue}
        isDynamicSearch={true}
      />
      <Grid container spacing={2}>
        {filteredSurfaces.map((m) => (
          <Grid key={m._id} item xs={12} md={6} lg={3}>
            <SurfaceCard data={m} onDelete={onDelete} onClick={() => onSelect(m)} />
          </Grid>
        ))}
      </Grid>
      {drawer && (
        <Drawer anchor="right" open={drawer.isOpen}>
          <SortingPanel
            closeDrawer={closeDrawer}
            onCancel={onSortingCancel}
            sorting={sorting}
            onUpdate={onUpdate}
          />
        </Drawer>
      )}
      {deletingSurface && (
        <ConfirmDialog
          isOpen={!!deletingSurface}
          onClose={() => setDeletingSurface(null)}
          onSubmit={onDeletingConfirmed}
          text="Are you sure you want to delete this Surface?"
          cancelBtn={'Cancel'}
          confirmBtn={'Delete'}
          disableEscape
          loadingOnSubmit
        />
      )}
    </>
  );
};

export default withStyles(styles)(Surfaces);
