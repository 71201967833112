import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PageLoader from 'components/PageLoader';
import SettingsHeader from './components/SettingsHeader';
import WorkShiftsHeader from './components/WorkShiftsHeader';
import SettingsForm from './components/SettingsForm';
import styles from './styles';
import { useTimeStates } from 'hooks/useTimeStates';
import { useForm, FormProvider } from 'react-hook-form';
import {
  getSettings,
  patchSettings,
  patchScheduleNotifications,
} from 'store/settings/settingsOperations';
import { assemblyPatchRequestData } from './settings-requests.helper';

const SettingsPage = ({ classes }) => {
  const [pageType, setPageType] = useState('read');
  const [submitting, setSubmitting] = useState(false);
  const { hours, minutes, dayPart } = useTimeStates({ takeSettingsHours: true });
  const dispatch = useDispatch();

  const settings = useSelector((state) => state.settings.settings);

  const methods = useForm({ mode: 'onBlur' });
  const {
    formState: { errors },
    handleSubmit,
    getValues,
    control,
    reset,
  } = methods;

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  useEffect(() => {
    if (pageType === 'read') {
      setSubmitting(false);
    }
  }, [pageType]);

  const handleChangeScheduleNotifications = (e) => {
    const data = { scheduleNotifications: e.target.checked };
    dispatch(patchScheduleNotifications(data));
  };

  const closeEdit = () => {
    reset({ ...settings, homePageImage: null, deleteImage: null });
    setPageType('read');
  };

  const onSubmit = () => {
    const values = getValues();

    const data = assemblyPatchRequestData(values, settings);

    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    if (values?.deleteImage) {
      formData.append('deleteImage', values?.deleteImage);
    } else if (values && values.homePageImage) {
      formData.append('uploadImage', values.homePageImage);
    }

    setSubmitting(true);

    dispatch(patchSettings(formData));
    closeEdit();
  };

  if (!settings) return null;

  return (
    <div className={classes.root}>
      <SettingsHeader pageType={pageType} changePageType={setPageType} />
      <PageLoader loading={submitting}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.formFlex}>
            <Grid container spacing={5}>
              <WorkShiftsHeader
                scheduleNotifications={settings.scheduleNotifications || false}
                handleChangeScheduleNotifications={handleChangeScheduleNotifications}
              />
              <SettingsForm
                pageType={pageType}
                control={control}
                hours={hours}
                minutes={minutes}
                dayPart={dayPart}
                errors={errors}
                settings={settings}
              />
            </Grid>
            {pageType === 'edit' && (
              <div className={classes.buttonHolder}>
                <Button
                  color="secondary"
                  variant="outlined"
                  className={classes.cancelButton}
                  onClick={closeEdit}
                  disabled={submitting}
                  disableTouchRipple
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  className={classes.saveButton}
                  disabled={submitting}
                  disableTouchRipple
                >
                  Save
                </Button>
              </div>
            )}
          </form>
        </FormProvider>
      </PageLoader>
    </div>
  );
};

SettingsPage.propTypes = {
  classes: propTypes.object.isRequired,
};

export default withStyles(styles)(SettingsPage);
