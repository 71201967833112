import classnames from 'classnames';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { createWorklogName } from 'pages/Schedule/components/ScheduleCalendar/components/ScheduleEvent/helpers/createWorklogName';
import { useFormattedHoursRange } from '../../../ScheduleCalendar/components/ScheduleEvent/hooks/useFormattedHoursRange';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import GreyUserI from 'assets/images/user-photo-placeholder.svg';
import LocationIcon from 'assets/images/location-icon.svg';
import { getProjectName } from 'helpers/_helpers';
import { agoDate, shortDate } from 'helpers/_date-helpers';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { generateHoursObject } from 'helpers/helpers';
import { useGetEquipmentInfo } from 'pages/Schedule/hooks/useGetEquipmentInfo';
import { useSelector, useDispatch } from 'react-redux';
import { useHandleDrop } from 'pages/Schedule/components/ScheduleCalendar/components/ScheduleEvent/hooks/useHandleDrop';
import { removeResources, setCrewLeader } from 'store/schedule/schedulePageOperation';
import { postWorklogCL } from 'store/currentWorklog/operations';
import SubmittedIcon from 'assets/images/done-icon.svg';
import PublishedIcon from 'assets/images/hourglass-icon.svg';
import unpublishedIcon from 'assets/images/not-icon.svg';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';

const WLAccordionItem = ({
  classes,
  sheet,
  // openedWLId,
  userId = '', //?
  sheetsWithoutForemanIds,
  handleUpdate,
}) => {
  const dispatch = useDispatch();
  const sheets = useSelector((state) => state.schedule.sheets);
  const { formattedHoursRange } = useFormattedHoursRange(sheet);
  const { getEquipmentInfo } = useGetEquipmentInfo(sheets.flatMap((el) => el.equipment));
  const { dateFormatWithTime } = useDateSettingsFormat();
  const getTimeFormatHook = useTimeSettingsFormat();
  const { handleDrop } = useHandleDrop({
    allSheetsThisDay: sheets,
    sheetsInView: sheets,
    sheet: sheet,
  });

  const [expanded, setExpanded] = useState(false);

  const handleRemoveResource = (type, id) => () => {
    dispatch(removeResources({ sheetId: sheet._id, [type]: [id] }));
  };

  const setForeman = (worker) => async () => {
    const sheetId = sheet._id;
    const userId = worker._id;
    const value = !worker.foreman;

    await dispatch(postWorklogCL({ foreman: value }, sheetId, userId));
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
    handleUpdate();
  };

  const navigate = useNavigate();

  const getImageSrc = () => {
    return GreyUserI;
  };

  const getSheetStatus = () => {
    const { published, submittedAt, canceledAt } = sheet;
    if (!published) return 'Draft';
    if (submittedAt) {
      return `Submitted ${agoDate(submittedAt, null, dateFormatWithTime)}`;
    } else if (canceledAt) {
      return 'Canceled';
    } else return 'Published';
  };

  const getJobNumber = () => sheet.project.jobNumber;

  const getFormattedHoursRange = () => {
    const { createdAt, hours } = sheet;
    if (!hours || hours?.start === undefined || hours?.end === undefined) return;
    const { hourStart, minuteStart, amPmStart, dayStart, hourEnd, minuteEnd, amPmEnd, dayEnd } =
      generateHoursObject(hours.start, hours.end, createdAt, getTimeFormatHook);

    const startObj = [null, hourStart, minuteStart, amPmStart];
    const endObj = [null, hourEnd, minuteEnd, amPmEnd];

    const start = `${getTimeFormatHook(startObj).formattedTime} ${shortDate(
      createdAt,
      dayStart,
      getTimeFormatHook().localDayMonth
    )}`;
    const end = `${getTimeFormatHook(endObj).formattedTime} ${shortDate(
      createdAt,
      dayEnd,
      getTimeFormatHook().localDayMonth
    )}`;

    return `${start} \u2013 ${end}`;
  };

  const openWorklogDetails = () => {
    navigate(`/schedule/${sheet._id}`);
  };

  const sheetLocation = (sheet, isStandalone, locationId, fieldName) => {
    if (!sheet.project) return '';
    const allPlaces =
      fieldName === 'sheetRallyPoints'
        ? [...(sheet?.sheetRallyPoints || []), ...(sheet.project?.rallyPoints || [])]
        : [...(sheet?.sheetLocations || []), ...(sheet.project?.locations || [])];
    const location = _.find(allPlaces, { _id: locationId });
    if (isStandalone) {
      if (!location) return '';
      const locationZip = location.zipcode ? `, ${location.zipcode}` : '';
      return location.name || `${location.address}${locationZip}`;
    }
    const nameOrAddress = location.name || location.address;
    return location && nameOrAddress ? ', ' + nameOrAddress : '';
  };

  const openLocation = (sheet, locationId, fieldName) => () => {
    const allPlaces =
      fieldName === 'sheetRallyPoints'
        ? [...(sheet?.sheetRallyPoints || []), ...(sheet.project?.rallyPoints || [])]
        : [...(sheet?.sheetLocations || []), ...(sheet.project?.locations || [])];
    const location = _.find(allPlaces, { _id: locationId });
    const url = `https://www.google.com/maps/place/${location.address}`;
    window.open(url, '_blank', 'noreferrer');
  };

  const getIndividualStartTime = () => {
    const workerId = userId;
    const worker = sheet.workers.find(({ _id }) => _id === workerId);

    const startTime = (worker && worker.startTime) || sheet.startTime;

    const startHoursObj = [null, startTime.hour, startTime.minute, null];

    return getTimeFormatHook(startHoursObj).formattedTime;
  };

  const getStatusIconComponent = (sheet) => {
    let imgWrapperName = '';
    let iconSrc = '';

    if (!sheet.published && !sheet.submittedAt) {
      imgWrapperName = 'unpublishedImgWrapper';
      iconSrc = unpublishedIcon;
    }
    if (sheet.published) {
      imgWrapperName = 'publishedImgWrapper';
      iconSrc = PublishedIcon;
    }
    if (sheet.submittedAt) {
      imgWrapperName = 'submittedImgWrapper';
      iconSrc = SubmittedIcon;
    }
    return (
      <div className={classnames(classes.accordionItemImgWrapper, classes[imgWrapperName])}>
        <img src={iconSrc} className={classnames(classes.previewCellImg)} alt="status" />
      </div>
    );
  };

  return (
    <div
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDrop={handleDrop}
    >
      <div className={classnames(classes.itemWrapper)}>
        <div
          className={classnames(
            classes.itemInner,
            sheetsWithoutForemanIds.includes(sheet._id) && classes.missingCL
          )}
          onClick={openWorklogDetails}
        >
          {getStatusIconComponent(sheet)}
          <div className={classnames(classes.accordionItemTextWrapper)}>
            <Typography
              style={{ display: 'block', marginBottom: 5 }}
              className={classnames(Boolean(sheet.canceledAt) && classes.canceled)}
              variant="caption"
              color="textPrimary"
            >
              {createWorklogName(sheet)}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {formattedHoursRange}
            </Typography>
          </div>
        </div>
        <ArrowRightIcon
          color="secondary"
          className={classnames(
            classes.previewCellArrow,
            expanded ? classes.openedArrow : classes.closedArrow
          )}
          fontSize={'small'}
          onClick={handleExpandClick}
        />
      </div>
      <Collapse in={expanded} timeout={0} unmountOnExit>
        <Card className={classes.card}>
          <Grid container className={classes.paddingBox}>
            <Grid
              item
              xs={12}
              className={classnames(classes.paddingBottom, classes.inlineFlex, classes.flexHeader)}
            >
              <div className={classes.photoWrapper}>
                <img
                  src={getImageSrc(sheet.project.contractor && sheet.project.contractor.logo)}
                  className={classes.photo}
                />
              </div>
              <div className={classnames(classes.flexColumn, classes.selfCenter)}>
                <Typography variant="h6" color="textPrimary" className={classes.name}>
                  {sheet.project.contractor && sheet.project.contractor.name}
                </Typography>
                {sheet.forceAccount && sheet.forceAccount !== 'No' && (
                  <div className={classes.flexWrapper}>
                    <Typography
                      variant="body2"
                      className={classnames(classes.forceAccount, classes.marginBottomNormal)}
                    >
                      FA: {sheet.forceAccount}
                    </Typography>
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12} className={classes.paddingBottom}>
              <Typography variant="h6" color="textPrimary">
                {getProjectName(sheet.project)}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.paddingBottom}>
              <Divider />
            </Grid>
            <Grid item xs={12} className={classes.paddingBottom}>
              <div
                className={classnames(
                  classes.inlineFlex,
                  classes.paddingBottom,
                  classes.itemsFlexStart
                )}
              >
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classnames(
                    classes.mediumFontWeight,
                    sheet.locations && sheet.locations.length ? classes.paddingTopSmall : null
                  )}
                >
                  #{getJobNumber()}
                </Typography>
                <div
                  className={classnames(
                    classes.flexColumn,
                    classes.widthAuto,
                    classes.overflowHidden
                  )}
                >
                  {sheet.rallyPoints &&
                    !!sheet.rallyPoints.length &&
                    !!sheet.rallyPoints[0] &&
                    sheet.rallyPoints.map((locationId) => (
                      <div
                        className={classnames(
                          classes.locationFlex,
                          classes.paddingBottomSmall,
                          classes.fullWidth
                        )}
                        key={`${locationId}`}
                      >
                        <img
                          src={LocationIcon}
                          alt="location"
                          onClick={openLocation(sheet, locationId, 'sheetRallyPoints')}
                        />
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          className={classes.location}
                        >
                          {sheetLocation(sheet, true, locationId, 'sheetRallyPoints')}
                        </Typography>
                      </div>
                    ))}
                  {sheet.locations &&
                    !!sheet.locations.length &&
                    !!sheet.locations[0] &&
                    sheet.locations.map((locationId) => (
                      <div
                        className={classnames(
                          classes.locationFlex,
                          classes.paddingBottomSmall,
                          classes.fullWidth
                        )}
                        key={`${locationId}`}
                      >
                        <img
                          src={LocationIcon}
                          alt="location"
                          onClick={openLocation(sheet, locationId, 'sheetLocations')}
                        />
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          className={classes.location}
                        >
                          {sheetLocation(sheet, true, locationId, 'sheetLocations')}
                        </Typography>
                      </div>
                    ))}
                  {!Boolean(sheet.locations.length) && !Boolean(sheet.rallyPoints.length) && (
                    <div className={classes.locationFlex}>
                      <Typography variant="body1" color="textPrimary" className={classes.location}>
                        N/A
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classnames(
                  classes.mediumFontWeight,
                  getSheetStatus() === 'Canceled' ? classes.redText : classes.yellowText
                )}
              >
                {getSheetStatus()}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.paddingBottom}>
              <Divider />
            </Grid>
            {!!sheet.startTime && (
              <Grid item xs={12} md={6} className={classes.paddingBottom}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
                >
                  Start Time:
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {getIndividualStartTime()}
                </Typography>
              </Grid>
            )}
            {!!sheet.travelTime && (
              <Grid item xs={12} md={6} className={classes.paddingBottom}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
                >
                  Travel Time:
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {sheet.travelTime.hours}:{sheet.travelTime.minutes}
                </Typography>
              </Grid>
            )}
            {sheet?.hours?.start && sheet?.hours?.start && (
              <Grid item xs={12} className={classes.paddingBottom}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
                >
                  On Job Time:
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {getFormattedHoursRange()}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12} className={classes.paddingBottom}>
              <Divider />
            </Grid>

            <Grid xs={12}>
              {Boolean(sheet.notes) && (
                <Grid item className={classes.paddingBottom}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classnames(classes.marginBottomSmall, classes.paddingBottomSmall)}
                  >
                    Note:
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    className={classnames(classes.notesText, classes.marginBottom)}
                  >
                    {sheet.notes}
                  </Typography>
                </Grid>
              )}
              {Boolean(sheet.workers.length) && (
                <Grid item>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.marginBottomSmall}
                  >
                    People:
                  </Typography>
                  <div className={classnames(classes.marginBottomSmall)}>
                    {sheet.workers.map((worker) => (
                      <div
                        key={worker._id}
                        className={classnames(classes.marginBottom, classes.itemWrapperContent)}
                      >
                        <div>
                          <div
                            className={classnames(
                              classes.flexBox,
                              classes.justifyContentStart,
                              classes.marginBottomSmall
                            )}
                          >
                            <div
                              className={classnames(
                                classes.photoWrapperContent,
                                classes[`${worker?.shifts?.timeOfDay?.toLowerCase()}Border`]
                              )}
                            >
                              <img src={GreyUserI} className={classes.photoWorker} />
                            </div>
                            <Typography
                              variant="body1"
                              color="textPrimary"
                              className={classnames(
                                classes.username,
                                classes[worker?.shifts?.timeOfDay?.toLowerCase()]
                              )}
                            >
                              {worker.username}
                            </Typography>
                          </div>
                        </div>
                        <div>
                          <Button
                            variant="text"
                            className={
                              worker.foreman ? classes.activeButton : classes.inactiveButton
                            }
                            onClick={setForeman(worker)}
                            disableRipple
                          >
                            (CL)
                          </Button>
                          <IconButton
                            className={classes.closeButton}
                            onClick={handleRemoveResource('workers', worker._id)}
                            aria-label="Close"
                            disableRipple
                            disableTouchRipple
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                      </div>
                    ))}
                  </div>
                  <Grid item xs={12} className={classes.paddingBottom}>
                    <Divider />
                  </Grid>
                </Grid>
              )}
              {Boolean(sheet.equipment.length) && (
                <Grid item>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classnames(classes.marginBottomSmall, classes.paddingBottomSmall)}
                  >
                    Equipment:
                  </Typography>
                  <div className={classnames(classes.marginBottomSmall)}>
                    {sheet.equipment.map((item) => (
                      <div
                        key={item._id}
                        className={classnames(
                          classes.marginBottom,
                          classes.itemWrapperContent,
                          classes.marginBottomSmall
                        )}
                      >
                        <div>
                          <div
                            className={classnames(
                              classes.equipmentBlock,
                              getEquipmentInfo(item._id, 'className')
                            )}
                            style={{
                              backgroundColor: getEquipmentInfo(item._id, 'color'),
                            }}
                          >
                            <div className={classes.equipmentNumber}>
                              {getEquipmentInfo(item._id, 'name')}
                            </div>
                          </div>
                        </div>
                        <div>
                          <IconButton
                            className={classes.closeButton}
                            onClick={handleRemoveResource('equipment', item._id)}
                            aria-label="Close"
                            disableRipple
                            disableTouchRipple
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                      </div>
                    ))}
                  </div>
                  <Grid item xs={12} className={classes.paddingBottom}>
                    <Divider />
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid container item xs={12}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classnames(
                  classes.regularFontWeight,
                  !sheet.ownerUsername && classes.paddingBottom
                )}
              >
                Created on {agoDate(sheet.createdAtDate, null, dateFormatWithTime)}
              </Typography>
              &nbsp;<span className={classnames(classes.secondaryText)}>&#8226;</span>&nbsp;
              {sheet.ownerUsername && (
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classnames(classes.regularFontWeight, classes.paddingBottom)}
                >
                  {sheet.ownerUsername}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Card>
      </Collapse>
    </div>
  );
};

export default withStyles(styles)(WLAccordionItem);
