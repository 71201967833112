import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const useResourcesEquipment = ({
  usedEquipment,
  unavailableResources,
}) => {
  const equipment = useSelector((state) => state.schedule.resources.equipment);
  const equipmentInitialized = useMemo(() => equipment !== null, [equipment]);
  const [equipmentList, setEquipmentList] = useState(null);

  useEffect(() => {
    if (!equipmentInitialized) return setEquipmentList(null);
    if (!usedEquipment) return setEquipmentList(equipment);
    setEquipmentList(
      equipment.map((eq) => ({
        ...eq,
        isUsed: !!usedEquipment.find((usedEq) => usedEq._id === eq._id),
        unavailable: !!unavailableResources.find(
          (r) => r.resourceId === eq._id
        ),
      }))
    );
  }, [usedEquipment, unavailableResources, equipmentInitialized, equipment]);

  return { equipmentList };
};
