import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import styles from './styles';
import PageLoader from '../../../../../../components/PageLoader';
import { Option, isIOS } from '../../../../../../components/Option';

const SortingAndFilteringPanel = ({
  classes,
  closeDrawer,
  onCancel,
  filter,
  onUpdate,
  setFilter,
}) => {
  const types = [
    {
      name: 'All',
      type: 'All',
    },
    {
      name: 'Volume (Linear Feet (LF) / Gallon)',
      type: 'volume',
    },
    { name: 'Weight (Linear Feet (LF)/Pound)', type: 'weight' },
    {
      name: 'Each',
      type: 'each',
    },
  ];
  const markingTypes = [
    { name: 'All', type: 'All' },
    { name: 'Line', type: 'line' },
    { name: 'Legend', type: 'legend' },
    { name: 'Lettering', type: 'lettering' },
    {
      name: 'Reflective Media',
      type: 'glassBeads',
    },
  ];
  return (
    <div className={classes.formWrapper} role="presentation">
      <Formik
        initialValues={filter}
        enableReinitialize={true}
        onSubmit={(values) => {
          onUpdate(values);
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <PageLoader loading={false}>
            <Form autoComplete="off" className={classes.formFlex}>
              <div>
                <Typography variant="h3" className={classes.marginHeader}>
                  Filters
                </Typography>
                <IconButton
                  className={classes.closeButton}
                  onClick={closeDrawer}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>
                <Grid container spacing={2} className={classes.flexContainer}>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="sortBy" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Sort By:
                      </Typography>
                      <TextField
                        select
                        id="sortBy"
                        name="sortBy"
                        value={values.sortBy}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Sort by' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={1} value="name">
                          Name
                        </Option>
                        <Option key={2} value="type">
                          Type
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="sortOrder" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Sort Order:
                      </Typography>
                      <TextField
                        select
                        id="sortOrder"
                        name="sortOrder"
                        value={values.sortOrder}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Days' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={1} value="asc">
                          {'A \u2013 Z'}
                        </Option>
                        <Option key={2} value="desc">
                          {'Z \u2013 A'}
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                </Grid>

                <hr />

                <Grid container spacing={2} className={classes.flexContainer}>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="unitType" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Type of Units used
                      </Typography>
                      <TextField
                        select
                        id="unitType"
                        name="unitType"
                        defaultValue={filter.unitType}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'unitType' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        {types.map((m) => (
                          <Option key={m.type} value={m.type}>
                            {m.name}
                          </Option>
                        ))}
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="markingType" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Marking Types:
                      </Typography>
                      <TextField
                        select
                        id="markingType"
                        name="markingType"
                        defaultValue={filter.markingType}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'markingType' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        {markingTypes.map((m) => (
                          <Option key={m.type} value={m.type}>
                            {m.name}
                          </Option>
                        ))}
                      </TextField>
                    </label>
                  </Grid>
                </Grid>
                <button className={classes.linkButton} onClick={() => onCancel()}>
                  Reset all filters
                </button>
              </div>
              <div className={classes.buttonHolder}>
                <Button color="secondary" className={classes.cancelButton} onClick={closeDrawer}>
                  Cancel
                </Button>
                <div className={classes.verticalDivider}></div>
                <Button type="submit" color="primary" className={classes.saveButton}>
                  Apply
                </Button>
              </div>
            </Form>
          </PageLoader>
        )}
      </Formik>
    </div>
  );
};


export default withStyles(styles)(SortingAndFilteringPanel);
