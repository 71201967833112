import ProjectTypesActions from './projectTypesActions';
import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

const initialState = {
  projectTypes: {
    projectTypes: [],
    snackBarInfo: null,
  },
  projects: {
    data: [],
    isLoading: false,
    isError: null,
    hasMore: true,
    pageCount: 0,
  }
};

const projects = createReducer(initialState.projects, {
  [ProjectTypesActions.getProjectsSuccess]: (state, { payload }) => ({
    ...state,
    data: payload.data,
    hasMore: payload.hasMore,
    isLoading: false,
    isError: null,
  }),
  [ProjectTypesActions.getProjectsSuccessAdd]: (state, { payload }) => {
    const projToAdd = payload.data.filter((proj) => !~state.data.findIndex((u) => u._id === proj._id));

    return {
    ...state,
    data: [...state.data, ...projToAdd],
    // data: [...state.data, ...payload.data],
    hasMore: payload.hasMore,
    isLoading: false,
    isError: null,
  }},
  [ProjectTypesActions.toggleProjectStatus]: (state, { payload }) => {
    const updatedData = state.data.map(el=>{
      if (el._id === payload){
        return {...el, active: !el.active}
      }else return el
    })

    return {
    ...state,
    data: updatedData,
    isLoading: false,
    isError: null,
    }
  },
  [ProjectTypesActions.deleteProject]: (state, { payload }) => {
    const updatedData = state.data.filter(el=>el._id !== payload)
    
    return {
      ...state,
      data: updatedData,
      isLoading: false,
      isError: null,
      }
  },
  [ProjectTypesActions.createNewProject]: (state, { payload }) => ({
    ...state,
    data: [ payload, ...state.data],
    isLoading: false,
    isError: null,
  }),
  [ProjectTypesActions.updateProject]: (state, { payload }) => {
    const updatedData = state.data.map(el=>{
      if (el._id === payload._id){
        return {...el, ...payload}
      }else return el
    })
    
    return {
    ...state,
    data: updatedData,
    isLoading: false,
    isError: null,
  }},
  [ProjectTypesActions.projectsRequest]: (state, { payload }) => ({
    ...state,
    isLoading: payload,
    isError: null,
  }),
  [ProjectTypesActions.getProjectsError]: (state, { payload }) => ({
    ...state,
    isLoading: false,
    isError: payload,
  }),
  [ProjectTypesActions.resetProjects]: () => ({
    ...initialState.projects
  }),
});

const projectTypes = createReducer(initialState.projectTypes, {
  [ProjectTypesActions.getProjectTypes]: (state, { payload }) => ({
    ...state,
    projectTypes: payload,
  }),
  [ProjectTypesActions.snackBarInfo]: (state, { payload }) => ({
    ...state,
    snackBarInfo: payload,
  }),
  [ProjectTypesActions.createProjectType]: (state, { payload }) => ({
    ...state,
    projectTypes: [...state.projectTypes, payload],
  }),
  [ProjectTypesActions.deleteProjectType]: (state, { payload }) => {
    return {
      ...state,
      projectTypes: state.projectTypes.filter((type) => type._id !== payload),
    };
  },
  [ProjectTypesActions.updateProjectTypes]: (state, { payload }) => {
    return {
      ...state,
      projectTypes: state.projectTypes.map((type) => {
        if (type._id === payload._id) {
          return payload;
        } else {
          return type;
        }
      }),
    };
  },
});

export default combineReducers({
  projectTypes,
  projects
});
