import React, { useState } from 'react';
import '../styles.scss';
import { useEffect } from 'react';
import FleetMaintenanceRole from '../../FleetMaintenanceRole/FleetMaintenanceRole';
import AccountingRole from '../../AccountingRole/AccountingRole';
import DispatcherRole from '../../DispatcherRole/DispatcherRole';
import FieldTechRole from '../../FieldTechRole/FieldTechRole';
import ProjectManagementRole from '../../ProjectManagementRole/ProjectManagementRole';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHomePageDataSelectedUser } from 'store/homePage/homePageOperations';


function AdminRole({ searchedUsers, doWeShowSearch, setDoWeShowSearch }) {

  const [selectedUser, setSelectedUser] = useState(null);
  const dispatch = useDispatch();

  const dataSelectedUser = useSelector((state) => state.homePage.homePageSelectedUser);

  useEffect(() => {
    if (!!selectedUser) {
      dispatch(fetchHomePageDataSelectedUser(selectedUser._id));
    }
  }, [selectedUser]);

  const styles = {
    search: {
      display: doWeShowSearch && 'block',
    },
  };

  return (
    <div className="admin-block-wrapper">
      {searchedUsers?.length ? (
        <div className="search-block" style={styles.search}>
          {searchedUsers.map((user, i) => {
            return (
              <div
                className="search-user-wrapper"
                key={i}
                onClick={() => {
                  setDoWeShowSearch(false);
                  setSelectedUser(searchedUsers[i]);
                }}
              >
                <span>
                  {user.profile.fullName} / # {user.profile.employeeNum}
                </span>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="search-block" style={styles.search}>
          <div className="search-user-wrapper" style={{textAlign: 'center'}}>No results</div>
        </div>
      )}
      {selectedUser && selectedUser.profile.role.roleName !== 'Admin' && (
        <div className="search-user-name-header-wrapper">
          <span className="search-user-name-header">{selectedUser?.profile?.fullName}</span>
          <span className="search-user-number-header"># {selectedUser?.profile?.employeeNum}</span>
        </div>
      )}
      {selectedUser && selectedUser.profile?.role?.roleName === 'Field Technician' && (
        <FieldTechRole data={dataSelectedUser} />
      )}
      {selectedUser && selectedUser.profile?.role?.roleName === 'Fleet Maintenance' && (
        <FleetMaintenanceRole data={dataSelectedUser} />
      )}
      {selectedUser && selectedUser.profile?.role?.roleName === 'Accounting' && (
        <AccountingRole data={dataSelectedUser} />
      )}
      {selectedUser && selectedUser.profile?.role?.roleName === 'Dispatcher' && (
        <DispatcherRole data={dataSelectedUser} />
      )}
      {selectedUser && selectedUser.profile?.role?.roleName === 'Project Management' && (
        <ProjectManagementRole data={dataSelectedUser} />
      )}
    </div>
  );
}
export default AdminRole;
