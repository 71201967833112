import { useCallback, useEffect } from 'react';
import { subscribeToKioskEvents } from '../helpers/subscribeToKioskEvents.sockets';

export const useKioskEvents = (setUserList) => {
  const handleUsernameUpdate = useCallback(
    (user) => {
      setUserList((prev) => prev.map((u) => (u._id === user._id ? user : u)));
    },
    [setUserList]
  );
  const handleUserTimeOfDayUpdate = useCallback(
    (user) => {
      if (user.timeOfDay === 'Inactive') return setUserList((prev) => prev.filter((u) => u._id !== user._id));

      setUserList((prev) => {
        const index = prev.findIndex((u) => u.username > user.username);
        return [...prev.slice(0, index), user, ...prev.slice(index)];
      });
    },
    [setUserList]
  );

  useEffect(() => {
    const unsubscribe = subscribeToKioskEvents({ handleUsernameUpdate, handleUserTimeOfDayUpdate });

    return () => unsubscribe();
  }, []);
};
