import axios from 'axios';
import settingsAction from './settingsAction';
import $api from 'http/index';
import { getTimeZone } from 'helpers/getTimeZone';
import { getOptions } from 'helpers/getOptions';

export const getSettings = (cb) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };
  try {
    const response = await $api.get(`${process.env.REACT_APP_BASE_URL}/settings`, options);
    dispatch(settingsAction.getSettings(response.data));
  } catch (error) {
    if (error.statusCode > 299) throw new Error(error.message);
  } finally {
    if (cb) cb();
  }
};

export const patchSettings = (data) => async (dispatch) => {
  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'X-User-TZ': getTimeZone(),
    },
  };
  try {
    const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/settings`, data, options);
    dispatch(settingsAction.updateSettings(response.data));
  } catch (error) {
    if (error.statusCode > 299) throw new Error(error.message);
  }
};
export const patchScheduleNotifications = (data) => async (dispatch) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/settings/schedule-notifications
    `,
      data,
      getOptions()
    );
    dispatch(settingsAction.updateSettings(response.data));
  } catch (error) {
    console.log(error);
  }
};
export const fetchGetImage = (imageKey) => async (dispatch) => {
  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/settings/homepage-image?imageKey=${imageKey}`,
      options
    );

    dispatch(settingsAction.getImage(response.data));
  } catch (error) {
    if (error.statusCode > 299) throw new Error(error.message);
  }
};
