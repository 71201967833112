export default (theme) => ({
  settingsDetailsBlock: {
    paddingBottom: 24,
  },
  secondaryText: {
    color: theme.palette.textColor.secondary,
    fontSize: '0.8rem',
    fontWeight: 400,
    marginRight: 10,
  },
  inlineBlock: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  regularFontWeight: {
    fontWeight: 300
  },
  paddingBottom: {
    paddingBottom: 16
  },
  paddingTop: {
    paddingTop: 16,
  },
  zeroPaddingTop: {
    paddingTop: '0 !important',
  },
  shiftImage: {
    height: 16,
    margin: '0 5px 2px 0',
  },
  primaryTextButton: {
    padding: 0,
    color: theme.palette.textColor.primary,
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: `${theme.palette.background.default} !important`
    }
  },
  textField: {
    maxWidth: 250,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 200,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '100%'
    },
  },
  fieldValue: {
    marginLeft: '10px',
  },
  tripleSelectWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: 215,
    '& > div': {
      maxWidth: 65
    }
  },
  proHours: {
    display: 'flex',
    alignItems: 'center'
  },
  ptoHoursText: {
    marginLeft: 25,
    display: 'flex',
  },
})
