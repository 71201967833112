import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Fuse from 'fuse.js';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import ConfirmDialog from 'components/ConfirmDialog';
import FiltersBar from '../../components/FiltersBar';
import GlassBeadCard from './GlassBeadCard/GlassBeadCard';
import PageLoader from 'components/PageLoader';
import { fetchGlassBeads, fetchDeleteGlassBead } from 'store/supplies/suppliesOperations';
import suppliesActions from 'store/supplies/suppliesActions';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import SortingPanel from './SortingPanel/SortingPanel';

const searchOptions = {
  shouldSort: true,
  keys: ['name'],
};

const GlassBeads = ({ classes, onSelect, setSnackbar }) => {
  const dispatch = useDispatch();
  const [fuse, setFuse] = useState(null);
  const [drawer, setDrawer] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [sorting, setSorting] = useState({ sortBy: 'name', sortOrder: 'asc' });
  const [deletingGlassBead, setDeletingGlassBead] = useState(false);

  const glassBeadsData = useSelector((state) => state.supplies.glassBeads.data);

  const successfullyGlassBeadsDeleted = useSelector(
    (state) => state.supplies.glassBeads.successfullyGlassBeadsDeleted
  );
  const snackbar = useSelector((state) => state.supplies.glassBeads.snackBarInfo);
  const loading = useSelector((state) => state.supplies.glassBeads.loading);

  useEffect(() => {
    dispatch(fetchGlassBeads());
  }, []);

  useEffect(() => {
    if (snackbar) {
      setSnackbar(snackbar);
      dispatch(suppliesActions.postGlassBeadError(false));
    }
  }, [snackbar]);

  useEffect(() => {
    if (successfullyGlassBeadsDeleted) {
      dispatch(suppliesActions.isDeleteGlassBeadSuccess(false));
    }
    setDeletingGlassBead(null);
  }, [successfullyGlassBeadsDeleted]);

  const openDrawer = useCallback(
    () => () => {
      setDrawer({ isOpen: true });
    },
    []
  );

  const closeDrawer = useCallback(() => {
    setDrawer({ isOpen: false });
  }, []);

  const onUpdate = useCallback((values) => {
    setSorting(values);
    dispatch(fetchGlassBeads(values));
    closeDrawer();
  }, []);

  const onSortingCancel = useCallback(() => {
    setSorting({
      sortBy: 'name',
      sortOrder: 'asc',
    });
    dispatch(fetchGlassBeads());
    closeDrawer();
  }, []);

  const onDelete = useCallback((glassBead) => {
    setDeletingGlassBead(glassBead);
  }, []);

  const onDeletingConfirmed = useCallback(async () => {
    await dispatch(fetchDeleteGlassBead(deletingGlassBead._id));
    setSnackbar({
      text: 'Successfully Deleted!',
      type: 'success',
    });
  });

  useMemo(() => {
    setFuse(new Fuse(glassBeadsData, searchOptions));
  }, [glassBeadsData]);

  const filteredGlassBeads = fuse && searchValue ? fuse.search(searchValue) : glassBeadsData;

  return loading ? (
    <PageLoader loading>
      <div style={{ height: 100 }} />
    </PageLoader>
  ) : (
    <>
      <FiltersBar openDrawer={openDrawer} updateSearchValue={setSearchValue} isDynamicSearch={true}/>
      <Grid container spacing={2}>
        {filteredGlassBeads.map((m) => (
          <Grid key={m._id} item xs={12} md={6} lg={3}>
            <GlassBeadCard data={m} onDelete={onDelete} onClick={() => onSelect(m)} />
          </Grid>
        ))}
      </Grid>
      {drawer && (
        <Drawer anchor="right" open={drawer.isOpen}>
          <SortingPanel
            closeDrawer={closeDrawer}
            onCancel={onSortingCancel}
            sorting={sorting}
            onUpdate={onUpdate}
          />
        </Drawer>
      )}
      {deletingGlassBead && (
        <ConfirmDialog
          isOpen={!!deletingGlassBead}
          onClose={() => setDeletingGlassBead(null)}
          onSubmit={onDeletingConfirmed}
          text="Are you sure you want to delete this Reflective Media?"
          cancelBtn={'Cancel'}
          confirmBtn={'Delete'}
          disableEscape
          loadingOnSubmit
        />
      )}
    </>
  );
};

export default withStyles(styles)(GlassBeads);
