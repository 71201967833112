import { useCallback, useState } from 'react';
import styles from './styles';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
import cs from 'classnames';
import TimeclockItemEmpty from '../DayReport/components/Timeclocks/components/TimeclockItemEmpty/TimeclockItemEmpty';
import { startOfDay, setSeconds, endOfDay, isValid } from 'date-fns';
import EditTimeclockModal from 'pages/TimeClock/modals/EditTimeclockModal/EditTimeclockModal';

const NoDataPayroll = ({
  classes,
  className,
  editMode,
  data,
  onCreateTimeclock: onCreate,
  currentDate,
}) => {
  const [editingTimeclock, setEditingTimeclock] = useState(null);

  const onCreateTimeclock = useCallback(() => {
    const currDate = isValid(currentDate) ? new Date(currentDate) : new Date();

    const newTimeclock = {
      user: data.user,
      inTime: startOfDay(currDate),
      outTime: setSeconds(endOfDay(currDate), 0),
    };

    setEditingTimeclock(newTimeclock);
  }, []);

  const onEditingTimeclockReset = useCallback(() => {
    setEditingTimeclock(null);
    return console.warn('resetting timeclock skipped');
  }, [editingTimeclock]);

  const onEditingTimeclockConfirmed = useCallback(() => {
    onCreate(editingTimeclock);
    setEditingTimeclock(null);
  }, [editingTimeclock]);

  return (
    <>
      {editMode ? (
        <Grid className={cs(classes.root, 'timeclock-day-report-worklog')}>
          <div className={''}>
            <div className={classes.timeclockWlHeader}>
              <Grid className={classes.worklogWrapper}>
                <Grid className={classes.worklogUsername}>
                  <Grid className={classes.worklogUsernameWrapper}>
                    <dl className={'dl'}>
                      <dt className={classes.usernameGrid}>Username:</dt>
                      <dd className={classes.usernameGridIndex}>{data?.user?.username}</dd>
                    </dl>
                    <dl className={'dl'}>
                      <dt className={classes.usernameCode}>HI code #:</dt>
                      <dd className={'dd'}>{data?.user?.profile?.HICode}</dd>
                    </dl>
                  </Grid>
                </Grid>
                <Grid className={classes.timeclockWrapper}>
                  {editMode && !data?.timeclock?.length && (
                    <TimeclockItemEmpty key={'new'} onChange={onCreateTimeclock} />
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
          {editMode && (
            <>
              <EditTimeclockModal
                onReset={onEditingTimeclockReset}
                data={editingTimeclock}
                onChange={setEditingTimeclock}
                open={!!editingTimeclock}
                onClose={() => setEditingTimeclock(null)}
                onCancel={() => setEditingTimeclock(null)}
                onSubmit={onEditingTimeclockConfirmed}
              />
            </>
          )}
        </Grid>
      ) : (
        <div className={cs(classes.root, className)} style={{ padding: 36 }}>
          No work is done
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(NoDataPayroll);
