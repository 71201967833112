import { areIntervalsOverlapping, isAfter, isEqual, parse } from 'date-fns';
import { getDate } from 'helpers/getDate';

export const validateEndAfterStart = (values, setError, clearErrors, getTimeFormatHook) => {
  if (!Array.isArray(values.startDate)) {
    const startHoursObj = [
      values.startDate,
      values.startHours,
      values.startMinutes,
      values.startAmPm,
    ];
    const startDate = parse(
      getTimeFormatHook(startHoursObj).formattedTimeDate,
      getTimeFormatHook(startHoursObj).formatForDateTimePars,
      new Date()
    );
    const endHoursObj = [values.endDate, values.endHours, values.endMinutes, values.endAmPm];
    const endDate = parse(
      getTimeFormatHook(endHoursObj).formattedTimeDate,
      getTimeFormatHook(endHoursObj).formatForDateTimePars,
      new Date()
    );

    if (isEqual(endDate, startDate)) {
      setError('startHours', { message: 'End time cannot be equal to start time', type: 'error' });
      return false;
    }
    if (!isAfter(endDate, startDate)) {
      setError('startHours', {
        message: 'End date cannot be earlier than start date',
        type: 'error',
      });
      return false;
    }
    clearErrors();
    return true;
  }

  const noErrors = values.startDate.reduce((res, _, i) => {
    const startHoursObj = [
      values.startDate[i],
      values.startHours[i],
      values.startMinutes[i],
      values.startAmPm?.[i],
    ];
    const startDate = parse(
      getTimeFormatHook(startHoursObj).formattedTimeDate,
      getTimeFormatHook(startHoursObj).formatForDateTimePars,
      new Date()
    );
    const endHoursObj = [
      values.endDate[i],
      values.endHours[i],
      values.endMinutes[i],
      values.endAmPm?.[i],
    ];
    const endDate = parse(
      getTimeFormatHook(endHoursObj).formattedTimeDate,
      getTimeFormatHook(endHoursObj).formatForDateTimePars,
      new Date()
    );

    if (isEqual(endDate, startDate))
      return setError(`startHours[${i}]`, {
        message: 'End time cannot be equal to start time',
        type: 'error',
      });
    if (!isAfter(endDate, startDate)) {
      setError(`startHours[${i}]`, {
        message: 'End date cannot be earlier than start date',
        type: 'error',
      });
    }
    return isAfter(endDate, startDate) && res;
  }, true);

  if (noErrors) clearErrors();
  return noErrors;
};

export const validateDatesOverlapping = (values, setError, clearErrors, getTimeFormatHook) => {
  clearErrors();

  if (
    areIntervalsOverlapping(
      {
        start: getDate(
          values.startDate[0],
          values.startHours[0],
          values.startMinutes[0],
          values.startAmPm?.[0],
          getTimeFormatHook
        ),
        end: getDate(
          values.endDate[0],
          values.endHours[0],
          values.endMinutes[0],
          values.endAmPm?.[0],
          getTimeFormatHook
        ),
      },
      {
        start: getDate(
          values.startDate[1],
          values.startHours[1],
          values.startMinutes[1],
          values.startAmPm?.[1],
          getTimeFormatHook
        ),
        end: getDate(
          values.endDate[1],
          values.endHours[1],
          values.endMinutes[1],
          values.endAmPm?.[1],
          getTimeFormatHook
        ),
      },
      { inclusive: true }
    )
  ) {
    setError('endDate[1]', {
      type: 'hasTimeoff',
      message: 'User already has timeoff for this day(s)',
    });
    setError('startDate[1]', {
      type: 'hasTimeoff',
      message: 'User already has timeoff for this day(s)',
    });
    return false;
  }
  return true;
};
