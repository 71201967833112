import axios from 'axios';
import $api from 'http/index';
import { getOptions } from 'helpers/getOptions';
import superadminActions from './superadminActions';
import settingsAction from './../settings/settingsAction';
import personalProfileActions from '../personalProfile/personalProfileActions';
import { downloadMultipleFiles } from 'helpers/downloadFile';

export const catchError = (error) => {
  if (error?.response?.data?.message) {
    if (Array.isArray(error.response.data.message))
      return error.response.data.message.join('\n');
    else  return error.response.data.message;
  } else {
    return error.message;
  }
};

export const fetchClients = (filters = {}) => async (dispatch) => {
  try {
    const options = {
      params: {
        subscription: filters?.subscription,
        active: filters?.active,
      }
    }

    const response = await $api.get(
      `${process.env.REACT_APP_BASE_URL}/organizations`,
      { ...options, ...getOptions(), }
    );

    dispatch(superadminActions.getClients(response.data));
  } catch (error) {
    dispatch(superadminActions.snackBarInfo({type: 'error', text: catchError(error)}));
    if (error.response.data.statusCode > 299) throw new Error(catchError(error));
  }
};


export const postNewClient = (data) => async (dispatch) => {
  try {
    const response = await $api.post(
      `${process.env.REACT_APP_BASE_URL}/organizations`,
      data,
      { ...getOptions(), }
    );

    dispatch(superadminActions.createClient(response.data));
    dispatch(superadminActions.snackBarInfo({type: 'success', text: 'Company successfully added'}));
  } catch (error) {
    dispatch(superadminActions.snackBarInfo({type: 'error', text: catchError(error)}));
    if (error.response.data.statusCode > 299) throw new Error(catchError(error));
  }
};


export const putClient = (clientId, data) => async (dispatch, getState) => {
  const currentOrganizationId = getState().personalProfile?.organization?._id || '';
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/organizations/${clientId}`,
      data,
      { ...getOptions(), },
    );

    dispatch(superadminActions.updateClient(response.data));
    dispatch(superadminActions.snackBarInfo({type: 'success', text: 'Company successfully updated'}));

    if( clientId === currentOrganizationId ){
      dispatch(settingsAction.updateSettings(response.data.settings));
    }
  } catch (error) {
    dispatch(superadminActions.snackBarInfo({type: 'error', text: catchError(error)}));
    if (error.response.data.statusCode > 299) throw new Error(catchError(error));
  }
};


export const fetchStatistic = (date, clientId) => async (dispatch) => {
  const options = {
    params: { date: date?.toISOString() }
  };
  if (clientId && clientId !== 'All') options.params.organizationId = clientId
  try {
    const response = await $api.get(
      `${process.env.REACT_APP_BASE_URL}/homepage/admin-panel`,
      { ...options, ...getOptions(), },
    );

    dispatch(superadminActions.getStatistic(response.data));
  } catch (error) {
    dispatch(superadminActions.snackBarInfo({type: 'error', text: catchError(error)}));
    if (error.response.data.statusCode > 299) throw new Error(catchError(error));
  }
}


export const loginToOrganization = (_id) => async (dispatch) => {
  try {
    const response = await $api.get(
      `${process.env.REACT_APP_BASE_URL}/organizations/${_id}/login`,
      { ...getOptions() }
    );

    const organization = response.data;

    dispatch(superadminActions.snackBarInfo({ type: 'success', text: `Succesfully logged in to ${organization.name}` }));
    dispatch(personalProfileActions.setOrganization(organization))
    dispatch(settingsAction.getSettings(organization.settings));
  } catch (error) {
    dispatch(superadminActions.snackBarInfo({type: 'error', text: catchError(error)}));
    if (error.response.data.statusCode > 299) throw new Error(catchError(error));
  }
}


export const fetchUsers = (filters = {}, searchValue, sortBy, sortOrder) => async (dispatch) => {
  try {
    const options = {
      params: {
        ...filters,
      }
    };
    if (sortBy) options.params.sortBy = sortBy;
    if (sortOrder) options.params.sortOrder = sortOrder;
    if (searchValue) options.params.searchValue = searchValue;

    const response = await $api.get(
      `${process.env.REACT_APP_BASE_URL}/users/admin-panel`,
      { ...options, ...getOptions(), }
    );

    dispatch(superadminActions.getUsers(response.data));
  } catch (error) {
    dispatch(superadminActions.snackBarInfo({type: 'error', text: catchError(error)}));
    if (error.response.data.statusCode > 299) throw new Error(catchError(error));
  }
}; 

export const postAssignUsers = (userIds, data) => async (dispatch) => {
  try {
    const body = {
      ...data,
      userIds,
    }
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/users/admin-panel/assign-organization`,
      body,
      { ...getOptions() },
    );

    dispatch(superadminActions.updateBunchUsers(response.data));
  } catch (error) {
    dispatch(superadminActions.snackBarInfo({type: 'error', text: catchError(error)}));
    if (error.response.data.statusCode > 299) throw new Error(catchError(error));
  }
}

export const postResetPassword = (userIds) => async (dispatch) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/users/admin-panel/reset-password`,
      { userIds },
      { ...getOptions() },
    );

    dispatch(superadminActions.snackBarInfo({ type: 'success', text: 'Emails succesfully sent' }));
  } catch (error) {
    dispatch(superadminActions.snackBarInfo({type: 'error', text: catchError(error)}));
    if (error.response.data.statusCode > 299) throw new Error(catchError(error));
  }
}

export const putUser = (userId, data) => async (dispatch) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/users/admin-panel/${userId}`,
      data,
      { ...getOptions(), },
    );

    dispatch(superadminActions.updateUser(response.data));
    dispatch(superadminActions.snackBarInfo({type: 'success', text: 'User successfully updated'}));
  } catch (error) {
    dispatch(superadminActions.snackBarInfo({type: 'error', text: catchError(error)}));
    if (error.response.data.statusCode > 299) throw new Error(catchError(error));
  }
};

export const postUser = (data) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/users/admin-panel`,
      data,
      { ...getOptions(), },
    );

    dispatch(superadminActions.createUser(response.data));
    dispatch(superadminActions.snackBarInfo({type: 'success', text: 'User successfully created'}));
  } catch (error) {
    dispatch(superadminActions.snackBarInfo({type: 'error', text: catchError(error)}));
    if (error.response.data.statusCode > 299){
      throw new Error(catchError(error))};
  }
};


export const deleteUser = (userId) => async (dispatch) => {
  try {
    await $api.delete(
      `${process.env.REACT_APP_BASE_URL}/users/admin-panel/${userId}`,
      { ...getOptions(), },
    );

    dispatch(superadminActions.deleteUser(userId));
    dispatch(superadminActions.snackBarInfo({type: 'success', text: 'User successfully deleted'}));
  } catch (error) {
    dispatch(superadminActions.snackBarInfo({type: 'error', text: catchError(error)}));
    if (error.response.data.statusCode > 299) throw new Error(catchError(error));
  }
};


export const exportUsers = (filters, searchValue, sortBy, sortOrder, organizationName) => async (dispatch) => {
  try {
    const options = {
      params: {
        ...filters,
      }
    };
    if (sortBy) options.params.sortBy = sortBy;
    if (sortOrder) options.params.sortOrder = sortOrder;
    if (searchValue) options.params.searchValue = searchValue;
    const searchParams = new URLSearchParams(options.params).toString();

    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/users/admin-panel/export-csv?${searchParams}`,
      { headers: { ...(getOptions().headers), 'Accept': 'multipart/form-data' }, method: 'GET' },
    )
    if (response.status >= 300) throw new Error('Something went wrong');

    const formData = await response.formData();

    const files = [];
    for (const pair of formData.entries()) {
      files.push({ name: `${pair[0]}.csv`, blob: new Blob([pair[1]], { type: 'text/csv' }),  });
    }
    if (!files.length) {
      dispatch(superadminActions.snackBarInfo({type: 'success', text: 'Nothing to export' }));
    }

    await downloadMultipleFiles(files);
  } catch (error) {
    dispatch(superadminActions.snackBarInfo({type: 'error', text: catchError(error)}));
    if (error.response.data.statusCode > 299) throw new Error(catchError(error));
  }
}


export const importUsers = (data) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/users/admin-panel/import-csv`,
      data,
      { ...getOptions('multipart/form-data') }
    );

    dispatch(superadminActions.snackBarInfo({ type: 'success', text: `Imported ${response.data.createdUsers} users` }));
  } catch (error) {
    dispatch(superadminActions.snackBarInfo({type: 'error', text: catchError(error)}));
    if (error.response.data.statusCode > 299) throw new Error(catchError(error));
  }
};