import { useSelector } from 'react-redux';
import { allDatesFormat, TIME_FORMAT } from './dateFormatConfig';
import { dateSettingsFormat } from 'common/dateFormatConfig';

/**
 * 
 * @param {{ dateFormat: string, timeFormat: string }} fallbackFormats. Time format = '12hour' | '24hour'
 * @returns 
 */
export const useDateSettingsFormat = (fallbackFormats) => {
  const dateFormat = useSelector(
    (store) => store.personalProfile.organization?.settings?.dateFormat || fallbackFormats?.dateFormat || allDatesFormat[0]
  );

  const timeFormat = useSelector(
    (store) => store.personalProfile.organization?.settings?.timeFormat || fallbackFormats?.timeFormat || TIME_FORMAT[0]
  );

  return dateSettingsFormat(dateFormat, timeFormat);
};
