import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSettings } from 'store/settings/settingsOperations';

/**
 * @param {string}("Mon" | "Sun") weekStart
 * @param {number}(1 - "Mon" | 0 - "Sun") ISOWeekDay
 * @param {string}("Mon" | "Sun") weekStart
 */

export const useWeekStart = () => {
  const dispatch = useDispatch();
  const weekStart = useSelector((state) => state.settings?.settings?.weekStart || 'Sun');

  useEffect(() => {
    if (!weekStart) {
      dispatch(getSettings());
    }
  }, [weekStart]);

  return {
    weekStart,
    ISOWeekDay: weekStart === 'Sun' ? 7 : 1,
    weekDayFromO: weekStart === 'Sun' ? 0 : 1,
  };
};
