export default theme => ({
  root: {
    padding: '10px 15px',
    width: '1280px',
    margin: '0 auto',
    '@media (max-width: 1300px)': {
      padding: '0px 15px',
      width: 'auto',
    },
  },
  drawer: {
    // [theme.breakpoints.down('sm')]: {
    //   width: '100%',
    // },
  },
  formFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 'calc(95vh - 110px)',
  },
  buttonHolder: {
    textAlign: 'center',
  },
  cancelButton: {
    margin: 20,
    marginBottom: 0,
    minWidth: 100,
    '&:hover': {
      color: 'inherit'
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  saveButton: {
    margin: 20,
    marginBottom: 0,
    minWidth: 100,
    '&:hover': {
      color: theme.palette.textColor.primary,
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
});
