import React, { useRef, useEffect } from 'react';
import './styles.scss';

const PieChart = ({
  data = {
    allowed: 0,
    designated: 0,
    available: 0,
  },
}) => {
  const myCanvas = useRef(null);

  function isCorrectData(data) {
    if (data.available < 0 || data.designated < 0 || data.allowed < 0) {
      return {
        PTOAvailable: 0,
        PTODesignated: 0,
      };
    }
    if (data.designated > data.allowed) {
      return {
        PTOAvailable: 0,
        PTODesignated: data.designated,
      };
    }
    if (data.designated === data.allowed && data.allowed != 0) {
      return {
        PTOAvailable: 0,
        PTODesignated: data.designated,
      };
    }
    return {
      PTOAvailable: data.available,
      PTODesignated: data.designated,
    };
  }
  useEffect(() => {
    myCanvas.current.width = 163;
    myCanvas.current.height = 163;
    let ctx = myCanvas.current.getContext('2d');

    function drawPieSlice(ctx, centerX, centerY, radius, startAngle, endAngle, color) {
      ctx.fillStyle = color;
      ctx.beginPath();
      ctx.moveTo(centerX, centerY);
      ctx.arc(centerX, centerY, radius, startAngle, endAngle);
      ctx.closePath();
      ctx.fill();
    }

    let Piechart = function (options) {
      this.options = options;
      this.canvas = options.canvas;
      this.ctx = this.canvas.getContext('2d');
      this.colors = options.colors;

      function moveLabelXRule() {
        if (data.designated === data.available && data.available < 10) {
          return 5;
        } else if (
          data.designated === data.available &&
          data.available >= 10 &&
          data.available < 100
        ) {
          return 12;
        } else if (data.designated === data.available && data.available >= 100) {
          return 15;
        } else {
          return 9;
        }
      }

      this.draw = function () {
        let total_value = 0;
        let color_index = 0;
        for (let categ in this.options.data) {
          let val = this.options.data[categ];
          total_value += val;
        }
        let start_angle = 17.279;
        for (let categ in this.options.data) {
          let val = this.options.data[categ];
          let slice_angle = (2 * Math.PI * val) / total_value;

          drawPieSlice(
            this.ctx,
            this.canvas.width / 2,
            this.canvas.height / 2,
            Math.min(this.canvas.width / 2, this.canvas.height / 2),
            start_angle,
            start_angle + slice_angle,
            this.colors[color_index % this.colors.length]
          );
          start_angle += slice_angle;
          color_index++;
        }

        function rotateLable() {
          if (data.designated > data.available) {
            if (data.designated / data.available <= 20 && data.available > 9) {
              return 17.25;
            } else {
              return 17.37;
            }
          }

          if (data.designated < data.available) {
            if (data.available / data.designated <= 20 && data.designated > 9) {
              return 17.25;
            } else {
              return 17.37;
            }
          }
        }

        start_angle = rotateLable();
        if (data.designated === data.available) {
          start_angle = 17.29;
        }

        for (let categ in this.options.data) {
          let val = this.options.data[categ];
          let slice_angle = (2 * Math.PI * val) / total_value;
          let pieRadius = Math.min(this.canvas.width / 2, this.canvas.height / 2);

          let labelX =
            this.canvas.width / 2 +
            (pieRadius / 2) * Math.cos(start_angle + slice_angle / 2) -
            moveLabelXRule();
          let labelY =
            this.canvas.height / 2 + (pieRadius / 2) * Math.sin(start_angle + slice_angle / 2) + 7;
          if ((data && data.available === 0) || data.designated === 0) {
            labelY = 90;
            labelX = 78;
            if (val > 9) {
              labelX = 73;
            }
            if (val > 99) {
              labelX = 68;
            }
          }
          if (val === 0) {
            val = '';
          }
          let labelText = val;
          this.ctx.fillStyle = 'white';
          this.ctx.font = 'normal 18px sans-serif';
          this.ctx.fillText(labelText, labelX, labelY);
          start_angle += slice_angle;
        }
      };
    };
    let myPiechart = new Piechart({
      canvas: myCanvas.current,
      data: isCorrectData(data),
      colors: ['#E1A166', '#6588E8'],
    });

    myPiechart.draw();
  }, [data]);
  const isDisableChartRule =
    (data.available <= 0 && data.designated <= 0) || data.available < 0 || data.designated < 0;
  return (
    <>
      <div className="chart-wrapper" style={(isDisableChartRule && { display: 'none' }) || {}}>
        <h2 className="chart-wrapper-title">Time off</h2>
        <div className="chart-block">
          <div className="chart-circle-wrapper">
            <canvas ref={myCanvas} className="canvas-chart" id="myCanvas"></canvas>
          </div>
          <div className="legend-wrapper">
            <div className="legend-block">
              <div className="pto-allowed-color"></div>
              <p className="legend-text">PTO Available</p>
            </div>
            <div className="legend-block">
              <div className="pto-designeted-color"></div>
              <p className="legend-text">PTO Designated</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PieChart;
