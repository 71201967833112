import axios from 'axios';
import { getItem, setItems } from '../helpers/localStorage';
import { logout } from 'store/login/loginOperations';
import store from 'store/store';

export const catchError = (error) => {
  if (error.response) {
    if (Array.isArray(error.response.data.message))
      throw new Error(error.response.data.message.join('\n'));
    else throw new Error(error.response.data.message);
  } else {
    throw new Error(error.message);
  }
};

const $api = axios.create();

// Add a response interceptor
$api.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger

    return response;
  },
  async function (error) {
    const { dispatch } = store;
    const originalRequest = error.config;

    if (error.response && error.response.status === 401) {
      try {
        const data = { refreshToken: getItem('refresh-token') };
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/refresh-token`, data);

        const localData = [
          { key: 'authorization-token', value: response.data.token },
          { key: 'authorization-token-exp-at', value: response.data.expiresAt },
          { key: 'refresh-token', value: response.data.refreshToken },
          { key: 'refresh-token-exp-at', value: response.data.refreshExpiresAt },
        ];

        setItems(localData);
        originalRequest.headers['X-Auth-Token'] = response.data.token;

        return $api.request(originalRequest);
      } catch (e) {
        dispatch(logout());
      }
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger

    return Promise.reject(error);
  }
);

export default $api;
