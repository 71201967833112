import React from 'react';
import { Formik, Form } from 'formik';
import { userTypes } from 'common/permissions';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PageLoader from 'components/PageLoader';
import { isIOS, Option } from 'components/Option/Option';
import styles from './styles';


const UsersFilter = ({
  classes,
  closeDrawer,
  onUpdateFilters,
  filters,
  filtersReset,
  clientsData,
}) => {
  return (
    <div className={classes.formWrapper} role="presentation">
      <Formik
        initialValues={filters}
        enableReinitialize={true}
        onSubmit={(values) => {
          onUpdateFilters(values);
        }}
      >
        {({ values, handleChange }) => (
          <PageLoader loading={false}>
            <Form autoComplete="off" className={classes.formFlex}>
              <div>
                <Typography variant="h3" className={classes.marginHeader}>
                  Filters
                </Typography>

                <IconButton
                  className={classes.closeButton}
                  onClick={closeDrawer}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={2} className={classes.flexContainer}>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="organizationId" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Company:
                      </Typography>
                      <TextField
                        select
                        id="organizationId"
                        name="organizationId"
                        value={values.organizationId}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Subscription' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={1} value="All">
                          All
                        </Option>
                        {clientsData.map((client) => {
                          return (
                            <Option key={client._id} value={client._id}>
                             {client.name}
                            </Option>
                          )
                        })}
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="userType" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        User Type:
                      </Typography>
                      <TextField
                        select
                        id="userType"
                        name="userType"
                        value={values.userType}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'User Type' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key="All" value="All">
                          All
                        </Option>
                        {Object.values(userTypes).map(role => (
                          <Option key={role} value={role}>
                            {role}
                          </Option>
                        ))}
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="status" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Status:
                      </Typography>
                      <TextField
                        select
                        id="status"
                        name="status"
                        value={values.status}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Status' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key="All" value="All">
                          All
                        </Option>
                        <Option key="Active" value="Active">
                          Active
                        </Option>
                        <Option key="Inactive" value="Inactive">
                          Inactive
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="lastSignIn" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Last sign in:
                      </Typography>
                      <TextField
                        select
                        id="lastSignIn"
                        name="lastSignIn"
                        value={values.lastSignIn}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Last Sign In' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key="All" value="All">
                          All period
                        </Option>
                        <Option key="Month" value="Month">
                          This month
                        </Option>
                        <Option key="Week" value="Week">
                          This week
                        </Option>
                        <Option key="Today" value="Today">
                          Today
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <button className={classes.linkButton} onClick={filtersReset}>
                      Reset all filters
                    </button>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.buttonHolder}>
                <Button color="secondary" className={classes.cancelButton} onClick={closeDrawer}>
                  Cancel
                </Button>
                <div className={classes.verticalDivider} />
                <Button type="submit" color="primary" className={classes.saveButton}>
                  Apply
                </Button>
              </div>
            </Form>
          </PageLoader>
        )}
      </Formik>
    </div>
  );
};

export default withStyles(styles)(UsersFilter);
