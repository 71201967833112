import React, { useState, useEffect, useCallback } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  postHiCode,
  putHICode,
  deleteHICode
} from 'store/people/hiCodesOperations';
import hiCodesActions from 'store/people/hiCodesActions';
import CloseIcon from 'assets/images/close-icon.svg'
import PencilI from "assets/images/edit-icon-pencil.svg"
import MenuItem from '@material-ui/core/MenuItem';
import styles from './styles';

// Try to make it as a reusable component
const HICodes = ({
  classes,
  value,
  changeValue,
  handleBlur,
  errors,
  touched,
  openSnackbar,
  disabled,
}) => {
  const { hiCodes: hiCodesOptions, hiCodesLoading: loading } = useSelector((state) => state.people.hiCodes);
  const snackBarInfo = useSelector((state) => state.people.snackBarInfo);
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState({ name: '' });

  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isItemDeleted, setIsItemDeleted] = useState(false);

  useEffect(() => {
    if (snackBarInfo) {
      openSnackbar(snackBarInfo.type, snackBarInfo.text);
      dispatch(hiCodesActions.snackBarInfo(null));
    }
  }, [snackBarInfo]);

  useEffect(() => {
    if (!value || loading) return;
    const valueOption = hiCodesOptions.find(code => code.name === value);

    if (valueOption) {
      setInputValue(valueOption);
    } else if (!disabled) {
      setIsCreate(true);
      setIsEdit(false);
      setIsItemDeleted(true);

      setInputValue({ name: value });
    }
  }, [hiCodesOptions, value, loading, disabled])

  const handleChangeInput = (e) => {
    const value = e.target.value;
    setInputValue((prev) => ({ ...prev, name: value }));
  };

  const onSelect = useCallback((e) => {
    const item = hiCodesOptions.find(code => code._id === e.target.value);

    changeValue(item.name);
  }, [hiCodesOptions]);

  const cancelEditCreate = (e) => {
    setIsCreate(false);
    setIsEdit(false);
    setIsItemDeleted(false);
  }


  // Add
  const addItem = () => {
    setInputValue({ name: '' });

    setIsCreate(true);
  };

  const onCreate = (e) => {
    dispatch(postHiCode(inputValue)).then(() => {
      setIsCreate(false);
      setIsItemDeleted(false);
    });

    changeValue(inputValue.name);
  }


  // Edit
  const editItem = useCallback((_id) => (e) => {
    const item = hiCodesOptions.find(code => code._id === _id);
    if (!item) return;

    setIsEdit(true);
    setInputValue(item);
  }, [hiCodesOptions])

  const onEdit = (e) => {
    dispatch(putHICode(inputValue)).then(() => {
      setIsEdit(false);
    });
    
    changeValue(inputValue.name);
  };


  // Delete
  const onDelete = useCallback((_id) => (e) => {
    e.stopPropagation();

    dispatch(deleteHICode(_id)).then(() => {
      if (value.name === inputValue.name) {
        setIsItemDeleted(true);
      }
    });
  }, [inputValue, value]);


  return (
    <>
      {(isCreate || isEdit) && !disabled ? (
        <TextField
          id="HICode"
          name="HICode"
          value={inputValue.name || ''}
          onChange={handleChangeInput}
          type="text"
          className={classes.textField}
          variant="outlined"
          fullWidth
          inputProps={{ 'aria-label': 'HI Code' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <div className={classes.inlineFlex}>
                  {isEdit ? (
                    <Button
                      variant="text"
                      className={classnames(classes.inputButton, classes.primaryButton)}
                      onClick={onEdit}
                      disableRipple
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      variant="text"
                      className={classnames(classes.inputButton, classes.primaryButton)}
                      onClick={onCreate}
                      disabled={!inputValue.name}
                    >
                      Add
                    </Button>
                  )}
                  <Button
                    variant="text"
                    className={classes.inputButton}
                    onClick={cancelEditCreate}
                    disableRipple
                  >
                    Cancel
                  </Button>
                </div>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <TextField
          select
          name="HICode"
          id="HICode"
          value={inputValue?._id || value}
          onChange={onSelect}
          onBlur={handleBlur}
          error={Boolean(errors.HICode) && Boolean(touched.HICode)}
          className={classes.textField}
          variant="outlined"
          fullWidth
          disabled={!!disabled}
        >
          {hiCodesOptions?.map((item) => (
            <MenuItem key={item._id} value={item._id} className={classes.option}>
              {item.name}
              <div className={classes.inputAdor}>
                <div className={classes.inlineFlex}>
                  <Button
                    color="primary"
                    className={classes.iconButton}
                    onClick={editItem(item._id)}
                    disableRipple
                  >
                    <img
                      src={PencilI}
                      className={classes.optionsIcons}
                      alt="edit"
                    />
                  </Button>
                  <Button
                    color="primary"
                    className={classes.iconButton}
                    onClick={onDelete(item._id)}
                    disableRipple
                  >
                    <img
                      src={CloseIcon}
                      alt="delete"
                      className={classes.optionsIcons}
                    />
                  </Button>
                </div>
              </div>
            </MenuItem>
          ))}
          {disabled ? ( // for personal profile non admins
            <MenuItem key={value} value={value} className={classes.option}>
              {value}
            </MenuItem>
          ) : (
            <MenuItem key={5} value="add">
              <Button
                variant="text"
                className={classes.addButton}
                onClick={addItem}
                disableRipple
              >
                + Add HI Code
              </Button>
            </MenuItem>
          )}
        </TextField>
      )}
      {isItemDeleted && !disabled && (
        <Typography variant="body1" color="textSecondary">
          This Health Insurance code was deleted. Do you want to add it again?
        </Typography>
      )}
    </>
  );
};

HICodes.propTypes = {
  value: propTypes.string.isRequired,
  changeValue: propTypes.func.isRequired,
  handleBlur: propTypes.func,
  errors: propTypes.object,
  touched: propTypes.object,
  openSnackbar: propTypes.func.isRequired,
  disabled: propTypes.bool,
};

export default withStyles(styles)(HICodes);
