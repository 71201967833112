import { createAction } from '@reduxjs/toolkit';

const setClockInStatus = createAction('kiosk/setClockInStatus');
const setSummary = createAction('kiosk/setSummary');
const setError = createAction('kiosk/setError');
const setAlert = createAction('kiosk/setAlert');
const setPto = createAction('kiosk/setPto');
const login = createAction('kiosk/login');
const logout = createAction('kiosk/logout');
const setCompany = createAction('kiosk/setCompany');
const setSpecialProfilePermissions = createAction('kiosk/setSpecialProfilePermissions');
const clearPersonalProfile = createAction('kiosk/clearPersonalProfile');

export default {
  setClockInStatus,
  setSummary,
  setError,
  setAlert,
  setPto,
  login,
  logout,
  setCompany,
  setSpecialProfilePermissions,
  clearPersonalProfile,
};
