import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';

import PageLoader from '../../../PageLoader';
import SearchForm from '../SearchForm';
import SelectedEquipment from './components/SelectedEquipment';

const EquipmentBlock = ({sheet,list,isSubmitting,setSubmitData, usedItems, unavailableResources}) => {

  const [selectedResources, setSelectedResources] = useState([])
  const [availableResources, setAvailableResources] = useState([])

  useEffect( ()=> {
    const { equipment } = sheet;

    if (equipment.length) {
      const usedResourcesIds = equipment.map(item => item._id);
      setAvailableResources(list.filter(el => !usedResourcesIds.includes(el._id))) 
      setSelectedResources([...equipment])

    } else {
      setAvailableResources([...list])
      setSelectedResources([])

    }
  },[list])

  useEffect( ()=> {

    if (isSubmitting) {
      const isValid = selectedResources.reduce((isValid, resource) => {
        if (!isValid) return false;
        return !(resource.hours && resource.hours.isNotValid);
      }, true);
      const rawData = selectedResources.map(r => {
        if (r.hours && r.hours.isNotValid) {
          const hours = {
            ...r.hours,
            travelTimeTo: {
              hours: r.hours.travelTimeTo?.hours || '0',
              minutes: r.hours.travelTimeTo?.minutes || '0',
            },
            travelTimeFrom: {
              hours: r.hours.travelTimeFrom?.hours || '0',
              minutes: r.hours.travelTimeFrom?.minutes || '0',
            },
          };
          delete hours.isNotValid;
          const resource = {...r, hours};
          return resource;
        } else return r;
      });
      setSubmitData(rawData, isValid);
    }
  },[isSubmitting])

  const formatList = () => {
    return availableResources.map(item => ({
      label: item.name,
      id: item._id,
      info: item
    }));
  }

  const selectItem = selectedId => {
    let selectedItem;
    const availableResourcesData = availableResources.filter(el => {
      if (el._id === selectedId) {
        selectedItem = {
          _id: el._id
        };
      }
      return el._id !== selectedId;
    });
    setAvailableResources(availableResourcesData)

    setSelectedResources(prev => [...prev, selectedItem])

  }

  const  getEquipment = equipment => list.find(el=>el._id === equipment._id) || equipment

const updateSelectedResource = (id, data) => {
    const resourceIndex = selectedResources.findIndex(el => el._id === id);
    const resources = [...selectedResources];
    resources[resourceIndex] = data;
    setSelectedResources(resources)
  }

  const removeSelectedResource = id => {
    const selectedResourcesData = selectedResources.filter(el => el._id !== id);
    const usedResourcesIds = selectedResourcesData.map(w => w._id);
    const availableResourcesData = list.filter(el => !usedResourcesIds.includes(el._id));
    setAvailableResources(availableResourcesData)
    setSelectedResources(selectedResourcesData)
  }

    return (
      <PageLoader loading={false}>
        <>
          <div>
            <SearchForm
              list={formatList()}
              selectItem={selectItem}
              type="equipment"
              usedItems={usedItems}
              unavailableResources={unavailableResources}
            />
          </div>

          <div>
            {selectedResources && !!selectedResources.length && selectedResources.map(r => (
              <SelectedEquipment
                key={r._id}
                item={getEquipment(r)}
                sheet={sheet}
                equipmentData={r}
                updateSelectedResource={updateSelectedResource}
                removeSelectedResource={removeSelectedResource}
              />
            ))}
          </div>
        </>
      </PageLoader>
    );
}

EquipmentBlock.propTypes = {
  list: propTypes.array.isRequired,
  sheet: propTypes.object.isRequired,
  isSubmitting: propTypes.bool.isRequired,
  setSubmitData: propTypes.func.isRequired,
  usedItems: propTypes.array.isRequired,
  unavailableResources: propTypes.array.isRequired
}

export default EquipmentBlock;
