import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { patchSurfaceWorklogMaterial } from 'store/currentWorklog/operations';
import { TextField, Typography } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import MenuItem from '@material-ui/core/MenuItem';
import currentWorklogActions from 'store/currentWorklog/actions';

const SurfaceInput = ({
  classes,
  groupedMaterials,
  sheet,
  shouldRenderAddButton,
  isMaterialForm,
  setFieldValue,
  values,
  hasAccess,
  locationOptions,
}) => {
  const dispatch = useDispatch();
  const surfaces = useSelector((store) => store.currentWorklog.worklogSheet.surfaces);
  const [surfaceSelectValue, setSurfaceSelectValue] = useState('');
  const [localSurfaces, setLocalSurfaces] = useState(surfaces || []);

  useEffect(() => {
    setLocalSurfaces(surfaces);
  }, [surfaces]);

  const locationValue = useMemo(() => {
    if (values?.location) {
      return values.location;
    }
    if (groupedMaterials.location) {
      return groupedMaterials.location;
    }
    if (locationOptions.length) {
      const index = locationOptions.length === 1 ? 0 : locationOptions.length - 1;
      return locationOptions[index].id;
    }
    return '';
  }, [groupedMaterials?.location, values?.location]);

  const surfaceValue = useMemo(() => {
    const surfaceName = values?.surface || groupedMaterials.surface || localSurfaces?.[0] || '';
    const surfaceObj = localSurfaces.find((el) => el.name === surfaceName);
    if (!surfaceObj) {
      setLocalSurfaces((prevValue) => [
        ...prevValue,
        {
          name: surfaceName,
          _id: uuidv4(),
          fallback: true,
        },
      ]);
      return surfaceName || '';
    }
    setSurfaceSelectValue(surfaceObj?._id || '');
    return surfaceObj?.name || surfaceName || '';
  }, [groupedMaterials?.surface, values?.surface, localSurfaces]);

  const handleChangeSurface = (e, name) => {
    const value = e?.target?.value || name;
    if (isMaterialForm) {
      setFieldValue('surface', value);
    } else {
      const prevSurface = surfaceValue;

      dispatch(
        patchSurfaceWorklogMaterial(sheet._id, {
          prevSurface,
          nextSurface: value,
          locationId: locationValue,
        })
      );
    }
  };

  const handleChangeSurfaceSelect = (e) => {
    const value = e.target.value;
    setSurfaceSelectValue(value);
    const surfaceName = localSurfaces.find((elem) => elem._id === value);
    handleChangeSurface(null, surfaceName.name);
    if (isMaterialForm) setFieldValue('surface', surfaceName.name);
  };

  return (
    <>
      <Typography variant="body1" color="textSecondary">
        Surface:
      </Typography>

      <TextField
        select
        name="surface"
        id="surface"
        value={surfaceSelectValue}
        onChange={handleChangeSurfaceSelect}
        className={classes.textField}
        variant="outlined"
        disabled={!isMaterialForm && !hasAccess}
        fullWidth
      >
        {localSurfaces.map((surfaceEl) => (
          <MenuItem key={surfaceEl._id} value={surfaceEl._id} className={classes.option}>
            {surfaceEl.name}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};

export default withStyles(styles)(SurfaceInput);
