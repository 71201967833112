import axios from 'axios';
import ProjectTypesActions from './projectTypesActions';
import { getTimeZone } from '../../helpers/getTimeZone';
import { PROJECTS_PER_PAGE } from './projectPageConstants';
import { catchError } from '../../http/index';

export const fetchProjects = (filters = {}, page = 0, searchValue = null, worklogHeaders) => async (dispatch, getState) => {

  const hasMore = getState().projectTypes.projects;

  if (!hasMore) return

  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'X-User-TZ': getTimeZone(),
    },
    params: {},
  };
  if (page >= 0) {
    options.params.skip = page * PROJECTS_PER_PAGE;
    options.params.limit = PROJECTS_PER_PAGE;
  }
  if (filters && Object.keys(filters).length) {
    if (filters.sortBy) options.params.sortBy = filters.sortBy;
    if (filters.sortOrder) options.params.sortOrder = filters.sortOrder;
    if (filters.shift) options.params.shift = filters.shift;
    if (filters.rated) options.params.rated = filters.rated;
    if (filters.status) options.params.status = filters.status;
    if (filters.typeName) options.params.type = filters.typeName;
  }

  if (searchValue) options.params.searchValue = searchValue;

  if (worklogHeaders?.id) options.headers['x-worklog-id'] = worklogHeaders?.id;
  if (worklogHeaders?.create) options.headers['x-worklog-create'] = worklogHeaders?.create;

  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/projects`, options);
    if(!page){
      dispatch(ProjectTypesActions.getProjectsSuccess({data: response.data, hasMore:response.data.length === PROJECTS_PER_PAGE}));
    }else {
      dispatch(ProjectTypesActions.getProjectsSuccessAdd({data: response.data, hasMore:response.data.length === PROJECTS_PER_PAGE}));
    }
  } catch (error) {
    dispatch(ProjectTypesActions.getProjectsError(error));
    catchError(error);
  }
};

export const patchProjectStatus = (data, projectId) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'X-User-TZ': getTimeZone(),
    },
  };
  try {
    await axios.patch(`${process.env.REACT_APP_BASE_URL}/projects/${projectId}/status`, data, options);
    dispatch(ProjectTypesActions.toggleProjectStatus(projectId));
  } catch (error) {
    dispatch(ProjectTypesActions.getProjectsError(error));
  }
};

export const fetchDeleteProject = (projectId) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'X-User-TZ': getTimeZone(),
    },
  };
  try {
    await axios.delete(`${process.env.REACT_APP_BASE_URL}/projects/${projectId}`, options);
    dispatch(ProjectTypesActions.deleteProject(projectId));
  } catch (error) {
    dispatch(ProjectTypesActions.getProjectsError(error));
  }
};

export const postCreateProject = (data) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'X-User-TZ': getTimeZone(),
    },
  };

    const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/projects`, data, options);
    dispatch(ProjectTypesActions.createNewProject(res.data));
};

export const postUpdateProject = (data, projectId) => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'X-User-TZ': getTimeZone(),
    },
  };

    const res = await axios.put(`${process.env.REACT_APP_BASE_URL}/projects/${projectId}`, data, options);
    dispatch(ProjectTypesActions.updateProject(res.data));

};

export const fetchProjectTypes = () => async (dispatch) => {
  const options = {
    headers: {
      'X-Auth-Token': localStorage.getItem('authorization-token'),
      'X-User-TZ': getTimeZone(),
    },
  };
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/project-types`, options);
    dispatch(ProjectTypesActions.getProjectTypes(response.data));
  } catch (error) {
    if (error.statusCode > 299) throw new Error(error.message);
  }
};

export const postProjectTypes = (data) => async (dispatch) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/project-types`,
      data,
      options
    );
    dispatch(ProjectTypesActions.createProjectType(response.data));
    dispatch(
      ProjectTypesActions.snackBarInfo({ type: 'success', text: 'Successfully Added!' })
    );
  } catch (error) {
    dispatch(
      ProjectTypesActions.snackBarInfo({
        type: 'error',
        text: 'Project Type with this name already exists in this system',
      })
    );
    if (error.statusCode > 299) throw new Error(error.message);
  }
};

export const putProjectTypes = (projectTypeId, data) => async (dispatch) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/project-types/${projectTypeId}`,
      data,
      options
    );
    dispatch(ProjectTypesActions.updateProjectTypes(response.data));
    dispatch(
      ProjectTypesActions.snackBarInfo({
        type: 'success',
        text: 'Successfully Updated!',
      })
    );
  } catch (error) {
    dispatch(
      ProjectTypesActions.snackBarInfo({
        type: 'error',
        text: 'Project Type with this name already exists in this system',
      })
    );
    if (error.statusCode > 299) throw new Error(error.message);
  }
};

export const deleteProjectType = (projectTypeId) => async (dispatch) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Token': localStorage.getItem('authorization-token'),
    },
  };
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/project-types/${projectTypeId}`,
      options
    );
    dispatch(ProjectTypesActions.deleteProjectType(projectTypeId));
    dispatch(
      ProjectTypesActions.snackBarInfo({
        type: 'success',
        text: 'Project Type successfully deleted',
      })
    );
  } catch (error) {
    if (error.statusCode > 299) throw new Error(error.message);
  }
};
