export default (theme) => ({
  locationSelect: {
    '& > div': {
      height: '34px !important',
    },
  },
  inputButton: {
    padding: 4,
    minWidth: 'auto',
    color: theme.palette.textColor.secondary,
    '&:hover': {
      color: theme.palette.textColor.secondary,
    },
    '&:focus': {
      color: theme.palette.textColor.secondary,
    },
  },
  primaryButton: {
    color: theme.palette.textColor.primary,
    '&:hover': {
      color: theme.palette.textColor.primary,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
    },
  },
  noHover: {
    '&:hover': {
      background: theme.palette.background.default,
    },
    '&:focus': {
      background: theme.palette.background.default,
    },
  },
  addWorkButton: {
    [theme.breakpoints.up('md')]: {
      marginTop: 15,
      textAlign: 'right',
    },
  },
  error: {
    color: theme.palette.textColor.red,
    padding: '0 5px',
    fontSize: 11,
  },
  requiredSign: {
    color: theme.palette.textColor.red,
  },

  textField: {
    [theme.breakpoints.up('lg')]: {
      // maxWidth: 250,
    },
  },
  locationSelect: {
    '& > div': {
      height: '34px !important',
    },
  },
  inlineFlex: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  inputButton: {
    padding: 4,
    minWidth: 'auto',
    color: theme.palette.textColor.secondary,
    '&:hover': {
      color: theme.palette.textColor.secondary,
    },
    '&:focus': {
      color: theme.palette.textColor.secondary,
    },
  },
  primaryButton: {
    color: theme.palette.textColor.primary,
    '&:hover': {
      color: theme.palette.textColor.primary,
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
    },
  },
  addButton: {
    padding: 4,
    minWidth: 'auto',
    height: '28.8px',
    color: theme.palette.textColor.primary,
    '&:hover': {
      color: theme.palette.textColor.primary,
      background: 'transparent',
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      background: 'transparent',
    },
  },
  deleteButton: {
    minWidth: 24,
    height: 24,
    padding: 5,
    '&:hover': {
      background: `${theme.palette.background.default}!important`,
    },
    '&:focus': {
      background: `${theme.palette.background.default}!important`,
    },
  },
  option: {
    fontSize: 14,
    '&:hover': {
      height: '28.8px',
    },
    '&:hover $iconButton': {
      display: 'block',
    },
  },
  iconButton: {
    display: 'none',
    '&.MuiButton-root': {
      minWidth: 0,
    },
  },
  inputAdor: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogRoot: {
    minWidth: '90%',
    [theme.breakpoints.up('sm')]: {
      minWidth: 380,
    },
  },
  flexBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  cancelButton: {
    textTransform: 'capitalize',
    margin: '0 20px',
    minWidth: 100,
    '&:hover': {
      color: 'inherit',
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  saveButton: {
    textTransform: 'capitalize',
    margin: '0 20px',
    minWidth: 100,
    '&:hover': {
      color: theme.palette.textColor.primary,
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  error: {
    color: theme.palette.textColor.red,
    padding: '0 5px',
    fontSize: 11,
  },
  optionsIcons: {
    width: 10,
    height: 10,
  },
});
