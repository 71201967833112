import React, { useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  Divider,
  Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classnames from 'classnames';
import { parse, format } from 'date-fns';

import SearchForm from './components/SearchForm';
import UsedResources from './components/UsedResources';
import ResourcesList from './components/ResourcesList';
import PageLoader from '../../../../../../components/PageLoader';
import DatePicker from '../../../../../../components/DatePicker';

import styles from './styles';
import TimeForm from './components/TimeForm';
import { getNextDay } from '../../../../../../components/Kiosk/helpers/getNextDay';
import { getOptions } from 'helpers/getOptions';
import $api from 'http/index';
import { getEquipmentEvents, getTimeoffs } from 'store/availability/availabilityPagesOperations';
import { useDispatch } from 'react-redux';
import { useWeekStart } from 'hooks/useWeekStart';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { globalBEDateFormat, DAY_PART } from 'common/dateFormatConfig';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';

const AssignModal = ({
  classes,
  type,
  onClose,
  events,
  defaultEventInfo,
  holidays = [],
  openSnackbar,
  openInfoModal,
}) => {
  const dispatch = useDispatch();
  const defaultTimeState = useMemo(() => ({ hour: '8', minute: '00', amPm: DAY_PART[0] }), []);
  const { weekDayFromO } = useWeekStart();
  const { dateFormat, dateFormatWithTime } = useDateSettingsFormat();
  const getTimeFormatHook = useTimeSettingsFormat();
  const [eventInfo, setEventInfo] = useState(defaultEventInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedResources, setSelectedResources] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [selectedTime, setSelectedTime] = useState([
    {
      date: format(parse(eventInfo.dayNumber, globalBEDateFormat, new Date()), dateFormat),
      time: defaultTimeState,
    },
    {
      date: getNextDay(parse(eventInfo.dayNumber, globalBEDateFormat, new Date()), dateFormat),
      time: defaultTimeState,
    },
  ]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [calendarOpenedForInput, setCalendarOpenedForInput] = useState(-1);
  const [allHolidays, setAllHolidays] = useState(holidays);

  const flatEvents = useMemo(() => events.reduce((res, e) => [...res, ...e], []), [events]); //!
  const selectedDates = useMemo(() => {
    return selectedTime.map((t) => t.date);
  }, [selectedTime]);
  const headerCounter = useMemo(() => {
    const item1 = type === 'people' ? 'user' : 'equipment';
    const item2 = selectedResources.length === 1 ? '' : 's';
    return `${selectedResources.length} ${item1}${item2} selected`;
  }, [type, selectedResources]);

  const mapResources = (resources) => {
    const mappedResources = resources.map((r) => {
      r.dates = selectedDates;

      const [startDate, endDate] = selectedDates;
      const [startDay, startMonth, startYear] = startDate.split('/');
      const [endDay, endMonth, endYear] = endDate.split('/');

      const [startTime, endTime] = selectedTime.map((t) => t.time);

      const startHoursObj = [startDate, startTime.hour, startTime.minute, startTime.amPm];

      r.utcStartDate = parse(
        getTimeFormatHook(startHoursObj).formattedTimeDate,
        getTimeFormatHook(startHoursObj).formatForDateTimePars,
        new Date()
      );
      // r.utcStartDate = parse(
      //   `${startMonth}-${startDay}-${startYear} ${startTime.hour}:${startTime.minute} ${startTime.amPm}`,dateFormatWithTime, new Date()
      // );
      const endHoursObj = [endDate, endTime.hour, endTime.minute, endTime.amPm];

      // r.utcEndDate = parse(
      //   `${endMonth}-${endDay}-${endYear} ${endTime.hour}:${endTime.minute} ${endTime.amPm}`,dateFormatWithTime, new Date()
      // );
      r.utcEndDate = parse(
        getTimeFormatHook(endHoursObj).formattedTimeDate,
        getTimeFormatHook(endHoursObj).formatForDateTimePars,
        new Date()
      );
      r.startTime = {
        hour: startTime.hour,
        minute: startTime.minute,
        // amPm: startTime.amPm,
      };
      r.endTime = {
        hour: endTime.hour,
        minute: endTime.minute,
        // amPm: endTime.amPm,
      };
      return r;
    });
    setSelectedResources(mappedResources);
  };
  useEffect(() => {
    mapResources(selectedResources);

    const eventForSelectedStartDate = flatEvents.find((e) => e.dayNumber === selectedTime[0].date);
    if (eventForSelectedStartDate) return setEventInfo(eventForSelectedStartDate);
  }, [selectedTime]);

  const updateResources = (item) => () => {
    if (selectedResources.find((el) => el.id === item.id)) {
      mapResources(selectedResources.filter((el) => el.id !== item.id));
      return;
    }
    mapResources([...selectedResources, item]);
  };

  const createEvents = async () => {
    if (!selectedResources.length) return onClose();
    setIsLoading(true);

    if (type === 'people') {
      try {
        const data = {
          userIds: selectedResources.map((el) => el.id),
          utcStartDate: selectedResources[0].utcStartDate,
          utcEndDate: selectedResources[0].utcEndDate,
          startTime: selectedResources[0].startTime,
          endTime: selectedResources[0].endTime,
        };

        const res = await $api.post(
          `${process.env.REACT_APP_BASE_URL}/timeoffs`,
          data,
          getOptions()
        );

        if (res?.data?.[0]?.message) {
          openInfoModal(res?.data?.[0]?.message);
        }

        dispatch(getTimeoffs(null, null, weekDayFromO));
      } catch (e) {
        openSnackbar('error', e.message);
      }
    } else {
      try {
        const data = {
          resourceIds: selectedResources.map((el) => el.id),
          utcStartDate: selectedResources[0].utcStartDate,
          utcEndDate: selectedResources[0].utcEndDate,
          startTime: selectedResources[0].startTime,
          endTime: selectedResources[0].endTime,
        };

        await $api.post(`${process.env.REACT_APP_BASE_URL}/calendar-events`, data, getOptions());
        dispatch(getEquipmentEvents());
      } catch (e) {
        openSnackbar('error', e.message);
      }
    }
    onClose();
  };

  const updateAllSelectedFlag = () => {
    setIsChanged(true);
    setIsAllSelected((prev) => !prev);
  };

  const toggleCalendar = (value = -1) => setCalendarOpenedForInput(value);

  const handleDateSelect = (date, options) => {
    const equalToStartDate =
      calendarOpenedForInput !== 0 &&
      options.selected &&
      format(date, dateFormat) === selectedTime[0].date;
    if (options.disabled || equalToStartDate) return;
    const formattedDate = format(date, dateFormat);

    setSelectedTime((prev) => [
      {
        ...prev[0],
        date: 0 === calendarOpenedForInput ? formattedDate : prev[0].date,
      },
      {
        ...prev[1],
        date:
          1 === calendarOpenedForInput
            ? formattedDate
            : getNextDay(parse(formattedDate, dateFormat, new Date()), dateFormat),
      },
    ]);
  };

  const handleDateReset = () => {
    setSelectedTime((prev) => [
      { ...prev[0], date: defaultEventInfo.dayNumber },
      {
        ...prev[1],
        date: getNextDay(parse(defaultEventInfo.dayNumber, dateFormat, new Date()), dateFormat),
      },
    ]);
  };

  const getIndividualHolidays = () => {
    const individual = allHolidays.filter(
      (h) => h.inDays.includes(eventInfo.dayNumber) && h.isIndividual
    );

    return individual.reduce(
      (res, holiday) => [...res, ...holiday.userIds.map((userId) => ({ ...holiday, userId }))],
      []
    );
  };

  const eventsWithIndividualHolidays = useMemo(
    () => [...getIndividualHolidays(), ...eventInfo.events],
    [eventInfo, allHolidays]
  );

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        classes: {
          root: classes.dialogRoot,
        },
      }}
      backdropclick={true}
      disableEscapeKeyDown={true}
    >
      <PageLoader loading={isLoading}>
        <>
          <div className={classes.header}>
            <Typography variant="h4">Assign {type}</Typography>
            <IconButton
              className={classes.closeButton}
              onClick={onClose}
              aria-label="Close"
              disableRipple
            >
              <CloseIcon />
            </IconButton>
          </div>

          <TimeForm
            values={selectedTime}
            setValues={setSelectedTime}
            openCalendar={(v) => () => toggleCalendar(v)}
          />

          <div className={classes.content}>
            <Divider />
            <SearchForm searchValue={searchValue} updateSearchValue={setSearchValue} />
            <Divider />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ padding: '10px 16px' }}
            >
              <Typography className={classes.selectedCount}>{headerCounter}</Typography>
              <Button
                color="secondary"
                variant="text"
                className={classnames(classes.extraBtn, classes.selectBtn)}
                onClick={updateAllSelectedFlag}
                disableTouchRipple
              >
                {isAllSelected ? 'Clear' : 'Select'} all
              </Button>
            </Box>
            <Divider />
          </div>

          <DialogContent classes={{ root: classes.content }}>
            <ResourcesList
              type={type}
              usedResources={eventsWithIndividualHolidays}
              selectedResources={selectedResources}
              updateResources={updateResources}
              selectedDates={selectedDates}
              isAllSelected={isAllSelected}
              updateAllResources={mapResources}
              searchValue={searchValue}
              isChanged={isChanged}
            />
          </DialogContent>

          <DialogActions classes={{ root: classes.rootActions }}>
            <Button
              onClick={onClose}
              variant="outlined"
              className={classes.cancelBtn}
              disableTouchRipple
            >
              Cancel
            </Button>
            <Button
              onClick={createEvents}
              variant="outlined"
              className={classes.confirmBtn}
              disableTouchRipple
            >
              Assign
            </Button>
          </DialogActions>
        </>
      </PageLoader>
      <div className={classes.leftSidebar}>
        <UsedResources
          event={eventInfo}
          type={type}
          holidays={allHolidays}
          eventsWithIndividualHolidays={eventsWithIndividualHolidays}
        />
      </div>

      {calendarOpenedForInput >= 0 && (
        <div className={classes.rightSidebar}>
          <DatePicker
            showPeriod={calendarOpenedForInput === 1}
            startValue={parse(selectedDates[0], dateFormat, new Date())}
            value={parse(selectedDates[calendarOpenedForInput], dateFormat, new Date())}
            toggleCalendar={toggleCalendar}
            handleSelect={handleDateSelect}
            handleReset={handleDateReset}
          />
        </div>
      )}
    </Dialog>
  );
};

AssignModal.propTypes = {
  classes: propTypes.object.isRequired,
  type: propTypes.string.isRequired,
  onClose: propTypes.func.isRequired,
  defaultEventInfo: propTypes.object.isRequired,
  events: propTypes.array.isRequired,
  openSnackbar: propTypes.func.isRequired,
  openInfoModal: propTypes.func,
};

export default withStyles(styles)(AssignModal);
