import React from 'react';
import propTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconSunny from 'assets/images/icon-sunny.svg';
import IconMoon from 'assets/images/icon-moon.svg';
import PageLoader from '../../../../../../components/PageLoader';
import styles from './styles';
import { isIOS, Option } from '../../../../../../components/Option';

const SortingAndFilteringPanel = ({
  classes,
  closeDrawer,
  filter,
  updateFilter,
  initialStatus,
  projectOptions,
}) => {
  const defaultValues = {
    sortBy: 'Job Number',
    sortOrder: 'asc',
    shift: 'All',
    rated: 'All',
    status: initialStatus,
    typeName: 'All',
  };

  const sortingTypeLabel = (sortBy) => {
    switch (sortBy) {
      case 'createdAt':
        return ['Newest \u2013 Oldest', 'Oldest \u2013 Newest'];
      case 'ID':
        return ['0 \u2013 9', '9 \u2013 0'];
      default:
        return ['A \u2013 Z', 'Z \u2013 A'];
    }
  };

  return (
    <div className={classes.formWrapper} role="presentation">
      <Formik
        initialValues={filter}
        enableReinitialize={true}
        onSubmit={(values) => {
          updateFilter(values);
        }}
      >
        {({ values, handleChange, resetForm }) => (
          <PageLoader loading={false}>
            <Form autoComplete="off" className={classes.formFlex}>
              <div>
                <Typography variant="h3" className={classes.marginHeader}>
                  Filters
                </Typography>

                <IconButton
                  className={classes.closeButton}
                  onClick={closeDrawer}
                  aria-label="Close"
                  disableTouchRipple
                >
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={2} className={classes.flexContainer}>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="sortBy" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Sort by:
                      </Typography>
                      <TextField
                        select
                        id="sortBy"
                        name="sortBy"
                        value={values.sortBy}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Sort by' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={5} value="Contractor">
                          Contractor
                        </Option>
                        <Option key={4} value="County">
                          County
                        </Option>
                        <Option key={6} value="Id">
                          ID
                        </Option>
                        <Option key={1} value="Job Number">
                          Job number
                        </Option>
                        <Option key={2} value="Route">
                          Route
                        </Option>
                        <Option key={3} value="Section">
                          Section
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="sortOrder" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Sort from:
                      </Typography>
                      <TextField
                        select
                        id="sortOrder"
                        name="sortOrder"
                        value={values.sortOrder}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Days' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={1} value="asc">
                          {sortingTypeLabel(values.sortBy)[0]}
                        </Option>
                        <Option key={2} value="desc">
                          {sortingTypeLabel(values.sortBy)[1]}
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                </Grid>

                <Divider className={classes.marginVertical} />

                <Grid container spacing={2} className={classes.flexContainer}>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="shift" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Shift:
                      </Typography>
                      <TextField
                        select
                        id="shift"
                        name="shift"
                        value={values.shift}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Shift' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={1} value="All">
                          All
                        </Option>
                        <Option key={2} value="Day">
                          {isIOS ? (
                            '🌤 '
                          ) : (
                            <img src={IconSunny} alt="sunny" className={classes.shiftImage} />
                          )}
                          Day
                        </Option>
                        <Option key={3} value="Night">
                          {isIOS ? (
                            '🌙 '
                          ) : (
                            <img src={IconMoon} alt="moon" className={classes.shiftImage} />
                          )}
                          Night
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="rated" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Certified Payroll:
                      </Typography>
                      <TextField
                        select
                        id="rated"
                        name="rated"
                        value={values.rated}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Rated' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={1} value="All">
                          All
                        </Option>
                        <Option key={2} value={'Yes'}>
                          Yes
                        </Option>
                        <Option key={3} value={'No'}>
                          No
                        </Option>
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.fieldWrapper}>
                    <label htmlFor="typeName" className={classes.label}>
                      <Typography variant="body1" color="textSecondary">
                        Type:
                      </Typography>
                      <TextField
                        select
                        id="typeName"
                        name="typeName"
                        value={values.typeName}
                        onChange={handleChange}
                        className={classes.textField}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'Type' }}
                        fullWidth
                        SelectProps={{
                          native: isIOS,
                        }}
                      >
                        <Option key={1} value="All">
                          All
                        </Option>
                        {projectOptions
                          ?.sort((a, b) => a.name.localeCompare(b.name))
                          ?.map((option) => (
                            <Option key={option._id} value={option.name}>
                              {option.name}
                            </Option>
                          ))}
                      </TextField>
                    </label>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      color="primary"
                      className={classes.resetButton}
                      onClick={() => resetForm({ values: defaultValues })}
                      disableTouchRipple
                    >
                      Reset all filters
                    </Button>
                  </Grid>
                </Grid>
              </div>

              <div className={classes.buttonHolder}>
                <Button color="secondary" className={classes.cancelButton} onClick={closeDrawer}>
                  Cancel
                </Button>

                <div className={classes.verticalDivider} />

                <Button type="submit" color="primary" className={classes.saveButton}>
                  Apply
                </Button>
              </div>
            </Form>
          </PageLoader>
        )}
      </Formik>
    </div>
  );
};

SortingAndFilteringPanel.propTypes = {
  classes: propTypes.object.isRequired,
  closeDrawer: propTypes.func.isRequired,
  filter: propTypes.object.isRequired,
  updateFilter: propTypes.func.isRequired,
  isLoading: propTypes.bool.isRequired,
};

export default withStyles(styles)(SortingAndFilteringPanel);
