export default (theme) => ({
  dialogRoot: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 390,
      minHeight: 475,
      maxHeight: '90vh',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      margin: 0,
      minHeight: '100%',
    },
  },
  formWrapper: {
    padding: '20px 16px 0',
    [theme.breakpoints.down('sm')]: {
      minWidth: 320,
    },
    [theme.breakpoints.up('md')]: {
      width: 390,
    },
  },
  headerText: {
    fontSize: 20,
    fontWeight: 500,
    maxWidth: 'calc(100% - 30px)',
    marginBottom: 20,
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 15,
    padding: 5,
  },
  buttonHolder: {
    textAlign: 'center',
  },
  assignHolder: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 33,
    marginTop: 6,
  },
  assignHolderSm: {
    marginTop: 36,
  },
  cancelButton: {
    margin: 20,
    minWidth: 100,
    '&:hover': {
      color: 'inherit',
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  saveButton: {
    margin: 20,
    minWidth: 100,
    '&:hover': {
      color: theme.palette.textColor.primary,
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
  formFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 450,
  },
  holiday: {
    overflow: 'visible',
  },
  selectedText: {
    fontSize: 14,
    fontWeight: 500,
  },
});
