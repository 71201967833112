import React from 'react';
import '../../styles.scss';
import InfoItem from '../InfoItem/InfoItem';
import isCorrectNumber from '../helpers/isCorrectNumber';

function FieldTechRole({ data }) {
  return (
    <>
      {data && (
        <>
          <h2 className="info-block-title">Projects</h2>
          <div className="cards-wrapper">
            {!!data.sheets && (
              <>
                <InfoItem
                  quantity={isCorrectNumber(data.sheets.week)}
                  info={'This week'}
                  sizeClass={'small'}
                />
                <InfoItem
                  quantity={isCorrectNumber(data.sheets.month)}
                  info={'This month'}
                  sizeClass={'small'}
                />
                <InfoItem
                  quantity={isCorrectNumber(data.sheets.year)}
                  info={'This year'}
                  sizeClass={'small'}
                />
              </>
            )}
          </div>
          <h2 className="info-block-title">Distance Striped YTD</h2>
          <div className="cards-wrapper">
            {!!data.distanceStriped && (
              <>
                <InfoItem
                  quantity={isCorrectNumber(data.distanceStriped.liquidMarking)}
                  info={'Liquid Marking'}
                  currency={'LF'}
                />
                <InfoItem
                  quantity={isCorrectNumber(data.distanceStriped.eradication)}
                  info={'Eradication'}
                  currency={'LF'}
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default FieldTechRole;
