import React, { useState, useEffect, useCallback } from 'react';
import propTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import { useSelector, useDispatch } from 'react-redux';
import ContractorsList from './components/ContractorsList';
import ContractorForm from './components/ContractorForm';
import SortingAndFilteringPanel from './components/SortingAndFilteringPanel';
import FiltersBar from '../../../../components/FiltersBar';
import PageLoader from '../../../../components/PageLoader';
import { drawerTypes } from '../../../Equipment/components/helpers/drawerTypes';
import { fetchContractorsData } from 'store/contractors/contractorsPageOperations';
import { useMemo } from 'react';
import { hasPermissionsFor } from 'helpers/_helpers';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const ContractorsPage = ({ openSnackbar, classes }) => {
  const [drawer, setDrawer] = useState({
    type: drawerTypes.filters,
    isOpen: false,
    contractor: {},
  });
  const getFilterStatusName = (index) => {
    return index === 0 ? 'Active' : 'Inactive';
  };

  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  const [userFilter, setUserFilter] = useState({
    sortBy: 'Name',
    sortOrder: 'asc',
    status: getFilterStatusName(tabValue),
  });

  useEffect(() => {
    updateVisibleContractors(userFilter, 0, searchValue);
  }, [tabValue]);

  const { data: contractors, isLoading, hasMore } = useSelector((store) => store.contractors);

  const filteredContractors = useMemo(
    () =>
      contractors.filter((contractor) =>
        tabValue === 0 ? contractor.active === true : contractor.active === false
      ),
    [contractors, tabValue]
  );

  const updateSearchValue = useCallback((value) => {
    setSearchValue(value);
    setPage(0);
    updateVisibleContractors(userFilter, 0, value);
  });

  const updateFilter = (filter) => {
    setUserFilter(filter);
    setPage(0);
    updateVisibleContractors(filter, 0, searchValue);
    closeDrawer();
  };

  const updateProjectsByPage = () => {
    updateVisibleContractors(userFilter, page, searchValue);
  };

  const updateVisibleContractors = useCallback(
    async (filter, pages, searchValue) => {
      setLoading(true);
      const usedFilter = filter || userFilter;

      await dispatch(fetchContractorsData(usedFilter, pages, searchValue))
        .then((e) => setPage(pages + 1))
        .catch((err) => console.log(''));
      setLoading(false);
      setInitialLoad(false);
    },
    [userFilter]
  );

  const openDrawer =
    (type, contractor = {}) =>
    () => {
      setDrawer({ type, isOpen: true, contractor });
    };

  const closeDrawer = () => {
    setDrawer({ type: 'filters', isOpen: false, contractor: {} });
  };

  const hasFullAccess = useMemo(() => hasPermissionsFor('projectsFullAccess'), []);

  const handleChangeTab = (event, newValue) => {
    if (newValue === tabValue) return;
    setTabValue(newValue);
    setUserFilter((prevFilter) => ({ ...prevFilter, status: getFilterStatusName(newValue) }));
  };

  return (
    <div>
      <Tabs value={tabValue} onChange={handleChangeTab} className={classes.tabs}>
        <Tab label="Active" disableRipple />
        <Tab label="Inactive" disableRipple />
      </Tabs>
      <FiltersBar
        openDrawer={openDrawer}
        updateSearchValue={updateSearchValue}
        isDynamicSearch={true}
      />

      <PageLoader loading={loading} style={{ overflow: 'auto' }}>
        <ContractorsList
          openDrawer={openDrawer}
          contractors={filteredContractors}
          updateProjectsByPage={updateProjectsByPage}
          hasMore={hasMore}
          hasFullAccess={hasFullAccess}
          page={page}
          loading={loading}
          openSnackbar={openSnackbar}
          initialLoad={initialLoad}
        />
      </PageLoader>

      <Drawer anchor="right" open={drawer.isOpen}>
        {drawer.type !== drawerTypes.filters ? (
          <ContractorForm
            closeDrawer={closeDrawer}
            openSnackbar={openSnackbar}
            contractor={drawer.contractor}
            type={drawer.type}
          />
        ) : (
          <SortingAndFilteringPanel
            closeDrawer={closeDrawer}
            filter={userFilter}
            updateFilter={updateFilter}
            isLoading={isLoading}
            initialStatus={getFilterStatusName(tabValue)}
          />
        )}
      </Drawer>
    </div>
  );
};

ContractorsPage.propTypes = {
  openSnackbar: propTypes.func.isRequired,
  contractors: propTypes.array.isRequired,
  isDataLoading: propTypes.bool.isRequired,
};

export default ContractorsPage;
