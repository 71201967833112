import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers } from 'store/people/peoplePageOperations';
import Fuse from 'fuse.js';
import AdminRole from './AdminRole/AdminRole';
import InputSearchBar from './InputSearchBar/InputSearchBar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const searchOptions = {
  shouldSort: true,
  keys: [
    'username',
    'profile.HICode',
    'profile.fullName',
    'profile.phoneNumber',
    'profile.employeeNum',
  ],
};

const AdminRoleWrapper = () => {
  const dispatch = useDispatch();
  const users = useSelector((store) => store.people.users);

  const [fuse, setFuse] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [doWeShowSearch, setDoWeShowSearch] = useState(false);

  useMemo(() => {
    setFuse(new Fuse(users, searchOptions));
  }, [users]);

  useEffect(() => {
    dispatch(fetchUsers({}, 0, Infinity, true));
  }, []);

  const searchedUsers = fuse && searchValue ? fuse.search(searchValue) : users;

  return (
    <>
      <ClickAwayListener onClickAway={() => setDoWeShowSearch(false)}>
        <div>
          <div className="admin-role-input-wrapper">
            <InputSearchBar
              updateSearchValue={setSearchValue}
              setDoWeShowSearch={setDoWeShowSearch}
              noFilters={true}
            />
          </div>
          <AdminRole
            searchedUsers={searchedUsers}
            setDoWeShowSearch={setDoWeShowSearch}
            doWeShowSearch={doWeShowSearch}
          />
        </div>
      </ClickAwayListener>
    </>
  );
};

export default AdminRoleWrapper;
