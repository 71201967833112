export default theme => ({
  calendarWrapper: {
    margin: '4px 0 10px 0'
  },
  dayName: {
    fontWeight: 300
  },
  regularFontWeight: {
    fontWeight: 300
  },
  noResults: {
    margin: '1.72em 0 2em',
    opacity: 0.5
  },
  inlineBlock: {
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  marginRight: {
    marginRight: 24
  },
  marginLeft: {
    marginLeft: 24
  }
});
