import io from 'socket.io-client';
import { getItem } from './localStorage';

const connectionOptions = {
  forceNew: true,
  reconnectionAttempts: 'Infinity', //avoid having user reconnect manually in order to prevent dead clients after a server restart
  timeout: 20000, //before connect_error and connect_timeout are emitted.
  transports: ['websocket'],
  secure: true,
  auth: (cb) => cb({
    token: getItem('authorization-token'),
  }),
};

class Sockets {
  _vault;
  _socketConsumers;
  constructor() {
    this._vault = new Map();
    this._socketConsumers = {};
  }

  openSocket(namespace) {
    const socket = io(`${process.env.REACT_APP_WS_URL}/${namespace}`, connectionOptions);
    this._vault.set(namespace, socket);
    return socket;
  }

  getSocket(namespace, skip) {
    if (!skip) {
      this._socketConsumers[namespace] = (this._socketConsumers[namespace] || 0) + 1
    };

    const socketInVault = this._vault.get(namespace);
    if (socketInVault) {
      if (socketInVault?.connected) return socketInVault
      return socketInVault.connect(connectionOptions);
    }

    if (skip) return;
    return this.openSocket(namespace)
  }

  emitEvent(namespace, event, payload) {
    const socket = this._vault.has(namespace);

    if (socket) {
      if (socket?.connected) {
        socket.emit(event, payload);
        socket.close();
      } else {
        const socketInVault = this._vault.get(namespace);
        socketInVault?.connect(connectionOptions);
        socketInVault.on('connect', () => {
          socketInVault.emit(event, payload);
          socketInVault.close();
        });
      }
    } else {
      this.openSocket(namespace);
      this.emitEvent(namespace, event, payload);
    }
  }

  closeSocket(namespace) {
    // Do not close connection, if there are other socket consumers
    if (this._socketConsumers[namespace] > 1) {
      this._socketConsumers[namespace] = this._socketConsumers[namespace] - 1;
      return;
    }

    this._socketConsumers[namespace] = 0;
    const socketInVault = this._vault.get(namespace);
    if (socketInVault) {
      socketInVault.removeAllListeners();
      socketInVault.close();
      this._vault.delete(namespace);
    }
  }

  getConsumers(namespace){
    return this._socketConsumers[namespace]
  }
}

export default new Sockets();
