import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import classnames from 'classnames';

import styles from './styles';
import { Controller } from 'react-hook-form';

const RightBlock = ({ classes, pageType, errors, control }) => {
  const { allowedTimeoff = 0, headquartersLocation = '' } = useSelector(
    (state) => state.settings.settings
  );

  const renderAllowedTimeoff = () => {
    if (pageType === 'read') {
      return (
        <div>
          <Typography variant="body1" color="textSecondary" className={classes.regularFontWeight}>
            Default PTO
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            className={classnames(classes.fieldValue, classes.paddingTop)}
          >
            {`${allowedTimeoff} hours`}
          </Typography>
        </div>
      );
    }
    return (
      <label htmlFor="allowedTimeoff">
        <Typography variant="body1" color="textSecondary" className={classes.regularFontWeight}>
          Default PTO
        </Typography>
        <span className={classes.proHours}>
          <Controller
            name="allowedTimeoff"
            control={control}
            defaultValue={allowedTimeoff}
            as={
              <TextField
                id="allowedTimeoff"
                type="number"
                className={classes.textField}
                variant="outlined"
                inputProps={{ 'aria-label': 'Default PTO' }}
              />
            }
          />
          <Typography variant="body1" className={classes.ptoHoursText}>
            hours
          </Typography>
        </span>
      </label>
    );
  };

  const renderHQLocation = () => {
    if (pageType === 'read') {
      return (
        <div>
          <Typography variant="body1" color="textSecondary" className={classes.regularFontWeight}>
            HQ Location:
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            className={classnames(classes.fieldValue, classes.paddingTop)}
          >
            {headquartersLocation}
          </Typography>
        </div>
      );
    } else {
      return (
        <label htmlFor="hqLocation">
          <Typography variant="body1" color="textSecondary" className={classes.regularFontWeight}>
            HQ Location:
          </Typography>
          <Controller
            name="hqLocation"
            control={control}
            defaultValue={headquartersLocation}
            as={
              <TextField
                id="hqLocation"
                name="hqLocation"
                className={classes.textField}
                variant="outlined"
                inputProps={{ 'aria-label': 'Headquarter Location' }}
                fullWidth={true}
              />
            }
          />
        </label>
      );
    }
  };

  return (
    <Grid item xs={12} md={6}>
      <Grid container spacing={4}>
        <Grid container item xs={7}>
          <Grid item xs={10}>
            {renderHQLocation()}
          </Grid>
        </Grid>
        <Grid container item xs={6}>
          <Grid item xs={10}>
            {renderAllowedTimeoff()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

RightBlock.propTypes = {
  classes: propTypes.object.isRequired,
  pageType: propTypes.string.isRequired,
  errors: propTypes.object.isRequired,
  control: propTypes.object.isRequired,
};

export default withStyles(styles)(RightBlock);
