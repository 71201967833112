import { KIOSK_EVENTS } from './kioskEvents.sockets';
import Sockets from 'helpers/sockets';
import { EVENT_NAMESPACES } from 'helpers/eventNamespaces.sockets';

export const subscribeToKioskEvents = ({ handleUsernameUpdate, handleUserTimeOfDayUpdate }) => {
  // const socket = Sockets.getSocket(EVENT_NAMESPACES.kiosk);
  // socket.on(KIOSK_EVENTS.updateUsername, handleUsernameUpdate);
  // socket.on(KIOSK_EVENTS.updateUserTimeOfDay, handleUserTimeOfDayUpdate);

  return () => {
  //   socket.off(KIOSK_EVENTS.updateUsername);
  //   socket.off(KIOSK_EVENTS.updateUserTimeOfDay);
  //   socket.close();
  };
};
