import React, { Component } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import classnames from 'classnames';
import { startOfDay, endOfDay, isBefore, parse, startOfMonth, endOfMonth, isAfter } from 'date-fns';
import UserListForADay from './components/UserListForADay';
import EquipmentListForADay from './components/EquipmentListForADay';
import styles from './styles';
import { connect } from 'react-redux';
import { dateSettingsFormat, globalBEDateFormat } from 'common/dateFormatConfig';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.textColor.secondary,
  },
  body: {
    fontSize: 14,
  },
  root: {
    border: '1px solid #dadbe3',
    padding: '0 8px',
    '&:last-child': {
      paddingRight: 8,
    },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#fff',
    },
    '& > th': {
      border: 'none',
      paddingBottom: 10,
    },
  },
}))(TableRow);

class CalendarTable extends Component {
  weekDaysSun = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  weekDaysMon = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

  isNotCurrentMonth = (date) => {
    const startOfMonth = startOfMonth(this.props.selectedDate);
    const endOfMonth = endOfMonth(this.props.selectedDate);
    const currentDate = parse(
      date,
      dateSettingsFormat(this.props.dateFormat).dateFormat,
      new Date()
    );
    return isAfter(currentDate, endOfMonth) || isBefore(currentDate, startOfMonth);
  };
  isInPast = (day) => {
    const currentDay = endOfDay(
      parse(day, globalBEDateFormat, new Date())
    );
    const today = startOfDay(new Date());
    return isBefore(currentDay, today);
  };

  render() {
    const {
      classes,
      selectedDate,
      userEvents,
      equipmentEvents,
      openDrawer,
      openSnackbar,
      openModal,
      openAssignModal,
      holidays,
      weekStart,
    } = this.props;

    const renderDays = weekStart === 'Sun' ? this.weekDaysSun : this.weekDaysMon;
    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <StyledTableRow>
              {renderDays.map((el, i) => (
                <StyledTableCell key={i} align="center" component="th" scope="row">
                  {el}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {userEvents
              ? userEvents.map((row, i) => (
                  <StyledTableRow key={i}>
                    {row.map((event) => (
                      <StyledTableCell
                        key={event.dayNumber}
                        className={classnames(
                          classes.zeroPadding,
                          this.isInPast(event.dayNumber) && classes.greyBackground
                        )}
                      >
                        <UserListForADay
                          event={event}
                          selectedDate={selectedDate}
                          openDrawer={openDrawer}
                          openSnackbar={openSnackbar}
                          isNotCurrentMonth={this.isNotCurrentMonth}
                          openModal={openModal}
                          openAssignModal={openAssignModal}
                          isInPast={this.isInPast}
                          holidays={holidays}
                        />
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))
              : equipmentEvents.map((row, i) => (
                  <StyledTableRow key={i}>
                    {row.map((event) => (
                      <StyledTableCell
                        key={event.dayNumber}
                        className={classnames(
                          classes.zeroPadding,
                          this.isInPast(event.dayNumber) && classes.greyBackground
                        )}
                      >
                        <EquipmentListForADay
                          event={event}
                          selectedDate={selectedDate}
                          openSnackbar={openSnackbar}
                          isNotCurrentMonth={this.isNotCurrentMonth}
                          openAssignModal={openAssignModal}
                          isInPast={this.isInPast}
                        />
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

CalendarTable.propTypes = {
  classes: propTypes.object.isRequired,
  selectedDate: propTypes.object.isRequired,
  userEvents: propTypes.array,
  equipmentEvents: propTypes.array,
  openDrawer: propTypes.func,
  openSnackbar: propTypes.func.isRequired,
  openModal: propTypes.func,
  openAssignModal: propTypes.func.isRequired,
};

export default connect((state) => ({
  dateFormat: state.personalProfile.organization?.settings?.dateFormat,
}))(withStyles(styles)(CalendarTable));
