import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import CrossIcon from 'components/icons/Cross';
import IconButton from 'components/core/IconButton';
import IconSunny from 'assets/images/icon-sunny.svg';
import IconMoon from 'assets/images/icon-moon.svg';
import EditIcon from 'assets/images/edit-icon.svg';
import HICodes from 'pages/People/components/UserForm/components/UserDetails/components/HICodes'

import { isIOS, Option } from 'components/Option';

const UserDetails = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  handleEmergencyName,
  deleteContact,
  handleEmergencyPhoneNumber,
  handleEmergencyAddress,
  addNewEmergencyContact,
  openNewEmergencyBlock,
  isNewEmergencyBlock,
  closeNewEmergencyBlock,
  setFieldTouched,
  validateForm,
  validateField,
  setUpNewPass,
  isAdmin,
  emergencyError,
  handleNewEmergencyName,
  handleNewEmergencyPhoneNumber,
  handleNewEmergencyRelationship,
  openDrawer,
  openSnackbar,
}) => {
  const [showPassword, setPasswordVisibility] = useState(false);
  const [isInfoOpen, setInfoOpen] = useState(false);

  const handleClickShowPassword = () => {
    setPasswordVisibility(!showPassword);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={3}>
          <label htmlFor="name">
            <Typography variant="body1" color="textSecondary">
              Username:
            </Typography>
            <TextField
              type="text"
              id="name"
              disabled={!isAdmin}
              value={values.name}
              onChange={handleChange}
              error={Boolean(errors.name) && Boolean(touched.name)}
              onBlur={handleBlur}
              variant="outlined"
              inputProps={{ 'aria-label': 'Username' }}
              fullWidth
            />
            {errors.name && touched.name ? <div className={'error'}>{errors.name}</div> : null}
          </label>
        </Grid>
        <Grid item xs={12} lg={3}>
          <label htmlFor="userPass">
            <div style={{ display: 'flex' }}>
              <Typography variant="body1" color="textSecondary">
                Password:
              </Typography>
              <div
                className={'pass-info-icon'}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setInfoOpen(true);
                  setTimeout(() => {
                    setInfoOpen(false);
                  }, 3000);
                }}
              >
                i
                <div className={'pass-info-block'} style={isInfoOpen ? { display: 'block' } : {}}>
                  Your new password should contain 8+ characters with at least 1 number, 1 uppercase
                  letter, 1 lowercase and 1 special character
                </div>
              </div>
            </div>
            <TextField
              type={showPassword ? 'text' : 'password'}
              id="userPass"
              autoComplete="new-password"
              value={values.userPass}
              onChange={handleChange}
              error={Boolean(errors.userPass) && Boolean(touched.userPass)}
              onBlur={handleBlur}
              variant="outlined"
              inputProps={{ 'aria-label': 'Password' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {values.userPass && (
                      <Typography className={'password-toggler'} onClick={handleClickShowPassword}>
                        {showPassword ? 'hide' : 'show'}
                      </Typography>
                    )}
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
            {errors.userPass && touched.userPass ? (
              <div className={'error'}>{errors.userPass}</div>
            ) : null}
          </label>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Typography
            variant="body1"
            onClick={values.userPass ? setUpNewPass(setFieldValue, values, setFieldTouched) : null}
            color={values.userPass ? 'primary' : 'textSecondary'}
            className={'set-up-pass'}
            style={{ cursor: values.userPass ? 'pointer' : 'default' }}
          >
            Set up new password
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={3}>
          <label htmlFor="fullName">
            <Typography variant="body1" color="textSecondary">
              Full Name:
            </Typography>
            <TextField
              type="text"
              id="fullName"
              value={values.fullName}
              disabled={!isAdmin}
              onChange={handleChange}
              error={Boolean(errors.fullName) && Boolean(touched.fullName)}
              onBlur={handleBlur}
              variant="outlined"
              inputProps={{ 'aria-label': 'Full Name' }}
              fullWidth
            />
            {errors.fullName && touched.fullName ? (
              <div className={'error'}>{errors.fullName}</div>
            ) : null}
          </label>
        </Grid>
        <Grid item xs={12} lg={3}>
          <label htmlFor="email">
            <Typography variant="body1" color="textSecondary">
              Email:
            </Typography>
            <TextField
              type="text"
              id="email"
              autoComplete="false"
              value={values.email}
              error={Boolean(errors.email) && Boolean(touched.email)}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              inputProps={{ 'aria-label': 'Email' }}
              fullWidth
            />
            {errors.email && touched.email ? <div className={'error'}>{errors.email}</div> : null}
          </label>
        </Grid>
        <Grid item xs={12} lg={3}>
          <label htmlFor="phoneNumber">
            <Typography variant="body1" color="textSecondary">
              Phone Number:
            </Typography>
            <TextField
              type="text"
              id="phoneNumber"
              value={values.phoneNumber}
              error={Boolean(errors.phoneNumber) && Boolean(touched.phoneNumber)}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              inputProps={{ 'aria-label': 'Phone number' }}
              fullWidth
            />
            {errors.phoneNumber && touched.phoneNumber ? (
              <div className={'error'}>{errors.phoneNumber}</div>
            ) : null}
          </label>
        </Grid>
      </Grid>

      <Divider xs={9} className={'settings-devider'} />

      <Grid container spacing={3}>
        <Grid item xs={12} lg={3}>
          <label htmlFor="employeeNum">
            <Typography variant="body1" color="textSecondary">
              Employee Number:
            </Typography>
            <TextField
              type="text"
              disabled={!isAdmin}
              id="employeeNum"
              value={values.employeeNum}
              onChange={handleChange}
              error={Boolean(errors.employeeNum) && Boolean(touched.employeeNum)}
              onBlur={handleBlur}
              variant="outlined"
              inputProps={{ 'aria-label': 'Employee number' }}
              fullWidth
            />
            {errors.employeeNum && touched.employeeNum ? (
              <div className={'error'}>{errors.employeeNum}</div>
            ) : null}
          </label>
        </Grid>
        <Grid item xs={12} lg={3}>
          <label htmlFor="HICode">
            <Typography variant="body1" color="textSecondary">
              Health Insurance Code:
            </Typography>
            <HICodes
              value={values.HICode}
              changeValue={(code) => setFieldValue('HICode', code)}
              handleBlur={handleBlur}
              errors={errors}
              touched={touched}
              openSnackbar={openSnackbar}
              disabled={!isAdmin}
            />
          </label>
        </Grid>
        <Grid item xs={12} lg={3}>
          <label htmlFor="timeOfDay">
            <Typography variant="body1" color="textSecondary">
              Shift:
            </Typography>
            <TextField
              select
              disabled={!isAdmin}
              type="text"
              id="timeOfDay"
              name="timeOfDay"
              value={values.timeOfDay === 'Inactive' ? 'Day' : values.timeOfDay}
              onChange={handleChange}
              variant="outlined"
              inputProps={{ 'aria-label': 'Shift' }}
              fullWidth
              SelectProps={{
                native: isIOS,
              }}
            >
              <Option value="Day">
                {isIOS ? '🌤 ' : <img src={IconSunny} alt="sunny" className={'shift-image'} />}
                Day
              </Option>
              <Option value="Night">
                {isIOS ? '🌙 ' : <img src={IconMoon} alt="moon" className={'shift-image'} />}
                Night
              </Option>
            </TextField>
          </label>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={3}>
          <label htmlFor="weekDays">
            <Typography variant="body1" color="textSecondary">
              Days:
            </Typography>
            <TextField
              select
              disabled={!isAdmin}
              id="weekDays"
              name="weekDays"
              value={values.weekDays}
              onChange={handleChange}
              variant="outlined"
              inputProps={{ 'aria-label': 'Days' }}
              SelectProps={{
                native: isIOS,
              }}
              fullWidth
            >
              <Option value="Mon-Fri">Mon &mdash; Fri</Option>
              <Option value="Sun-Thu">Sun &mdash; Thu</Option>
              <Option value="Tue-Sat">Tue &mdash; Sat</Option>
            </TextField>
          </label>
        </Grid>
        <Grid item xs={12} lg={3}>
          <label htmlFor="allowedTime">
            <Typography variant="body1" color="textSecondary">
              PTO Hours Per Year:
            </Typography>
            <TextField
              type="number"
              id="allowedTime"
              disabled={!isAdmin}
              value={values.allowedTime}
              onChange={handleChange}
              error={Boolean(errors.allowedTime) && Boolean(touched.allowedTime)}
              onBlur={handleBlur}
              variant="outlined"
              inputProps={{ 'aria-label': 'PTO hours per year' }}
              fullWidth
            />
            {errors.allowedTime && touched.allowedTime ? (
              <div className={'error'}>{errors.allowedTime}</div>
            ) : null}
          </label>
        </Grid>
      </Grid>

      {values.emergencyContacts && (
        <>
          <Divider xs={9} className={'settings-devider'} />
          <Typography variant="h5" style={{ marginBottom: '25px' }}>
            Emergency contacts
          </Typography>
          {values.emergencyContacts.map((el, i) => (
            <div key={el._id || `emergencycontact_${i}`} className={'emergency-block'}>
              <Grid container spacing={3} alignItems={'center'}>
                <Grid
                  item
                  xs={12}
                  lg={9}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <Typography variant="h6">{el.relationship}</Typography>
                  <Grid>
                    <img src={EditIcon} alt="edit" className={'edit-contact'} onClick={openDrawer('edit', el, i)} />
                    <IconButton onClick={deleteContact(setFieldValue, el.id, i, values)}>
                      <CrossIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={3} alignItems={'center'}>
                <Grid item xs={12} lg={3}>
                  <label htmlFor={`emergencyContacts[${i}].name`}>
                    <Typography variant="body1" color="textSecondary">
                      Name:
                    </Typography>
                    <TextField
                      type="text"
                      disabled
                      id={`emergencyContacts[${i}].name`}
                      value={values.emergencyContacts[i].name}
                      onChange={handleEmergencyName(setFieldValue, el._id, i, values)}
                      onBlur={handleBlur}
                      variant="outlined"
                      inputProps={{ 'aria-label': 'Name' }}
                      fullWidth
                    />
                  </label>
                </Grid>

                <Grid item xs={12} lg={3}>
                  <label htmlFor={`values.emergencyContacts[${i}].phoneNumber`}>
                    <Typography variant="body1" color="textSecondary">
                      Phone number:
                    </Typography>
                    <TextField
                      type="text"
                      id={`values.emergencyContacts[${i}].phoneNumber`}
                      disabled
                      value={values.emergencyContacts[i].phoneNumber}
                      onChange={handleEmergencyPhoneNumber(setFieldValue, el._id, i, values)}
                      onBlur={handleBlur}
                      variant="outlined"
                      inputProps={{ 'aria-label': 'Phone number' }}
                      fullWidth
                    />
                  </label>
                </Grid>

                <Grid item xs={12} lg={3}>
                  <label htmlFor={`values.emergencyContacts[${i}].address`}>
                    <Typography variant="body1" color="textSecondary">
                      Address:
                    </Typography>
                    <TextField
                      type="text"
                      id={`values.emergencyContacts[${i}].address`}
                      disabled
                      value={values.emergencyContacts[i].address}
                      onChange={handleEmergencyAddress(setFieldValue, el._id, i, values)}
                      onBlur={handleBlur}
                      variant="outlined"
                      inputProps={{ 'aria-label': 'Address' }}
                      fullWidth
                    />
                  </label>
                </Grid>
              </Grid>
            </div>
          ))}
          {!isNewEmergencyBlock && (
            <Typography
              variant="body1"
              color="primary"
              onClick={openNewEmergencyBlock}
              style={{ cursor: 'pointer' }}
            >
              Add new emergency contact
            </Typography>
          )}
          {isNewEmergencyBlock && (
            <div className={'new-emergency-block'}>
              <Typography variant="h5" style={{ marginBottom: '25px' }}>
                New emergency contacts
              </Typography>
              <Grid container>
                <Grid container spacing={3} alignItems={'center'}>
                  <Grid item xs={12} lg={4}>
                    <label htmlFor="emergencyRelationship">
                      <Typography variant="body1" color="textSecondary">
                        Relationship:
                      </Typography>
                      <TextField
                        type="text"
                        id="emergencyRelationship"
                        value={values.emergencyRelationship || ''}
                        onChange={handleNewEmergencyRelationship(setFieldValue)}
                        onBlur={handleBlur}
                        error={Boolean(emergencyError.emergencyRelationship)}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'emergencyRelationship' }}
                        fullWidth
                      />
                      {emergencyError.emergencyRelationship ? (
                        <div className={'error'}>{emergencyError.emergencyRelationship}</div>
                      ) : null}
                    </label>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={4}>
                    <label htmlFor="emergencyName">
                      <Typography variant="body1" color="textSecondary">
                        Name:
                      </Typography>
                      <TextField
                        type="text"
                        id="emergencyName"
                        value={values.emergencyName || ''}
                        onChange={handleNewEmergencyName(setFieldValue)}
                        onBlur={handleBlur}
                        error={Boolean(emergencyError.emergencyName)}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'emergencyName' }}
                        fullWidth
                      />
                      {emergencyError.emergencyName ? (
                        <div className={'error'}>{emergencyError.emergencyName}</div>
                      ) : null}
                    </label>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <label htmlFor="emergencyPhone">
                      <Typography variant="body1" color="textSecondary">
                        Phone number:
                      </Typography>
                      <TextField
                        type="text"
                        id="emergencyPhone"
                        value={values.emergencyPhone || ''}
                        onChange={handleNewEmergencyPhoneNumber(setFieldValue)}
                        error={Boolean(emergencyError.emergencyPhone)}
                        onBlur={handleBlur}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'emergencyPhone' }}
                        fullWidth
                      />
                      {emergencyError.emergencyPhone ? (
                        <div className={'error'}>{emergencyError.emergencyPhone}</div>
                      ) : null}
                    </label>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <label htmlFor="emergencyAdress">
                      <Typography variant="body1" color="textSecondary">
                        Address:
                      </Typography>
                      <TextField
                        type="text"
                        id="emergencyAdress"
                        value={values.emergencyAdress || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        inputProps={{ 'aria-label': 'emergencyAdress' }}
                        fullWidth
                      />
                    </label>
                  </Grid>
                </Grid>
                <div className={'emergency-btns-block'}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    onClick={closeNewEmergencyBlock(setFieldValue, errors, setFieldTouched)}
                    style={{
                      cursor: 'pointer',
                      padding: '15px 15px 0',
                    }}
                  >
                    Cancel
                  </Typography>
                  <Typography
                    variant="body1"
                    color="primary"
                    onClick={addNewEmergencyContact(
                      setFieldValue,
                      values,
                      errors,
                      setFieldTouched,
                      validateForm,
                      validateField
                    )}
                    style={{
                      cursor: 'pointer',
                      padding: '15px 15px 0',
                    }}
                  >
                    Add
                  </Typography>
                </div>
              </Grid>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default UserDetails;
