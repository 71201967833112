import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Controller, useFormContext, useController } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { isIOS, Option } from 'components/Option';
import styles from './styles';

const WeekShift = ({ classes, control, pageType }) => {
  const settings = useSelector((state) => state.settings.settings || {});

  const { field: { onChange: onWeekStartChange } } = useController({
    name: 'weekStart',
    control,
    defaultValue: settings?.weekStart || 'Sun',
  });
  const { field: { onChange: onInjuryReportChange } } = useController({
    name: 'injuryReport',
    control,
    defaultValue: settings?.injuryReport || false,
  });

  const { field: { onChange: onMiscStipendChange } } = useController({
    name: 'miscStipend',
    control,
    defaultValue: settings?.miscStipend || false,
  });
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue('weekStart', settings?.weekStart || 'Sun');
    setValue('injuryReport', settings?.injuryReport || false);
    setValue('miscStipend', settings?.miscStipend || false);
  }, [settings]);

  return (
    <Grid container item xs={12} spacing={5}>
      <Grid item xs={12}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant="h4">Week Shift</Typography>
          </Grid>

          {pageType === 'edit' ? (
            <Grid item xs={12} md={6} lg={3}>
              <label htmlFor="weekStart">
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classes.regularFontWeight}
                >
                  Select Week Shift:
                </Typography>
                <Controller
                  name="weekStart"
                  control={control}
                  defaultValue={settings?.weekStart || 'Sun'}
                  as={
                    <TextField
                      select
                      id="weekStart"
                      name="weekStart"
                      variant="outlined"
                      onChange={onWeekStartChange}
                      className={classes.textField}
                      inputProps={{ 'aria-label': 'weekStart' }}
                      SelectProps={{
                        native: isIOS,
                      }}
                      style={{ width: '250px' }}
                    >
                      <Option key={'Sun'} value={'Sun'}>
                        Sun - Sat
                      </Option>
                      <Option key={'Mon'} value={'Mon'}>
                        Mon - Sun
                      </Option>
                    </TextField>
                  }
                />
              </label>
            </Grid>
          ) : (
            <Grid item xs={12} md={6} lg={3}>
              <label htmlFor="weekStart">
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classes.regularFontWeight}
                >
                  Selected Week Shift:
                </Typography>
                <Typography variant="h6">
                  {settings?.weekStart === 'Mon' ? 'Monday - Sunday' : 'Sunday - Saturday'}
                </Typography>
              </label>
            </Grid>
          )}

          <Grid item xs={12} md={6} lg={3} style={{ alignSelf: 'center' }}>
            <label htmlFor="miscStipend" style={{ display: 'flex', alignItems: 'center' }}>
              <Controller
                name="miscStipend"
                control={control}
                defaultValue={false}
                type="checkbox"
                render={(value) => {
                  return (
                    <Checkbox
                      {...value}
                      checked={!!value?.value}
                      onChange={(e) => onMiscStipendChange(e.target.checked)}
                      color="primary"
                      disabled={pageType !== 'edit'}
                    />
                  );
                }}
              />
              <Typography variant="body1">
                Miscellaneous Stipend
              </Typography>
            </label>
          </Grid>

          <Grid item xs={12} md={6} lg={3} style={{ alignSelf: 'center' }}>
            <label htmlFor="injuryReport" style={{ display: 'flex', alignItems: 'center' }}>
              <Controller
                name="injuryReport"
                control={control}
                defaultValue={false}
                type="checkbox"
                render={(value) => {
                  return (
                    <Checkbox
                      {...value}
                      checked={!!value?.value}
                      onChange={(e) => onInjuryReportChange(e.target.checked)}
                      color="primary"
                      disabled={pageType !== 'edit'}
                    />
                  );
                }}
              />
              <Typography variant="body1">
                Injury Report
              </Typography>
            </label>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default withStyles(styles)(WeekShift);
