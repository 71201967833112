import $api from 'http/index';
import { getOptions } from 'helpers/getOptions';
import { MODAL_TYPES } from '../constants';

export const userWorklogsDayHours = async (sheetId, workerId) => {
  try {
    const res = await $api.get(
      `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/workers/${workerId}/day-hours`,
      {
        ...getOptions(),
      }
    );
    return res.data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const postDuplicate = async (data, sheetId, setModal, openSnackbar) => {
  try {
    await $api.post(
      `${process.env.REACT_APP_BASE_URL}/sheets/${sheetId}/duplicate`,
      { from: data.period.from, to: data.period.to },
      {
        ...getOptions(),
      }
    );
    openSnackbar('success', 'Work Log has been duplicated successfully');
    setModal({ type: MODAL_TYPES.calendar, isOpen: false, data: null });
  } catch (error) {
    if (error?.response?.data?.type === 'duplication-conflicts') {
      if (
        error.response.data.data.some(
          (worklog) => worklog?.workerTimeoffs && worklog?.workerTimeoffs.length
        )
      ) {
        return setModal({
          type: MODAL_TYPES.timeoff,
          isOpen: true,
          data: error?.response?.data?.data,
        });
      }
      setModal({ type: MODAL_TYPES.conflict, isOpen: true, data: error?.response?.data?.data });
    }
    openSnackbar('error', error?.response?.data?.message || error.message);
  }
};

export const putConflictActionResources = async (data) => {
  await $api.put(
    `${process.env.REACT_APP_BASE_URL}/sheets/resources`,
    { data: data },
    {
      ...getOptions(),
    }
  );
};
