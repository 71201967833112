import React, { Component } from 'react';
import { startOfDay, endOfDay, differenceInMonths, format } from 'date-fns';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import './styles.css';
import classnames from 'classnames';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { connect } from 'react-redux';
import { getWeekStartDayNumO } from 'helpers/_helpers';
import { momentAdepter } from 'common/dateFormatConfig';

class DatePickerRange extends Component {
  state = {
    from: this.props.dates[0],
    to: this.props.dates[1],
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.to !== this.state.to || prevState.from !== this.state.from) {
      this.props.setFieldValue('dates', [this.state.from, this.state.to]);
    }
    if (prevProps.dates !== this.props.dates) {
      this.setState({
        from: this.props.dates[0],
        to: this.props.dates[1],
      });
    }
  }

  showFromMonth = () => {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (differenceInMonths(new Date(to), new Date(from)) < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  };

  handleFromChange = (from) => {
    this.setState({ from: startOfDay(from) });
  };

  handleToChange = (to) => this.setState({ to: endOfDay(to) }, this.showFromMonth);

  getDate = (field) => {
    if (field === 'from') {
      const date1 = format(new Date(this.state.from), this.props.dateFormat);
      const date2 = format(new Date(this.props.dates[0]), this.props.dateFormat);
      return date1 !== date2 ? this.props.dates[0] : this.state.from;
    } else {
      const date1 = format(new Date(this.state.to), this.props.dateFormat);
      const date2 = format(new Date(this.props.dates[1]), this.props.dateFormat);
      return date1 !== date2 ? this.props.dates[1] : this.state.to;
    }
  };

  render() {
    const { from, to } = this.state;
    const { reinitialize, isFilterDrawer, weekStart } = this.props;
    const modifiers = { start: from, end: to };

    return (
      <div className={classnames('InputFromTo', isFilterDrawer && 'drawerFilters')}>
        <DayPickerInput
          value={reinitialize ? this.getDate('from') : from}
          placeholder="From"
          formatDate={formatDate}
          parseDate={parseDate}
          format={momentAdepter(this.props.dateFormat).momentShortDayDate}
          inputProps={{
            readOnly: 'true',
          }}
          dayPickerProps={{
            firstDayOfWeek: getWeekStartDayNumO(weekStart),
            selectedDays: [from, { from, to }],
            disabledDays: { after: to },
            toMonth: to,
            modifiers,
            numberOfMonths: 1,
            onDayClick: () => this.to.getInput().focus(),
            canChangeMonth: !this.props.noMonthChange,
          }}
          onDayChange={this.handleFromChange}
        />{' '}
        —{' '}
        <div className={classnames('InputFromTo-to', isFilterDrawer && 'drawerFilters')}>
          <DayPickerInput
            ref={(el) => (this.to = el)}
            value={reinitialize ? this.getDate('to') : to}
            placeholder="To"
            formatDate={formatDate}
            parseDate={parseDate}
            format={momentAdepter(this.props.dateFormat).momentShortDayDate}
            inputProps={{
              readOnly: 'true',
            }}
            dayPickerProps={{
              firstDayOfWeek: getWeekStartDayNumO(weekStart),
              selectedDays: [from, { from, to }],
              disabledDays: { before: from },
              modifiers,
              month: from,
              fromMonth: from,
              numberOfMonths: 1,
              canChangeMonth: !this.props.noMonthChange,
            }}
            onDayChange={this.handleToChange}
          />
        </div>
      </div>
    );
  }
}

// export default DatePickerRange;
export default connect((state) => ({
  weekStart: state.settings?.settings?.weekStart || 'Sun',
  dateFormat: state.personalProfile.organization?.settings?.dateFormat,
}))(DatePickerRange);
