import { createAction } from '@reduxjs/toolkit';

const setDate = createAction('availability/setDate');
const setTimeoffsByType = createAction('availability/setTimeoffsByType');
const setAllUsers = createAction('availability/setAllUsers');
const setEquipmentEvents = createAction('availability/setEquipmentEvents');
const setAllEquipments = createAction('availability/setAllEquipments');
const setHolidays = createAction('availability/setHolidays');

export default {
  setDate,
  setTimeoffsByType,
  setAllUsers,
  setEquipmentEvents,
  setAllEquipments,
  setHolidays,
};
