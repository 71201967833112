export default theme => ({
  paddingBottom: {
    paddingBottom: 10,
  },
  equipmentBlock: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '17px',
    width: 'max-content',
    maxWidth: '100%',
    height: 24
  },
  equipmentNumber: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: theme.palette.textColor.white,
    padding: '3px 6px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  inlineFlex: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  hoursBlock: {
    height: '100%',
    width: '100%',
    justifyContent: 'flex-end',
  },
});
