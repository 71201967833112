import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import kioskPageActions from 'store/kiosk/kioskPageActions';
import Box from '../../components/Field';
import Kiosk from '../../components/Kiosk';
import { useKioskUser } from './hooks/useKioskUser';
import './styles.scss';
import $api from 'http/index';
import { getOptions } from 'helpers/getOptions';
import { removeKey } from 'helpers/localStorage';

export const MobileKiosk = () => {
  const dispatch = useDispatch();
  const { user, authorize, logout, error: authError, clearError } = useKioskUser();

  const [userList, setUserList] = useState([]);

  const logoutFromStore = () => {
    removeKey('x-auth-kiosk-token')
    dispatch(kioskPageActions.logout())
    dispatch(kioskPageActions.clearPersonalProfile())
  };


  useEffect(() => {
    logoutFromStore();

    const apiCall = async () => {
      try {
        const response = await $api.get(`${process.env.REACT_APP_BASE_URL}/users?isKiosk=true`, getOptions());
        setUserList(response.data);
      } catch (e) {
        console.log(e);
      }
    };
    apiCall();
    return () => logoutFromStore()
  }, []);

  return (
    <Box display="flex" flexDirection="column" className="kioskModeWrapper">
      <Box
        className="page-width fullTab"
      >
        <Box style={{ padding: '0 15px', boxSizing: 'border-box', maxWidth: '1280px', margin: '0 auto' }}>
          <Kiosk
            logoutFromStore={logoutFromStore}
            authError={authError}
            clearAuthError={clearError}
            selectedUser={user}
            userList={userList}
            authorize={authorize}
            logout={logout}
          />
        </Box>
      </Box>
    </Box>
  );
};
