import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IOSSwitch from 'components/IOSSwitch';
import classnames from 'classnames';
import styles from './styles';

export const onDSLogin = (e) => {
  const callbackUrl = `${window.location.origin}/home`;
  const testLink =
    `${process.env.REACT_APP_DOCUSIGN_PATH}/oauth/auth?response_type=code&scope=impersonation%20signature` +
    `&client_id=${process.env.REACT_APP_DOCUSIGN_API_INTEGRATION_KEY}&redirect_uri=${callbackUrl}`;
  window.open(testLink);
};

const WorkShifts = ({ classes, scheduleNotifications, handleChangeScheduleNotifications }) => {

  return (
    <Grid container item alignItems="center" className={classes.workShiftBlock}>
      <Grid item xs={12} md={6}>
        <Typography variant="h4">Work Shifts</Typography>
      </Grid>
      <Grid item xs={12} md={4} className={classes.scheduleNotificationBlock}>
        <span className={classnames(classes.secondaryText, classes.inlineBlock)}>
          Update Schedule Notifications:
        </span>
        <IOSSwitch
          name="updateScheduleNotifications"
          checked={scheduleNotifications || false}
          onChange={handleChangeScheduleNotifications}
          value={scheduleNotifications || false}
          small
        />
      </Grid>
    </Grid>
  );
};

WorkShifts.propTypes = {
  classes: propTypes.object.isRequired,
  scheduleNotifications: propTypes.bool.isRequired,
  handleChangeScheduleNotifications: propTypes.func.isRequired,
};

export default withStyles(styles)(WorkShifts);
