const asyncTimeout = async (time = 1000) => new Promise((res) => setTimeout(res, time))

export const downloadFile = (blob, filename) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', filename);

  document.body.appendChild(link);

  link.click();

  link.parentNode.removeChild(link);
};

/**
 * @param {[{ blob: Blob, name: string }]} files array of files, file is a base64 string
*/
export const downloadMultipleFiles = async (files) => {
  if (!Array.isArray(files) || !files?.length) return;

  const link = document.createElement('a');
  document.body.appendChild(link);

  for (const file of files) {
    const url = window.URL.createObjectURL(file.blob);
    link.setAttribute('href', url);
    link.setAttribute('download', file.name);

    link.click();
    await asyncTimeout(200) // wait;
  }
  
  link.parentNode.removeChild(link);
}
