import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { parse, format, addDays } from 'date-fns';
import cn from 'classnames';

import PageLoader from '../../../../components/PageLoader';
import HolidayDetails from './components/HolidayDetails';
import styles from './styles';
import amStyles from '../AvailabilityCalendar/components/AssignModal/styles';
import { getClientTzOffset } from '../../../../helpers/_helpers';
import { useForm } from 'react-hook-form';
import AssignModal from './components/AssignModal';
import AddIcon from '@material-ui/icons/Add';
import DialogActions from '@material-ui/core/DialogActions';
import { getOptions } from 'helpers/getOptions';
import $api from 'http/index';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { globalBEDateFormat } from 'common/dateFormatConfig';

const useAMStyles = makeStyles(amStyles);

const HolidayModal = ({
  classes,
  closeModal,
  holiday,
  defaultIndividual,
  openSnackbar,
  toggleRefreshHoliday,
}) => {
  const { dateFormat } = useDateSettingsFormat();

  const [selectedResources, setSelectedResources] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [assignModalOpened, setAssignModalOpened] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const {
    getValues,
    setValue,
    formState: { errors },
    control,
    watch,
    handleSubmit,
    register,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      individual: defaultIndividual ? 'true' : 'false',
      endDate: format(addDays(new Date(), 1), dateFormat),
    },
  });
  const watchHolidayType = watch('holidayType');
  const watchIndividual = watch('individual');
  const amClasses = useAMStyles();

  const handleChangeHolidayType = () => {
    const format = dateFormat;
    const value = format(addDays(parse(getValues().startDate, format, new Date()), 1), format);
    setValue('endDate', value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  useEffect(() => {
    if (!holiday || initialized) return;
    setTimeout(() => {
      setValue('holidayType', holiday.startDate === holiday.endDate ? 'oneDay' : 'period');
      setValue('name', holiday.name);
      setValue('isPaid', holiday.isPaid);
      setValue(
        'startDate',
        format(parse(holiday.startDate, globalBEDateFormat, new Date()), dateFormat)
      );
      // setValue('startDate', holiday.startDate);
      setValue(
        'endDate',
        format(parse(holiday.endDate, globalBEDateFormat, new Date()), dateFormat)
      );
      // setValue('endDate', holiday.endDate);
      setValue('hours', holiday.hours || '8');
      setValue('minutes', holiday.minutes || '00');
      setValue('individual', holiday.isIndividual ? 'true' : 'false');
      setSelectedResources((holiday.userIds || []).map((id) => ({ id })));
    }, 0);
    setInitialized(true);
  }, [holiday, initialized]);

  useEffect(() => setSelectedResources([]), [watchIndividual]);

  const setSubmitting = (d) => setIsSubmitting(d);

  useEffect(() => {
    if (isSubmitting) {
      handleSubmitL(isSubmitting);
    }
  }, [isSubmitting]);

  const handleSubmitL = async (values) => {
    const data = {
      isIndividual: values.individual === 'true',
      name: values.name,
      isPaid: values.isPaid,
      utcStartDate: parse(values.startDate, dateFormat, new Date()).toISOString(),
    };
    if (values.holidayType === 'oneDay') {
      data.hours = +values.hours;
      data.minutes = +values.minutes;
      data.utcEndDate = parse(values.startDate, dateFormat, new Date()).toISOString();
    } else {
      data.utcEndDate = parse(values.endDate, dateFormat, new Date()).toISOString();
    }
    data.userIds = selectedResources.map((r) => r.id) || [];

    if (holiday) {
      try {
        await $api.put(
          `${process.env.REACT_APP_BASE_URL}/holidays/${holiday._id}`,
          data,
          getOptions()
        );
        toggleRefreshHoliday();
        openSnackbar('success', 'Holiday was updated successfully');
        closeModal();
      } catch (e) {
        openSnackbar('error', e?.response?.data?.message || e.message);
      }
    } else {
      try {
        await $api.post(`${process.env.REACT_APP_BASE_URL}/holidays`, data, getOptions());
        toggleRefreshHoliday();
        openSnackbar('success', 'Holiday was created successfully');
        closeModal();
      } catch (e) {
        openSnackbar('error', e?.response?.data?.message || e.message);
      }
    }
    setIsSubmitting(false);
  };

  const openAssignModal = () => setAssignModalOpened(true);
  const closeAssignModal = () => setAssignModalOpened(false);

  const canSubmit =
    Object.keys(errors).length === 0 &&
    (watchIndividual !== 'true' || selectedResources.length !== 0);

  return (
    <Dialog
      open
      onClose={closeModal}
      PaperProps={{
        classes: {
          root: cn(classes.dialogRoot, classes.holiday),
        },
      }}
      backdropclick
      disableEscapeKeyDown
    >
      <div className={classes.formWrapper} role="presentation">
        <PageLoader loading={!!isSubmitting} small>
          <form autoComplete="off" className={classes.formFlex}>
            <div>
              <Typography className={classes.headerText}>
                {holiday ? 'Edit' : 'Add'} Holiday/Company Time Off
              </Typography>

              <IconButton
                className={classes.closeButton}
                onClick={closeModal}
                aria-label="Close"
                disableTouchRipple
              >
                <CloseIcon />
              </IconButton>

              <HolidayDetails
                errors={errors}
                handleChangeHolidayType={handleChangeHolidayType}
                control={control}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
              />
            </div>

            {
              <div
                className={cn(
                  classes.assignHolder,
                  watchHolidayType === 'period' ? classes.assignHolderSm : ''
                )}
              >
                <Button
                  color="primary"
                  variant="text"
                  className={cn(amClasses.extraBtn, amClasses.assignBtn)}
                  onClick={openAssignModal}
                  disableTouchRipple
                >
                  <AddIcon /> {watchIndividual === 'false' ? 'Exclude' : 'Assign'} People
                </Button>
                <Typography color="secondary" className={classes.selectedText}>
                  {selectedResources.length ? `${selectedResources.length} people selected` : ''}
                </Typography>
              </div>
            }

            <DialogActions classes={{ root: amClasses.rootActions }}>
              <Button
                color="secondary"
                variant="outlined"
                className={amClasses.cancelBtn}
                onClick={closeModal}
                disabled={!!isSubmitting}
                disableTouchRipple
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="outlined"
                className={amClasses.confirmBtn}
                onClick={handleSubmit(setSubmitting)}
                disabled={!!isSubmitting || !canSubmit}
                disableTouchRipple
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </PageLoader>
      </div>
      {assignModalOpened && (
        <AssignModal
          selectedResources={selectedResources}
          setSelectedResources={setSelectedResources}
          handleClose={closeAssignModal}
          small={watchHolidayType === 'period'}
          isIndividual={watchIndividual === 'true'}
        />
      )}
    </Dialog>
  );
};

HolidayModal.propTypes = {
  classes: propTypes.object.isRequired,
  closeModal: propTypes.func.isRequired,
  openSnackbar: propTypes.func.isRequired,
};

export default withStyles(styles)(HolidayModal);
