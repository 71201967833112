export default theme => ({
  cellContent: {
    width: 177,
    height: 193,
    zIndex: 0
  },
  dayNumber: {
    fontSize: 30,
    lineHeight: 1.5,
    color: theme.palette.textColor.secondary,
    padding: '0 5px'
  },
  todayNumber: {
    color: theme.palette.textColor.primary
  },
  absolute: {
    position: 'absolute',
    height: 'auto',
    border: '1px solid #dadbe3',
    borderTop: 'none',
    width: 'calc(100% + 2px)',
    left: '-1px',
    top: 0,
    backgroundColor: theme.palette.background.default,
    zIndex: 20,
    padding: '0 1px 0 0'
  },
  padding: {
    padding: '0 5px'
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 0 10px',
    justifyContent: 'space-between'
  },
  equipmentBlock: {
    borderRadius: '17px',
    minWidth: 34,
    height: 21,
    color: '#fff',
    maxWidth: 140
  },
  equipmentNumber: {
    height: 19,
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Poppins',
    padding: '3px 6px 0',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  actionBtn: {
    textTransform: 'none',
    padding: 0,
    minWidth: 15,
    minHeight: 15,
    marginLeft: 5,
    '&:hover': {
      color: 'inherit',
      backgroundColor: 'inherit !important',
    },
    '&:focus': {
      color: 'inherit',
      backgroundColor: 'inherit !important',
    },
    '& img': {
      height: 12
    }
  },
  list: {
    maxHeight: 118,
    overflow: 'hidden',
    padding: '0 5px'
  },
  othersBtn: {
    padding: 0,
    margin: '0 5px',
    minWidth: 'auto',
    textTransform: 'none',
    color: theme.palette.textColor.primary,
    fontSize: 14,
    fontWeight: 500,
    '&:hover': {
      color: theme.palette.textColor.primary,
      backgroundColor: 'inherit !important',
    },
    '&:focus': {
      color: theme.palette.textColor.primary,
      backgroundColor: 'inherit !important',
    }
  },
  visible: {
    maxHeight: 'none'
  },
  borderBottom: {
    paddingBottom: 10
  },
  fullWidth: {
    maxWidth: '100%'
  },
  greyBackground: {
    backgroundColor: theme.palette.background.lightGrey
  }
});
