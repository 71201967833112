import Sockets from 'helpers/sockets';

// TODO: To think, is it possible to make structure with inheritance for socket reducers
// To use this fabric actions object MUST contain redux actions 'socketConnect' and 'socketDisconnect'
export function subscriptionsFabric(actions, eventsEnum, handlers) {
  return (store) => {
    const namespace = eventsEnum.namespace;
    const eventKeys = Object.keys(eventsEnum).filter((key) => key !== 'namespace');
    const eventValues = eventKeys
      .map((key) => eventsEnum[key])
      .filter((event) => !!handlers[event] && typeof handlers[event] === 'function');
    // Save events in map to off exact listeners
    // so for same namespaces and same events there will be possibility to add multiple listeners
    const eventsMap = new Map();

    return (next) => async (action) => {
      if (actions.socketConnect?.match(action) && action.payload?.namespace === namespace) {
        const socket = Sockets.getSocket(namespace);

        if (!socket.connected) {
          socket.on('connect', () => {
            socket.emit(
              `${namespace}:join-room`,
              store.getState().personalProfile?.organization?._id
            );
          });

          // Try to reconnect. Off reconnection on disconnecting
          socket.on('disconnect', () => {
            setTimeout(() => {
              socket?.connect();
            }, 5000);
          });

          socket.on('connect_failed', (error) => {
            console.error(error);
          });
        }

        // add listeners only on 'socketConnect' action, so they will be added once
        if (!eventsMap.size) {
          for (const event of eventValues) {
            // for dynamic events
            if (/\{.*\}/.test(event)) {
              const { name, listener } = await handlers[event](store);

              socket.on(name, listener);
              eventsMap.set(name, listener);
            } else {
              const listener = handlers[event]?.(store);

              socket.on(event, listener);
              eventsMap.set(event, listener);
            }
          }
        }
      }

      // on disconnect (call action on useEffect(() => return () => [HERE]), []);
      if (actions.socketDisconnect?.match(action) && action.payload?.namespace === namespace) {
        const socket = Sockets.getSocket(namespace, true);
        // check via events map, so in case of multiple calling 'disconnect' event for same area/store ->
        // -> it will off other listeners and connection at all

        if (socket?.connected) {
          if (Sockets.getConsumers(namespace) === 1) {
            for (const [event, listener] of eventsMap) {
              if (!listener || !event) continue;
              socket.off(event, listener);
              eventsMap.delete(event);
            }
          }

          // It will off 'disconnect' and all 'connect' listeners
          Sockets.closeSocket(namespace);
        }
      }

      next(action);
    };
  };
}
