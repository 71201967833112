import { createReducer } from '@reduxjs/toolkit';
import availabilityPageActions from './availabilityPageActions';
import { format } from 'date-fns';

const initialState = {
  selectedDate: null,
  timeoffsByType: { approved: [], denied: [], requested: [] },
  holidays: [],
  users: null,
  equipmentEvents: [],
  equipment: null,
};

const availability = createReducer(initialState, {
  [availabilityPageActions.setDate]: (state, { payload }) => ({ ...state, selectedDate: payload }),
  [availabilityPageActions.setAllUsers]: (state, { payload }) => ({ ...state, users: payload }),
  [availabilityPageActions.setAllEquipments]: (state, { payload }) => ({ ...state, equipment: payload }),
  [availabilityPageActions.setHolidays]: (state, { payload }) => ({ ...state, holidays: payload }),
  [availabilityPageActions.setEquipmentEvents]: (state, { payload }) => ({ ...state, equipmentEvents: payload }),
  [availabilityPageActions.setTimeoffsByType]: (state, { payload }) => {
    return {
      ...state,
      timeoffsByType: payload,
    };
  },
});

export default availability;
