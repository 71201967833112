import React, { useEffect, useState, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';
import CloseIcon from 'assets/images/close-icon.svg'
import styles from './styles';

const MaterialCard = ({ classes, onDelete = () => null, data, onDublicate, ...props }) => {
  const materialCard = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isShowBtn, setIsShowBtn] = useState(false);

  const dataMaterials = useSelector(
    (state) => state.supplies && state.supplies.materials && state.supplies.materials.data
  );
  useEffect(() => {
    materialCard.current.clientHeight > 155 ? setIsShowBtn(true) : setIsShowBtn(null);
  }, [materialCard, dataMaterials]);
  return (
    <div className={classes.root} {...props}>
      <div className={classes.header}>
        <span className={classes.title}>{data && data.name}</span>
        <Button
          color="secondary"
          className={classes.deleteButton}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDelete(data);
          }}
          disableRipple
        >
          <img src={CloseIcon} alt="delete" />
        </Button>
        <hr className={classes.divider} />
      </div>
      <div
        className={classes.body}
        style={isOpen ? { maxHeight: 'none' } : { maxHeight: '155px', overflow: 'hidden' }}
      >
        <dl className={classes.definitionList} ref={materialCard}>
          <dt className={classes.definitionTitle}>Unit of Measurement:</dt>
          <dd className={classes.definitionDefinition}>
            {(data && data.type === 'weight' && 'Weight (Linear Feet (LF)/Pound)') ||
              (data && data.type === 'volume' && 'Volume (Linear Feet (LF) / Gallon)') ||
              (data && data.type === 'each' && 'Each')}
          </dd>
          {data && data.lines && Boolean(data.lines.length) && (
            <>
              <dt className={classes.definitionTitle}>Line:</dt>
              <dl style={{ display: 'flex', flexWrap: 'wrap' }}>
                {data &&
                  data.lines &&
                  data.lines.map((elem) => (
                    <dd
                      className={classes.definitionDefinition}
                      key={elem._id}
                      style={{ marginRight: '6px' }}
                    >
                      {`${elem.width}" - ${elem.ratio} LF/${
                        data.type === 'volume' ? 'Gal;' : 'Lbs;'
                      }`}
                      &#32;
                    </dd>
                  ))}
              </dl>
            </>
          )}
          {data && data.legends && Boolean(data.legends.length) && (
            <>
              <dt className={classes.definitionTitle}>Legend Specifications:</dt>
              <dl style={{ display: 'flex', flexWrap: 'wrap' }}>
                {data &&
                  data.legends &&
                  data.legends.map((elem) => (
                    <dd
                      className={classes.definitionDefinition}
                      key={elem._id}
                      style={{ marginRight: '6px' }}
                    >
                      {`${elem.symbol};`}
                    </dd>
                  ))}
              </dl>
            </>
          )}
          {data && data.letterings && Boolean(data.letterings.length) && (
            <>
              <dt className={classes.definitionTitle}>Lettering symbols:</dt>
              <dl style={{ display: 'flex', flexWrap: 'wrap' }}>
                {data &&
                  data.letterings &&
                  data.letterings.map((elem) => (
                    <dd
                      className={classes.definitionDefinition}
                      key={elem._id}
                      style={{ marginRight: '6px' }}
                    >
                      {`${elem.name};`}
                    </dd>
                  ))}
              </dl>
            </>
          )}
          {data && data.glassBeads && Boolean(data.glassBeads.length) && (
            <>
              <dt className={classes.definitionTitle}>Link of Reflective Media:</dt>
              <dl style={{ display: 'flex', flexWrap: 'wrap' }}>
                {data &&
                  data.glassBeads &&
                  data.glassBeads.map((elem) => (
                    <dd
                      className={classes.definitionDefinition}
                      key={elem._id}
                      style={{ marginRight: '6px' }}
                    >{`${elem.ratio}' LF/${elem.unitType === 'lbs/gal' ? 'G;' : 'Lbs;'}`}</dd>
                  ))}
              </dl>
            </>
          )}
        </dl>
      </div>
      <hr className={classes.divider} />
      <div
        className={classes.buttonsWrapper}
        style={isShowBtn ? { justifyContent: 'space-between' } : { justifyContent: 'flex-end' }}
      >
        {isShowBtn && (
          <div
            className={classes.actionsWrapper}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsOpen((prev) => !prev);
            }}
          >
            <p className={classes.textIconMore}>{isOpen ? 'Show less' : 'Show more'}</p>
            <div className={isOpen ? classes.nonActionsIcon : classes.actionsIcon}>
              <ExpandMoreIcon />
            </div>
          </div>
        )}

        <button
          className={classes.dublicateButton}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDublicate(data._id);
          }}
        >
          <span className={classes.dublicateButtonTitle}>Duplicate material</span>
        </button>
      </div>
    </div>
  );
};

export default withStyles(styles)(MaterialCard);
